import React from "react";

import TableElement from "../TableElement";
import { SAVED_SEARCHES_CONFIG } from "./SavedSearches.config";
import ShowModalwindow from "./ShowModalWindowDelete";
import PageHeading from "../PageHeading";
import { ContentLoader } from "../ContentLoader";
import EmptyState from "../EmptyState";
import Pagination from "../Pagination";
import LoaderModal from "../LoaderModal";
import ErrorState from "../ErrorState";

import global from "../../styles/globel/style.module.scss";
import style from "./style.module.scss";

//Saved search component
const SavedSearches = (props) => {
  return (
    <div className={style.savedSearch}>
      <ShowModalwindow {...props} />
      <PageHeading text={"Saved Searches"}>
        {!props.search.savedSearchLoading && !props.loading && (
          <div className={global.searchresultCount}>
            <span>
              {props.search?.saved?.length > 0
                ? "Showing " +
                props.search.saved.length +
                " out of " +
                props.search.savedSearchTotalCount +
                " Results"
                : ""}
            </span>
          </div>
        )}
      </PageHeading>
      {props.search.savedSearchLoading ? (
        <div className={style.savedSearch__tbldata}>
          <ContentLoader hasWrapper={true} height={1.5} number={9} />
        </div>
      ) : props.search.saved.length > 0 ? (
        <>
          <div className={style.savedSearch__tbldata}>
            <TableElement
              columns={SAVED_SEARCHES_CONFIG.COLUMNS({
                deleteHandler: props.handleDelete,
                loadHandler: props.handleSetSaved,
                exportHandler: props.exportHandler,
                setAsGroupDefaultQuery: props.setAsGroupDefaultQuery,
                defaultQueryId: props.defaultQueryId,
                userProfile: props.userProfile,
              })}
              data={props.search.saved}
              //  onChange={props.handleChange}
              scrollY={400}
            />
            {props.search.savedSearchTotalCount > 5 &&
              SavedSearchPagination({
                ...props.state.pagination,
                totalCount: props.search.savedSearchTotalCount,
                onChange: props.handleSavedSearchPagination,
              })}
          </div>
          {props.state.exportLoader && <LoaderModal />}
        </>
      ) : props.search.error ? (
        <ErrorState
          content="ERROR"
          type={"errorImage"}
          customHeight={"500"}
          errorMessage={props?.search?.errorMessage}
        />
      ) : (
        <EmptyState customHeight="10" type={"dashboardWelcomeImage"} />
      )}
    </div>
  );
};

export default SavedSearches;

function SavedSearchPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return (
    <>
      <Pagination
        pageSize={pageSize}
        current={pageNumber}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={true}
        pageSizeOptions={["5", "10", "20", "50"]}
      />
    </>
  );
}
