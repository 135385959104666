import React from 'react';
import PropTypes from 'prop-types';

import ButtonElement from '../../Components/ButtonElement';

import style from './style.module.scss';

export default class ConfirmationMessage extends React.Component {
  render() {
    return (
      <div className={style.confirmationMessage}>
        <h3>{this.props.heading}</h3>
        <p>{this.props.message}</p>
        {this.props.isAlert && (
          <div className={style.messageDeletion}>
            <span>Note:</span>
            <span>{this.props.alertInfo}</span>
          </div>
        )}
        <ul className={style.confirmationMessage__list}>
          <li hidden={this.props.hide}>
            <ButtonElement
              loading={this.props.loading}
              onClick={this.props.onCancel}
              children={this.props.cancelButtonLabel}
            />
          </li>
          <li>
            <ButtonElement onClick={this.props.onOk} children={this.props.confirmButtonLabel} type='primary' />
          </li>
        </ul>
      </div>
    );
  }
}

ConfirmationMessage.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  isAlert: PropTypes.bool,
  alertInfo: PropTypes.any,
  hide: PropTypes.any,
  loading: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
};

ConfirmationMessage.defaultProps = {
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Confirm',
};
