import moment from "moment";
import { formatDate } from "utils/commonUtils";

//AdvanceSearch payload create ----
export const createAdvanceSearchPayload = ({ data, pagination }) => {
  let payloadData = {
    filtersListforData: [],
  };
  let groupData = [];
  Object.keys(data || {}).map((group) => {
    groupData.push({
      key: {
        filterOperator: data[group]["isOR"] ?? true,
      },
      value: {
        filterGroupList: getFilterInAdvanceSearch(data[group]),
      },
    });
  });
  payloadData["filtersListforData"] = groupData;

  return payloadData;
};
const getFilterInAdvanceSearch = (data) => {
  let filterData = [];
  Object.keys(data || {})
    .filter((i) => i != "isOR")
    .map((filter) => {
      filterData.push({
        key: {
          filterOperator: data[filter]["isOR"] ?? true,
        },
        value: {
          soloFilter: getFacetInAdvanceSearch(data[filter]),
        },
      });
    });
  return filterData;
};
const getFacetInAdvanceSearch = (data) => {
  let facetData = [
    {
      key: {
        fieldName: data["key"],
      },
      value: {
        facetList: [],
      },
    },
  ];
  let facetValues = [];
  if (data.key?.toLowerCase().includes("date")) {
    handleDatePublishedPayloadHelper({ data, facetData, facetValues });
  } else if (data["facet"]) {
    Object.keys(data["facet"])?.map((facet) => {
      facetValues.push({
        key: data["facet"][facet]["isOR"] ?? true,
        value: data["facet"][facet].data
          ?.split("*")
          .filter((item) => item !== " ") || [""],
        options: data["facet"][facet].option || "",
      });
    });
    facetData[0]["value"]["facetList"] = facetValues;
  }
  return facetData;
};
//------

export const handleDatePublishedPayloadHelper = ({
  data,
  facetData,
  facetValues,
}) => {
  Object.keys(data["facet"]).map((facet) => {
    facetValues.push({
      key: data["facet"][facet]["isOR"] ?? true,
      value: handlePayloadTypeSelector(data["facet"][facet])?.split(",") || [
        "",
      ],
      options: "",
    });
  });
  return (facetData[0]["value"]["facetList"] = facetValues);
};

const handlePayloadTypeSelector = (data) => {
  let payload;
  let string = "";
  if (data.data == "lastNdays") {
    payload = handleLastNDaysPayload(data.option);
    string += `${payload.from},${payload.to}`;
  } else if (data.data == "UntilNow") {
    payload = UntilNowPayload(data);
    string += `${payload.from},${payload.to}`;
  } else {
    payload = betWeenRange(data);
    string += `${payload.from},${payload.to}`;
  }
  return string;
};

export const handleLastNDaysPayload = (days) => {
  let now = new Date();
  const backdate = new Date(now.setDate(now.getDate() - days));
  let fullDateInfo = {
    from: formatDate(moment(backdate)),
    to: formatDate(moment(new Date())),
  };
  return fullDateInfo;
};

export const UntilNowPayload = (data) => {
  var newdate = data.option.split("/").join("-");
  let fullDateInfo = {
    from: newdate,
    to: formatDate(moment(new Date())),
  };
  return fullDateInfo;
};

export const betWeenRange = (data) => {
  let fromDate = data.option[0].split("/").join("-");
  let toDate = data.option[1].split("/").join("-");
  let fullDateInfo = { from: fromDate, to: toDate };
  return fullDateInfo;
};
