import React, { useEffect } from "react";
import ConfirmationMessage from "../../../../Components/ConfirmationMessage";
import ModalWindow from "../../../../Components/ModalWindow";
import {
  DELETE_INSIGHTS_INFO,
  INSIGHTS_CONSTANTS,
} from "../../../../Constants/Insights/Insights.constants";
//Delete insight confirmation box
export function ShowModalwindow(props) {
  useEffect(() => {
    document.addEventListener("keypress", (e) => {
      if (e.target.classList.value != "ant-btn ant-btn-primary") {
        if (e.key === "Enter") props.deleteModalHandlerYes();
      }
    });
  });

  return (
    <>
      <ModalWindow
        id={"insightDeleteMainDiv"}
        title={false}
        isNonMaskable={true}
        open={props.state.showDeleteModal}
        footer={false}
        isNotClosable={true}
      >
        <ConfirmationMessage
          loading={props.state.loading}
          cancelButtonLabel={INSIGHTS_CONSTANTS.NO_TEXT_CONSTANT}
          confirmButtonLabel={INSIGHTS_CONSTANTS.YES_TEXT_CONSTANT}
          heading={INSIGHTS_CONSTANTS.INSIGHT_DELETE_MODAL_HEADER}
          message={INSIGHTS_CONSTANTS.INSIGHT_DELETE_MODAL_BODY}
          onOk={(e) => props.deleteModalHandler(e, "modalOff")}
          isNonMaskable={true}
          isAlert={true}
          alertInfo={DELETE_INSIGHTS_INFO}
          onCancel={(e) => props.deleteModalHandlerYes()}
        />
      </ModalWindow>
    </>
  );
}
