import React from 'react';

import template from './template';

class Settings extends React.Component {
  state = {
    tab: 'Users',
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  onTabChange = (value) => {
    this.setState({tab: value});
  };

  render() {
    return template.call(this);
  }
}

export default Settings;
