export const FILTER_SEARCH = {
  SAVE_SEARCH: 'Save Search Results',
  SAVE_YOUR_SEARCH_RESULTS: 'Name the search result',
  SAVE_YOUR_SEARCH_RESULTS_SUB_HEADING: 'Save your search results with a name:',
  SORT_BY: 'Sort by:',
  optionList: [
    {value: 'query1', label: 'query1'},
    {value: 'coronavirus', label: 'coronavirus'},
    {value: 'sample', label: 'sample'},
    {value: 'pingar', label: 'pingar'},
  ],
  optionListSort: [
    {value: 'Relevance', label: 'Relevance'},
    {value: 'Ascending', label: 'Ascending'},
    {value: 'Descending', label: 'Descendig'},
  ],
};

export const FILTER_DISABLE_TOOLTIP =
  'Basic filters are disabled when advance search filters are applied to avoid logical conflicts';

export const VIEW_INSIGHT_DISABLED_TOOLTIP = 'The view insights page is already opened in another tab';
