
import { connect } from "react-redux";
import { ClearFilterFieldData, fetchProjectList, FilterFields, GetCurrentProfileAction, setSearchData } from "Redux/Actions";
import global from "../../styles/globel/style.module.scss";
import { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES_CONFIG } from "Components/SideBar/Sidebar.config";
import { ROUTES } from "Constants/Routes/Routes.constants";
import { MyPageWrapper } from "Components/PageWrapper/MyPageWrapper";
import MySideBar from "Components/SideBar/MySideBar";
import PageBody from "Components/PageBody";
import PageHeading from "Components/PageHeading";
import { SelectElement } from "Components/SelectElement";
import { changeUserProjects } from "api/users";
import { notify } from "Components/Notification";
import ProfileOptions from "Components/ProfileOptions/ProfileOptions";
import PrivateRoute from "utils/PrivateRoute";
import MyHome from "Routes/Home/MyHome";
import Insights from "Routes/Insights";
import Home from "Routes/Home";
import Dashboard from "Routes/Dashboard";
import DownloadPreview from "Routes/DownloadPreview";
import Settings from "Routes/Settings";
import Users from "Routes/Users/Users";
import UserLogs from "Routes/UserLogs";
import { KEYS } from "dataKeys";
import { COMMUNICATION_KEYS } from "constants";
import { ProjectSwitchingModal } from "./ProjectSwitchingModal";
import { AdvanceSearchContainer } from "Routes/AdvanceSearch/AdvanceSearchContainer";
import UserGroups from "Routes/UserGroups/UserGroups";

const mapStateToProps = state=>{
    return {
        insight: state.CreateInsights,
        search: state.Search.search,
        projectList: state.FetchProjectList,
        FilterFields: state.FilterFields,
        CurrentProfile: state.Settings.profile,

        userProfileLoading: state.Settings.profile.profileLoading,
        userProjectSettings: state.Settings.userProjectSettings
    };
};

const mapDispatchToProps = dispatch=>{
    return {
        getProjectList: (params) => dispatch(fetchProjectList(params)),
        loadFilterFields: () => dispatch(FilterFields()),
        GetCurrentProfileAction: () => dispatch(GetCurrentProfileAction()),
        ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
        setSearchData: (params) => dispatch(setSearchData(params)),
    };
};

const MyMainFrameComponent = props=>{

    const initialState = {
        showModal: false,
        timeOutCount: 0,
        currentProject: null,
        currentPass: "",
        newPass: "",
        confirmPass: "",
        superAdminPassChange: false,
        showAdvanceSearchDropDown: {
          visible: false,
          className: "searchToggle",
        },
        sideBarColapse: {
          visible: true,
          className: "sideBarColapse",
          iconClassName: "colapseIcon",
          colapsePageBodyClassName: "colapsePageBody",
        },
        filterVisible: {
          visible: false,
          className: "filterToggle",
        },
        projectSwitching: false,
        showProjectSwitchingModal: false,
        events: ["click", "scroll", "load", "keydown"],
        timerTime: 1800000, //30 mint 1800000
        showLogoutModal: false,
        loading: false,
        resetLoader: false,
        projectSwitched: false,
        projectSelectDisabled: false,
    };

    const [state, setState] = useState(initialState);

    const setMainframeState = data=>setState( prevState=>({...prevState, ...data }));


    
    const [sideBarColapse, setSideBarColapse] = useState({
        visible: true,
        className: "sideBarColapse",
        iconClassName: "colapseIcon",
        colapsePageBodyClassName: "colapsePageBody",
    });


    const handleSideBarColapse = (value) => {

        const newState = { 
            ...state.sideBarColapse,
            visible: value === "isFilter" ? true : !state.sideBarColapse.visible
        };

        setMainframeState(newState);
      };

    const searchStyle = `${global.searchHolder} ${state.showAdvanceSearchDropDown.visible ? global[state.showAdvanceSearchDropDown.className] : ''}`;

    const setPageHeading = () => {
        let title = "";
        ROUTES_CONFIG["Admin"].map(({ name, route }) => {
          if (
            props.location.pathname === route &&
            props.location.pathname != ROUTES.VIEW_IN_NEW_TAB &&
            props.location.pathname != ROUTES.VIEW_TAB_ARTICLES
          ) {
            return (title = name);
          }
          if (
            props.location.pathname === route &&
            props.location.pathname != ROUTES.VIEW_IN_NEW_TAB
          ) {
            return (title = "Articles");
          }
          if (props.location.pathname === ROUTES.INSIGHTS) {
            return (title = "Insights");
          }
        });
        return title;
    };

    const hasToShowSideBar = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let queryString = props.history.location.search;
        const queryterm = queryParams.get("from");
        if (queryterm == "newtab" 
            || queryString == "?insight" 
            || props.history.location.pathname == "/insights" 
            || window.location.pathname == "/loading"
        ) {
            return false;
        } else {
            return true;
        }
    };

    const hasToShowHeader = () => {
        if (window.location.pathname == "/loading") {
            return false;
        } else {
            return true;
        }
    };

    const projectListHandle = () =>
        props.projectList?.data.map((item) => ({
          value: item.id,
          label: item.name,
    }));

    const handleFilterVisible = ()=>{
        const filterFields = props.FilterFields;
        if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
            props.loadFilterFields();
        }
        
        !state.filterVisible.visible && handleSideBarColapse("isFilter");
        setMainframeState({
            filterVisible: {
                ...state.filterVisible,
                visible: !state.filterVisible.visible,
            }
        });
        
    };

    const channel = useMemo(()=>{
        const c = new BroadcastChannel('selectedList');
        return c;
    }, []); 


    useEffect(()=>{
        props.GetCurrentProfileAction();

        props.getProjectList();

        return ()=>{
            channel.close();
        };
    }, []);

    useEffect(()=>{
        setMainframeState({currentProject: props.CurrentProfile.currentProject});
    }, [props.CurrentProfile]);

    const superAdminCredChange = ()=>{
        setMainframeState({
            superAdminPassChange: !state.superAdminPassChange,
            currentPass: '',
            newPass: '',
            confirmPass: '',
        });
    };

    const handleModalVisible = ()=>{
        setMainframeState({ showLogoutModal: true });
    };

    const selectProjectHandler = projectId=>{
        if (state.currentProject == projectId){
            return;
        }

        setMainframeState({ projectSwitching: true });

        const email = localStorage.getItem(KEYS.ACCESS_EMAIL);
        
        changeUserProjects({emailId: email, projectId}).then(({data}) =>{
            if (state.filterVisible.visible){
                handleFilterVisible();
            }

            setMainframeState({ showProjectSwitchingModal: true });
            sessionStorage.setItem(KEYS.PROJECT_SWITCHED, true);
            notify.success("Project changed successfully", true);

            setTimeout(()=>{
                channel.postMessage({
                    type: COMMUNICATION_KEYS.PROJECT_CHANGE,
                    payload: {
                        data: {
                            projectSwitched: true,
                            message: "Project switched successfully",
                        },
                        metadata: { time: new Date(), source: "MainFrame" },
                    }
                });

                let search = props.history.location.search;
                props.history.replace({
                    ...props.history.location,
                    state: {},
                    search: search,
                });
                props.history.go(0);
            }, 2000);

            setMainframeState({
                currentProject: data.result.currentProject,
                projectSwitching: false
            });

        }).catch(err=>{
            setMainframeState({ projectSwitching: false });
            notify.error("Project change failed", true);
        });
    };
    
    if(!props.userProjectSettings){
        return null;
    }

    return (
        <div className={global.mainWrapper}>
            <div className={searchStyle} style={{overflowY: 'scroll'}}>
                {state.showAdvanceSearchDropDown.visible && (
                    <AdvanceSearchContainer mainFrameState={{
                        state, 
                        setState: setMainframeState, 
                        props: {history: props.history},
                        handleFilterVisible
                    }} isInsights={setPageHeading() == "Insights" ? true : false} />
                )}
            </div>

            <MyPageWrapper hideSideBar={hasToShowSideBar()}>
                {hasToShowSideBar() && (<MySideBar 
                    showSideBarNav={hasToShowSideBar()}
                    CurrentUserProfile={props.CurrentUserProfile}
                    location={props.location}
                />)}

                <PageBody addOnClassName={sideBarColapse.visible ? sideBarColapse.colapsePageBodyClassName : null}>
                    {hasToShowHeader() && (<PageHeading text={setPageHeading()}>
                        {props.location.pathName === ROUTES.INSIGHTS  
                        || props.location.pathName === ROUTES.DOWNLOAD_PREVIEW ? 
                        (<div className={global.headerProjectname}>
                            <p>Project Selected:</p>
                            <h4>{props.CurrentProfile?.projectinfo?.name}</h4>
                        </div>) : (
                            <>
                            <div className={global.headerSelect}>
                                <SelectElement 
                                    disabled={state.projectSelectDisabled || state.projectSwitching || props.projectList.loading || props.CurrentProfile.profileLoading}
                                    optionList={projectListHandle()}
                                    placeholder="Select project"
                                    value={
                                      props.projectList?.data?.length
                                        ? props.CurrentProfile?.projectinfo?.id
                                        : null
                                    }
                                    onChange={selectProjectHandler}
                                    loading={state.projectSwitching || props.projectList.loading || props.CurrentProfile.profileLoading}
                                />
                            </div>

                            <ProfileOptions superAdminCredChange={superAdminCredChange} handleModalVisible={handleModalVisible}/>
                            </>
                        )}
                    </PageHeading>)}
                    
                    <Switch>
                        <Route exact path={ROUTES.INDEX} render={()=><Redirect to={ROUTES.HOME} />} />

                        <PrivateRoute path={ROUTES.HOME} component={MyHome} componentProps={{
                            mainFrameState: {
                                state, 
                                setState: setMainframeState, 
                                
                                handleFilterVisible
                            }
                        }} />

                        <PrivateRoute
                            path={ROUTES.VIEW_TAB_ARTICLES}
                            component={Home}
                            componentProps={{ mainFrameState: {
                                state:{
                                    filterVisible: state.filterVisible,
                                }, 
                                handleFilterVisible
                            } }}
                        />

                        <PrivateRoute
                            path={ROUTES.INSIGHTS}
                            component={Insights}
                            componentProps={{ mainFrameState: {
                                state:{
                                    filterVisible: state.filterVisible,
                                    showAdvanceSearchDropDown: state.showAdvanceSearchDropDown
                                }, 
                                setState: setMainframeState, 
                                handleFilterVisible
                            } }}
                        />

                        <PrivateRoute
                            path={ROUTES.DASHBOARD}
                            component={Dashboard}
                            componentProps={{ mainFrameState: {
                                state:{
                                    filterVisible: state.filterVisible,
                                }, 
                                
                                handleFilterVisible
                            } }}
                        />

                        <PrivateRoute path={ROUTES.SETTINGS} component={Settings} />
                        <PrivateRoute path={ROUTES.USER_LOGS} component={UserLogs} />
                        <PrivateRoute path={ROUTES.USERS} component={Users} />
                        <PrivateRoute path={ROUTES.USER_GROUPS} component={UserGroups} />
                        <PrivateRoute
                            path={ROUTES.DOWNLOAD_PREVIEW}
                            component={DownloadPreview}
                        />
                        <PrivateRoute path={ROUTES.USER_LOGS} component={UserLogs} />
                        <PrivateRoute
                            path={ROUTES.VIEW_IN_NEW_TAB}
                            component={Insights}
                            componentProps={{ mainFrameState: {
                                state:{
                                    filterVisible: state.filterVisible,
                                }, 
                                
                                handleFilterVisible
                            } }}
                        />

                    </Switch>

                    <ProjectSwitchingModal showProjectSwitchingModal={state.showProjectSwitchingModal} />
                </PageBody>
            </MyPageWrapper>
        </div>
    );
};

export const MyMainFrame = connect(mapStateToProps, mapDispatchToProps)(MyMainFrameComponent);