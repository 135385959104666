import React from 'react';
import PropTypes from 'prop-types';

import AppLoaderImage from '../../../public/img/app-loader.gif';

import style from './style.module.scss';

const AppLoader = (props) => {
  const {hasWrapper, heading, content} = props;
  const withWrapper = () => <div className={style.appLoaderWrapper}>{withoutWrapper()}</div>;

  const withoutWrapper = () => {
    return (
      <>
        <div className={style.appLoaderInner}>
          <div className={style.appLoaderInner__loadimage}>
            <img src={AppLoaderImage} />
          </div>
          <h2 className={'loader'}>{heading}</h2>
          <p>{content}</p>
        </div>
      </>
    );
  };

  if (hasWrapper) return withWrapper();
  else return withoutWrapper();
};

AppLoader.propTypes = {
  hasWrapper: PropTypes.bool,
  heading: PropTypes.string,
  content: PropTypes.string,
};

AppLoader.defaultProps = {
  hasWrapper: true,
  heading: 'Hold on! ',
  content: 'You are being redirected.',
};

export default AppLoader;
