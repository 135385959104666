import React from 'react';
import ReactECharts from 'echarts-for-react';

import style from './style.module.scss';
import globel from '../../styles/globel/style.module.scss';
import EmptyState from '../EmptyState';

const StreamChart = (props) => {
  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgba(0,0,0,0.2)',
          width: 1,
          type: 'solid',
        },
      },
    },
    legend: {
      type: 'scroll',
      data: props.data.legend,
      width: 'auto',
      bottom: '16',
    },
    dataZoom: [
      {
        type: 'inside',
        zoomOnMouseWheel: true,
      },
    ],
    singleAxis: {
      top: 50,
      bottom: 70,
      axisTick: {},
      axisLabel: {},
      type: 'time',
      axisPointer: {
        animation: true,
        label: {
          show: true,
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          opacity: 0.2,
        },
      },
    },
    series: [
      {
        type: 'themeRiver',
        emphasis: {
          itemStyle: {
            shadowBlur: 20,
            shadowColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
        data: props.data.data,
      },
    ],
  };
  return (
    <>
      {props.data.data.length ? (
        <div
          className={`${props.dashboard ? style['chart-root-container'] : globel.chartItem} ${
            props.isDownload && globel.previewCharts
          }`}>
          <ReactECharts option={options} style={{height: '100%'}} />
        </div>
      ) : (
        <EmptyState content={'CHART_DATA'} type={'welcomeImage'} />
      )}
    </>
  );
};

export default StreamChart;
