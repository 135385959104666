import React from 'react';
import InputField from '../../../Components/InputField';
import style from './style.module.scss';
function SelectFilter(props) {
  return (
    <div className={style.facetFilter}>
      <InputField
        showSearch={true}
        name={'Select Filter'}
        title={' Select Filter'}
        htmlForName={'Select Filter'}
        optionList={props.optionList}
        value={props.value}
        placeholder={'no filter selected [basic search mode]'}
        onChange={props.onChange}
        inputType='selectElement'
        allowClear={true}
        // style={props.value && props.style}
      />
    </div>
  );
}

export default SelectFilter;
