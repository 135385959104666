import React, { useEffect, useState } from "react";
import { Tag } from "./Tag";
import style from "./style.module.scss";
export function ExcludeTags({ tags, handleRemoveExclusionList }) {
  const [tagErrorMEssage, setTagErrorMEssage] = useState("");
  const [removeLoading, setRemoveLoading] = useState({});

  useEffect(() => {
    if (tags.length > 19) {
      setTagErrorMEssage("Word limit reached!");
    } else {
      setTagErrorMEssage("");
    }
  }, [tags]);

  return (
    <div className={style.tagBlock}>
      <ul className={style.tagBlock__list}>
        {renderTags({
          tags,
          handleRemoveExclusionList,
          removeLoading,
          setRemoveLoading,
        })}
        <li>
          {" "}
          {tagErrorMEssage != "" && (
            <p className={style.notabPane}>{tagErrorMEssage}</p>
          )}
        </li>
      </ul>
    </div>
  );
}

const renderTags = ({
  tags,
  handleRemoveExclusionList,
  removeLoading,
  setRemoveLoading,
}) => {
  return tags.map((each) => (
    <Tag
      text={each.keyword}
      key={each.id}
      value={each.id}
      closable={true}
      onClose={(e) =>
        onClose({
          text: each.id,
          handleRemoveExclusionList,
          setRemoveLoading,
        })
      }
      loading={removeLoading}
    />
  ));
};
const onClose = ({ text, handleRemoveExclusionList, setRemoveLoading }) => {
  setRemoveLoading({ [text]: true });
  handleRemoveExclusionList(text);
};
