import {connect} from 'react-redux';
import Settings from './Settings';

function mapStateToProps(state) {
  return {CurrentUserProfile: state.Settings.profile};
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
