import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './style.scss';
export const TextInput = (props) => {
  const inputChange = (eve) => {
    props.onChange &&
      props.onChange({
        ...props,
        value: eve.target.value,
        name: eve.target.name,
        index: props.index,
        formObject: props.formObject,
        ...eve,
      });
  };
  return (
    <Input
      allowClear={props.allowClear}
      readOnly={props.readOnly}
      onChange={inputChange}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      defaultValue={props.defaultValue}
      value={props.value}
      type={props.htmlType}
      name={props.name}
      autoFocus={props.autoFocus}
      autoComplete='off'
      key={props.key}
      onKeyDown={props.onKeyDown}
      onPressEnter={props.onPressEnter}
      onClick={props.onClick}
      id={props.id}
      max={props.max}
      min={props.min}
      size={props.size}
      shape={props.shape}
      onBlur={props.onBlur}
      style={props.style}
      showCount={props.showCount}
      maxLength={props.maxLength}
      className={`${props.className || ''} ${!props.isValid ? ' ' : ''}`}
    />
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  htmlType: PropTypes.string,
  name: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  size: PropTypes.string,
  shape: PropTypes.string,
  className: PropTypes.string,
  onPressEnter: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};
