export const onSearchBarEnter = (props, { searchString }) => {
  props.setSearchData({
    value: searchString,
    sortorder: "DESC",
    sortby: searchString ? "relevance" : null,
  });
};

export const formatOptionList = (data) => {
  let newArray = [];
  data.map((item) => {
    let dropDownObject = { value: item.id, label: item.name };
    newArray.push(dropDownObject);
  });
};
