import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { TextInput } from "../TextInput";
import SVGIcons from "../SVGIcons";
import style from "./style.module.scss";
import { onSearchBarEnter } from "./helper";
import { setInsightData } from "../../Redux/Actions";
import { HEADER_CONSTANTS } from "../../Constants/Header/Header.constants";
import { SEARCH_INFO } from "constants";
import TooltipContainer from "Components/Tooltip";

const SearchBarInsights = (props, className) => {
  const [searchKey, setSearchKey] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchString, setSearchString] = useState("");
  const [showIcon, setShowIcon] = useState(false);
  const [mount, setMount] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setSearchKey(props.search.searchValue);
    setSearchInput(props.search.searchValue);
  }, [props.search.searchValue]);
  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (searchInput?.trim() !== searchString) {
      setSearchString(searchInput.trim());
    }
  }, [searchInput]);

  useEffect(() => {
    const debounceDelay = 500;
    let debounceTimeout;
    if (searchString === "") {
      debounceTimeout = setTimeout(() => {
        props.search.searchValue &&
          onSearchBarEnter(props, { history, searchString });
      }, debounceDelay);
    }
    return () => clearTimeout(debounceTimeout);
  }, [searchString]);

  useEffect(() => {
    if (props.search.searchValue == "") {
      setSearchInput("");
      setSearchKey("");
      setSearchString("");
    }
  }, [props.search.searchValue]);

  function handleInputChange(event) {
    setSearchKey("");
    setSearchInput(event.value);
  }

  return (
    <div className={`${style.mainSearchInsights} ${className}`}>
      <div className={style.mainSearchInsights__input}>
        <TextInput
          allowClear={true}
          className={style.inputElement}
          name={"mainSearchInsights-block"}
          placeholder={searchKey || HEADER_CONSTANTS.SEARCH_PLACEHOLDER}
          onPressEnter={() => {
            onSearchBarEnter(props, { history, searchString });
          }}
          onChange={handleInputChange}
          value={searchInput}
          onFocus={() => setShowIcon(true)}
          htmlType={""}
        />
        <span className={style.serachIconInsights}>
          <SVGIcons type="SVG-lens" />
        </span>
      </div>
      <TooltipContainer
        title={SEARCH_INFO.CONTENT}
        placement="bottom"
        overlayStyle={{ width: "auto" }}
        overlayInnerStyle={SEARCH_INFO.STYLE}
      >
        <span className={style.insightSearchInfo}>
          <SVGIcons type="SVG-infoIcon" />
        </span>
      </TooltipContainer>
    </div>
  );
};

function mapStateToProps(state) {
  return { search: state.CreateInsights };
}

function mapDispatchToProps(dispatch) {
  return {
    setInsightData: (params) => {
      dispatch(setInsightData(params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarInsights);
