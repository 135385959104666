/**
 * Contains API class to setup new axios instance
 */
import axios from "axios";
import env from "react-dotenv";

import { getAccessToken } from "../utils/commonUtils";
import { SAML_LOGOUT_URL } from "../Constants/Login/Login.constants";
import { notify } from "../Components/Notification";
import { CANCEL_EXCLUDED_APIS } from "./constants";

const pendingRequests = {};
const CancelToken = axios.CancelToken;

// API request will be cancelled after this timeout (in milliseconds)
const API_TIMEOUT = 120000;

/**
 * API class to create axios instances with passed base URL.
 */
export class APIInstance {
  /** @type {import('axios').AxiosInstance} */
  api;

  /** @constructor @param {{baseURL: string}} param */
  constructor({ baseURL, noAbort = false }) {
    this.domainURL = env.API_DOMAIN_URL;
    this.baseURL = this.domainURL + baseURL;
    this.api = this.axiosInstance();
    this.pending = {};
    this.CancelToken = axios.CancelToken;
    this.abortController = new AbortController();
    this.setCancelPendingInterceptor(this.api);
  }

  /**
   * Create axios instance with user timezone and passed base URL
   */
  axiosInstance() {
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: API_TIMEOUT,
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isCancel(error)) {
          error.message = "CANCEL";
        }
        if (error.code === "ECONNABORTED") {
          if (typeof error.response === "object")
            error.response["status"] = 408;
          else if (!error.response) error.response = { status: 408 };
          // Didn't figured out a solution for else case, So just adding the same code
          else error.response = { status: 408 };
        }
        throw error;
      }
    );

    return this.api;
  }

  // update pending requests (add || delete, cancel)
  updatePending(config, cancel) {
    let url = "";
    if (config && config.url) {
      url = `${config.baseURL}${config.url}`;
    }
    // Return in case method does not exists
    if (!config || !config.method) {
      return;
    }

    let flagUrl = "";
    if (config.method) {
      flagUrl = url + "&" + config.method;
    }
    if (config.params) {
      flagUrl += "&" + JSON.stringify(config.params);
    }
    if (cancel) {
      if (
        pendingRequests[flagUrl] &&
        CANCEL_EXCLUDED_APIS.filter((item) => flagUrl.includes(item)).length ===
          0
      ) {
        pendingRequests[flagUrl]();
      }
      pendingRequests[flagUrl] = cancel;
    } else {
      delete pendingRequests[flagUrl];
    }
  }

  // set cancel pending interceptor for all requests
  setCancelPendingInterceptor(instance) {
    if (!instance) instance = this.axiosInstance();
    instance.interceptors.request.use((config) => {
      if (config.noCancel) return config;
      const token = getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.cancelToken = new CancelToken((cancel) => {
        this.updatePending(config, cancel);
      });
      return config;
    });
    instance.interceptors.response.use(
      (response) => {
        this.updatePending(response.config);
        return response;
      },
      (error) => {
        const { response } = error;
        if (response && response.status === 401) {
          if (getAccessToken()) {
            notify.error(
              "Looks like you don't have sufficient permissions to access! Please contact the system admin."
            );
          }
          localStorage.clear();
          sessionStorage.clear();
          setTimeout(function () {
            window.location.href = SAML_LOGOUT_URL;
          }, 2000);
        }
        this.updatePending(error.config);
        return Promise.reject(error);
      }
    );
    return instance;
  }

  // cancel all pending requests
  cancelPending() {
    Object.keys(this.pending).forEach((e) => {
      if (this.pending[e]) {
        this.pending[e]();
        delete this.pending[e];
      }
    });
  }
}
