import {notify} from '../../../../Components/Notification';
import {INITIAL_STATE} from '../initialState';

export function saveInsightsSuccess(state, payload) {
  notify.success('Insights saved sucessfully', true);
  return {
    ...state,
    loading: false,
    error: false,
  };
}

export function saveInsightsStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function saveInsightsFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}
