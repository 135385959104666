import React from "react";
import { SavedDashboards, DashboardCanvas, BuildDashboard } from "./Components";
import ErrorState from "Components/ErrorState";

import HeaderSection from "Components/HeaderSection";

import globel from "../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import ModalWindow from "Components/ModalWindow";
import Loader from "Components/Loader";

function template(props) {
  return (
    <div
      className={`${globel.layoutBlock} ${
        this.props.mainFrameState.state.filterVisible.visible &&
        this.state.displayDashboards
          ? globel[this.props.mainFrameState.state.filterVisible.className]
          : ""
      } `}
    >
      <div className={style.DashboardContainer}>
        <HeaderSection
          {...this.props}
          handleFilterVisible={this.props.mainFrameState.handleFilterVisible}
          filterVisible={this.props.mainFrameState.state.filterVisible}
          dateFilter={this.props.importedInsights.dateFilter}
          filter={this.props.importedInsights.filter}
          handleSavedModal={this.handleSavedModal}
          displayDashboards={this.state.displayDashboards}
          handleButtonClick={this.handleButtonClicks}
        />
        <>
          {this.state.displayDashboards ? (
            <>
              {this.props.importedInsights.importError ? (
                <ErrorState
                  content="ERROR"
                  type={"errorImage"}
                  errorMessage={this.props.importedInsights.errorMessage}
                />
              ) : (
                <BuildDashboard
                  history={this.props.history}
                  location={this.props.location}
                />
              )}
            </>
          ) : this.state.loadDashboardLoading ? (
            <Loader
              hasWrapper={true}
              customHeight={"608px"}
              classList={["sm blue"]}
            />
          ) : (
            <>
              {this.state.getInsightError.error ? (
                <ErrorState
                  content="ERROR"
                  type={"errorImage"}
                  errorMessage={this.state.getInsightError.errorMessage}
                />
              ) : this.props.importedInsights.importError ? (
                <ErrorState
                  content="ERROR"
                  type={"errorImage"}
                  errorMessage={this.props.importedInsights.errorMessage}
                />
              ) : (
                <DashboardCanvas {...this} />
              )}
            </>
          )}
          {this.state.showSavedDashboard && (
            <ModalWindow
              open={this.state.showSavedDashboard}
              title={"Saved Dashboards"}
              width={900}
              okText="Close"
              onOk={(e) => this.handleSavedModal(false)}
              isNonMaskable={true}
              isNotClosable={true}
              isHideCancelButton={true}
            >
              {this.props.importedInsights.error ? (
                <ErrorState
                  content="ERROR"
                  type={"errorImage"}
                  errorMessage={this.props.importedInsights.errorMessage}
                />
              ) : (
                <SavedDashboards {...this} />
              )}
            </ModalWindow>
          )}
        </>
      </div>
    </div>
  );
}

export default template;
