import React from "react";
import { SAVED_SEARCHES_CONSTANTS } from "./SavedSearches.constants";
import ModalWindow from "../ModalWindow";
import ConfirmationMessage from "../ConfirmationMessage";
function ShowModalwindow(props) {
  return (
    <>
      <ModalWindow
        isNotClosable={true}
        title={false}
        footer={false}
        open={props.state.showSavedSearchDeleteModal}
      >
        <ConfirmationMessage
          cancelButtonLabel="Yes"
          confirmButtonLabel="No"
          loading={props.state.savedSearchDeleteLoading}
          heading={SAVED_SEARCHES_CONSTANTS.DELETE_HEADING}
          message={SAVED_SEARCHES_CONSTANTS.DELETE_CONFIRM_MESSAGE}
          onOk={(e) => props.deleteModalCloseHandler()}
          onCancel={(e) => props.deleteModalHandlerYes(e, "modalOff")}
        />
        {/* <p>
          {SAVED_SEARCHES_CONSTANTS.DELETE_CONFIRM_MESSAGE}
          {props.state.selectedTitle}
        </p> */}
      </ModalWindow>
    </>
  );
}

export default ShowModalwindow;
