import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import {ROUTES} from '../Constants/Routes/Routes.constants';
import {CommonUtils} from './commonUtils';

export default function PrivateRoute({component: Component, componentProps, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        CommonUtils.isLoggedIn() ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect to={{pathname: ROUTES.LOGIN, state: {from: props.location}}} />
        )
      }
    />
  );
}
