import ProfileAvatar from '../../../public/img/avatar.png';

export const HEADER_CONSTANTS = {
  SEARCH_PLACEHOLDER: 'Search Reports, Projects, etc..',
  DROPDOWN_OPTIONS: [{value: 'All Projects', label: 'All Projects'}],
  SELECT_PLACEHOLDER: 'All Projects',
  PROFILE_MENU: ['Profile', 'Settings', 'Logout'],
  LOGOUT_MODAL_HEADER: 'Sad to see you go!',
  LOGOUT_MODAL_BODY: 'Are you sure you want to logout for now?',
  LOGOUT_MODAL_ONE_HEADER: 'Confirm log out',
  LOGOUT_TIMEOUT_MODAL_HEADER: 'Hey, are you still there?',
  LOGOUT_TIMEOUT_MODAL_BODY:
    'Your screen has been idle for a while. Please click on "Stay Active" if this session needs to be retained.',
};

export const INITIAL_STATE = {
  openSandWich: false,
  activeClass: '',
  showDropdown: false,
  logoutModal: false,
  addOnClass: false,
  profileModal: false,
  profileImg: ProfileAvatar,
  updatedProfile: ProfileAvatar,
  group: {
    group1: {
      filter1: {facet1: {value1: ''}},
      filter2: {facet1: {value1: ''}},
    },
  },
  advanceSearchWindow: false,
};

export const ADVANCE_SEARCH_OK_TEXT = 'Apply';
export const ADVANCE_SEARCH_HEADER = 'Advanced Search';
