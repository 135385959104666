import React from "react";
import ConfirmationMessage from "../ConfirmationMessage";
import ModalWindow from "../ModalWindow";

const RemoveDashboardMOdal = (props) => {
  return (
    <ModalWindow
      isNotClosable={true}
      open={props.showRemoveModal}
      footer={false}
    >
      <ConfirmationMessage
        heading={" Confirm Removal"}
        message={
          "Are you sure you want to remove this chart from the dashboard?"
        }
        onCancel={(e) => props.onRemoveHandler(props.selectedDashboard)}
        onOk={(e) => props.removeModalHandlerNo(e, "modalOff")}
        cancelButtonLabel="Remove "
        confirmButtonLabel="Cancel"
      />
    </ModalWindow>
  );
};

export default RemoveDashboardMOdal;
