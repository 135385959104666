import {
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_STARTED,
  SEND_EMAIL_FAILED,
} from '../../Redux.constants';
import {sendEmailSuccess, sendEmailStarted, sendEmailFailed} from './Helper';
import {INITIAL_STATE} from './initialState';

export default function SendEmail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_EMAIL_SUCCESS:
      return sendEmailSuccess(state, action.payload);
    case SEND_EMAIL_STARTED:
      return sendEmailStarted(state, action.payload);
    case SEND_EMAIL_FAILED:
      return sendEmailFailed(state, action.payload);
    default:
      return {...state};
  }
}
