import React from 'react';
import SVGIcons from '../../../SVGIcons';
import TooltipContainer from '../../../Tooltip';

const LoadResults = (props) => {
  return (
    <>
      <TooltipContainer title={props.toolTipTitle} placement='bottomLeft'>
        <span
          onClick={(e) => {
            e.preventDefault();
            return props.handleLoad(true);
          }}>
          <SVGIcons type={props.icon ? props.icon : 'SVG-import'} />
        </span>
      </TooltipContainer>
    </>
  );
};

export default LoadResults;
