import { notify } from "../../../../../Components/Notification";
import { KEYS } from "../../../../../dataKeys";
import { errorMEssageHandler } from "../../../../../utils/ErrorHandler";

export const createSaveDashboardPayload = ({
  state,
  insightList,
  insightPresentId,
}) => ({
  emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
  dashboardName: state.state.dashboardName,
  dashboardContents: insightList,
  insightsPresent: insightPresentId,
  layout: JSON.stringify(state.state.layout),
});

export const handleInsightsDataHelper = ({ selectedValues }) => {
  let insightListArray = [];
  selectedValues
    .filter((item) => item && item)
    .map((insightItem) => {
      insightListArray.push({ id: insightItem });
    });
  return insightListArray;
};

//Update response handler
export const updateResponseHandler = ({ state, error, message }) => {
  if (error) {
    state.setState({
      saveLoading: false,
      saveError: errorMEssageHandler(message?.status).description,
    });
  } else {
    state.setState({
      saveModalWindow: false,
      dashboardName: state.state.dashboardName,
      saveLoading: false,
      saveError: "",
    });
    notify.success("Dashboard updated successfully", true);
  }
};

export const updateDashboardController = ({ state }) => {
  state.setState({ saveLoading: true });
  let insightList = [];
  state.props.insightsList.insightData.map((item) => {
    if (state.state.selectedInsights.includes(item.id)) {
      insightList.push(item);
    }
  });
  let payload = {
    emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
    dashboardName: state.state.dashboardName,
    dashboardContents: insightList,
    insightsPresent: state.state.selectedInsights,
    id: state.state.dashboardId,
    layout: JSON.stringify(state.state.layout),
  };
  state.props.updateSavedDashboard(payload, ({ error, message }) =>
    updateResponseHandler({ error, message, state })
  );
};
