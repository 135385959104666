import React from "react";
import template from "./template";

class DashboardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isView: false,
      isEdit: false,
      selectedList: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.location?.state?.isView !== prevProps.location?.state?.isView
    ) {
      this.setState({
        isView: this.props.location.state?.isView,
      });
    }
    if (
      this.props.location?.state?.isEdit !==
        prevProps.location?.state?.isEdit &&
      this.props.location?.state?.isEdit !== undefined
    ) {
      this.setState({
        ...this.state,
        isEdit: this.props.location.state?.isEdit,
      });
    }
    if (
      this.props.location?.state?.selectedList?.length !==
        prevProps.location?.state?.selectedList?.length &&
      this.props.location?.state?.selectedList?.length !== undefined
    ) {
      this.setState({
        selectedList: this.props.location.state?.selectedList,
      });
    }
  }
  render() {
    return template.call(this);
  }
}
export default DashboardHeader;
