import React, { Fragment } from "react";

import ButtonElement from "../../../Components/ButtonElement";
import ToggleSwitch from "../../../Components/ToggleSwitch";
import TooltipContainer from "../../../Components/Tooltip";
import AdvanceFilter from "./AdvanceFilter";

import { isEmptyFields } from "../helperFunctions";

import style from "./style.module.scss";

function GroupFilter(props) {
  return (
    <div className={style.filterWrapper}>
      <div className={style.filterblockInner}>
        {Object.keys(props.group)
          ?.filter((item) => item !== "group0")
          .map((value, key) => (
            <Fragment key={value + key}>
              <div
                className={style.filterContention}
                hidden={key == 0 ? true : false}
              >
                <span>And</span>
                <span>
                  <ToggleSwitch
                    size={"small"}
                    handleChange={(e) =>
                      props.handleGroupToggle({ status: e, group: value })
                    }
                    value={props.group[value]?.isOR}
                  />
                </span>
                <span>OR</span>
              </div>
              <div className={style.advanceBlock}>
                <div className={style.advanceBlock__element}>
                  <AdvanceFilter {...props} value={value} />
                </div>
                <div className={style.advanceBlock__addgroup}>
                  <div
                    className={style.addgroupElement}
                    hidden={
                      key == 0 &&
                      Object.keys(props.group)?.filter(
                        (item) => item !== "group0"
                      ).length < 2
                        ? true
                        : false
                    }
                  >
                    <ButtonElement
                      className={style.facetButtons}
                      children={"- Group"}
                      size="small"
                      onClick={() => props.handleGroupDelete(value)}
                    />
                  </div>
                  <div
                    className={style.addgroupElement}
                    hidden={
                      key !=
                      Object.keys(props.group)?.filter(
                        (item) => item !== "group0"
                      ).length -
                        1
                        ? true
                        : false
                    }
                  >
                    <TooltipContainer
                      placement={"top"}
                      title={
                        isEmptyFields(props.group)
                          ? "Please select value for filter block"
                          : ""
                      }
                    >
                      <span>
                        <ButtonElement
                          size="small"
                          className={style.facetButtons}
                          children={"+ Group"}
                          disabled={isEmptyFields(props.group)}
                          onClick={props.handleGroupAdd}
                          type="primary"
                          styleProps={
                            isEmptyFields(props.group)
                              ? { pointerEvents: "none" }
                              : {}
                          }
                        />
                      </span>
                    </TooltipContainer>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
}

export default GroupFilter;
