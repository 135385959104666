import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';

export function renderSettingX(root) {
  var xRenderer = am5xy.AxisRendererX.new(root, {
    minGridDistance: 30,
    strokeOpacity: 1,
    strokeWidth: 1,
  });
  xRenderer.labels.template.setAll({
    rotation: -80,
    centerY: am5.p50,
    centerX: am5.p100,
    paddingRight: 15,
    oversizedBehavior: 'truncate',
    maxWidth: 140,
  });
  xRenderer.grid.template.set('forceHidden', true);
  return xRenderer;
}
export function renderSettingY(root) {
  let yRenderer = am5xy.AxisRendererY.new(root, {
    strokeOpacity: 1,
    strokeWidth: 1,
  });
  yRenderer.grid.template.set('forceHidden', true);
  return yRenderer;
}
