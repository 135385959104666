import React from 'react';
import {template} from './template';
import {
  checkUserAdminFields,
  storeUserAuthKeys,
} from '../Login/helper';
import {notify} from '../../Components/Notification';
import { ROUTES } from '../../Constants/Routes/Routes.constants';

class SuperAdminLogin extends React.Component {
  state = {
    password: '',
    email: '',
  };

  login = (data) => {
    this.setState({email: '', password: ''});
    storeUserAuthKeys(data, this.props.history);
  };

  superAdminLoginFieldChange = (field, value) => {
    this.setState({[field]: value});
  };
  cancelSupreAdimnModal = () => {
    this.setState({email: '', password: ''});
    window.location.href = ROUTES.LOGIN
  };

  superAdminLogin = () => {
    let {email, password} = this.state;
    let data = {emailid: email, password};
    let valid = checkUserAdminFields(data);
    if (valid.error) {
      notify.error(valid.message);
    } else {
      this.props.signIn(data, this.login);
    }
  };
  render() {
    return template.call(this);
  }
}
export default SuperAdminLogin;

