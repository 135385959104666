import React from "react";

import InputField from "Components/InputField";
import ModalWindow from "Components/ModalWindow";
import {
  SAVE_INSIGHT_NAME_PLACEHOLDER,
  UPDATE_INSIGHT_INFO,
} from "Constants/Insights/Insights.constants";

import style from "./style.module.scss";

export function SaveModal(props) {
  return (
    <>
      <ModalWindow
        title={"Save Insights"}
        okText={"Save"}
        width={"500px"}
        isNotClosable={true}
        isNonMaskable={true}
        onCancel={props.SaveModalCancelHandler}
        onOk={props.SaveModalOkHandler}
        confirmLoading={props.state.SaveLoading}
        open={props.props.saveModalWindow}
      >
        <div className={style.saveinsightBlock}>
          <div className={style.saveinsightBlock__input}>
            <InputField
              title={" Save the insight as"}
              htmlForName={"Save the insight with name"}
              placeholder={SAVE_INSIGHT_NAME_PLACEHOLDER}
              value={props.state.insightName}
              onChange={props.handleInsightsName}
              onPressEnter={props.SaveModalOkHandler}
              error={
                props.state.insightNameErrorMessage != ""
                  ? props.state.insightNameErrorMessage
                  : null
              }
            />
          </div>
          <div className={style.saveinsightBlock__information}>
            {!props.state.isEditDashboard &&
            props.props.location &&
            props.props.location.state &&
            props.props.location.state.isEdit
              ? UPDATE_INSIGHT_INFO
              : null}
          </div>
        </div>
      </ModalWindow>
    </>
  );
}
