import {
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_STARTED,
  FETCH_PROJECT_LIST_SUCCESS,
} from "../../Redux.constants";
import {} from "../../../api/";
import { getProjectListApi } from "../../../api/userProjects";
import { handleApiError } from "../../../utils/commonUtils";

export function fetchProjectList(payload) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_PROJECT_LIST_STARTED,
      payload: {},
    });
    try {
      const { data } = await getProjectListApi();
      dispatch({
        type: FETCH_PROJECT_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: FETCH_PROJECT_LIST_FAILED,
          payload: error,
        })
      );
    }
  };
}
