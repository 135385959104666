import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import template from "./template";
class DateSection extends React.Component {
  state = {
    pickerType: "date",
    showTime: false,
    lastNdays: 10,
    errorMessage: "",
  };

  componentDidMount() {
    this.pickerSelector();
    this.setState({ lastNdays: this.props.state.numberOfDays });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.state.numberOfDays != this.props.state.numberOfDays) {
      if (this.props.state.numberOfDays !== undefined){
        this.setState({ lastNdays: this.props.state.numberOfDays });
      } 
      else {
        this.setState({ lastNdays: 10 });
      }
    }
  }

  pickerSelector = () => {
    switch (this.props.dateFormat) {
      case "YYYY/MM":
        this.setState({ pickerType: "month" });
        break;
      case "YYYY":
        this.setState({ pickerType: "year" });
        break;
      case "MMMM Do YYYY, h:mm:ss a":
        this.setState({ showTime: true });
        break;
      default:
        this.setState({ pickerType: "date" });
    }
  };

  handleDateSelectType = (event) => {
    this.props.handleChangefilterDateType &&
      this.props.handleChangefilterDateType(event.target.value);
    if (event.target.value === 3) {
      this.handleLastNDaysChange();
    }
  };

  handleLastNdays = (event) => {
    if (
      (!isNaN(event.target.value) &&
        event.target.value > 0 &&
        event.target.value <= 1000) ||
      event.target.value == ""
    ) {
      this.setState({ lastNdays: event.target.value, errorMessage: "" });
    } else {
      this.setState({ errorMessage: "Maximum allowed is 1000" });
    }
  };

  handleLastNDaysChange = () => {
    if (this.state.lastNdays == "" || this.state.lastNdays == undefined) {
      this.setState({ errorMessage: "Please enter a value" });
    } else {
      let now = new Date();
      const backdate = new Date(
        now.setDate(now.getDate() - this.state.lastNdays)
      );
      let fullDateInfo = {
        from: moment(backdate).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
      };
      this.props.handleChangeDate({
        e: fullDateInfo,
        name: "to",
        type: "lastNDays",
        numberOfDays: this.state.lastNdays,
      });
      this.setState({ errorMessage: "" });
    }
  };

  render() {
    return template.call(this);
  }
}
DateSection.propTypes = {
  /**
   * dateFormat
   */
  dateFormat: PropTypes.any,
  /**
   * handleChangefilterDateType
   */
  handleChangefilterDateType: PropTypes.func,
  /**
   * handleDateFilterSearch
   */
  handleDateFilterSearch: PropTypes.func,
  /**
   * handleChangeDate
   */
  handleChangeDate: PropTypes.func,
};
export default DateSection;
