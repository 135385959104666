import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const getDashboardList = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/GetDashboardsPagesize', payload);
};

export const deleteDashboard = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.delete('/DeleteDashboards', {data: payload});
};

export const saveDashboards = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/AddDashboards', payload);
};

export const editDashboard = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/UpdateDashboards', payload);
};

export const viewDashboardApi = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/GetDashboardById', payload);
};

/**
 *
 * @param {{emailId: string, pageNumber: number, pageSize: number}} param
 */
export const getDashboardsByEmail = ({emailId, pageNumber, pageSize}) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetDashboardsByEmailId', {emailId, pageNumber, pageSize});
};

export const GetDashboardDocumentsById = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetDocumentsById', payload);
};
