import { errorMEssageHandler } from "../../../../utils/ErrorHandler";

/**
 * Advanced Search started
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} new state of advanced search started
 */
export function advanceSearchStarted(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: true,
      error: false,
      searchDone: false,
    },
  };
}

/**
 * advanced search success
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} new state for advanced search success
 */
export function advanceSearchSuccess(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: false,
      data: payload.data,
      totalCount: payload.totalCount,
      searchDone: true,
    },
  };
}
/**
 * advanced search failed
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} new state of failed advanced search
 */
export function advanceSearchFailed(state, payload) {
  // notify.error(payload.message, true);
  return {
    ...state,
    search: {
      ...state.search,
      loading: false,
      error: true,
      data: [],
      errorMessage: errorMEssageHandler(payload?.response?.status),
    },
  };
}
