export const ROUTES = {
  LOGIN: '/login',
  LOADING: '/loading',
  HOME: '/home',
  DETAILS: '/details',
  INDEX: '/',
  INSIGHTS: '/insights',
  DASHBOARD: '/dashboard',
  BUILD_DASHBOARD: '/builddashboard',
  BUILD_INSIGHT: '/buildinsight',
  SETTINGS: '/settings',
  CREATE_INSIGHT: '/createinsight',
  ADVANCE_SEARCH: '/advancesearch',
  VIEW_DASHBOARD: '/viewdashboard',
  USER_LOGS: '/userlogs',
  DOWNLOAD_PREVIEW: '/downloadpreview',
  VIEW_IN_NEW_TAB: '/viewtabinsights',
  VIEW_TAB_ARTICLES: '/viewtabarticles',
  SUPER_ADMIN_LOGIN: '/5up3r@dmin',
  USERS: '/users',
  USER_GROUPS: '/usergroups',
};
