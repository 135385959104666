import { DATE_SORT_KEY, TITLE_SORT_KEY } from "Routes/Home/constants";
import { getDatePublishedFieldName, getTitleFieldName } from "./helper";
import { MySortButtons } from "./MySortButtons";
import { useEffect, useState } from "react";

export const MySortControls = props=>{
    // TODO: what's the purpose of SortControls if all it does it just render SortButtons? 


    const [state, setLocalState] = useState({
        showSorting: false,
        sortingType: "PublishDate",
    });

    const setState = data => {
        setLocalState(prevState=>{
            const newState = {...prevState, ...data};
            return newState;
        });
    };

    const handleSortingVisible = (value) => {
        setState({ showSorting: value });
    };
        

    const handleSort = (value, type) => {
        const sortorder = type == "activeUp" ? "DESC" : "ASC";
        const sortby = value === TITLE_SORT_KEY
            ? getTitleFieldName(props.allFieldsWithDatatype)
            : value === DATE_SORT_KEY
            ? getDatePublishedFieldName(props.allFieldsWithDatatype)
            : value;
        props.setSearchData({ sortby, sortorder });
    };

    const handleChangeSortingType = (type) =>{
        setState({ sortingType: type, showSorting: false });
    };
        

    // sort by title
    const handleTitle = () => {
        props.setPublishDate({ visible: false, className: "" }); // TODO: check how the state is handled in the parent component, and see if this needs to be a partial updater 
        props.setRelevance({ visible: false, className: "" });
        props.setConceptName({ visible: false, className: "" });
        props.setTagValues({ visible: false, className: "" });
        
        switch (props.title.className) {
          case "":
            handleSort(TITLE_SORT_KEY, "activeUp");
            return props.setTitle({ className: "activeDown", visible: true });
          case "activeDown":
            handleSort(TITLE_SORT_KEY, "activeDown");
            return props.setTitle({ className: "activeUp", visible: true });
          case "activeUp":
            handleSort(TITLE_SORT_KEY, "activeUp");
            return props.setTitle({ className: "activeDown", visible: true });
        }
    };
    
    // sort by publish date
    const handlePublish = () => {
        props.setRelevance({ visible: false, className: "" });
        props.setTitle({ visible: false, className: "" });
        props.setConceptName({ visible: false, className: "" });
        props.setTagValues({ visible: false, className: "" });

        switch (props.publishDate.className) {
          case "":
            handleSort(DATE_SORT_KEY, "activeUp");
            return props.setPublishDate({
              className: "activeDown",
              visible: true,
            });
          case "activeDown":
            handleSort(DATE_SORT_KEY, "activeDown");
            return props.setPublishDate({
              className: "activeUp",
              visible: true,
            });
          case "activeUp":
            handleSort(DATE_SORT_KEY, "activeUp");
            return props.setPublishDate({
              className: "activeDown",
              visible: true,
            });
        }
    };
    
    // sort by relevance
    const handleRelevance = () => {
        props.setTitle({ visible: false, className: "" });
        props.setPublishDate({ visible: false, className: "" });
        props.setConceptName({ visible: false, className: "" });
        props.setTagValues({ visible: false, className: "" });

        switch (props.relevance.className) {
          case "":
            handleSort("relevance", "");
            return props.setRelevance({
              className: "activeDown",
              visible: true,
            });
          case "activeDown":
            handleSort("relevance", "activeDown");
            return props.setRelevance({
              className: "activeUp",
              visible: true,
            });
          case "activeUp":
            handleSort("relevance", "activeUp");
            return props.setRelevance({
              className: "activeDown",
              visible: true,
            });
        }
    };

    // sort by concept name
    const handleConceptName = ()=>{
        props.setTitle({ visible: false, className: "" });
        props.setPublishDate({ visible: false, className: "" });
        props.setRelevance({ visible: false, className: "" });
        props.setTagValues({ visible: false, className: "" });

        switch (props.conceptName.className) {
          case "":
            handleSort("conceptName", "");
            return props.setConceptName({
              className: "activeDown",
              visible: true,
            });
          case "activeDown":
            handleSort("conceptName", "activeDown");
            return props.setConceptName({
              className: "activeUp",
              visible: true,
            });
          case "activeUp":
            handleSort("conceptName", "activeUp");
            return props.setConceptName({
              className: "activeDown",
              visible: true,
            });
        }

    };

    // sort by tag values
    const handleTagValues = ()=>{
        props.setTitle({ visible: false, className: "" });
        props.setPublishDate({ visible: false, className: "" });
        props.setRelevance({ visible: false, className: "" });
        props.setConceptName({ visible: false, className: "" });

        switch (props.tagValues.className) {
          case "":
            handleSort("tagValues", "");
            return props.setTagValues({
              className: "activeDown",
              visible: true,
            });
          case "activeDown":
            handleSort("tagValues", "activeDown");
            return props.setTagValues({
              className: "activeUp",
              visible: true,
            });
          case "activeUp":
            handleSort("tagValues", "activeUp");
            return props.setTagValues({
              className: "activeDown",
              visible: true,
            });
        }
    };


    const propsForSortButtons = {
        handleSortingVisible: handleSortingVisible,
        showSorting: state.showSorting,
        sortingType: state.sortingType,
        relevance: props.relevance,
        title: props.title,
        publishDate: props.publishDate,
        tagValues: props.tagValues, 
        conceptName: props.conceptName,
        handlePublish: handlePublish,
        handleTitle: handleTitle,
        handleRelevance: handleRelevance,
        handleConceptName, 
        handleTagValues,
        listViewType: props.listViewType, // not used 
        handleListView: props.handleListView, // not used 
        children: props.children, // WTF?.. 
        search: props.search, 
        searchData: props.searchData,
        loading: !(
          !props.searchData?.loading &&
          props.searchData.data?.length
        ),
        handleChangeSortingType: handleChangeSortingType
    };


    useEffect(()=>{
        // there is ComponentDidUpdate. Make it run on props change for now, then decide
        if (props.search.emptySearch) {
            setState({ showSorting: false, sortingType: "PublishDate" });

        }
    }, [props.search?.emptySearch]);

    useEffect(()=>{
        if (!props.search.value){
            setState({ showSorting: false, sortingType: "PublishDate" });
            props.setPublishDate({
              className: "activeDown",
              visible: true,
            });
        }
    }, [props.search.value]);

    useEffect(()=>{
        if (props.search.sortby === "relevance"){
            setState({ showSorting: false, sortingType: "Relevance" });
            props.setRelevance({
              className: "activeDown",
              visible: true,
            });
        }
    }, [props.search.sortby, props.search.value]);

    return (<>
    <MySortButtons {...propsForSortButtons} />
    </>);
};