import React from "react";

import Loader from "Components/Loader";
import { SaveModal } from "./Children";
import { ChartBlock } from "./Children";
import { ContentLoader } from "Components/ContentLoader";

import style from "./style.module.scss";
import ErrorState from "Components/ErrorState";
import { InsightBreadCrumb } from "./Children";

import globel from "styles/globel/style.module.scss";
import { OverWriteWindow } from "./Children";
import TagFilter from "Components/TagFilter";
import AlertElement from "Components/AlertElement";
import { createAdvanceSearchQuery } from "Routes/Home/Helper";
import SVGIcons from "Components/SVGIcons";
import { SEARCH_TYPE } from "constants";

function template() {
  return (
    <>
      {this.state.overWriteWindow && <OverWriteWindow {...this} />}

      {this.props.createInsights.error ? (
        <>
          <ErrorState
            content="ERROR"
            type={"errorImage"}
            errorMessage={this.props.createInsights.errorMessage}
          />
        </>
      ) : (
        <>
          {this.props.saveModalWindow && (
            <SaveModal
              {...this}
              isEdit={this.props?.history?.location?.state?.isEdit}
            />
          )}
          {this.props.insightDataSetLoading ? (
            <div className={style.insightSidebar}>
              <ContentLoader
                number={1}
                height={6.2}
                width={100}
                spaceBetween={1}
              />
              <ContentLoader
                number={1}
                height={6.2}
                width={100}
                spaceBetween={1}
              />
              <ContentLoader
                number={1}
                height={19.68}
                width={100}
                spaceBetween={2}
              />
            </div>
          ) : (
            <div className={style.insightWrapper}>
              <div className={style.insightContent}>
                <div className={style.insightBlock}>
                  {this.props.insightData.data != undefined &&
                    Object.keys(this.props.insightData.data).length > 0 &&
                    chartNameLabel({ state: this })}
                  {this.props.createInsights.breadCrumbData?.length > 0 &&
                    !this.props.insightData.loading && (
                      <InsightBreadCrumb
                        crumbs={this.props.createInsights.breadCrumbData}
                        handleClick={this.handleBreadCrumb}
                        isClearable={["KEY_PHRASE_CLOUD", "HEAT_MAP"].includes(
                          this.props.createInsights.chartName
                        )}
                        clearButtonText={"Back to default"}
                      />
                    )}
                  {this.props.createInsights.searchType !==
                    SEARCH_TYPE.ADVANCED_SEARCH && !this.props.insightData.loading && (
                    <TagFilter
                      items={this.props.filterResults}
                      handleDeleteTags={this.props.handleDeleteTags}
                      handleDeleteMainTag={this.props.handleDeleteMainTag}
                      handleDeleteDate={this.props.handleDeleteDate}
                      handleDeleteAllTags={this.props.handleDeleteAllTags}
                    />
                  )}
                  {this.props.createInsights.searchType ===
                    SEARCH_TYPE.ADVANCED_SEARCH &&
                    !this.props.insightData.loading && (
                      <div className={style.advancesearchInsights}>
                        <div className={style.advancesearchInsights__item}>
                          <AlertElement
                            information={true}
                            children={createAdvanceSearchQuery(
                              this.props.createInsights
                                ?.advanceSearchPayloadData
                            )}
                            icon={"SVG-infoIcon"}
                          />
                        </div>
                        <a
                          className={style.advancesearchInsights__button}
                          onClick={() => {
                            this.props.setInsightData({
                              advanceSearchPayloadData: {},
                              searchType: SEARCH_TYPE.NORMAL_SEARCH,
                            });
                          }}
                        >
                          <span>
                            <SVGIcons type={"SVG-closeIcon"} />
                          </span>
                        </a>
                      </div>
                    )}
                  {this.props.insightData.loading ? (
                    <Loader
                      hasWrapper={true}
                      customHeight={"6vh"}
                      classList={["sm blue"]}
                    />
                  ) : (
                    <div className={style.insightBlock__chart}>
                      <div className={globel.chartWrapper}>
                        <ChartBlock {...this} />
                      </div>
                    </div>
                  )}
                </div>
                <div className={style.insightInformation}></div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default template;

function chartNameLabel({ state }) {
  return (
    state.props?.location &&
    state.props.location.state?.isEdit && (
      <div className={style.chartLabels}>
        <h3>{state.state.insightName}</h3>
      </div>
    )
  );
}
