import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
const LoaderComponent = (props) => {
  let customStyle = {};
  if (props.customHeight) {
    customStyle = {
      minHeight: props.customHeight,
    };
  }
  const withWrapper = () => {
    const overlayClassName = props.hasOverlay ? 'loader-block overlay' : 'loader-block';
    const className = `${overlayClassName} ${props.className}`;
    return (
      <div className={className} style={customStyle}>
        {withoutWrapper()}
      </div>
    );
  };

  const withoutWrapper = () => {
    let classList = [`${props.type}Loader`];
    if (props && props.classList && typeof props.classList === 'object') classList = classList.concat(props.classList);
    let list = [];
    switch (props.type) {
      case TYPES.CIRCULAR:
        for (let i = 0; i < 13; i++) {
          list.push(<div key={i} className='circularLoader__item' />);
        }
        break;
      case TYPES.LINEAR:
        return <div className={classList.join(' ')}></div>;
    }
    return <div className={classList.join(' ')}>{list}</div>;
  };

  if (props && props.hasWrapper) return withWrapper();
  else return withoutWrapper();
};

export const TYPES = {
  CIRCULAR: 'circular',
  LINEAR: 'linear',
};

LoaderComponent.propTypes = {
  /**
   * custom height
   */
  customHeight: PropTypes.string,
  /**
   * has Overlay
   */
  hasOverlay: PropTypes.bool,
  /**
   * class list
   */
  classList: PropTypes.any,
  /**
   * has wrapper
   */
  hasWrapper: PropTypes.bool,
  /**
   * main block class name
   */
  className: PropTypes.string,
  /**
   * loader type
   */
  type: PropTypes.oneOf(['circular', 'linear']),
};

LoaderComponent.defaultProps = {
  type: TYPES.LINEAR,
};

export default LoaderComponent;
