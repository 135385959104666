import {isArray} from 'lodash';
import {DATE, DATE_FILTER_KEY, LAST_USED_DATE, PUBLISHED_DATE} from '../../../Routes/Home/constants';
import {DATE_FIELDS_DATA_TYPE} from '../FilterSection.constants';
import {setDateFilterData} from '../../../utils/commonUtils';
import {generateAdvanceSearchPayload} from 'Routes/Home/Helper';

export const trimTextCheckbox = (data) => {
  let newArray = [];
  data.map((item) => {
    let trimmed = '';
    let title = item;
    title = typeof title === 'string' ? title : '' + title;
    trimmed = title.substring(0, 20);
    if (trimmed.length < title.length) {
      trimmed += '...';
    }
    newArray.push(trimmed);
  });

  return newArray;
};

/**
 *
 * @param {{[field: string]: {checked: boolean, from: string, to: string, format: string, dataType: string} |
 * {checked: boolean,loading: boolean,data: string[],value: string[],dataType: number}}} filterFieldsObject
 * @returns
 */
export const handlePublishDatePosition = (filterFieldsObject, tab) => {
  /** @type {string[]} */
  const dateFilters = [];
  /** @type {string[]} */
  const otherFilters = [];
  filterFieldsObject &&
    Object.keys(filterFieldsObject).forEach((filterName) => {
      const filterObject = filterFieldsObject[filterName];
      if (filterObject.dataType === DATE_FIELDS_DATA_TYPE) {
        if (filterName.includes('Published')) {
          dateFilters.push(filterName);
        }
      } else {
        if (!filterName.includes(DATE)) {
          otherFilters.push(filterName);
        }
      }
    });

  let customSortedData = [...dateFilters, ...otherFilters];
  ['News', 'External Reports'].includes(tab)
    ? (customSortedData = customSortedData.filter((item) => item !== 'Source Type'))
    : '';
  return customSortedData;
};

export const findSearchType = (pathname) => {
  switch (pathname) {
    case '/insights':
      return 1;
    case '/dashboard':
      return 2;
    default:
      return 0;
  }
};

export const findPayloadData = ({type, self}) => {
  if (type == 0) {
    return {
      filtersListforData: generateAdvanceSearchPayload(self.search),
      searchWords: self.search.searchWords,
      searchCriterias: self.search.searchCriterias,
      buckets: self.search.buckets,
    };
  } else if (type == 1) {
    return {
      filtersListforData: generateAdvanceSearchPayload(self.createInsights, true),
      searchWords: self.createInsights.searchWords,
      searchCriterias: self.createInsights.searchCriterias,
      buckets: self.createInsights.buckets,
    };
  }
};
