import {
  GET_ALL_USER_SETTINGS_FAILED,
  GET_ALL_USER_SETTINGS_SUCCESS,
  GET_ALL_USER_SETTINGS_STARTED,
  CHANGE_DATE_FORMAT_STARTED,
  CHANGE_DATE_FORMAT_SUCCESS,
  CHANGE_DATE_FORMAT_FAILED,
  SET_CURRENT_PROFILE_STARTED,
  SET_CURRENT_PROFILE_SUCCESS,
  SET_CURRENT_PROFILE_FAILED,
  CHANGE_SORT_ORDER_STARTED,
  CHANGE_SORT_ORDER_SUCCESS,
  CHANGE_SORT_ORDER_FAILED,
  ADD_USER_DETAILS_FAILED,
  ADD_USER_DETAILS_STARTED,
  ADD_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILED,
  UPDATE_USER_DETAILS_STARTED,
  UPDATE_USER_DETAILS_SUCCESS,
  DELETE_USER_DETAILS_FAILED,
  DELETE_USER_DETAILS_STARTED,
  DELETE_USER_DETAILS_SUCCESS,
} from "../../Redux.constants";
import {
  getAllUserSettings,
  savePreferenceSettings,
  saveUserDetailsSetting,
} from "../../../api/settings";
import { getCurrentUserDetails } from "../../../api/users";
import { KEYS } from "../../../dataKeys";
import { notify } from "../../../Components/Notification";
import { handleApiError } from "../../../utils/commonUtils";

export function GetUserSettings(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_USER_SETTINGS_STARTED,
      payload: {},
    });
    try {
      const { data } = await getAllUserSettings(payload);
      dispatch({
        type: GET_ALL_USER_SETTINGS_SUCCESS,
        payload: { data: data.result },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_ALL_USER_SETTINGS_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function ChangeFormatSettings(payload) {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_DATE_FORMAT_STARTED,
      payload: {},
    });
    try {
      dispatch({
        type: CHANGE_DATE_FORMAT_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: CHANGE_DATE_FORMAT_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function GetCurrentProfileAction() {
  return async (dispatch) => {
    dispatch({ type: SET_CURRENT_PROFILE_STARTED, payload: {} });
    try {
      let emailId = localStorage.getItem(KEYS.ACCESS_EMAIL) ?? "";
      const { data } = await getCurrentUserDetails({ emailId });
      if (!data?.result.currentProject) {
        notify.error("No active project found, Please select one!");
      }
      dispatch({ type: SET_CURRENT_PROFILE_SUCCESS, payload: data.result });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({ type: SET_CURRENT_PROFILE_FAILED, payload: error })
      );
    }
  };
}

export function updatePreferenceSettings(payload) {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_SORT_ORDER_STARTED,
      payload: {},
    });
    try {
      let emailId = localStorage.getItem(KEYS.ACCESS_EMAIL) ?? "";
      const data = await savePreferenceSettings({
        emailId,
        dateFormat: payload.dateFormat,
        sortOrder: payload.sortOrder,
        showAllFields: !payload.hideResultsZeroCount,
      });
      dispatch({
        type: CHANGE_SORT_ORDER_SUCCESS,
        payload: payload,
      });
      payload.handleNotify(true);
    } catch (error) {
      handleApiError(error, () => {
        payload.handleNotify(false);
        dispatch({
          type: CHANGE_SORT_ORDER_FAILED,
          payload: error,
        });
      });
    }
  };
}

export function AddUserDetails(payload, callBack) {
  return async (dispatch) => {
    dispatch({
      type: ADD_USER_DETAILS_STARTED,
      payload: {},
    });
    try {
      const { data } = await saveUserDetailsSetting(payload);
      dispatch({
        type: ADD_USER_DETAILS_SUCCESS,
        payload: { data: data?.result },
      });
      callBack();
    } catch (error) {
      handleApiError(error, () => {
        const errorMessage = error?.response?.data?.result;
        if (errorMessage) notify.error(errorMessage);
        dispatch({
          type: ADD_USER_DETAILS_FAILED,
          payload: error,
        });
      });
    }
  };
}

export function UpdateUserDetails(payload, callBack) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_DETAILS_STARTED,
      payload: {},
    });
    try {
      const data = await saveUserDetailsSetting(payload);
      dispatch({
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: { data: data.data },
      });
      callBack();
    } catch (error) {
      handleApiError(error, () => {
        const errorMessage = error?.response?.data?.result;
        if (errorMessage) notify.error(errorMessage);
        dispatch({
          type: UPDATE_USER_DETAILS_FAILED,
          payload: error,
        });
      });
    }
  };
}

export function DeleteUserDetails(payload, callBack) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_USER_DETAILS_STARTED,
      payload: {},
    });
    try {
      const data = await saveUserDetailsSetting(payload);
      dispatch({
        type: DELETE_USER_DETAILS_SUCCESS,
        payload: { data: data.data },
      });
      callBack();
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: DELETE_USER_DETAILS_FAILED,
          payload: error,
        })
      );
    }
  };
}
