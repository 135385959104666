import React from "react";
import ModalWindow from "../ModalWindow";
import { HEADER_CONSTANTS } from "../../Constants/Header/Header.constants";
import ConfirmationMessage from "../ConfirmationMessage";

const Showlogoutmodal = (props) => {
  return (
    <ModalWindow
      title={false}
      open={props.showLogoutModal}
      footer={false}
      isNotClosable={true}
    >
      <ConfirmationMessage
        cancelButtonLabel="Yes"
        confirmButtonLabel="No"
        loading={props.loading}
        heading={HEADER_CONSTANTS.LOGOUT_MODAL_HEADER}
        message={HEADER_CONSTANTS.LOGOUT_MODAL_BODY}
        onOk={props.handleModal}
        onCancel={props.handleIsLogout}
      />
    </ModalWindow>
  );
};

export default Showlogoutmodal;
