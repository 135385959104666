import {
  INSIGHTS_GET_MAPPING_PAYLOAD_STARTED,
  INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS,
  INSIGHTS_GET_MAPPING_PAYLOAD_FAILED,
  GET_INSIGHTS_START,
  GET_INSIGHTS_SUCCESS,
  DELETE_INSIGHTS,
  GET_INSIGHT_DATASET_STARTED,
  GET_INSIGHT_DATASET_SUCCESS,
  GET_INSIGHT_DATASET_FAILED,
  GET_ALL_INSIGHTS_STARTED,
  GET_ALL_INSIGHTS_FAILED,
  GET_ALL_INSIGHTS_SUCCESS,
  GET_INSIGHTS_ERROR,
} from "../../Redux.constants";
import {
  deleteInsight,
  getInsightsList,
  getInsightDataSet,
  getFullInsightsDetailsUser,
} from "../../../api/insights";
import { KEYS } from "../../../dataKeys";
import { getAllFieldsList } from "../../../api/search";
import { DEFAULT_PAGE_COUNT } from "../../../constants";
import { handleApiError } from "../../../utils/commonUtils";

export function InsightsMappingPayload(payload) {
  return async (dispatch) => {
    dispatch({
      type: INSIGHTS_GET_MAPPING_PAYLOAD_STARTED,
      payload: {},
    });
    //debugger;
    try {
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      let { data } = await getAllFieldsList({ emailId: email });
      dispatch({
        type: INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS,
        payload: {
          data: data?.result?.sort((a, b) => a.name.localeCompare(b.name)),
        },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: INSIGHTS_GET_MAPPING_PAYLOAD_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function getInsightData({ pageNumber, pageSize }) {
  return async (dispatch) => {
    dispatch({
      type: GET_INSIGHTS_START,
      payload: {},
    });
    try {
      let { data } = await getInsightsList({
        emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
        pageNumber: pageNumber || 1,
        pageSize: pageSize || DEFAULT_PAGE_COUNT,
      });

      dispatch({
        type: GET_INSIGHTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_INSIGHTS_ERROR,
          payload: error,
        })
      );
    }
  };
}

export function deleteInsightData(id, callBack) {
  return async (dispatch) => {
    try {
      await deleteInsight({ id });
      dispatch({
        type: DELETE_INSIGHTS,
        payload: id,
      });
      callBack();
    } catch (error) {}
  };
}

export function InsightsDataset(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_INSIGHT_DATASET_STARTED,
      payload: {},
    });
    try {
      let data = await getInsightDataSet(payload);
      dispatch({
        type: GET_INSIGHT_DATASET_SUCCESS,
        payload: { data: data },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_INSIGHT_DATASET_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function getAllInsightsList(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_INSIGHTS_STARTED,
      payload: {},
    });
    try {
      let fullInsightsData = await getFullInsightsDetailsUser(payload);
      let insightDetails = fullInsightsData.data.result;
      dispatch({
        type: GET_ALL_INSIGHTS_SUCCESS,
        payload: { data: fullInsightsData, insightDetails },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_ALL_INSIGHTS_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function setInsightList(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_INSIGHTS_SUCCESS,
      payload,
    });
  };
}
