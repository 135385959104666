import {SAVE_INSIGHT_FAILED, SAVE_INSIGHT_SUCCESS, SAVE_INSIGHT_STARTED} from '../../Redux.constants';
import {saveInsights} from '../../../api/insights';
import {handleApiError} from '../../../utils/commonUtils';

export function SaveInsights(payload, handleExisting) {
  return async (dispatch) => {
    dispatch({
      type: SAVE_INSIGHT_STARTED,
      payload: {},
    });
    try {
      const data = await saveInsights(payload);
      dispatch({
        type: SAVE_INSIGHT_SUCCESS,
        payload: data,
      });
      handleExisting({error: false, message: null});
    } catch (error) {
      handleApiError(error, () => {
        handleExisting({error: true, message: error});
        dispatch({
          type: SAVE_INSIGHT_FAILED,
          payload: error,
        });
      });
    }
  };
}
