import React from 'react';
// import {notify} from '../../Components/Notification';
// import {ROUTES} from '../../Constants/Routes/Routes.constants';
import {EMAIL_ERROR1} from '../../Constants/Login/Login.constants';
import {template} from './template';
import {
  //   componentMountHelper,
  //   confirmPasswordHelper,
  //   forgotPasswordOkHelper,
  //   generatePayload,
  //   rememberMeHelper,
  storeUserAuthKeys,
  //   validatePassword,
} from './helper';
import {KEYS} from '../../dataKeys';
import {checkUserAdminFields} from './helper';
import {notify} from '../../Components/Notification';
// import {handleAdfsResponse} from './Children/RightSide/helper';

class Login extends React.Component {
  state = {
    userName: '',
    password: '',
    email: '',
    newPassword: '',
    status: false,
    confirmPassword: {value: '', errorMessage: ''},
    otp: '',
    passWordResetBlock: false,
    confimLogoutModal:
      this.props.history.location.state !== undefined ? this.props.history.location.state.logout : false,
    errorMessage: EMAIL_ERROR1,
    validityCheck: {
      userName: false,
      password: false,
      email: false,
    },
    forgetPasswordModal: false,
    otpStatus: false,
    successModal: false,
    passWordValidityErrorMessage: '',
    adfsPopupError: false,
    loginInProgress: false,
    loginFailed: false,
  };

  componentDidMount() {
    // const msalInstance = this.props.msalInstance;
    // this.setState({loginInProgress: true});
    // msalInstance
    //   .handleRedirectPromise()
    //   .then((response) => {
    //     if (response) {
    //       handleAdfsResponse({
    //         response,
    //         msalInstance,
    //         history: this.props.history,
    //         self: this,
    //       });
    //     } else {
    //       this.setState({loginInProgress: false});
    //     }
    //   })
    //   .catch((err) => {
    //     this.setState({loginInProgress: false});
    //     // this.setState({adfsPopupError: true});
    //   });
  }

  handleForceLogout = () => {
    // const msalInstance = this.props.msalInstance;
    // msalInstance.logoutRedirect();

    this.setState({adfsPopupError: false});
    localStorage.setItem(KEYS.POP_UP_BLOCKED, true);
  };
  login = (data) => {
    this.setState({email: '', password: ''});
    storeUserAuthKeys(data, this.props.history);
  };

  superAdminLoginFieldChange = (field, value) => {
    this.setState({[field]: value});
  };
  cancelSupreAdimnModal = () => {
    this.setState({email: '', password: ''});
  };

  superAdminLogin = () => {
    let {email, password} = this.state;
    let data = {emailid: email, password};
    let valid = checkUserAdminFields(data);
    if (valid.error) {
      notify.error(valid.message);
    } else {
      this.props.signIn(data, this.login);
    }
  };
  render() {
    return template.call(this);
  }
}
export default Login;

// login = (data) => {
//   storeUserAuthKeys(data, this.props.history);
// };

// handleUserName = (value) => {
//   this.setState({
//     userName: value.target.value,
//     validityCheck: { ...this.state.validityCheck, userName: false },
//   });
// };

// handleLogin = (event) => {
//   if (this.state.userName == '' && this.state.password == '') {
//     notify.error(EMPTY_CREDENTIAL_ERROR, true);
//   } else {
//     event.preventDefault();
//     let payload = generatePayload({
//       userName: this.state.userName,
//       password: this.state.password,
//     });
//     if (event.keyCode == 13) {
//       this.props.signIn(payload, this.login);
//     } else {
//       this.props.signIn(payload, this.login);
//     }
//   }
// };

// isLetter(str) {
//   return str.length === 1 && str.match(/[a-z]/i);
// }

// validateSubmitMail = (data) => {
//   if (data == 'Fail') {
//     this.setState({
//       validityCheck: { ...this.state.validityCheck, email: true },
//       errorMessage: EMAIL_ERROR2,
//     });
//   } else {
//     let results = true;
//     this.setState({
//       validityCheck: { ...this.state.validityCheck, email: !results },
//     });
//     if (results) {
//       this.setState({
//         status: true,
//       });
//     }
//   }
// };
// showPasswordForm = (value) => {
//   if (value) {
//     this.setState({ otpStatus: value, otpErrorMsg: '' });
//   } else {
//     this.setState({ otpStatus: false, otpErrorMsg: OTP_VALIDATION_ERROR });
//   }
// };
// forgotPasswordOkHandler = (e, value) => forgotPasswordOkHelper(this);

// validateSendEmail = () => {
//   if (this.state.email == '') {
//     this.setState({
//       errorMessage: EMAIL_ERROR1,
//       validityCheck: { ...this.state.validityCheck, email: true },
//     });
//   } else {
//     this.props.sendEmail({ email: this.state.email }, this.validateSubmitMail);
//   }
// };

// handlePasswordValidity = (event) => {
//   let validity = validatePassword(this.state.newPassword);
//   let errorMessage = choosePasswordErrorMessage(this.state.newPassword);
//   this.setState({
//     validityCheck: { ...this.state.validityCheck, password: !validity },
//     passWordValidityErrorMessage: errorMessage,
//   });
// };

// handlePassword = (event) => this.setState({ password: event.target.value });

// handleNewPassword = (value) => {
//   this.setState({
//     newPassword: value.target.value,
//     validityCheck: { ...this.state.validityCheck, password: false },
//   });
// };

// handleConfirmPassword = (value) => confirmPasswordHelper(value, this);

// handleSuccessModal = () => this.setState({ successModal: false });

// handleOTP = (value) => this.setState({ otp: value.target.value });

// handleResendOTP = () => this.props.sendEmail({ email: this.state.email }, this.validateSubmitMail);

// handleRememberMe = (event) => {
//   rememberMeHelper(event);
// };
