import React, { Component } from "react";

import { getInsightsById } from "../../api/insights";
import ButtonElement from "../../Components/ButtonElement";
import ErrorState from "../../Components/ErrorState";
import { DOWNLOAD_PREVIEW_CONSTANTS } from "../../Constants/DownloadPreview/DownloadPreview.constants";
import Dashboardpreview from "./Children/Preview";
import {
  dashboardMountHelper,
  downloadPdfHelper,
  handleInsightResponceData,
} from "./helper";

import style from "./style.module.scss";
import {
  createInsightsDataPayload,
  formatInsightData,
  handleInsightApiCall,
} from "Routes/Insights/Helper";
import { SEARCH_TYPE } from "constants";
import TooltipContainer from "Components/Tooltip";
class Downloadpreview extends Component {
  constructor(props) {
    super(props);
    this.previewRef = React.createRef();
  }
  state = {
    data: [],
    chartNames: [],
    config: [],
    labels: [],
    items: [],
    selectedInsights: [],
    insightsData: [],
    dashboardName: "",
    importLoading: false,
    isView: true,
    layout: [],
    newCounter: 0,
    items: [],
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    isDashboard: false,
    isInsight: false,
    chartContent: "",
    insightName: "",
    exportPdfLoading: false,
    previewType: "",
    dataLoading: true,
  };

  setlocalState = (data) =>
    this.setState((prevState) => ({ ...prevState, ...data }));

  async componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isDashboard
    ) {
      this.setState({
        isDashboard: true,
        dataLoading: true,
      });
      this.props.getViewDashboardData(
        this.props.location.state.id,
        ({ data, error }) => {
          if (error) return this.setState({ dataLoading: false });
          dashboardMountHelper({
            importInsights: this.props.importInsights,
            data,
            setState: this.setlocalState,
          });
        }
      );
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isInsight
    ) {
      this.setState({ dataLoading: true, isInsight: true });
      const { record } = this.props.location.state;
      let { data } = await getInsightsById({ id: record.id });
      const { insightsContentbyte } = data.result;
      const {
        requestPayload,
        chartName,
        config,
        payload,
      } = insightsContentbyte;
      const isAdvanceSearch = requestPayload?.advanceSearchPayloadData["group1"]
        ? true
        : false;
      handleInsightApiCall({
        setlocalState: (data) =>
          handleInsightResponceData({
            ...data,
            config,
            chartName,
            setlocalState: this.setlocalState,
          }),
        payload: createInsightsDataPayload(
          formatInsightData({
            dateFilter: requestPayload?.dateFilters,
            filter: requestPayload?.filtersforData[0] || {},
            requestPayload: requestPayload,
            advanceSearchPayloadData: isAdvanceSearch
              ? requestPayload?.advanceSearchPayloadData
              : {},
            breadCrumbs: requestPayload?.searchWords || [],
            searchCriterias: requestPayload?.searchCriterias || [],
            searchValue: requestPayload?.searchValue || "",
            chartName,
            searchType: isAdvanceSearch
              ? SEARCH_TYPE.ADVANCED_SEARCH
              : SEARCH_TYPE.NORMAL_SEARCH,
            config: { [chartName]: config },
            payload: payload,
          })
        ),
      });
      this.setState({
        chartContent: data.insightsContentbyte,
        dataSet: record.dataSet,
        insightName: record.insightsName,
      });
    }

    let urlSearchValue = this.props?.location?.search?.split("?")[1];
    this.setState({ previewType: urlSearchValue });
  }

  onLayoutChange = (value) => {
    this.setState({ layout: value });
  };
  printDocument = () => downloadPdfHelper({ state: this });

  handleBackToDefault = () => {
    window.location.href = "/insights?from=newtab";
  };

  handleBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  render() {
    return (
      <div className={style.downloadElementblock}>
        {this.props.createInsights.error ||
        this.props.importedInsights.importError ? (
          <ErrorState
            content="ERROR"
            type={"errorImage"}
            errorMessage={
              this.props.createInsights.errorMessage ||
              this.props.importedInsights.errorMessage
            }
            customHeight={"500"}
          />
        ) : (
          <>
            <div className={style.downloadElementblock__heading}>
              {this.props.location.state.isInsight && (
                <ButtonElement
                  children={DOWNLOAD_PREVIEW_CONSTANTS.BACK_TO_DEFAULT}
                  type="secondary"
                  onClick={this.handleBackToDefault}
                />
              )}
              {this.props.location.state.isDashboard && (
                <ButtonElement
                  children={DOWNLOAD_PREVIEW_CONSTANTS.BACK_TO_DASHBOARD_CANVAS}
                  type="secondary"
                  onClick={this.handleBackToDashboard}
                />
              )}
              <ButtonElement
                type="primary"
                onClick={() => this.printDocument()}
                //icon={'SVG-download'}
                loading={this.state.exportPdfLoading}
              >
                <TooltipContainer
                  placement="left"
                  title={
                    "Download only if the insight has been loaded completely"
                  }
                >
                  {DOWNLOAD_PREVIEW_CONSTANTS.BUTTON_LABEL}
                </TooltipContainer>
              </ButtonElement>
            </div>
            <div className={style.downloadElementblock__body}>
              <Dashboardpreview {...this} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Downloadpreview;
