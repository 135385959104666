import { notify } from "../../../../Components/Notification";
import { errorMEssageHandler } from "../../../../utils/ErrorHandler";
import { INITIAL_STATE } from "../initialState";

export function fetchProjectListSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    data: payload,
  };
}

export function fetchProjectListStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function fetchProjectListFailed(state, payload) {
  notify.error(errorMEssageHandler(payload?.response?.status), true);
  return {
    ...state,
    loading: false,
    error: true,
  };
}
