import { notify } from "../Components/Notification";

export const errorHandler = (error) => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status == 500
  ) {
    notify.error("Internal Server Error", true);
  } else if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status == 401
  ) {
  } else {
    if (error?.response?.data?.result)
      notify.error(error.response.data.result, true);
    else if (error && error.message) notify.error(error.message, true);
  }
};

export const errorMEssageHandler = (error) => {
  let description = ERROR_HANDLING[error]?.description
    ? ERROR_HANDLING[error]?.description
    : "Looks likes an error occurred";
  let title = ERROR_HANDLING[error]?.title
    ? ERROR_HANDLING[error]?.title
    : "Hmm... Something is not right!";
  let status = { description, title };
  return status;
};

export const ERROR_HANDLING = {
  204: {
    statusCode: 204,
    title: "Uh..Oh!",
    description: "Looks like there is nothing yet to display here.",
  },
  301: {
    statusCode: 301,
    title: "Oopzy..Daisy!",
    description:
      "The content that you are looking for might have been moved away from here.",
  },
  400: {
    statusCode: 400,
    title: "Sorry!",
    description: "Something is wrong with the request!",
  },
  401: {
    statusCode: 401,
    title: "Thou Shalt Not Pass!",
    description: "You are not authorized to proceed!",
  },
  402: {
    statusCode: 402,
    title: "Payment Required!",
    description: "You might wanna pay for this service.",
  },
  403: {
    statusCode: 403,
    title: "Forbidden!",
    description: "You are beyond the borders.",
  },
  404: {
    statusCode: 404,
    title: "That's a four o' four!",
    description: "I just found that the page doesn't exist!'",
  },
  405: {
    statusCode: 405,
    title: "That's a four o' five!",
    description: "I just found that the page doesn't exist!'",
  },
  408: {
    statusCode: 408,
    title: "Oh, timed out!",
    description: "I didn't expect this to be taking so long!",
  },
  500: {
    statusCode: 500,
    title: "Oh no!",
    description: "The servers might have broken something!",
  },
  502: {
    statusCode: 502,
    title: "Almost there!",
    description: "Bad gateway error!",
  },
  503: {
    statusCode: 503,
    title: "Service Unavailable",
    description: "The item is not in the menu!",
  },
  504: {
    statusCode: 504,
    title: "No way!",
    description: "It's the gateway timeout!",
  },
  507: {
    statusCode: 507,
    title: "Insufficient storage",
    description: "You might wanna take this to the admin!",
  },
};
