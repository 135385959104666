import React from "react";

import { CreateInsights, SavedInsights } from "./components";
import ErrorState from "Components/ErrorState";
import HeaderSection from "Components/HeaderSection";
import FilterSection from "Components/FilterSection";
import ModalWindow from "Components/ModalWindow";

import {
  CLOSE_TAB_NEW_MESSAGE1,
  CLOSE_TAB_NEW_MESSAGE2,
} from "Constants/Insights/Insights.constants";

import style from "./style.module.scss";
import global from "styles/globel/style.module.scss";
import { SEARCH_TYPE } from "constants";
import { MyFilterSection } from "Components/MyFilter/MyFilterSection/MyFilterSection";

function template(props) {
  const insightData = this.props.InsightsPage?.insightData;
  const { chartName } = this.props.createInsights;
  const { data, dataLoading: loading } = this.state;
  const chartLoadingOrNoData =
    loading ||
    (chartName === "STREAM_CHART"
      ? data?.data?.length === 0
      : data?.length === 0);

  return (
    <div
      className={`${global.layoutBlock} ${
        this.props.mainFrameState.state.filterVisible.visible
          ? global[this.props.mainFrameState.state.filterVisible.className]
          : ""
      } `}
    >
      <div className={global.pageAside}>
        <FilterSection
          {...this}
          handleSelectAll={this.handleSelectAll}
          handleClearAll={this.handleClearAll}
          loading={this.props.FilterFields.loading}
          error={this.props.FilterFields.error}
          errorMessage={this.props.FilterFields.errorMessage}
          dateFilter={this.props.createInsights.dateFilter}
          filter={this.props.createInsights.filter}
        />
      </div>
      <div className={style.InsightContainer}>
        <HeaderSection
          {...this.props}
          handleSave={this.handleSave}
          loading={loading}
          loadingOrNoData={chartLoadingOrNoData}
          handleFilterVisible={this.props.mainFrameState.handleFilterVisible}
          filterVisible={this.props.mainFrameState.state.filterVisible}
          handleSavedInsightModal={this.handleSavedInsightModal}
          showViewInNewTabDisabled={this.state.showViewInNewTabDisabled}
          filterDisable={
            this.props.createInsights.searchType == SEARCH_TYPE.ADVANCED_SEARCH
              ? true
              : false
          }
        />
        <>
          {this.props.createInsights.error ? (
            <ErrorState
              content="ERROR"
              type={"errorImage"}
              errorMessage={this.props.createInsights.errorMessage}
              customHeight={"500"}
            />
          ) : (
            <CreateInsights
              insightData={{
                data: this.state.data,
                totalCount: this.state.totalCount,
                loading:
                  this.state.dataLoading || this.state.switchProjectLoading,
              }}
              history={this.props.history}
              location={this.props.location}
              saveModalWindow={this.state.saveModalWindow}
              SaveModalCancelHandler={this.SaveModalCancelHandler}
              handleShowModalWindow={this.handleShowModalWindow}
              filterResults={this.state.filterResults}
              handleDeleteTags={this.handleDeleteTags}
              handleDeleteMainTag={this.handleDeleteMainTag}
              handleDeleteAllTags={this.handleDeleteAllTags}
              handleDeleteDate={this.handleDeleteDate}
            />
          )}

          {this.state.showSavedInsight && (
            <ModalWindow
              open={this.state.showSavedInsight}
              title={"Saved Insights"}
              width={900}
              okText="Close"
              onOk={(e) => this.handleSavedInsightModal(false)}
              isNonMaskable={true}
              isNotClosable={true}
              isHideCancelButton={true}
            >
              {this.props.InsightsPage.error ? (
                <ErrorState
                  content="ERROR"
                  type={"errorImage"}
                  errorMessage={this.props.InsightsPage.errorMessage}
                  customHeight={"500"}
                />
              ) : (
                <SavedInsights {...this} insightData={insightData} />
              )}
            </ModalWindow>
          )}
        </>
      </div>
      {this.state.notInteractive && (
        <ModalWindow
          open={true}
          title={"Syncing Stopped!"}
          width={900}
          okText="Close this tab"
          onOk={this.handleTabCloseWindow}
          isNonMaskable={true}
          isNotClosable={true}
          isHideCancelButton={true}
        >
          <h5>{CLOSE_TAB_NEW_MESSAGE1} </h5>
          <h5>{CLOSE_TAB_NEW_MESSAGE2}</h5>{" "}
        </ModalWindow>
      )}
    </div>
  );
}

export default template;
