import {notify} from '../../../../Components/Notification';
import {errorMEssageHandler} from '../../../../utils/ErrorHandler';

export function getUserProfileSuccess(state, payload) {
  return {
    ...state,
    userListloading: false,
    error: false,
    data: payload.result,
    totalCount: payload.resultHits,
  };
}

export function getUserProfileStarted(state, payload) {
  return {
    ...state,
    userListloading: true,
    error: false,
  };
}

export function getUserProfileFailed(state, payload) {
  return {
    ...state,
    userListloading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function updateUserProfileStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function updateUserProfileSuccess(state, payload) {
  payload.apiHandler('success');
  return {
    ...state,
    loading: false,
    error: false,
    userProfile: payload,
  };
}

export function updateUserProfileFailed(state, payload) {
  payload.apiHandler('failed');
  return {
    ...state,
    loading: false,
    error: true,
  };
}
