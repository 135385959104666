import React, { Component } from "react";
import SVGIcons from "../SVGIcons";
import PropType from "prop-types";
import style from "./style.module.scss";
import TooltipContainer from "../Tooltip";
export default class AlertElement extends Component {
  state = {
    viewMore: false,
  };
  setStyle = () => (this.props.information ? style.alertInfo : style.alert);
  handleChildren = (data) => {
    let trimmedData = this.state.viewMore
      ? data?.substring(0, data.length)
      : data.length > 419
      ? data?.substring(0, 419).concat("...")
      : data?.substring(0, 419);
    return trimmedData;
  };
  render() {
    return (
      <div className={style.alertBlock}>
        <div className={`${style.alertInner} ${this.setStyle()}`}>
          <TooltipContainer title={"Advanced search query"}>
            <span className={style.alertInner__icon}>
              <SVGIcons type={this.props.icon} />
            </span>
          </TooltipContainer>
          <p>
            {this.handleChildren(this.props.children)}
            {this.props.children.length > 419 && (
              <a
                className={style.textViewmore}
                onClick={() =>
                  this.setState((prev) => ({
                    viewMore: !prev.viewMore,
                  }))
                }
              >
                {this.state.viewMore ? "view less" : "view more"}{" "}
              </a>
            )}
          </p>
        </div>
      </div>
    );
  }
}
AlertElement.Prototype = {
  children: PropType.element,
  information: PropType.bool,
};
AlertElement.defaultValue = {
  childern: null,
};
