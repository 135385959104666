import {
  ADD_TAGS_FAILED,
  ADD_TAGS_START,
  ADD_TAGS_SUCCESS,
} from "../../../Redux.constants";
import { addTags as addTagsApi } from "../../../../api/search";
import { KEYS } from "../../../../dataKeys";
import { handleApiError } from "../../../../utils/commonUtils";
import { documentSelectionChangeAction } from '../../../../Redux/Reducers/Home/HomeState';
/**
 *
 * @param {{dispatch: any,
 * payload: {uids: number[], tags: {type: 'public', value: string}[], allArticleList: any[], searchType: string}}} param0
 */
export async function addTagsResultsHandler({ dispatch, payload }) {
  dispatch({
    type: ADD_TAGS_START,
    payload: {},
  });
  const user_tags = payload.tags.map((tag) => tag.value);
  const uids = payload.uids;
  const updatedData = [...payload.allArticleList].filter(a => a !== undefined);
  const uidsReturn = updatedData.map(d => d.DocumentId);
  try {
    const { data } = await addTagsApi({
      emailId: localStorage.getItem(KEYS.ACCESS_EMAIL),
      uids: uids?.map(u => String(u)),
      uidsReturn: uidsReturn?.map(u => String(u)),
      append: payload.append,
      user_tags,
    });



    payload.uids?.forEach(u => {
      const articleIndex = payload.allArticleList?.findIndex(
        (article) => article.DocumentId === u
      );
      if (articleIndex !== -1) {
        updatedData[articleIndex] = data.result.find(d => d.DocumentId === u);
      }
    });

    payload.setSearchLocalData(updatedData);
    if (payload.clearSelection) {
      dispatch(documentSelectionChangeAction({ toClear: true }));
    }

    dispatch({
      type: ADD_TAGS_SUCCESS,
      payload: {uids},
    });
  } catch (error) {
    handleApiError(error, () =>
      dispatch({
        type: ADD_TAGS_FAILED,
        payload: { error, user_tags, uids }
      })
    );
  }
}
