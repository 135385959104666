import {random} from 'lodash';
import {ADVANCE_SEARCH_DYNAMIC_KEYS} from '../../Constants/Settings/Settings.constants';
import {formatFilterLabel} from '../../utils/formatFilterLabel';
import {
  DATE,
  DATE_FILTER_KEY,
} from '../Home/constants';
import {checkIsTexOrDatetField} from './controller';
import {
  convertArrayToString,
  getFilteredLength,
  getSelectedFilters,
  getSelectedValues,
  getValues,
  removeDuplicates,
  removeSelectedValues,
  renameDeletedKeys,
} from './helperFunctions';
import { DATE_FIELDS_DATA_TYPE } from 'Components/FilterSection/FilterSection.constants';

//----Exporting functions----//
//Formating select values
export const formatSelectValue = (props) => {
  let allFilters = props.FilterFields.advanceSearchFilter;
  const selectedFilter = getSelectedFilters(props);
  /** @type {string[]} */
  const advancedDateFilters = [];
  /** @type {string[]} */
  const otherAdvancedFilters = [];
  allFilters &&
    Object.keys(allFilters)
      ?.filter((item) => !selectedFilter.includes(item))
      ?.forEach((filterName) => {
        const filterObject = allFilters[filterName];
        if (filterObject.dataType === DATE_FIELDS_DATA_TYPE) {
          if (filterName.includes('Published')) {
            advancedDateFilters.push(filterName);
          }
        } else {
          if (!filterName.includes(DATE)) {
            otherAdvancedFilters.push(filterName);
          }
        }
      });
  let advancedFilters = [...advancedDateFilters, ...otherAdvancedFilters];
  return advancedFilters
    .sort((current, previous) => current.localeCompare(previous))
    .map((item) => ({label: formatFilterLabel(item), value: item}));
};

// Formating select value
export const formatSelectValueList = ({props, sliceCount, searchValue}) => {
  const settings = props.FilterFields.advanceSearchFilter;
  const key = props.group[props.value][props.filter].key;
  const selectedValues = getSelectedValues(props);
  const listingValues =
    key == DATE_FILTER_KEY && selectedValues.length != 0
      ? ['date']
      : removeSelectedValues(
          removeDuplicates(
            getValues(
              getValuesParams({
                data: settings[key]?.value,
                sliceCount,
                searchValue,
              })
            )
          ),
          selectedValues
        );
  const newArray = listingValues
    ?.sort((current, previous) => current?.localeCompare(previous))
    ?.map((value) => {
      return {label: value, value};
    });

  return newArray;
};
const getValuesParams = ({data, sliceCount, searchValue}) => {
  if (searchValue) {
    return data.filter((item) => item.value.toLowerCase().includes(searchValue.toLowerCase()));
  } else {
    return data.slice(0, sliceCount);
  }
};

// Add filter
export const filterAddHelper = ({setGroup, state, groupName, filterName}) => {
  let filterLength = getFilteredLength(state[groupName]) + 1;
  let filterObject = {
    facet: {value1: {}},
    isOR: true,
  };
  setGroup.setState({
    ...state,
    [groupName]: {
      ...state[groupName],
      [`filter${filterLength}`]: filterObject,
    },
  });
};

// Add Groups
export const groupAddHelper = ({setGroup, state}) => {
  let groupLength = random(5, 10000);
  let groupObject = {
    filter1: {facet: {value1: {}}},
    isOR: true,
  };
  setGroup.setState({
    ...state,
    [`group${groupLength}`]: groupObject,
  });
};

// Add facets
export const facetAddHelper = ({setGroup, facet, filter, group, state}) => {
  let facetLength = getFilteredLength(state[group][filter][facet]) + 1;
  setGroup.setState({
    ...state,
    [group]: {
      ...state[group],
      [filter]: {
        ...state[group][filter],
        [facet]: {
          ...state[group][filter][facet],
          [`value${facetLength}`]: {isOR: true, data: ''},
        },
      },
    },
  });
};

export const handleSelectFilterHelper = ({setGroup, state, value, facet, filter, group}) => {
  setGroup.setState({
    ...state,
    [group]: {
      ...state[group],
      [filter]: {
        ...state[group][filter],
        key: value,
        [facet]: {value1: {}},
      },
    },
  }); /* Setting selected value*/
};

// Delete facet
export const facetDeleteHelper = ({item, state, setGroup}) => {
  const {value, facet, group, filter} = item;
  let groupData = state;
  let facetList = groupData[group][filter];
  delete facetList[facet][value];
  facetList[facet] = renameDeletedKeys(facetList[facet], 'value');
  groupData[group][filter] = facetList;
  setGroup.setState({...groupData});
};

// Delete filter
export const filterDeleteHelper = ({item, state, setGroup}) => {
  const {group, filter} = item;
  let groupData = state;
  let filterList = groupData[group];
  delete filterList[filter];

  groupData[group] = renameDeletedKeys(groupData[group], 'filter');
  setGroup.setState({...groupData});
};

// Delete group
export const groupDeleteHelper = ({group, state, setGroup}) => {
  let groupData = state;
  delete groupData[group];
  if (groupData.group0) {
    const groupKey = Object.keys(groupData)[1];
    groupData = {
      ...groupData,
      [groupKey]: {...groupData[groupKey], isOR: false},
    };
  }
  setGroup.setState({...groupData});
};

// Handle AND / OR operations toggle for facet
export const facetToggleHelper = ({item, state, setGroup}) => {
  const {value, facet, group, filter, status} = item;
  let groupData = state;
  groupData[group][filter][facet][value].isOR = status;
  setGroup.setState({...groupData});
};

// Handle AND / OR operations toggle for filter
export const filterToggleHelper = ({item, state, setGroup}) => {
  const {group, filter, status} = item;
  let groupData = state;
  groupData[group][filter].isOR = status;
  setGroup.setState({...groupData});
};

// Handle AND / OR operations toggle for group
export const groupToggleHelper = ({item, state, setGroup}) => {
  const {group, status} = item;
  let groupData = state;
  groupData[group].isOR = status;
  setGroup.setState({...groupData});
};

// Handle selected value
export const selectValueHelper = ({item, state, setGroup}) => {
  const {result, filter, group, facet, value, option, select} = item;
  let groupData = state;
  groupData[group][filter][facet][value].data = select ? convertArrayToString(result) : result;
  let keyName = groupData[group][filter]['key'];
  if (checkIsTexOrDatetField(keyName) === 'date') {
    groupData[group][filter] = {
      facet: {value1: {data: result, option: ''}},
      isOR: true,
      key: keyName,
    };
  }
  if (!groupData[group][filter][facet][value].option && option) groupData[group][filter][facet][value].option = 'All';
  setGroup.setState({...groupData});
};

// Handle select options
export const selectOptionsHelper = ({item, state, setGroup}) => {
  const {result, filter, group, facet, value} = item;
  let groupData = state;
  groupData[group][filter][facet][value].option = result;
  setGroup.setState({...groupData});
};

// Check is Dynamic field
export const checkIsTextField = (data) => {
  const isIncludes = ADVANCE_SEARCH_DYNAMIC_KEYS.filter((value) => value === data).length != 0 ? true : false;
  return isIncludes;
};

export const handleResetState = (state) => {
  Object.keys(state.state).forEach((group) => delete state.state[group]);
  state.setState({group1: {filter1: {facet: {value1: {}}}}});
};
