import React from "react";
import Loader from "../Loader";
import ModalWindow from "../ModalWindow";
import AppLoaderImage from "../../../public/img/app-loader.gif";

import style from "./style.module.scss";

const LoaderModal = () => {
  return (
    <ModalWindow open={true} title={""} footer={true} isNotClosable={true}>
      <div className={style.modalLoaderInner}>
        <h2>Loading ...</h2>
        <div className={style.modalLoaderInner__loadimage}>
          <img src={AppLoaderImage} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default LoaderModal;
