import React from "react";

import GroupFilter from "./children/GroupFilter";
import {
  facetAddHelper,
  facetDeleteHelper,
  facetToggleHelper,
  filterAddHelper,
  filterDeleteHelper,
  filterToggleHelper,
  groupAddHelper,
  groupDeleteHelper,
  groupToggleHelper,
  handleResetState,
  handleSelectFilterHelper,
  selectOptionsHelper,
  selectValueHelper,
} from "./helper";
import ButtonGroup from "./children/ButtonGroup";
import Loader from "../../Components/Loader";
import { isEmptyFields, onApplyhelper } from "./helperFunctions";
import CustomScrollbar from "../../Components/CustomScrollbar";
import { handleFacetClearhelper, onCancelHelper } from "./controller";
import style from "./style.module.scss";
import ErrorState from "../../Components/ErrorState";
import { ADVANCED_SEARCH_DESCRIPTION } from "../../Constants/AdvanceSearch/AdvanceSearch.constants";
import { setAdvancedSearchStateObject } from "utils/dataFormatter";

class AdvanceSearch extends React.Component {
  state = {
    ...(this.props.search[this.props.isInsights ? "searchValue" : "value"]
      ? setAdvancedSearchStateObject(
          this.props.search[this.props.isInsights ? "searchValue" : "value"]
        )
      : {}),
    group1: {
      filter1: { facet: { value1: {} } },
      ...(this.props.search[this.props.isInsights ? "searchValue" : "value"]
        ? { isOR: false }
        : {}),
    },
  };
  componentDidMount() {
    if (Object.keys(this.props.search.advanceSearchPayloadData).length !== 0)
      this.setState(this.props.search.advanceSearchPayloadData);
  }
  handleFacetAdd = ({ value, facet, filter, group }) =>
    facetAddHelper({
      value,
      facet,
      filter,
      group,
      setGroup: this,
      state: this.state
    });

  handleFacetDelete = (item) =>
    facetDeleteHelper({ item, state: this.state, setGroup: this });

  handleFilterAdd = (groupName, filterName) =>
    filterAddHelper({
      setGroup: this,
      groupName,
      filterName,
      state: this.state,
    });

  handleGroupAdd = () => groupAddHelper({ setGroup: this, state: this.state });

  handleSelectFilter = ({ value, facet, filter, group }) =>
    handleSelectFilterHelper({
      setGroup: this,
      value,
      facet,
      filter,
      group,
      state: this.state
    });
  handleFilterDelete = (item) =>
    filterDeleteHelper({ item, state: this.state, setGroup: this });
  handleGroupDelete = (group) =>
    groupDeleteHelper({ group, state: this.state, setGroup: this });
  handleFacetToggle = (item) =>
    facetToggleHelper({ item, state: this.state, setGroup: this });
  handleFilterToggle = (item) =>
    filterToggleHelper({ item, state: this.state, setGroup: this });
  handleGroupToggle = (item) =>
    groupToggleHelper({ item, state: this.state, setGroup: this });
  handleSelectValue = (item) =>
    selectValueHelper({ item, state: this.state, setGroup: this });

  handleSelectValue2 = (item, conceptName) =>{
    const newState = {...this.state};
    newState[item.group][item.filter].key = conceptName;
    this.setState(newState);
    
    selectValueHelper({ item, state: newState, setGroup: this });
  };


  handleSelectOption = (item) =>
    selectOptionsHelper({ item, state: this.state, setGroup: this });
  handleOnApply = () => onApplyhelper(this);
  handleOnCancel = (type) => onCancelHelper(type, this);
  handleFacetClear = (item) =>
    handleFacetClearhelper({ item, state: this.state, setGroup: this });
  handleClearAll = () => {
    handleResetState(this);
    this.props.clearAdvanceSearchPayload();
  };
  isFiltersEmpty = (state) => {
    return !Object.keys(state).some((group) =>
      Object.keys(state[group] || {}).some((filter) =>
        Object.keys(state[group][filter]["facet"] || {}).some(
          (facet) =>
            state[group][filter].key ||
            state[group][filter]["facet"][facet].data
        )
      )
    );
  };

  render() {
    return (
      <div className={style.advancesearchBlock}>
        <div className={style.advancesearchBlock__header}>
          <h3>Advanced Search</h3>
          <p>{ADVANCED_SEARCH_DESCRIPTION}</p>
        </div>
        {this.props.FilterFields.loading ? (
          <Loader
            hasWrapper={true}
            customHeight={"100vh"}
            classList={["sm blue"]}
          />
        ) : this.props.FilterFields.error ? (
          <div className={style.advancesearchInner}>
            <ErrorState
              customHeight={"500"}
              content="ERROR"
              type={"errorImage"}
              errorMessage={this.props.FilterFields.errorMessage}
            />
          </div>
        ) : (
          <div className={style.advancesearchInner}>
            <div className={style.advancesearchInner__body}>
              <CustomScrollbar className="advance-search-scroller">
                <GroupFilter
                  {...this.props}
                  {...this}
                  handleSelectFilter={this.handleSelectFilter}
                  handleGroupAdd={this.handleGroupAdd}
                  handleFilterAdd={this.handleFilterAdd}
                  handleFacetDelete={this.handleFacetDelete}
                  handleFilterDelete={this.handleFilterDelete}
                  handleFacetAdd={this.handleFacetAdd}
                  handleGroupDelete={this.handleGroupDelete}
                  group={this.state}
                />
              </CustomScrollbar>
            </div>
            <div className={style.advancesearchInner__footer}>
              <ul className={style.advanceList}>
                <ButtonGroup
                  onApply={this.handleOnApply}
                  disabled={isEmptyFields(this.state)}
                  onCancel={this.handleOnCancel}
                  onClearAll={this.handleClearAll}
                  clearAllDisabled={this.isFiltersEmpty(this.state)}
                />
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdvanceSearch;
