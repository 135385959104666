import { SEARCH_TYPE } from 'constants';
import { notify } from '../../Components/Notification';
import { DATE_FILTER_KEY } from '../Home/constants';

// Remove 'isOR' from the Object keys
export const getFilteredLength = (data) =>
  Object.keys(data).filter((i) => i != 'isOR').length;
// Renaming the object keys after the deletion
export const renameDeletedKeys = (data, keyName) => {
  let newData = {};
  Object.keys(data)
    .filter((i) => i != 'isOR')
    .map((item, key) => {
      newData[`${keyName}${key + 1}`] = data[item];
    });
  if (data['isOR'] !== undefined) {
    newData['isOR'] = data['isOR'];
  }
  return newData;
};

//check data is not empty
export const checkPayloadData = (data) => {
  let isEmpty = true;
  let emptyFieldFound = false;
  Object.keys(data)
    .filter((i) => i != 'isOR')
    .map((group) => {
      Object.keys(data[group])
        .filter((i) => i != 'isOR')
        .map((filter) => {
          Object.keys(data[group][filter]['facet']).map((facet) => {
            if (
              (data[group][filter]['key'] == DATE_FILTER_KEY &&
                !data[group][filter]['facet'][facet].option) ||
              (data[group][filter]['key'] != DATE_FILTER_KEY &&
                !data[group][filter]['facet'][facet].data &&
                data[group][filter]['key'] !== undefined)
            )
              emptyFieldFound = true;
            if (
              data[group][filter]['facet'][facet].data != undefined &&
              data[group][filter]['facet'][facet].data !== ''
            )
              isEmpty = false;
          });
        });
    });
  return { isEmpty, emptyFieldFound };
};

//check to show the filter add button
export const showFilterAddButton = (index, props) =>
  index != Object.keys(props.group[props.value]).length - 1 ? true : false;

// tp check the filter are not empty
export const isEmptyFields = (data) => {
  let isEmpty = false;
  Object.keys(data)
    .filter((item) => item !== 'group0')
    .filter((i) => i != 'isOR')
    .map((group) => {
      Object.keys(data[group])
        .filter((i) => i != 'isOR')
        .map((filter) => {
          Object.keys(data[group][filter]['facet']).map((facet) => {
            if (data[group][filter].key === DATE_FILTER_KEY) {
              if (!data[group][filter]['facet'][facet].option) isEmpty = true;
            } else {
              if (!data[group][filter]['facet'][facet].data) isEmpty = true;
            }
          });
        });
    });

  return isEmpty;
};
// get selected values for the filter
export const getSelectedValues = ({ value, filter, group }) => {
  const selectedFacet = group[value][filter]['facet'];
  let selectedValues = [];
  Object.keys(selectedFacet).map((item) => {
    if (selectedFacet[item].data) selectedValues.push(selectedFacet[item].data);
  });
  return selectedValues;
};
// Remove selected value from listing
export const removeSelectedValues = (data, selectedValues) => {
  let newListValues = [];
  data?.map((item) => {
    if (selectedValues.filter((value) => value == item).length === 0)
      newListValues.push(item);
  });
  return newListValues;
};
//Remove duplicates form the advance search filter
export const removeDuplicates = (data) =>
  data?.filter((item, index) => data?.indexOf(item) === index);

export const getValues = (data) => data.map((item) => item.value);
//Get selected filters
export const getSelectedFilters = (data) => {
  const { value, group } = data;
  const filters = data.FilterFields.advanceSearchFilter;
  let selectedFilters = [];
  const selectGroup = group[value];
  Object.keys(selectGroup)
    ?.filter((i) => i != 'isOR')
    ?.map((item) => {
      if (filters[selectGroup[item].key]?.data?.length <= 1)
        selectedFilters.push(selectGroup[item].key);
    });
  return selectedFilters;
};

export const convertArrayToString = (data) => {
  let string = '';
  data?.map((item) => (string += `${item} * `));
  return string;
};

export const convertStringToArray = (data) => {
  let newArray = [];
  data
    ?.split('*')
    .filter((item) => item != ' ')
    .map((item) => newArray.push(item.trim()));
  return newArray;
};

//handle onApply
export const onApplyhelper = (state) => {
  const emptyStatus = checkPayloadData(state.state);
  if (emptyStatus.isEmpty) return notify.info('Choose any filter ! ');
  else if (emptyStatus.emptyFieldFound)
    return notify.info('Please choose value for the selected filter ! ');
  //Call Advance search action
  state.props.advanceSearch({
    data: state.state,
    search: state.props.search
  });
  const queryParams = new URLSearchParams(window.location.search);
  const queryterm = queryParams.get('page');
  const fromTerm = queryParams.get('from');
  let searchTerm =
    queryterm == null && fromTerm == null
      ? null
      : queryterm && fromTerm
      ? `page=${queryterm}from=${fromTerm}`
      : queryterm
      ? `page=${queryterm}`
      : `from=${fromTerm}`;

  let currentPathname = state.props.mainFrameState.props.history.pathname;
  state.props.mainFrameState.props.history.push({
    pathname: currentPathname,
    state: {
      type: SEARCH_TYPE.ADVANCED_SEARCH,
    },
    search: searchTerm,
  });
  const element = document.getElementById('MainBody');
  if (element) {
    element.classList.remove('scrollDisable');
  }
  state.props.mainFrameState.setState({
    showAdvanceSearchDropDown: {
      ...state.props.mainFrameState.state.showAdvanceSearchDropDown,
      visible: false,
    },
    filterVisible: {
      ...state.props.mainFrameState.state.filterVisible,
      visible: false,
    },
  });
};
