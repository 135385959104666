import React from "react";
import InputField from "Components/InputField";
import ModalWindow from "Components/ModalWindow";
import { DASHBOARD_CONSTANTS } from "../../../../../Constants/Dashboard/Dashboard.constants";
import style from "./SaveDashboardModal.module.scss";
function SaveDashboardModal(props) {
  return (
    <>
      <ModalWindow
        title={DASHBOARD_CONSTANTS.SAVE_MODAL_HEADING}
        okText={"Save"}
        width={"500px"}
        tit
        isNotClosable={true}
        isNonMaskable={true}
        onCancel={props.SaveModalCancelHandler}
        onOk={props.SaveModalOkHandler}
        confirmLoading={props.state.saveLoading}
        open={true}
      >
        <InputField
          className={style.subfolderinputLabel}
          onPressEnter={props.SaveModalOkHandler}
          name={"Save-Results-Modal-Block"}
          placeholder={"Untitled"}
          htmlForName={DASHBOARD_CONSTANTS.SAVE_MODAL_SUB_HEADING}
          title={DASHBOARD_CONSTANTS.SAVE_MODAL_SUB_HEADING}
          onChange={props.handleDashboardName}
          autoFocus={true}
          value={props.state.dashboardName}
          error={
            props.state.saveError != "" && props.state.saveError === "empty"
              ? DASHBOARD_CONSTANTS.SAVE_MODAL_EMPTY_ERROR
              : props.state.saveError || ""
          }
        />
      </ModalWindow>
    </>
  );
}

export default SaveDashboardModal;
