import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withMsal } from "@azure/msal-react";

import NavigationLinks from "./Children/NavigationLinks";
import Showlogoutmodal from "./ShowLogoutModal";
import ShowProfilePicModal from "./ShowProfilePicModal";
import { imageUploadhelper, scrollHelper, logoutHelper } from "./Helper";

import style from "./style.module.scss";
import PingarEmblem from "../../../public/img/pinger-emblem.svg";
import ProfileAvatar from "../../../public/img/avatar.png";
import LogoutButton from "./Children/LogoutButton";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "",
      showDropdown: false,
      showLogoutModal: false,
      addOnClass: false,
      profileModal: false,
      profileImg: ProfileAvatar,
      updatedProfile: ProfileAvatar,
      loading: false,
      isSearchClear: false,
    };
  }
  isClearSearch = (value) => {
    this.setState({ isSearchClear: value });
  };

  toggleSandwich = () => {
    this.setState({
      openSandWich: !this.state.openSandWich,
    });
  };

  handleProfile = () => this.setState({ showDropdown: true });

  handleModalVisible = () => this.setState({ showLogoutModal: true });

  handleModalHide = () => this.setState({ showLogoutModal: false });

  handleIsLogout = () => logoutHelper({ state: this });

  showProfile = () =>
    this.setState({
      addOnClass: !this.state.addOnClass,
    });

  onScroll = () => scrollHelper({ state: this });

  handleChangeProfile = () => this.setState({ profileModal: true });

  handleProfileCancel = () =>
    this.setState({
      profileModal: false,
      profileImg: this.state.updatedProfile,
    });

  handleUpload = (event) => imageUploadhelper({ event, state: this });

  handleUploadOk = () =>
    this.setState({
      updatedProfile: this.state.profileImg,
      profileModal: false,
    });

  render() {
    return (
      <>
        <aside className={style.mainSidebar}>
          <div className={style.headerBrand}>
            <div className={style.headerBrand__emblem}>
              <span>
                <img src={PingarEmblem} />
              </span>
            </div>
          </div>
          {this.props.showSideBarNav && (
            <ul className={style.sidebarMenu}>
              <NavigationLinks
                {...this.props}
                isClearSearch={this.isClearSearch}
                // userRole={this.props.CurrentUserProfile?.userProfile === 1 ? 'Admin' : 'Analyst'}
                userRole="Admin"
                menuActive={style.menuActive}
              />
            </ul>
          )}
          <LogoutButton {...this} />
        </aside>
        <Showlogoutmodal
          handleModal={this.handleModalHide}
          handleIsLogout={this.handleIsLogout}
          showLogoutModal={this.state.showLogoutModal}
          loading={this.state.loading}
        />
        <ShowProfilePicModal {...this} />
      </>
    );
  }
}
export default withRouter(withMsal(SideBar));
