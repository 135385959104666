import React from 'react';

import { ContentLoader } from '../../Components/ContentLoader';
import ContentBody from '../../Components/ContentBody';
import Pagination from '../../Components/Pagination';
import TableElement from '../../Components/TableElement';
import { USER_LOGS_CONFIG } from './Userlog.config';
import ErrorState from '../../Components/ErrorState';

import globel from '../../styles/globel/style.module.scss';
import { DEFAULT_PAGE_COUNT } from '../../constants';

export function template(props) {
  return (
    <>
      <ContentBody>
        {this.props.activityLog.loading ? (
          showContentLoader()
        ) : (
          <>
            {this.props.activityLog.error ? (
              <ErrorState
                customHeight={'500'}
                content='ERROR'
                type={'errorImage'}
                errorMessage={this.props.activityLog.errorMessage}
              />
            ) : (
              <>
                <div className={globel.paginationWrapper}>
                  <div className={globel.searchresultCount}>
                    <span>
                      {this.props.activityLog.data.length > 0
                        ? 'Showing ' +
                        this.props.activityLog.data.length +
                        ' out of ' +
                        this.props.activityLog?.totalCount +
                        ' Results'
                        : ''}
                    </span>
                  </div>
                  <div className={globel.searchresultPage}>
                    {UserLogPagination({
                      ...this.state.pagination,
                      totalCount: this.props.activityLog?.totalCount,
                      onChange: this.handleUserLogPagination,
                    })}
                  </div>
                </div>
                <TableElement
                  columns={USER_LOGS_CONFIG.COLUMNS({ sortedInfo: this.state.sortedInfo })}
                  data={this.props.activityLog.data.length > 0 ? this.props.activityLog.data : []}
                  onChange={this.handleChange}
                  scrollY={'calc(100vh - 240px)'}
                />
              </>
            )}
          </>
        )}
      </ContentBody>
    </>
  );
}

function showContentLoader() {
  return <ContentLoader height={2} width={100} number={10} hasWrapper={true} />;
}

/**
 *
 * @param {{ pageNumber: number, pageSize: number, totalCount: number, onChange}} param0
 * @returns
 */
function UserLogPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return totalCount > DEFAULT_PAGE_COUNT ? (
    <Pagination
      pageSize={pageSize}
      current={pageNumber}
      total={totalCount}
      onChange={onChange}
      showSizeChanger={true}
      pageSizeOptions={['10', '20', '50', '100']}
    />
  ) : (
    <></>
  );
}
