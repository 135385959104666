import Component from './SuperLogin';
import {connect} from 'react-redux';

import {signIn} from '../../Redux/Actions';

function mapStateToProps(state) {
  return {
    ...state.Login,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    signIn: (params, login) => dispatch(signIn(params, login)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
