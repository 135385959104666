import React from 'react';
import {Input} from 'antd';
import PropTypes from 'prop-types';
import './style.scss';
function PasswordField(props) {
  const inputChange = (eve) => {
    props.onChange &&
      props.onChange({
        ...props,
        value: eve.target.value,
        name: eve.target.name,
        index: props.index,
        formObject: props.formObject,
        ...eve,
      });
  };
  return (
    <Input.Password
      onChange={inputChange}
      disabled={props.disabled}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      value={props.value}
      type={props.htmlType}
      name={props.name}
      autoFocus={props.autoFocus}
      key={props.key}
      onKeyDown={props.onKeyDown}
      onPressEnter={props.onPressEnter}
      onClick={props.onClick}
      id={props.id}
      size={props.size}
      shape={props.shape}
      max={props.max}
      min={props.min}
      onBlur={props.onBlur}
      style={props.style}
      className={`${props.className || ''} ${!props.isValid ? ' ' : ''}`}
    />
  );
}

PasswordField.propTypes = {
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  value: PropTypes.any,
  htmlType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  size: PropTypes.string,
  shape: PropTypes.string,
};

export default PasswordField;
