import {LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_STARTED} from '../../Redux.constants';
import {login} from '../../../api/login';
import {handleApiError} from '../../../utils/commonUtils';

export function signIn(payload, handleLogin) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_STARTED,
      payload: {},
    });
    try {
      const data = await login(payload);
      handleLogin(data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: LOGIN_FAILED,
          payload: error,
        })
      );
    }
  };
}
