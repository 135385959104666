import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.scss';

export function Dropdown({children, text, customButton, addOnClass}) {
  let btn = (
    <a>
      <span>{text}</span>
    </a>
  );
  if (customButton) btn = customButton;
  return (
    <div className={addOnClass ? `${style.dropdownBlock} ${style.collapse}` : style.dropdownBlock}>
      <>{btn}</>
      <div className={style.dropdownMenu}>{children}</div>
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  customButton: PropTypes.element,
  addOnClass: PropTypes.bool,
};
