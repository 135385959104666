import React from "react";
import CustomScrollbar from "../../../../../Components/CustomScrollbar";
import EmptyState from "../../../../../Components/EmptyState";
import { switchChartsInsights } from "../../../../../utils/switchChartsInsights";

export function ChartBlock(props) {
  return (
    <>
      {props.props.createInsights.chartName != "" ? (
        <>
          {props.props.insightData.data != undefined &&
          Object.keys(props.props.insightData.data).length > 0 ? (
            <>
              <CustomScrollbar className="insight-graph-scroller">
                <>
                  <div>
                    Total documents: {props.props?.insightData?.totalCount}
                  </div>
                  {switchChartsInsights({
                    config:
                      props.props.createInsights.config[
                        props.props.createInsights.chartName
                      ],
                    chartName: props.props.createInsights.chartName,
                    data: props.props.insightData.data,
                    WordCloudClickAction: props.handleWordCloudClick,
                    breadCrumbData: props.props.createInsights.breadCrumbData,
                  })}
                </>
              </CustomScrollbar>
            </>
          ) : (
            emptyStateSection()
          )}
        </>
      ) : (
        <>
          <EmptyState
            content={"CREATE_INSIGHT"}
            type={"dashboardWelcomeImage"}
          />
        </>
      )}
    </>
  );
}

function emptyStateSection(props) {
  return (
    <>
      <EmptyState content={"CHART_DATA"} type={"welcomeImage"} />
    </>
  );
}
