import {
  saveInsightsFailed,
  saveInsightsStarted,
  saveInsightsSuccess,
} from './helper';
import {
  SAVE_INSIGHT_FAILED,
  SAVE_INSIGHT_STARTED,
  SAVE_INSIGHT_SUCCESS,
} from '../../Redux.constants';
import {INITIAL_STATE} from './initialState';

export default function SaveInsights(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_INSIGHT_SUCCESS:
      return saveInsightsSuccess(state, action.payload);
    case SAVE_INSIGHT_STARTED:
      return saveInsightsStarted(state, action.payload);
    case SAVE_INSIGHT_FAILED:
      return saveInsightsFailed(state, action.payload);
    default:
      return {...state};
  }
}
