import { setDateFilterData } from "utils/commonUtils";
import {
  ALL_ARTICLE_TAB,
  EXTERNAL_TAB,
  NEWS_TAB,
} from "../../../Constants/Home/Home.constants";
import { formatFilterLabel } from "../../../utils/formatFilterLabel";
import { createAdvanceSearchQuery } from "Routes/Home/Helper";

export const handleFormatTextData = (data) => {
  if (checkIsEmpty(data)) return "No filters applied";
  let textData = "";
  const filterKeys = Object.keys(data.filter || {});

  textData += `Search  keyword : ${data?.value || ""}\n`;
  if (data.searchWords?.length > 0)
    textData += `Word cloud drill-down: ${
      data.searchWords.join(",") || ""
    } \n `;
  (data.dateFilter[0]?.value.from != "" &&
    data.dateFilter[0]?.value.to != "") ||
  filterKeys.length
    ? (textData += "Filters applied :- \n")
    : null;
  if (
    data.dateFilter[0]?.value.from != "" &&
    data.dateFilter[0]?.value.to != ""
  )
    textData += `\t${data.dateFilter[0]?.key} : ${data.dateFilter[0]?.value.from} - ${data.dateFilter[0]?.value.to}\n`;
  filterKeys.length &&
    filterKeys.map((item) => {
      textData += `\t${formatFilterLabel(item)} : ${data.filter[item].map(
        (value) => " " + value + " "
      )} \n`;
    });
  return textData;
};
const checkIsEmpty = (data) =>
  Object.keys(data.filter || {}).length === 0 &&
  !(
    data.dateFilter[0]?.value.from != "" && data.dateFilter[0]?.value.to != ""
  ) &&
  data.searchWords?.length == 0 &&
  !data?.value;

export const handleFormatAdvanceSearchTextData = ({
  data,
  searchWord,
  value,
}) => {
  let textData = "";
  if (searchWord?.length > 0)
    textData += `Word cloud drill-down: ${searchWord.join(",") || ""} \n `;
  value ? (textData += `Search  keyword : ${value}\n`) : null;
  textData += "Filters applied :- \n";
  textData += createAdvanceSearchQuery(data);
  return textData;
};

export const createSaveResultPayload = (state) => {
  const searchObject = state.props.search;
  return {
    searchKeyword: state.state.saveQueryName.trim(),
    searchHistory: {
      metaData: JSON.stringify({
        searchCriterias: searchObject.searchCriterias,
        searchWords: searchObject.searchWords,
        filter: searchObject.filter,
        dateFilter: setDateFilterData(searchObject.dateFilter),
        selectedField: searchObject.selectedField,
        value: searchObject.value,
        searchType: searchObject.searchType,
        advanceSearchPayloadData: searchObject.advanceSearchPayloadData,
        insightIds: searchObject.insightIds,
      }),
    },
    searchType: 1,
  };
};

/**
 * @param {null | undefined | {include: boolean, field: string, values: string[]}[]} searchCriteria
 * @returns
 */
export const getTabNameFromSearchCriteria = (searchCriteria) => {
  if (searchCriteria && searchCriteria.length > 0) {
    const sourceTypeCriteria = searchCriteria.find(
      (criteria) => criteria.field === "Source Type"
    );
    if (sourceTypeCriteria?.include === false) return NEWS_TAB;
    else if (sourceTypeCriteria?.include === true) return EXTERNAL_TAB;
    else return ALL_ARTICLE_TAB;
  } else return ALL_ARTICLE_TAB;
};
