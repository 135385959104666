import { deleteSaved, getSavedSearchList, getAllSavedSearch } from "../../../../api/search";
import { handleApiError } from "../../../../utils/commonUtils";
import {
  SEARCH_DELETE_SAVED,
  SEARCH_FAILED,
  SEARCH_SAVED,
  SEARCH_SAVED_SUCCESS,
  SEARCH_STARTED,
  GET_SAVED_SEARCHES_START,
  GET_SAVED_SEARCHES_SUCCESS,
  GET_SAVED_SEARCHES_FAILED,
} from "../../../Redux.constants";
import { saveRecentApi } from "../Helper/helper";
import { notify } from '../../../../Components/Notification';

export async function saveResultHandler({ dispatch, payload, callBack }) {
  try {
    let data = await saveRecentApi(payload, true);
    dispatch({
      type: SEARCH_SAVED_SUCCESS,
      payload: data.data.result,
    });
    callBack();
  } catch (error) {
    handleApiError(error, () =>
      callBack({ error: true, message: error.response.data.result })
    );
  }
}

export async function deleteSavedHandler({ dispatch, payload, callBack }) {
  try {
    await deleteSaved({ Emailid: payload.emailid, id: payload.id });
    dispatch({
      type: SEARCH_DELETE_SAVED,
      payload: payload.id,
    });
    notify.success(" Deleted the saved query successfully");
  } catch (error) {
    handleApiError(error, () =>
      dispatch({ type: SEARCH_FAILED, payload: error })
    );
    notify.error(`Failed to delete saved search: ${error.response?.data}`);
  }
  if (callBack) callBack();
}

export async function savedSearchPaginateDispatchHelper({ dispatch, payload }) {
  dispatch({
    type: SEARCH_STARTED,
    payload: { saved: true },
  });
  try {
    const result = await getSavedSearchList(payload);
    dispatch({
      type: SEARCH_SAVED,
      payload: result.data.result,
    });
  } catch (error) {
    handleApiError(error, () =>
      dispatch({ type: SEARCH_FAILED, payload: error })
    );
  }
}

export async function getSavedSearchesHelper({ dispatch, callback }) {
  dispatch({
    type: GET_SAVED_SEARCHES_START,
    payload: {},
  });
  try {
    const dataSearch = await getAllSavedSearch();
    dispatch({
      type: GET_SAVED_SEARCHES_SUCCESS,
      payload: dataSearch.data,
    });
  } catch (error) {
    handleApiError(error, () => {
      dispatch({
        type: GET_SAVED_SEARCHES_FAILED,
        payload: error,
      })
      notify.error(`Failed to all saved search: ${error.response?.data}`);
    });
  }
  if (callback) callback();
}