import React from "react";
import parse from "html-react-parser";

import CheckBox from "Components/CheckBox";
import { ContentLoader } from "Components/ContentLoader";
import ModalWindow from "Components/ModalWindow";
import CustomScrollbar from "Components/CustomScrollbar";
import { SearchInput } from "Components/SearchInput";
import { DASHBOARD_CONSTANTS } from "../../../Constants/Dashboard/Dashboard.constants";

import style from "./style.module.scss";

export function ShowInsightsImportModal(props) {
  return (
    <>
      <ModalWindow
        isNotClosable={true}
        isNonMaskable={true}
        width={"700px"}
        okText={DASHBOARD_CONSTANTS.IMPORT_TEXT}
        onCancel={(e) => props.addModalHandlerCancel(e, "modalOff")}
        onOk={(e) => props.addModalHandlerOk(e, "modalOff")}
        confirmLoading={props.state.importLoading}
        open={props.state.showAddModal}
        disableOkButton={props.state.selectedInsights.length === 0}
      >
        <div className={style.importDashboard}>
          <div className={style.importDashboard__header}>
            <h3>Import Insights to the Dashboard</h3>
          </div>
          <div className={style.importDashboard__content}>
            <div className={style.dashboardMessage}>
              <p>{parse(DASHBOARD_CONSTANTS.ADD_MODAL_CONSTANT_TEXT)}</p>
            </div>
            {props.state.insightInfoMessage && (
              <div className={style.dashboardErrormsg}>
                <p> {props.state.insightInfoMessage}</p>
              </div>
            )}
            <>
              {props.state.listLoading ? (
                <ContentLoader
                  height={2}
                  width={100}
                  number={10}
                  hasWrapper={true}
                />
              ) : (
                <>
                  <div className={style.importSearch}>
                    <SearchInput
                      value={props.state.searchValue}
                      placeholder={DASHBOARD_CONSTANTS.SEARCH_PLACEHOLDER}
                      handleChange={props.handleSearchChange}
                    />
                  </div>
                  <div className={style.importChecklist}>
                    <CustomScrollbar className="checklist-scroller">
                      <div className={style.checklistBlock}>
                        <CheckBox
                          value={props.state.selectedInsights}
                          items={props.searchHandler(
                            formatListInsightsValue(props.state.fullInsightData)
                          )}
                          handleChange={props.handleChange}
                          isDashboard={true}
                        />
                        {props.searchHandler(
                          formatListInsightsValue(props.state.fullInsightData)
                        ).length === 0 && <span>No result found</span>}
                      </div>
                      {props.state.loadMoreLoading ? (
                        <ContentLoader
                          height={2}
                          number={1}
                          width={100}
                          hasWrapper={true}
                        />
                      ) : (
                        <>
                          {props.state.totalSize ==
                          props.state.fullInsightData.length ? (
                            <></>
                          ) : (
                            <a
                              className={style.loadMoreLink}
                              onClick={props.loadMore}
                            >
                              Load More
                            </a>
                          )}
                        </>
                      )}
                    </CustomScrollbar>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </ModalWindow>
    </>
  );
}

function formatListInsightsValue(data) {
  let insightArray = [];
  data.map((item) => {
    insightArray.push({ name: item.insightsName, id: item.id });
  });
  return insightArray;
}
