import { COMMUNICATION_KEYS } from "constants";
import {
  SAML_LOGOUT_URL,
  SUPER_ADMIN_LOGOUT_URL,
} from "../../../Constants/Login/Login.constants";
import { notify } from "../../Notification";

const channel = new BroadcastChannel("selectedList");

export const imageUploadhelper = ({ event, state }) => {
  const reader = new FileReader();
  reader.onload = () => {
    if (reader.readyState === 2) {
      state.setState({ profileImg: reader.result });
    }
  };
  reader.readAsDataURL(e.file.originFileObj);
};

export const scrollHelper = ({ state }) => {
  let activeClass = "";
  if (window.scrollY >= 100) {
    if (state.state.activeClass === "") {
      activeClass = "headerFixed";
      state.setState({ activeClass });
    }
  } else if (state.state.activeClass === "headerFixed") {
    activeClass = "";
    state.setState({ activeClass });
  }
};

export const logoutHelper = async ({ state }) => {
  try {
    state.setState({ loading: true, logoutModal: false });
    localStorage.clear();
    sessionStorage.clear();
    channel.postMessage({
      type: COMMUNICATION_KEYS.PARENT_TERMINATE,
      isLogout: true,
    });
    if (state.props.CurrentUserProfile.userProfile === 3) {
      window.location.href = SUPER_ADMIN_LOGOUT_URL;
    } else {
      window.location.href = SAML_LOGOUT_URL;
    }
  } catch (error) {
    state.setState({ loading: false, logoutModal: false });
  }
};

// const clearReduxStateData = (state) => {
//   // state.props.setSearchData({ clear: true });
// };

export const formatOptionList = (data) => {
  let newArray = [];
  if (data.length > 0) {
    data.map((item) => {
      let dropDownObject = { value: item.id, label: item.name };
      newArray.push(dropDownObject);
    });
  }
  return newArray;
};
