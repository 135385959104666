export const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  insightData: [],
  totalCount: 0,
  dataSet: [],
  dataSetLoading: false,
  fullInsightsDetails: [],
  allInsightsList: [],
  insightDataSetLoading: false,
  dataSetError: false,
};
