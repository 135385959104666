import {
  HORIZONTAL_LABEL,
  VERTICAL_AXIS_LABEL,
  WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL,
  WORD_CLOUD_MINIMUM_OCCURANCE_LABEL,
} from "Constants/Insights/CreateInsights.constants";
import {
  InsightsRequest,
  getHeatmapData,
  getKeyphraseCloudData,
} from "api/insights";
import { KEYS } from "dataKeys";

export const generatePayload = (payload) => {
  const chartName = payload.chartName;
  let { requestPayload, paramsPayload } = defaultPayloadGenerator();

  switch (chartName) {
    case "BAR_CHART":
      paramsPayload = payload.parameters[HORIZONTAL_LABEL].value || "";
      requestPayload = payLoadData(paramsPayload);
      break;

    case "KEY_PHRASE_CLOUD":
      paramsPayload = "";
      requestPayload = generateKeyphrasePayload(payload);
      break;

    case "HEAT_MAP":
      paramsPayload = payload.parameters[HORIZONTAL_LABEL]?.value || "";
      requestPayload = generateHeatMapPayload(paramsPayload, payload);
      break;
  }
  return { requestPayload, paramsPayload };
};
const payLoadData = (paramsPayload) => {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  let requestpayload = {
    buckets: [
      {
        field: paramsPayload == "all_data" ? "" : paramsPayload,
      },
    ],
    emailId: email,
  };
  return requestpayload;
};
const generateKeyphrasePayload = (payload) => {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  payload = {
    // searchFields: KEYPHRASE_DATA_FETCHING_FIELDS,
    wordCount: Number(
      payload.parameters[WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL]?.value
    ),
    minCount:
      Number(payload.parameters[WORD_CLOUD_MINIMUM_OCCURANCE_LABEL]?.value) ||
      Number(payload.parameters["Minimum word occurance"]?.value),
    resType: 1,
    emailId: email,
  };
  return payload;
};

const generateHeatMapPayload = (paramsPayload, payload) => {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  let field2 = payload.parameters[VERTICAL_AXIS_LABEL]?.value;
  let requestpayload = {
    buckets: [{ field: paramsPayload }, { field: field2 }],
    emailId: email,
  };
  return requestpayload;
};
const defaultPayloadGenerator = () => {
  const paramsPayload = "";
  const requestPayload = {
    buckets: [{ field: "website_domain" }],
    size: 100,
    id: 272,
  };
  return { paramsPayload, requestPayload };
};

export const handleApi = async (requestpayload, chartName) => {
  if (chartName === "KEY_PHRASE_CLOUD") {
    const data = await getKeyphraseCloudData(requestpayload);
    return data?.data;
  } else if (chartName === "HEAT_MAP") {
    const data = await getHeatmapData(requestpayload);
    return data.data;
  } else {
    const data = await InsightsRequest(requestpayload);
    return data.data;
  }
};
