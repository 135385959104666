export const MyInsightHeader = props=>
    {
        const [showConfigs, setShowConfigs] = useState(false);
        const [showExclusionList, setShowExclusionList] = useState(false);
        const [isButtonPressed, setButtonPressed] = useState(false);
      
        const toggleConfigurationsUI = () => {
          if (props.loading && !showConfigs) return;
          setShowConfigs(!showConfigs);
        };
      
        const showExclusionModal = () => {
          setShowExclusionList(true);
          setButtonPressed(true);
        };
      
        const handleAdvanceSearchClick = () => {
          const filterFields = props.FilterFields;
          if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
            props.loadFilterFields();
          }
          const element = document.getElementById("MainBody");
          if (element) element.classList.add("scrollDisable");
          props.mainFrameState.setState({
            showAdvanceSearchDropDown: {
              ...props.mainFrameState.state.showAdvanceSearchDropDown,
              visible: !props.mainFrameState.state.showAdvanceSearchDropDown.visible,
            },
          });
        };
        return (
          <div className={style.headerControls}>
            <div className={style.controlsSearch}>
              <ul className={style.controlsSearch__list}>
                <li>
                  <TooltipContainer
                    title={props.filterDisable && FILTER_DISABLE_TOOLTIP}
                    placement={"right"}
                  >
                    <a
                      to={{}}
                      disabled={props.filterDisable}
                      className={`${style.filtersearchButton} ${
                        props.filterVisible.visible ? style["filterToggled"] : ""
                      }`}
                      onClick={
                        !props.filterDisable ? props.handleFilterVisible : undefined
                      }
                    >
                      <span>
                        <SVGIcons type={"SVG-arrow"} />
                      </span>
                      <span>Filter</span>
                    </a>
                  </TooltipContainer>
                </li>
                <li>
                  <SearchBarInsights className={style.text} isInsights={true} />
                </li>
                <li>
                  <ButtonElement
                    size={"small"}
                    disabled={props.loading}
                    onClick={toggleConfigurationsUI}
                  >
                    <span>Modify Insights</span>
                  </ButtonElement>
                </li>
                {props.createInsights.chartName == "KEY_PHRASE_CLOUD" && (
                  <li>
                    <ButtonElement size={"small"} onClick={showExclusionModal}>
                      Exclusion List
                    </ButtonElement>
                  </li>
                )}
              </ul>
            </div>
            <div className={style.controlsElement}>
              <div className={style.advancDrawertrigger}>
                <a
                  to={{}}
                  className={style.advancesearchButton}
                  onClick={handleAdvanceSearchClick}
                >
                  <span>Advanced Search</span>
                </a>
              </div>
              <ul className={style.controlsElement__list}>
                <li>
                  <div className={style.controlsItem}>
                    <SaveResults
                      title={"Save this Insight"}
                      handleSave={props.handleSave}
                      disabled={props.loadingOrNoData}
                    />
                  </div>
                </li>
                <li>
                  <div className={style.controlsItem}>
                    <LoadResults
                      toolTipTitle={"Saved Insights"}
                      handleLoad={(value) => props.handleSavedInsightModal(value)}
                    />
                  </div>
                </li>
              </ul>
            </div>
            {showConfigs && (
              <InsightsParametersModal
                showConfigs={showConfigs}
                handleClose={toggleConfigurationsUI}
                handleUpdate={toggleConfigurationsUI}
              />
            )}
            {showExclusionList && (
              <ExcludeModal
                isModalOpen={showExclusionList}
                isButtonPressed={isButtonPressed}
                text={""}
                onClose={() => {
                  setShowExclusionList(false), setButtonPressed(false);
                }}
              />
            )}
          </div>
        );
    }