import React from 'react';
import moment from 'moment';
import SVGIcons from 'Components/SVGIcons';
import TooltipContainer from 'Components/Tooltip';
import globel from '../../styles/globel/style.module.scss';

export const DASHBOARD_CONFIG = {
  COLUMNS: ({deleteHandler, handleViewDashboard, projectList, handleDownloadDashboard}) => [
    showProjectTitle(),
    showProject(projectList),
    showCreatedDate(),
    showUpdatedDate(),
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <ul className={globel.tableActions}>
          <li>
            <TooltipContainer mouseLeaveDelay={0} placement={'bottom'} title={'View Dashboard'}>
              <span onClick={(e) => handleViewDashboard(record)}>
                <SVGIcons type={'SVG-viewicon'} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer placement={'bottom'} title={'Download Dashboard'} mouseLeaveDelay={0}>
              <span onClick={(e) => handleDownloadDashboard(record)}>
                <SVGIcons type={'SVG-download'} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer mouseLeaveDelay={0} placement={'bottom'} title={'Delete Dashboard'}>
              <span onClick={(e) => deleteHandler(record)}>
                <SVGIcons type={'SVG-delete'} />
              </span>
            </TooltipContainer>
          </li>
        </ul>
      ),
    },
  ],
};

function showProjectTitle() {
  return {
    title: 'Title',
    dataIndex: 'dashboardName',
    key: 'dashboardName',
    sorter: (a, b) => a.dashboardName.localeCompare(b.dashboardName),
    render: (title) => `${title}`,
  };
}

function showProject(projectList) {
  return {
    title: 'Project',
    dataIndex: 'projectId',
    key: 'projectId',
    sorter: (a, b) =>
      showProjectName(a.projectId, projectList).localeCompare(showProjectName(b.projectId, projectList)),

    render: (project) => `${showProjectName(project, projectList)}`,
  };
}

function showCreatedDate() {
  return {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (date) => `${formatDate(date)}`,
    sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
  };
}

function showProjectName(project, projectList) {
  let projectNameInfo = projectList.filter((item) => project == item.id);
  return projectNameInfo[0]?.name;
}
const showUpdatedDate = () => ({
  title: 'Updated Date',
  dataIndex: 'updatedDate',
  key: 'updatedDate',
  render: (date) => `${formatDate(date)}`,
  sorter: (a, b) => moment(a.updatedDate).unix() - moment(b.updatedDate).unix(),
  defaultSortOrder: 'descend',
});

const formatDate = (value) => {
  value = value + 'Z';
  let date = new Date(value);
  return date.toLocaleDateString('FR-CA');
};
