import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import './style.scss';
const ToggleSwitch = (props) => {
  return (
    <Switch
      size={props.size}
      onChange={props.handleChange}
      checked={props.value}
      disabled={props.disabled}
      className={props.className}
    />
  );
};

export default ToggleSwitch;
