import { Fragment, useState } from "react";
import { SEARCH_TYPE } from "constants";
import { connect } from "react-redux";
import { setInsightData, setSearchData } from "Redux/Actions";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { RefreshSearch } from "Redux/Reducers/Home/HomeState";
import style from "./style.module.scss";
import { SearchAcrossConcepts2 } from "Components/SearchAcrossConcepts/SearchAcrossConcepts2";
import ButtonGroup from "./children/ButtonGroup";
import { Button } from "antd";

import { PlusCircleOutlined } from '@ant-design/icons';
import ToggleSwitch from "Components/ToggleSwitch";

const SearchAcrossConceptsCombinedComponent = props=>{

    const [searchAcrossConcepts, setSearchAcrossConcepts] = useState([null]);


    const handleSearchAcrossConceptsSetValue = (value, index)=>{
        setSearchAcrossConcepts(prev=>prev.map((item, ix)=>{
            if (ix === index){
                return value;
            }
            else {
                return item;
            }
        })); 
    };

    const handleSearchAcrossConceptsToggleAndOr = (value, index)=>{
        setSearchAcrossConcepts(prev=>prev.map((item, ix)=>{
            if (ix === index){
                return {...item, isOR: value};
            }
            else {
                return item;
            }
        })); 
    };

    const handleOnApply2 = ()=>{
        const sdo = {
            group1: {}
        };

        for (let i = 0; i < searchAcrossConcepts.length; i++){
            if (!searchAcrossConcepts[i]){
                continue;
            }
            const filterPropName = `filter${i+1}`;

            sdo.group1[filterPropName] = {
                facet: {
                    value1: {
                        data: `${searchAcrossConcepts[i].keyword} * `,
                        option: 'All'
                    }
                }, 
                key: searchAcrossConcepts[i].conceptName
            };

            if (i > 0){
                sdo.group1[filterPropName].isOR = !!(searchAcrossConcepts[i-1].isOR);
            }
        }

    
        props.advanceSearch({
          data: sdo,
          pagination: {
            pageNumber: 1,
            pageSize: props.searchPageSize
          },
          sort: {
            sortorder: props.searchSortOrder, 
            sortby: props.searchSortBy
          },
          searchCriterias: props.searchCriterias,
          searchWords: props.searchWords
        });
    
        const queryParams = new URLSearchParams(window.location.search);
        const queryterm = queryParams.get('page');
        const fromTerm = queryParams.get('from');
        let searchTerm =
          queryterm == null && fromTerm == null
            ? null
            : queryterm && fromTerm
            ? `page=${queryterm}from=${fromTerm}`
            : queryterm
            ? `page=${queryterm}`
            : `from=${fromTerm}`;
    
        let currentPathname = props.mainFrameState.props.history.pathname;
        props.mainFrameState.props.history.push({
          pathname: currentPathname,
          state: {
            type: SEARCH_TYPE.ADVANCED_SEARCH,
          },
          search: searchTerm,
        });
        const element = document.getElementById('MainBody');
        if (element) {
          element.classList.remove('scrollDisable');
        }
        
        props.mainFrameState.setState({
          showAdvanceSearchDropDown: {
            ...props.mainFrameState.state.showAdvanceSearchDropDown,
            visible: false,
          },
          filterVisible: {
            ...props.mainFrameState.state.filterVisible,
            visible: false,
          },
        });
    
        
    };
    
    const handleOnCancel2 = ()=>{
        debugger;
    };
    
    const handleClearAll2 = ()=>{
        setSearchAcrossConcepts(null);
        props.clearAdvanceSearchPayload();
    };

    const addSearch = ()=>{
        setSearchAcrossConcepts(prev=>[...prev, null]);
    };

    return (<div className={style.advancesearchBlock}>
        <div className={style.advancesearchBlock__header}>
          <h3>Search Across Taxonomies</h3>
        </div>

        <div className={style.advancesearchInner}>

            {searchAcrossConcepts.map((searchDto, ix)=>{
                return (<Fragment key={`sac-frg-${ix}`}>
                <div style={{padding: '10px 20px 10px 20px'}} key={`sac2-${ix}`}>
                    <SearchAcrossConcepts2 
                        index={ix}
                        searchObject={searchDto}
                        onSetValue={handleSearchAcrossConceptsSetValue}
                    />
                </div>

                {/*  toggle AND/OR is for the next item but stored in the current item for simplicity:  */}
                {(searchDto && (ix != searchAcrossConcepts.length - 1)) ? <div style={{padding: '0 0 10px 20px'}}>
                    <span style={{padding: '4px'}}>AND</span>
                    <span style={{padding: '4px'}}>
                    <ToggleSwitch
                        size={"small"}
                        handleChange={e => handleSearchAcrossConceptsToggleAndOr(e, ix)}
                        value={!!searchDto.isOR}
                    />
                    </span>
                    <span style={{padding: '4px'}}>OR</span>
                </div> : null}
 
            </Fragment>);
            })}

               
           <div style={{padding: '0 0 10px 20px'}}>
            <Button 
            style={{minWidth: '30px', minHeight: '30px'}}
                type="primary" 
                shape="circle" 
                icon={<PlusCircleOutlined />}
                onClick={() => addSearch()}
                size="small" />
            </div>
            
            <div className={style.advancesearchInner__footer}>
                <ul className={style.advanceList}>
                    <ButtonGroup
                        onApply={handleOnApply2}
                        disabled={!searchAcrossConcepts}
                        onCancel={handleOnCancel2}
                        onClearAll={handleClearAll2}
                        clearAllDisabled={false}
                    />
                </ul>
            </div>
        </div>
    </div>);
};

const mapStateToProps = (reduxState, componentProps)=>{
    if(componentProps.isInsights){
        return {
            search: reduxState.CreateInsights
        };
    }
    else {
        return {
            search: reduxState.Search.search
        };
    }
};

const mapDispatchToProps = (dispatch, componentProps)=>{
    return {
        advanceSearch: params=>{
            let data; 
            if (componentProps.isInsights){
                data = setInsightData({
                   filter: {}, 
                   dateFilter: DEFAULT_DATE_FILTER_VALUE ,
                   advanceSearchPayloadData: params.data,
                   searchType: SEARCH_TYPE.ADVANCED_SEARCH
                });
            }
            else {
                data = setSearchData({
                    filter: {}, 
                    dateFilter: DEFAULT_DATE_FILTER_VALUE ,
                    advanceSearchPayloadData: params.data,
                    searchType: SEARCH_TYPE.ADVANCED_SEARCH
                });
            }

            dispatch(data);
            dispatch(RefreshSearch());
        },
        clearAdvanceSearchPayload: ()=>{
            debugger;
            let data;
            if (componentProps.isInsights){
                data = setInsightData({
                    advanceSearchPayloadData: {},
                    searchType: SEARCH_TYPE.NORMAL_SEARCH
                });
            }
            else {
                data = setSearchData({
                    advanceSearchPayloadData: {},
                    searchType: SEARCH_TYPE.NORMAL_SEARCH
                });
            }

            dispatch(data);
        }
    };
};


export const SearchAcrossConceptsCombined = connect(mapStateToProps, mapDispatchToProps)(SearchAcrossConceptsCombinedComponent);
