import React from "react";
import style from "./style.module.scss";
import FilterSortButton from "./FilterSortButton";
import { getDatePublishedFieldName, getTitleFieldName } from "./helper";
import {
  DATE_SORT_KEY,
  TITLE_SORT_KEY,
} from "../../../../../../Routes/Home/constants";

class SortControls extends React.Component {
  state = {
    showSorting: false,
    sortingType: "PublishDate",
  };
  mount = false;

  componentDidUpdate(prevProps) {
    if (
      prevProps.search.emptySearch !== this.props.search.emptySearch &&
      this.props.search.emptySearch
    ) {
      this.mount
        ? this.setState({ showSorting: false, sortingType: "PublishDate" })
        : (this.mount = true);
    }
    if (
      prevProps.search.value !== this.props.search.value &&
      !this.props.search.value
    ) {
      this.setState({ showSorting: false, sortingType: "PublishDate" });
      this.props.setPublishDate({
        className: "activeDown",
        visible: true,
      });
    }
    if (
      (prevProps.search.sortby != this.props.search.sortby &&
        this.props.search.sortby === "relevance") ||
      (prevProps.search.value !== this.props.search.value &&
        this.props.search.sortby === "relevance")
    ) {
      this.setState({ showSorting: false, sortingType: "Relevance" });
      this.props.setRelevance({
        className: "activeDown",
        visible: true,
      });
    }
  }

  handleSortingVisible = (value) => this.setState({ showSorting: value });

  handleSort = (value, type) => {
    const sortorder = type == "activeUp" ? "DESC" : "ASC";
    const sortby =
      value === TITLE_SORT_KEY
        ? getTitleFieldName(this.props.allFieldsWithDatatype)
        : value === DATE_SORT_KEY
        ? getDatePublishedFieldName(this.props.allFieldsWithDatatype)
        : value;
    this.props.setSearchData({ sortby, sortorder });
  };
  handleChangeSortingType = (type) =>
    this.setState({ sortingType: type, showSorting: false });

  handleTitle = () => {
    this.props.setPublishDate({ visible: false, className: "" });
    this.props.setRelevance({ visible: false, className: "" });
    switch (this.props.title.className) {
      case "":
        this.handleSort(TITLE_SORT_KEY, "activeUp");
        return this.props.setTitle({ className: "activeDown", visible: true });
      case "activeDown":
        this.handleSort(TITLE_SORT_KEY, "activeDown");
        return this.props.setTitle({ className: "activeUp", visible: true });
      case "activeUp":
        this.handleSort(TITLE_SORT_KEY, "activeUp");
        return this.props.setTitle({ className: "activeDown", visible: true });
    }
  };

  handlePublish = () => {
    this.props.setRelevance({ visible: false, className: "" });
    this.props.setTitle({ visible: false, className: "" });
    switch (this.props.publishDate.className) {
      case "":
        this.handleSort(DATE_SORT_KEY, "activeUp");
        return this.props.setPublishDate({
          className: "activeDown",
          visible: true,
        });
      case "activeDown":
        this.handleSort(DATE_SORT_KEY, "activeDown");
        return this.props.setPublishDate({
          className: "activeUp",
          visible: true,
        });
      case "activeUp":
        this.handleSort(DATE_SORT_KEY, "activeUp");
        return this.props.setPublishDate({
          className: "activeDown",
          visible: true,
        });
    }
  };

  handleRelevance = () => {
    this.props.setTitle({ visible: false, className: "" });
    this.props.setPublishDate({ visible: false, className: "" });
    switch (this.props.relevance.className) {
      case "":
        this.handleSort("relevance", "");
        return this.props.setRelevance({
          className: "activeDown",
          visible: true,
        });
      case "activeDown":
        this.handleSort("relevance", "activeDown");
        return this.props.setRelevance({
          className: "activeUp",
          visible: true,
        });
      case "activeUp":
        this.handleSort("relevance", "activeUp");
        return this.props.setRelevance({
          className: "activeDown",
          visible: true,
        });
    }
  };

  render() {
    return (
      <>
        {SortButtons({
          handleSortingVisible: this.handleSortingVisible,
          showSorting: this.state.showSorting,
          sortingType: this.state.sortingType,
          relevance: this.props.relevance,
          title: this.props.title,
          publishDate: this.props.publishDate,
          handlePublish: this.handlePublish,
          handleTitle: this.handleTitle,
          handleRelevance: this.handleRelevance,
          listViewType: this.props.listViewType,
          handleListView: this.props.handleListView,
          children: this.props?.children,
          loading: !(
            !this.props.searchData?.loading &&
            this.props.searchData.data?.length
          ),
          handleChangeSortingType: this.handleChangeSortingType,
          ...this.props,
        })}
      </>
    );
  }
}
export default SortControls;

function SortButtons({
  handleSortingVisible,
  showSorting,
  title,
  publishDate,
  relevance,
  handlePublish,
  handleTitle,
  handleRelevance,
  loading,
  sortingType,
  handleChangeSortingType,
  search,
  searchData,
}) {
  const disabledClass = loading ? style.disabled : "";
  const { value } = search;
  const { data, loading: dataLoading } = searchData;
  return (
    <div className={style.controlsBox + " " + disabledClass}>
      <FilterSortButton
        title={title}
        publishDate={publishDate}
        handlePublish={handlePublish}
        handleTitle={handleTitle}
        relevance={relevance}
        handleRelevance={handleRelevance}
        showSorting={showSorting}
        sortingType={sortingType}
        handleChangeSortingType={handleChangeSortingType}
        handleSortingVisible={handleSortingVisible}
        isRelevanceVisible={
          !dataLoading && data?.length && value?.length ? true : false
        }
      />
    </div>
  );
}
