import { connect } from "react-redux";

import {
  addSaveSearchResult,
  deleteSavedResults,
  getSavedSearchList,
  FilterFields,
  getDashboardArticles,
  ClearFilterFieldData,
  setSearchData,
} from "../../Redux/Actions/";
import Home from "./Home";

function mapStateToProps(state) {
  return {
    ...state.Search,
    FilterFields: state.FilterFields,
    CurrentProject: state.Settings.profile.projectinfo,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleDeleteSaved: (params, callBack) =>
      dispatch(deleteSavedResults(params, callBack)),
    handleSavedResults: (params, callBack) =>
      dispatch(addSaveSearchResult(params, callBack)),
    handleSavedSearchPaginate: (params) => dispatch(getSavedSearchList(params)),
    loadFilterFields: () => dispatch(FilterFields()),
    handleGetDashboardPreview: (params, responceHandler) =>
      dispatch(getDashboardArticles(params, responceHandler)),
    ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
