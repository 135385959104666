import React from "react";
import UserGroupActions from "./UserGroupActions";

export const TABLE_COLUMNS = {
  COLUMNS: ({ sortedInfo, handleUpdate, handleDelete }) => [
    {
      title: "Group Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "id"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "name"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => <span> {text}</span>,
    },
    {
      title: "Default Search",
      dataIndex: "defaultSearch",
      key: "defaultSearch",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "defaultSearch"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => <span> {text}</span>,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "note"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
        <UserGroupActions
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          record={record}
        />
        );
      },
    },
  ],
};

