import {
  UpdateSaveInsightsFailed,
  UpdateSaveInsightsStarted,
  UpdateSaveInsightsSuccess,
} from './helper';
import {
  UPDATE_SAVE_INSIGHT_FAILED,
  UPDATE_SAVE_INSIGHT_STARTED,
  UPDATE_SAVE_INSIGHT_SUCCESS,
} from '../../Redux.constants';
import {INITIAL_STATE} from './initialState';

export default function UpdateSaveInsights(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_SAVE_INSIGHT_SUCCESS:
      return UpdateSaveInsightsSuccess(state, action.payload);
    case UPDATE_SAVE_INSIGHT_STARTED:
      return UpdateSaveInsightsStarted(state, action.payload);
    case UPDATE_SAVE_INSIGHT_FAILED:
      return UpdateSaveInsightsFailed(state, action.payload);
    default:
      return {...state};
  }
}
