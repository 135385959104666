import React, {Component} from 'react';
import {DatePicker} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';
export default class DatePickerElement extends Component {
  onChange = (range, dateString) => {
    this.props.handleChange && this.props.handleChange({...this.props, range, dateString}, dateString);
  };

  disabledDateToday = (current) => {
    // Can not select days before today and today
    return current && current.valueOf() < moment().endOf('day');
  };

  disabledPrevious = (current) => {
    // Can not select days before today
    return current && current.valueOf() < moment().add(-1, 'day');
  };

  disabledAfter = (current) => {
    return current && moment(current).startOf('day') > moment().endOf('day');
  };

  disabledDateTodayAfter = (current) => {
    return current && current.valueOf() > moment().add(-1, 'day');
  };

  customFormat = (value) => `${value.format(this.props.dateFormat)}`;
  render() {
    return (
      <DatePicker
        format={this.customFormat}
        picker={this.props.pickerType}
        allowClear={true}
        size={this.props.size}
        className={this.props.className}
        showNow={false}
        selected={moment(this.props.select)}
        showTime={this.props.showTimeSelect ? {format: 'HH:mm'} : null}
        // {...this.props}
        value={this.props.value}
        disabledDate={
          this.props.disableToday
            ? this.disabledDateToday
            : this.props.disablePrevious
            ? this.disabledPrevious
            : this.props.disabledAfter
            ? this.disabledAfter
            : this.props.disabledDateTodayAfter
            ? this.disabledDateTodayAfter
            : this.props.disabledDate
        }
        onChange={this.onChange}
        inputReadOnly={true}
        placeholder={this.props.placeholder}
        suffixIcon={false}
      />
    );
  }
}
DatePickerElement.propTypes = {
  /**
   * handleChange
   */
  handleChange: PropTypes.func,
  /**
   * dateFormat
   */
  dateFormat: PropTypes.any,
  /**
   * pickerType
   */
  pickerType: PropTypes.any,
  /**
   * size
   */
  size: PropTypes.any,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * value
   */
  value: PropTypes.any,
  /**
   * dateFormat
   */
  dateFormat: PropTypes.any,
  /**
   * showTimeSelect
   */
  showTimeSelect: PropTypes.any,
  /**
   * disableToday
   */
  disableToday: PropTypes.any,
  /**
   * disablePrevious
   */
  disablePrevious: PropTypes.any,
  /**
   * disabledAfter
   */
  disabledAfter: PropTypes.any,
  /**
   * disabledDateTodayAfter
   */
  disabledDateTodayAfter: PropTypes.any,
  /**
   * disabledDate
   */
  disabledDate: PropTypes.any,
  /**
   * placeholder
   */
  placeholder: PropTypes.string,
};
