import React from 'react';
import EmptyState from '../../Components/EmptyState';

import style from './style.module.scss';

const Emptypage = (prop) => {
  return (
    <EmptyState customHeight='430' type='welcomeImage' />
  );
};

export default Emptypage;
