import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addTagModalToggle } from "Redux/Actions";
import DisplayResultCard from "./Children/ResultCard";
import { MyDisplayTagsModal } from "./MyDisplayTagsModal";
import PreviewModal from "./Children/PreviewModal";
export const MySearchCard = props => {
  const [ListItems, setListItems] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setListItems(props.data.user_tags?.filter((t) => t.length > 0) || []);
  }, [props.data.user_tags]);

  const dispatch = useDispatch();
  const handleTagsList = () => {
    const uid = props.data.id ? props.data.id : props.data.DocumentId;
    const modalPayload = {
      visible: true,
      uids: [uid],
      allArticleList: props.allArticleList,
      setSearchLocalData: props.setSearchLocalData,
      userTags: props.data.user_tags
    };
    dispatch(addTagModalToggle(modalPayload));
  }
  
  return (
    <>
      {showPreview && (
        <PreviewModal
          {...props}
          showModal={showPreview}
          setShowPreview={setShowPreview}
        />
      )}
      <DisplayResultCard
        //   {...props}

        data={props.data}
        dateFormat={props.dateFormat}
        listViewType={props.listViewType}
        expandAllCard={props.expandAllCard}
        expandAllTags={props.expandAllTags}
        handleExpandCardActive={props.handleExpandCardActive}
        metaDataList={props.metaDataList}
        currentTabType={props.currentTabType}

        handleTagsList={handleTagsList}
        ListItems={ListItems}
        setShowPreview={setShowPreview}
        quickCodes={props.quickCodeList}
        setShowQuickCode={props.setShowQuickCode}
        canvasContext={props.canvasContext}
      />
    </>
  );
};
