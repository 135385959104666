import React, {useState} from 'react';
import {Collapse} from 'antd';
import PropTypes from 'prop-types';
import SVGIcons from '../SVGIcons';
import './style.scss';
import {formatTitleCase} from '../../utils/formatFilterLabel';
import {useEffect} from 'react';
function CollapseElement(props) {
  const [open, setOpen] = useState(['0', '1'].includes(props.index) ? true : false);

  const handleChange = () => {
    setOpen(!open);
    props.handleOpenItems();
  };

  useEffect(() => {
    if (open && props.onOpenElement) {
      props.onOpenElement();
    }
  }, [open]);

  useEffect(() => {
    if (props.heading == '0' || props.index == '1') {
      props.handleOpenItems();
    }
  }, []);

  const {Panel} = Collapse;
  return (
    <Collapse
      className={props.className}
      onChange={handleChange}
      defaultActiveKey={['0', '1']}
      expandIcon={() =>
        open ? (
          <span className='header-icon'>
            <SVGIcons type='SVG-minus' />
          </span>
        ) : (
          <span className='header-icon'>
            <SVGIcons type='SVG-plus' />
          </span>
        )
      }>
      <Panel header={<span>{formatTitleCase(props.heading)}</span>} key={props.index}>
        {props.children}
      </Panel>
    </Collapse>
  );
}
CollapseElement.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * heading
   */
  heading: PropTypes.string,
  /**
   * index
   */
  index: PropTypes.any,
  /**
   * children
   */
  children: PropTypes.any,
  /**
   * Function to invoke on opening the element
   */
  onOpenElement: PropTypes.func,
};
export default CollapseElement;
