import {notify} from '../../../../Components/Notification';

export function resetStarted(state, payload) {
  return {
    ...state,
    resetPasswordData: {
      loading: true,
      error: false,
    },
  };
}

export function resetSuccess(state, payload) {
  return {
    ...state,
    resetPasswordData: {
      loading: false,
      error: false,
    },
  };
}

export function resetFailed(state, payload) {
  return {
    ...state,
    resetPasswordData: {
      loading: false,
      error: payload,
    },
  };
}
