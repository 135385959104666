import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';

const PageHeading = ({ text, children }) => {
  return (
    <div className={style.pageHeader}>
      <h3>{text}</h3>
      <div className={style.pageHeader__controlls}>{children}</div>
    </div>
  );
};

export default PageHeading;

PageHeading.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};
