import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import './style.scss';
const TableElement = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const delay = 1000;

  useEffect(() => {
    if (props.loading) {
      const timer = setTimeout(() => {
        setShowLoading(true);
      }, delay);

      return () => clearTimeout(timer);
    } else {
      setShowLoading(false);
    }
  }, [props.loading]);

  const TheTable = () => {
    return (
      <Table
        columns={props.columns}
        dataSource={props.data}
        pagination={false}
        sorter={true}
        onChange={props.onChange}
        scroll={{ x: props.scrollX, y: props.scrollY }}
        loading={false}
      />);
  };

  return (
    <>
      {showLoading ? (
        <Spin spinning={true} tip={`Loading ${props.resourceName ?? ""}`} delay={delay}>
          <TheTable />
        </Spin>
      ) : (
        <TheTable />
      )}
    </>
  );
};

export default TableElement;

