import React, { Component } from 'react';
import Loader from '../../Components/Loader';
import style from './style.module.scss';

export default class Loading extends Component {
  render() {
    return (
      <div className={style.LoadingContainer}>
        <span className={style.LoaderInnerContainer}>
          <Loader hasWrapper={true} classList={['xs blue']} customHeight={100} />
        </span>
        <p className={style.Message}>Please wait!</p>
        <p className={style.Message}>The page is loading...</p>
      </div>
    );
  }
}
