import React, {Component} from 'react';
import PropType from 'prop-types';
import style from './style.module.scss';
import {boolean} from 'yup/lib/locale';
export default class PageWrapper extends Component {
  render() {
    return (
      <div className={`${style.pageWrapper} ${!this.props.hideSideBar ? style.hideSideBar : ''} `}>
        {this.props.children}
      </div>
    );
  }
}
PageWrapper.defaultValue = {
  childern: null,
  hideSideBar: boolean,
};
