import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";

import { CustomCheckboxGroup } from "../CustomCheckboxGroup/CustomCheckboxGroup";

import "./style.scss";

const CheckBox = (props) => {
  if (props.isDashboard) {
    return (
      <Checkbox.Group
        size={props.size}
        shape={props.shape}
        className={props.className}
        onChange={props.handleChange}
        value={props.value}
      >
        {props.items.map((item, index) => (
          <>
            <Checkbox
              value={item.id}
              defaultChecked={true}
              key={index + item.name}
            >
              {item.name}
            </Checkbox>
          </>
        ))}
      </Checkbox.Group>
    );
  }
  const items = props.items.map((item) => ({
    label: item.value,
    value: item.value,
    count: item.count,
  }));

  return (
    <CustomCheckboxGroup
      showSelectAll={props.showSelectAll}
      handleSelectAll={props.handleSelectAll}
      handleClearAll={props.handleClearAll}
      loading={props.loading}
      options={items}
      selectedOptions={props.value}
      onOptionsChange={props.handleChange}
      searchPlaceHolder={"Search " + props.placeholderSearch}
    />
  );
};

CheckBox.propTypes = {
  size: PropTypes.any,
  shape: PropTypes.any,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.any,
  items: PropTypes.any,
  placeholderSearch: PropTypes.string,
};

export default CheckBox;
