import React from "react";
import ModalWindow from "../ModalWindow";
import { ProfilePicture } from "../ProfilePictue";
import UploadFile from "../UploadFile";

function ShowProfilePicModal(props) {
  return (
    <>
      <ModalWindow
        title={"Upload Profile Picture"}
        isNotClosable={true}
        okText={"ok"}
        cancelText={"cancel"}
        onCancel={props.handleProfileCancel}
        onOk={props.handleUploadOk}
        open={props.state.profileModal}
      >
        <UploadFile {...props} />
      </ModalWindow>
    </>
  );
}

export default ShowProfilePicModal;
