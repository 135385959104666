export const KEYS = {
  ACCESS_TOKEN_FIELD: 'accessToken_cdnsajkh23o64GDsbcxzkcjdSH23dks687dfusgf',
  ACCESS_EMAIL: 'accessEmail',
  LOGIN_TIMEOUT: 'loginTimeout',
  USER_ROLE: 'userRole',
  RememberMe: 'rememberMe',
  IS_LOGOUT: 'isLogout',
  POP_UP_BLOCKED: 'pop_up_blocked',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LAST_INTERACTION: 'lastInteractionTime',
  WORD_CLOUD_TEMPORARY_LIST: 'wordCloudTemporaryExcludeList',
  PROJECT_SWITCHED: 'projectSwitch',
};
