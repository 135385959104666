import React from "react";

import { TabElement } from "../../Components/TabElement";
import RoleSettings from "./Children/RoleSettings";
import SystemSettings from "./Children/SystemSettings";

function template() {
  const tabItems = [
    {
      key: "Users",
      label: "Users",
      children: (
        <RoleSettings CurrentUserProfile={this.props.CurrentUserProfile} />
      ),
    },
    {
      key: "Preferences",
      label: "Preferences",
      children: <SystemSettings />,
    },
  ];
  return (
    <>
      {this.props?.CurrentUserProfile?.userProfile != 2 ? (
        <TabElement
          default={"Users"}
          className="setting-tab"
          destroyInactiveTabPane
          activeKey={this.state.tab}
          onChange={this.onTabChange}
          items={tabItems}
        />
      ) : (
        <SystemSettings />
      )}
    </>
  );
}

export default template;
