import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CHART_CONSTANTS,
  CHART_FIELDS,
  WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL,
} from "Constants/Insights/CreateInsights.constants";
import { SelectElement } from "Components/SelectElement";
import InputField from "Components/InputField";
import { ParameterButtonBlock } from "./ParameterButtonBlock";
import { formatAvailableFieldsDropdown } from "../helper/formatter";
import { ContentLoader } from "Components/ContentLoader";
import ModalWindow from "Components/ModalWindow";
import { getChartParamsDefaultValue } from "../helper/insightsHelper";
import { WORD_CLOUD_MAXIMUM_WORD_COUNT } from "Constants/Insights/Insights.constants";
import { updateChartValidator } from "../helper/validators";
import { setInsightData } from "Redux/Actions";
import { notify } from "Components/Notification";

import style from "./style.module.scss";
import { switchCharts } from "Redux/Reducers/CreateInsights/Helper/dataFormatter";
import { formatInsightData } from "Routes/Insights/Helper";
import { generatePayload } from "Routes/Insights/Helper/chartHandler";

export function InsightChartFields({ handleClose, handleUpdate }) {
  const dispatch = useDispatch();

  const payloadFields = useSelector((state) => state.InsightsPage.data);
  const loading = useSelector((state) => state.InsightsPage.loading);
  const dataSetLoading = useSelector(
    (state) => state.InsightsPage.dataSetLoading
  );
  const createInsights = useSelector((state) => state.CreateInsights);

  const [selectedChart, setSelectedChart] = useState(createInsights.chartName);
  const [selectedFields, setSelectedFields] = useState(createInsights.payload);
  const [allFields, setAllFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    setAllFields(payloadFields);
  }, [selectedChart]);

  const handleSelectChart = (chartName) => {
    let SelectedFieldContent = getChartParamsDefaultValue(chartName);
    setSelectedFields(SelectedFieldContent);
    setSelectedChart(chartName);
  };

  const handleSelectParameters = (value, fields) => {
    let newAllFieldsArray = allFields.filter((i) => i != value);
    let unSelectElement =
      selectedFields[fields] && selectedFields[fields].value
        ? selectedFields[fields].value
        : "";
    unSelectElement != "" && newAllFieldsArray.push(unSelectElement);
    setSelectedFields({ ...selectedFields, [fields]: { value: value } });
    setAllFields(newAllFieldsArray);
  };

  const handleNonDraggable = (event, fields) => {
    if (!isNaN(event.target.value)) {
      if (!event.allowClear) {
        if (
          fields == WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL &&
          event.target.value > WORD_CLOUD_MAXIMUM_WORD_COUNT
        ) {
          setErrorMessages({
            ...errorMessages,
            [fields]:
              "Please enter a number less than or equal to " +
              WORD_CLOUD_MAXIMUM_WORD_COUNT,
          });
        } else {
          setSelectedFields({
            ...selectedFields,
            [fields]: { value: event.target.value, index: "" },
          });
          setErrorMessages({ [fields]: "" });
        }
      }
    } else {
      setErrorMessages({ ...errorMessages, [fields]: "Please enter a number" });
    }
  };

  const paramsValue = (fields) => {
    return selectedFields[fields.label] && selectedFields[fields.label].value
      ? selectedFields[fields.label].value
      : "";
  };

  const applyParams = () => {
    setErrorMessages({});
    if (!updateChartValidator(selectedFields, selectedChart)) {
      const config = switchCharts({
        chartName: selectedChart,
        parameters: selectedFields,
      });
      dispatch(
        setInsightData({
          chartName: selectedChart,
          payload: selectedFields,
          buckets:
            generatePayload({
              parameters: selectedFields,
              chartName: selectedChart,
            })?.requestPayload?.buckets || [],
          config: { [selectedChart]: config },
          ...formatInsightData({ breadCrumbs: [] }),
        })
      );
      handleUpdate();
    } else {
      notify.info("All fields are required. ", true);
    }
  };

  return (
    <div className={style.insightElements}>
      <div className={style.insightElements__item}>
        <div className={style.listBlock}>
          <h4>Insight Parameters</h4>
          <div className={style.listBlock__content}>
            <div className={style.listInputs}>
              <SelectElement
                optionList={CHART_CONSTANTS.DATASET_OPTIONS}
                onChange={handleSelectChart}
                value={selectedChart == "" ? null : selectedChart}
                placeholder={CHART_CONSTANTS.SELECT_CHART_PLACEHOLDER}
              />
            </div>
          </div>
        </div>
      </div>
      {selectedChart && (
        <div className={style.insightElements__item}>
          <div className={style.listBlock}>
            <div className={style.listBlock__content}>
              <ul className={style.chartParameters}>
                {CHART_FIELDS[selectedChart]?.map((fields, index) => (
                  <li key={index}>
                    {InputElements({
                      allFields,
                      loading,
                      fields,
                      selectedChart,
                      selectedFields,
                      handleSelectParameters,
                      dataSetLoading,
                      handleNonDraggable,
                      errorMessages,
                      paramsValue,
                    })}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className={style.insightElements__footer}>
        <ParameterButtonBlock
          handleClose={handleClose}
          handleUpdate={applyParams}
        />
      </div>
    </div>
  );
}

function InputElements({
  allFields,
  loading,
  fields,
  selectedChart,
  selectedFields,
  handleSelectParameters,
  dataSetLoading,
  handleNonDraggable,
  errorMessages,
  paramsValue,
}) {
  return (
    <>
      {fields.draggable ? (
        <>
          {/* Non number fields previously these where draggable fields */}
          {loading ? (
            <ContentLoader number={1} height={1} width={100} spaceBetween={0} />
          ) : (
            <>
              <InputField
                htmlForName={fields.label}
                title={fields.label}
                optionList={formatAvailableFieldsDropdown(
                  allFields,
                  selectedChart,
                  fields.label,
                  selectedFields
                )}
                onChange={(value) =>
                  handleSelectParameters(value, fields.label)
                }
                value={paramsValue(fields) != "" ? paramsValue(fields) : null}
                showSearch={true}
                allowClear={false}
                placeholder="Select fields "
                loading={
                  selectedChart === "KEY_PHRASE_CLOUD" ? false : dataSetLoading
                }
                inputType="selectElement"
                disabled={selectedChart === "KEY_PHRASE_CLOUD" ? true : false}
              />
            </>
          )}
        </>
      ) : (
        <>
          {fields?.info && <span>{fields.label}</span>}
          {fields?.type == "tag" ? (
            <></>
          ) : (
            !fields?.info && (
              <InputField
                allowClear={false}
                title={fields.label}
                htmlForName={fields.label}
                size="small"
                value={paramsValue(fields)}
                onChange={(event) =>
                  fields.editable
                    ? handleNonDraggable(event, fields.label)
                    : null
                }
                error={
                  errorMessages[fields.label] &&
                  errorMessages[fields.label] != "" &&
                  `${errorMessages[fields.label]}`
                }
                hint={fields.hint ?? ""}
              />
            )
          )}
        </>
      )}
    </>
  );
}

export function InsightsParametersModal({
  handleClose,
  handleUpdate,
  showConfigs,
}) {
  return (
    <>
      <ModalWindow
        isNotClosable={true}
        isNonMaskable={true}
        width="500px"
        footer={null}
        open={showConfigs}
      >
        <InsightChartFields
          handleClose={handleClose}
          handleUpdate={handleUpdate}
        />
      </ModalWindow>
    </>
  );
}
