import React from 'react';
import {Upload, message, Button} from 'antd';
import ProfileAvatar from '../../../public/img/avatar.png';
export class UploadFile extends React.Component {
  render() {
    return (
      <>
        <div className='img-holder'></div>
        <Upload.Dragger
          showUploadList={false}
          listType='picture'
          onChange={this.props.handleUpload}>
          Drag files here or click to upload
          <img
            src={
              this.props.state.profileImg === ProfileAvatar
                ? ProfileAvatar
                : this.props.state.profileImg ===
                  this.props.state.updatedProfile
                ? ProfileAvatar
                : this.props.state.profileImg
            }
            alt=''
            id='img'
            className='img'
          />
        </Upload.Dragger>
      </>
    );
  }
}

export default UploadFile;
