export const ADVANCE_SEARCH_DYNAMIC_KEYS = ['title', 'user_tags', 'summary', 'quickodes'];
export const FILTER_CONSTANTS = {
  NOTE: 'Note: Turn ON or OFF the filters on the left side and change the unique values to be selected as the facets',
  BUTTON_NEXT: 'Next',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_SAVE: 'Save',
  PLACEHOLDER_INPUT: 'Search the data field to set up the filter',
  DATAFIELDS: ['Airline Sectors', 'Author'],
  NAME_PLACEHOLDER: 'Name your query',
  DATE_FORMATS: [
    {value: 'YYYY/MM/DD', label: 'YYYY/MM/DD'},
    {value: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
    {value: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
    {value: 'YYYY/MM', label: 'YYYY/MM'},
    {value: 'MM/DD', label: 'MM/DD'},
    {value: 'YYYY', label: 'YYYY'},
    {value: 'DD MONTH YYYY', label: 'DD MONTH YYYY'},
    // {value: 'MMMM Do YYYY, h:mm:ss a', label: 'MMMM Do YYYY, h:mm:ss a'},
  ],
  TIME_FORMAT: [
    {
      value: 'HH:MM:SS',
      label: 'HH:MM:SS',
    },
    {
      value: 'HH:MM:SS XM',
      label: 'HH:MM:SS XM',
    },
    {
      value: 'HH:MM',
      label: 'HH:MM',
    },
    {
      value: 'HH:MM XM',
      label: 'HH:MM XM',
    },
  ],
  LANGUAGES: [
    {
      value: 'Chinese',
      label: 'Chinese',
    },
    {
      value: 'English',
      label: 'English',
    },
    {
      value: 'Hindi',
      label: 'Hindi',
    },
    {
      value: 'Korean',
      label: 'Korean',
    },
    {
      value: 'Russian',
      label: 'Russian',
    },
  ],
  SORT_ORDER: [
    {
      value: 0,
      label: 'Alphabetically  ascending',
    },
    {
      value: 1,
      label: 'Alphabetically  descending',
    },
    {
      value: 2,
      label: 'Based on results count - ascending',
    },
    {
      value: 3,
      label: 'Based on results count - descending',
    },
  ],
};

export const PROJECT_SETTINGS = {
  TEAM: [
    {
      value: 'Team1',
      label: 'Team1',
    },
    {
      value: 'Team2',
      label: 'Team2',
    },
    {
      value: 'Team3',
      label: 'Team3',
    },
    {
      value: 'Team4',
      label: 'Team4',
    },
    {
      value: 'Team5',
      label: 'Team5',
    },
  ],
  PROJECT: [
    {
      value: 'Project1',
      label: 'Project1',
    },
    {
      value: 'Project2',
      label: 'Project2',
    },
    {
      value: 'Project2',
      label: 'Project2',
    },
  ],
};

export const PREFERENCES_TAB_ITEMS = [
  {
    name: 'Date Settings',
    key: 'date-settings',
  },
  {
    name: 'Filter Settings',
    key: 'filter-settings',
  },
];

export const DATE_SETTINGS_LABEL = 'Select sort order of the filter options:';
export const HIDE_RESULTS_WITH_ZERO_COUNT = 'Hide results with zero count';
