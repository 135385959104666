import React from 'react';
import InputField from '../../Components/InputField';
import ButtonElement from '../../Components/ButtonElement';
import style from './style.module.scss';

export function template() {
    return (
      <section className={style.superAdminLoginWrapper}>
          <div className={style.superAdminLoginForm}>
          <h4>Super Admin Login </h4>
          <ul>
                <li>
                    <InputField
                        placeholder={'someone@example.com'}
                        onChange={(e) => this.superAdminLoginFieldChange('email', e.target.value.trim())}
                        value={this.state.email}
                    />
                </li>
                <li>
                    <InputField
                        placeholder={'Password'}
                        inputType={'password'}
                        onChange={(e) => this.superAdminLoginFieldChange('password', e.target.value.trim())}
                        value={this.state.password}
                    />
                </li>
                <li>
                    <ButtonElement onClick={()=>this.cancelSupreAdimnModal()}>Cancel</ButtonElement>
                    <ButtonElement type={'primary'} loading= {this.props.login.loading} onClick={this.superAdminLogin}>Login</ButtonElement>
                </li>
                </ul>
            </div>
      </section>
    );
  }