import React from 'react';
import { Tag } from 'antd';
import PropType from 'prop-types';
import './style.scss';
function TagElement(props) {
  return (
    <Tag closable={props.closable} onClose={props.onClose} className={props.className}>
      {props.children}
    </Tag>
  );
}
export default TagElement;

TagElement.Prototype = {
  className: PropType.string,
  closable: PropType.bool,
};
