import axios from "axios";
import { KEYS } from "dataKeys";
import { getAccessToken } from "utils/commonUtils";
import decode from "jwt-decode";
import moment from "moment";
import env from "react-dotenv";

export const filterVisibleHelper = (state) => {
  const filterFields = state.props.FilterFields;
  if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
    state.props.loadFilterFields();
  }
  !state.state.filterVisible.visible && state.handleSideBarColapse("isFilter");
  state.setState({
    filterVisible: {
      ...state.state.filterVisible,
      visible: !state.state.filterVisible.visible,
    },
  });
};

export const checkPasswordRegex = (currentPass, newPass, confirmPass) => {
  if (!currentPass) {
    return { error: true, message: "Please enter the current password" };
  } else if (currentPass == newPass) {
    return {
      error: true,
      message:
        "New Password is same as the current one. Please enter a different password.",
    };
  } else if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[a-zA-Z\d@$!%*?&]{8,}$/.test(newPass)
  ) {
    return {
      error: true,
      message:
        "The new password be at least 8 characters long containing at least an upper case letter, a lowercase letter, and a number (0-9)",
    };
  } else if (newPass != confirmPass) {
    return {
      error: true,
      message: "New password mismatch. Please confirm password!",
    };
  } else {
    return { error: false, message: "" };
  }
};

export const validateAccessToken = (timeOut) => {
  clearTimeout(timeOut);
  const token = getAccessToken();
  const expTime = moment(decode(token).exp * 1000);
  const currentTime = moment(new Date());
  const diff = expTime.diff(currentTime, "minutes");
  if (diff === 0) return;
  if (diff < 4) return updateToken();

  const timeOutCount = (diff - 3) * 60000;
  timeOut = setTimeout(() => updateToken(), timeOutCount);
};
export const updateToken = async () => {
  const header = {
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const urlDomain =
    env.REACT_APP_ENV === "local"
      ? "https://localhost:8000"
      : window.location.origin;

  const { data } = await axios.get(urlDomain + "/auth/update-token", header);
  const { accessToken } = data;
  if (accessToken) {
    document.cookie = KEYS.ACCESS_TOKEN_FIELD + "=" + accessToken;
  }
};
