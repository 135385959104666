import { COMMUNICATION_KEYS } from "constants";
import { KEYS } from "../../../dataKeys";
import { setDateFilterData } from "../../../utils/commonUtils";
import { DATE_FILTER_KEY } from "../constants";
import { formatSearchPayload, searchApiHandler } from "./handlers";
import { onMessageHandler } from "./communication";
import { SEARCH_TYPE } from "constants";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";

export const handleDeleteTagHelper = async ({
  state,
  removedItem,
  setSearchData,
  setState,
  handleDeleteMainTag,
  tag,
  search,
}) => {
  const tags = state.filterResults[tag].filter((item) => item !== removedItem);
  const newFilterResults = {...state.filterResults};
  if (tags.length == 0){
    delete newFilterResults[tag]; // tag is actually tagGroup 
  }
  else {
    newFilterResults[tag] = tags;
  }
  setState({
    filterResults: newFilterResults,
  });

  if (Object.keys(newFilterResults).length == 0){
    setState({forceRunSearch: true});
  }
  
  setSearchData({
    filter: {
      ...search.filter,
      [tag]: tags,
    },
  });
  if (tags.length === 0) {
    handleDeleteMainTag(tag);
  }
};

export const handleDeleteMainTagHelper = async ({
  state,
  setState,
  setSearchData,
  tag,
}) => {
  const selectedTags = state.filterResults;
  delete selectedTags[tag];
  setState({
    filterResults: selectedTags,
    forceRunSearch: Object.keys(selectedTags).length == 0 
  });
  if (!tag.toLowerCase().includes("date")) {
    let newFilterResults = Object.assign({}, selectedTags);
    delete newFilterResults[DATE_FILTER_KEY];
    setSearchData({
      filter: {
        ...newFilterResults,
      },
    });
  } else {
    setSearchData({
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });
  }
};

export const searchPayloadGenerator = (state) => ({
  filtersforData: [{ ...state.props.search.filter }],
  dateFilters: setDateFilterData(state.props.search.dateFilter),
  field_Value: {
    key: state.props.search.selectedField,
    value: state.props.search.value,
  },
  pageSize: state.props.search.pageSize,
  sortby: state.props.search.sortby,
  sortorder: state.props.search.sortorder,
  searchCriterias: state.props.search.searchCriterias,
});

export const homeMountHelper = ({
  location,
  history,
  channel,
  search,
  setSearchData,
  setState,
  handleStayActive,
  handleGetDashboardPreview,
  FilterFields,
  loadFilterFields,
}) => {
  const locationState = history?.location?.state;

  channel.postMessage({ type: COMMUNICATION_KEYS.START_FROM_ARTICLE });
  channel.onmessage = (event) =>
    onMessageHandler({
      event,
      location,
      history,
      setSearchData,
      setState,
      handleStayActive,
    });

  if (locationState?.type == SEARCH_TYPE.DASHBOARD_PREVIEW) {
    setState({ loading: true });
    handleGetDashboardPreview(
      {
        filter: locationState.filter,
        ids: locationState.insightIds,
        pageNumber: 1,
        pageSize: search.pageSize,
        sortOrder: search.sortorder,
        sortby: search.sortby,
      },
      ({ data, error, total }) => {
        if (error) return;
        setState({ loading: false, data: data, totalCount: total });
      }
    );
    history.replace({
      pathname: "/home",
      state: {},
    });
  } else {
    const queryParams = new URLSearchParams(window.location.search);
    const queryterm = queryParams.get("page");
    if (queryterm !== "newtab") {
      searchApiHandler({
        payload: formatSearchPayload(search),
        setState,
        setSearchData,
      });
    }
  }

  //Avoid reload modal poppup on project switching
  window.addEventListener("load", (event) => {
    if (sessionStorage.getItem(KEYS.PROJECT_SWITCHED) == "false") {
      // state.setState({showReloadModal: true});
      event.preventDefault();
      return (event.value = "");
    } else {
      sessionStorage.setItem(KEYS.PROJECT_SWITCHED, "false");
    }
  });

  const filterFields = FilterFields;
  if (filterFields.loading !== true && filterFields.allFields?.length === 0)
    loadFilterFields();
};

export const formatBreadCrumbData = (breadCrumbData) => {
  let searchWords = [];
  breadCrumbData?.map((item) => {
    searchWords.push(item.label);
  });
  return searchWords;
};

//++++++++++++//
// generate advance search payload from filter object
export const generateAdvanceSearchPayload = (payload, insight = false) => {

  let filterGroupList = [];

  Object.keys(payload.filter).map((item) => {
    let isDatePublished = false;
    if (item.includes("Date")) {
      isDatePublished = true;
    }
    let newObject = generatePayloadSearchObject(payload, item, isDatePublished);
    filterGroupList.push(newObject);
  });
  let dateFilterObject = generatePayloadSearchObject(
    payload.dateFilter,
    "Date Published",
    false
  );
  filterGroupList.push(dateFilterObject);
  let searchFilterObject = generatePayloadSearchObject(
    insight ? payload.searchValue : payload.value,
    "search",
    false
  );
  filterGroupList.push(searchFilterObject);
  return [
    {
      key: {
        filterOperator: true,
      },
      value: {
        filterGroupList: filterGroupList,
      },
    },
  ];
};
const generatePayloadSearchObject = (payload, fieldName, isDatePublished) => {
  let facetObject = {
    key: {
      filterOperator: false,
    },
    value: {
      soloFilter: [
        {
          key:
            fieldName == "search"
              ? {}
              : {
                  fieldName: fieldName,
                },
          value: {
            facetList: [
              {
                key: true,
                value: generateFacetSearchValue(
                  payload,
                  fieldName,
                  isDatePublished
                ),
                options:
                  fieldName == "Date Published"
                    ? ""
                    : fieldName == "search"
                    ? "All"
                    : "Exact",
              },
            ],
          },
        },
      ],
    },
  };
  return facetObject;
};
const generateFacetSearchValue = (payload, fieldName, isDatePublished) => {
  if (fieldName == "Date Published" && !isDatePublished) {
    let fromDate = Array.isArray(payload)
      ? payload[0]?.value?.from ?? ""
      : payload?.value?.from ?? "";
    let toDate = Array.isArray(payload)
      ? payload[0]?.value?.to || ""
      : payload?.value?.to ?? "";
    return [fromDate, toDate];
  } else if (fieldName == "search") {
    return [payload];
  } else {
    if (isDatePublished) {
      return Array.isArray(payload.filter[fieldName])
        ? [payload.filter[fieldName][0], payload.filter[fieldName][0]]
        : [""];
    } else {
      return payload.filter[fieldName];
    }
  }
};
//------------------------------//
