import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ButtonElement from "../../../../Components/ButtonElement";
import {
  LOGIN_ADFS_BUTTON_TITLE,
  LOGIN_FAILED_MESSAGE,
  RESTRICTION_MESSAGE,
} from "../../../../Constants/Login/Login.constants";
import ShowModal from "./ShowModal";
import SVGIcons from "../../../../Components/SVGIcons";
import { handleLoginWithADFS } from "./helper";

import MainLogo from "../../../../Components/MainLogo";
import { APP_VERSION } from "../../../../constants";
import style from "./style.module.scss";

const RightSide = (props) => {
  const history = useHistory();
  const [adfsLoading, setAdfsLoading] = useState(false);

  return (
    <>
      {props.state.loginFailed ? (
        <div className={style.loginForms}>
          <div className={style.loginForms__inner}>
            <div className={style.alertWrapper}>
              <LoginAlertBanner messageObject={LOGIN_FAILED_MESSAGE} />
            </div>
          </div>
        </div>
      ) : (
        <div className={style.loginForms}>
          <div className={style.loginForms__inner}>
            <div className={style.brandLogo}>
              <MainLogo />
            </div>
            <div className={style.compantSlogen}>
              <h3>Content Insights</h3>
              <p>
                Visualize Data. Explore Insights. Experience The Difference.
              </p>
            </div>
            <div className={style.listWrapper}>
              {props.state.forgetPasswordModal && <ShowModal {...props} />}
              <ul className={style.loginList}>
                <li>
                  <ButtonElement
                    type="primary"
                    onClick={() =>
                      handleLoginWithADFS({ history, setAdfsLoading })
                    }
                    className="microsoft-btn"
                    icon={"SVG-ms"}
                    size={"large"}
                    loading={adfsLoading || props.state.loginInProgress}
                  >
                    {LOGIN_ADFS_BUTTON_TITLE}
                  </ButtonElement>
                </li>
              </ul>
            </div>
            <div className={style.alertWrapper}>
              <LoginAlertBanner messageObject={RESTRICTION_MESSAGE} />
            </div>
            <div className={style.appVersion}>
              <p>
                <span>Pingar Insights</span>
                <span>version {APP_VERSION}</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightSide;

function LoginAlertBanner({ messageObject }) {
  return (
    <>
      <div className={style.loginAlert}>
        <div className={style.alertIcon}>
          <span>
            <SVGIcons type={"SVG-alertIcon"} />
          </span>
        </div>
        <div className={style.alertContent}>
          <h3>{messageObject?.HEADING}</h3>
          <p>{messageObject?.LINE_1}</p>
          <p>{messageObject?.LINE_2}</p>
        </div>
      </div>
    </>
  );
}
