import Component from './Login';
import {connect} from 'react-redux';

import {resetPasswordAction, sendEmail, signIn} from '../../Redux/Actions';

function mapStateToProps(state) {
  return {
    ...state.ResetPasswordReducer,
    ...state.Login,
    ...state.SendEmail,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (params, modalClose) => dispatch(resetPasswordAction(params, modalClose)),
    signIn: (params, login) => dispatch(signIn(params, login)),
    sendEmail: (params, validateSubmitMail) => dispatch(sendEmail(params, validateSubmitMail)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
