import { viewDashboardApi } from "../../../api/dashboard";
import { notify } from "Components/Notification";
import { DEFAULT_PAGE_COUNT } from "../../../constants";
import { ROUTES } from "../../../Constants/Routes/Routes.constants";
import { KEYS } from "../../../dataKeys";
import { handleApiError } from "../../../utils/commonUtils";

/*format the modal window checkbox values with insight name*/
export function formatListInsightsValue(data) {
  let insightArray = [];
  data.map((item) => {
    insightArray.push({ label: item.insightsName, value: item.id });
  });
  return insightArray;
}

export function formatListInsightsName(data) {
  let insightArray = [];
  data.map((item) => {
    insightArray.push(item.insightsName.toUpperCase());
  });
  return insightArray;
}

export const handleViewDashboardHelper = async ({ state, record }) => {
  try {
    state.setState({ showSavedDashboard: false, loadDashboardLoading: true });
    let dashboardContents = await viewDashboardApi({ id: record.id });

    let layout = dashboardContents.data.result.layout;
    let payloadList = formatInsightData({
      selectedValues: dashboardContents.data.result.insightsPresent,
      state,
    });
    state.setState({ loadDashboardLoading: false });
    await state.props.history.push({
      pathname: ROUTES.DASHBOARD,
      state: {
        isView: true,
        isEdit: false,
        payload: payloadList,
        selectedList: payloadList,
        dashboardName: record.dashboardName,
        dashboardId: record.id,
        layout: JSON.parse(layout),
      },
    });
    state.setState({
      displayDashboards: true,
      insightsData: payloadList,
    });
  } catch (error) {
    handleApiError(error, () => notify.error("Failed to fetch data", true));
  }
};

const formatInsightData = ({ state, selectedValues }) => {
  let insightListArray = [];
  selectedValues
    .filter((item) => item.type !== "filter")
    .map((item) => {
      insightListArray.push(item);
    });
  return insightListArray;
};

export const handleSelectEditDashboard = (data) => {
  let selectedList = [];
  data.map((item) => {
    selectedList.push(item.id);
  });
  return selectedList;
};

export const handleEditDashboardHelper = ({ state, record }) => {
  let selectedInsight = handleSelectEditDashboard(record.dashboardContentsbyte);
  state.setState({ displayDashboards: true });
  state.props.history.push({
    pathname: ROUTES.DASHBOARD,
    state: {
      isEdit: true,
      payload: record.dashboardContentsbyte,
      selectedList: selectedInsight,
      dashboardName: record.dashboardName,
      dashboardId: record.id,
      layout: JSON.parse(record.layout),
    },
  });
};
//handle download dashboard click
export const handleDownloadDashboardHelper = ({ state, record }) => {
  state.props.history.push({
    pathname: ROUTES.DOWNLOAD_PREVIEW,
    search: "?dashboard",
    state: {
      isDashboard: true,
      id: record.id,
      breadCrumbs: [
        {
          label: "Dashboard Canvas",
          link: "/dashboard",
        },
        { label: "Download Dashboard", link: "" },
      ],
    },
  });
};

//delete modal handle helper
export const deleteModalHandlerHelper = ({ state }) => {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  state.setState({
    deleteLoading: true,
  });
  state.props.handleDeleteDashboardData(state.state.selectedData?.id, () => {
    notify.success(
      state.state.selectedData?.dashboardName + " Deleted Successfully",
      true
    );
    state.deleteModalHandler("", "modalOff");
    if (state.props.importedInsights?.dashboardData.length == 0) {
      state.props.getDashboardData({
        pageNumber: 1,
        pageSize: DEFAULT_PAGE_COUNT,
        emailid: email,
      });
    } else {
      state.props.getDashboardData({
        pageNumber: state.state.pagination.pageNumber,
        pageSize: state.state.pagination.pageSize,
        emailid: email,
      });
    }
  });
};
