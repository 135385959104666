import { getAllFieldsList } from "../../../api/search";
import { saveFilterSettings } from "../../../api/settings";
import { DATE_FIELDS_DATA_TYPE } from "../../../Components/FilterSection/FilterSection.constants";
import { KEYS } from "../../../dataKeys";

export function removeDuplicates(data) {
  return data.filter((item, index) => data.indexOf(item) === index);
}

/**
 *
 * @param {{dataType: number, isFilter: boolean, name: string}[]} filters
 * @returns
 */
const setData = (filters) => {
  // /**
  //  * @type {{[field: string]: {checked: boolean, from: string, to: string, format: string, dataType: string} |
  //  * {checked: boolean,loading: boolean,data: string[],value: string[],dataType: number}}}
  //  */
  let newSample = {};
  let obj;
  filters.forEach((item) => {
    if (item.dataType === DATE_FIELDS_DATA_TYPE) {
      obj = {
        checked: true,
        from: "",
        to: "",
        format: "DD/MM/YYYY",
        dataType: item.dataType,
      };
    } else {
      obj = {
        checked: true,
        loading: false,
        data: [],
        value: [],
        dataType: item.dataType,
      };
    }
    newSample = { ...newSample, [item.name]: obj };
  });
  return newSample;
};

export async function saveUserFilterSettings(payload) {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  let saveSettingsPayload = {
    emailid: email,
    settingsContent: payload.data,
    name: payload.name,
    projectId: payload.project,
  };
  let saveResponse = await saveFilterSettings(saveSettingsPayload);
  return saveResponse;
}

export async function getAllFilterFieldsList() {
  let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
  // const {data} = await getFilterGenericFields({emailId: email});
  const { data } = await getAllFieldsList({ emailId: email });

  const filters = data.result
    .filter((item) => item.isFilter)
    .sort((a, b) => a.name.localeCompare(b.name));

  const formattedFilters = setData(filters);
  const allFields = data.result.map((filter) => filter.name).sort();
  const allFieldsWithMetadata = data.result.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return {
    data: formattedFilters,
    allFields,
    allFieldsWithMetadata,
  };
}
