import React from "react";
import ConfirmationMessage from "../../../Components/ConfirmationMessage";
import ModalWindow from "../../../Components/ModalWindow";
export function DeleteUserGroupModal(props) {
  return (
    <>
      <ModalWindow
        isNotClosable={true}
        title={false}
        footer={false}
        open={props.visible}
      >
        <ConfirmationMessage
          cancelButtonLabel="Yes"
          confirmButtonLabel="No"
          loading={props.loader}
          heading={"Confirm Delete"}
          message={`Are you sure, you want to delete this user group '${props.group.name}' ?`}
          onOk={(e) => {
            props.onCancel();
          }}
          onCancel={(e) => {
            props.confirmDelete();
          }}
        />
      </ModalWindow>
    </>
  );
}
