import { APIInstance } from "./apiInstance";
import { BASE_URL } from "./constants";

export function Search(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/reMultipleSearch", payload);
}

/**
 *
 * @param {{emailId: string | null, uid: string, user_tags: string[]}} payload
 * @returns
 */
export function addTags({ emailId, uids, user_tags, uidsReturn, append }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updatewithid", { emailId, uids, keywords: user_tags, uidsReturn, append });
}

export async function getKeywords(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/genericCollection");
}

/**
 *
 * @param {{emailId: string | null}} param
 * API Method to get Filter field list from backend
 * @returns {{data: string[]}}
 */
export function getFilterGenericFields({ emailId }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  // return api.get('/genericfeilds');
  return api.post("/genericfeilds", { emailid: emailId });
}

/**
 *
 * @param {{emailId: string | null}} param
 * API Method to get Filter field list with details like dataType and isFilter
 * @returns {{data: {dataType: number, isFilter: boolean, name: string}[]}}
 */
export function getAllFieldsList({ emailId }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/FilterFields", { emailId: emailId });
}

/**
 * API Method to get Filter list of specific field from backend
 * @param {{fieldName: string}} payload
 * @returns {{data: string[]}}
 */
export function getFiltersByFields({ fieldName, emailId }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/SearchByField", { fieldName, emailId });
}

export function addRecentSearch(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/addsearchhistory", payload);
}

export function getRecent(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/getsearchhistory", payload);
}

/**
 *
 * @param {{pageNumber: number, pageSize: number, emailid: string, isSavedResult: boolean, avoidDetails: boolean}} payload
 * @returns
 */
export function getSavedSearchList(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetsearchhistoryPageSize", payload);
}

export function getAllSavedSearch() {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/SummaryOfAllSearchhistories");
}

/**
 *
 * @param {{id: number,emailid: string}} payload
 * @returns
 */
export function getSavedSearchById(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetsearchhistoryById", payload);
}

export function deleteSaved(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/deleteSearch", payload);
}

export function advancedSearch(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });
  const api = instance.api;
  return api.post("/advancesearch", payload);
}

export function addPrivateTags(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/addPrivateTags", payload);
}

export function updateQuickCodes(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updateQuickCodes", payload);
}

export function updateSearchHistory(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updatesearchhistory", payload);
}

export function getFullContent(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetFullArticle", payload);
}

export function filteFieldsByCount(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetFieldData", payload);
}

export function exportAsXlsx(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/AdvanceSearchXLS", payload);
}

export function searchConcepts(searchText){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/search-concepts", {advanceSearchKeyword: searchText});
}


export function searchConcepts2(searchText){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/search-concepts2", {advanceSearchKeyword: searchText});
}
