import { connect } from "react-redux";
import { ClearFilterFieldData, setSearchData } from "../../Redux/Actions/";
import Sidebar from "./Sidebar";

function mapStateToProps(state) {
  return { ...state.Search, CurrentUserProfile: state.Settings.profile };
}
function mapDispatchToProps(dispatch) {
  return {
    ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
