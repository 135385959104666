import { SEARCH_TYPE } from "constants";
import { useRef, useState } from "react";
import style from "../style.module.scss";
import TooltipContainer from "Components/Tooltip";
import SVGIcons from "Components/SVGIcons";
import SearchBar from "Components/SearchBar";
import ButtonElement from "Components/ButtonElement";
import { MySortContent } from "./SortContent/MySortContent";
import SaveResults from "./children/SaveResults";
import LoadDropdown from "./LoadResults/LoadDropDown";
import SaveResultsModal from "./children/SaveResultsModal";
import LoadResults from "./children/LoadResults";
import ExportsResults from "./children/ExportsResults";
import { FILTER_DISABLE_TOOLTIP, VIEW_INSIGHT_DISABLED_TOOLTIP } from "./constants";
import { notify } from "Components/Notification";
import { createSaveResultPayload, getTabNameFromSearchCriteria, handleFormatAdvanceSearchTextData, handleFormatTextData } from "./helper";
import { KEYS } from "dataKeys";
import { MyLoadDropdown } from "./LoadResults/MyLoadDropDown";
import { getDefaultQuery, setDefaultQuery } from "../../../api/userGroups";
import { handleApiError } from "../../../utils/commonUtils";

export const MyHomeHeader = props => {
    //debugger;

    // initial state copied from constructor
    const [state, setLocalState] = useState({
        saveQueryName: "",
        loadingResults: false,
        saveModalError: "",
        sortedInfo: { columnKey: "", order: "" },
        pagination: { pageNumber: 1, pageSize: 5 },
        savedSearchDeleteLoading: false,
        showSavedSearchDeleteModal: false,
        selectedTitle: "",
        selectedData: {},
        showLoadMenu: false,
        exportLoader: false,
        defaultQueryId: null,
    });

    const setState = data => {
        setLocalState(prevState => {
            console.log('prevState: ', prevState);
            console.log('data: ', data);
            return { ...prevState, ...data };
        });
    };

    const setLoadRef = useRef();
    const loadRef = useRef();


    const {
        search, // from mapStateToProps in MyHome 
        handleSave,
        handleExport,
        exportLoading,
        handleViewNewTab,
        viewTabButtonVisible,
        searchData,
        projectId,
        userProfile,
    } = props;


    const isAdvanceSearch = search.searchType === SEARCH_TYPE.ADVANCED_SEARCH;

    const handleAdvanceSearchClick = () => {
        const filterFields = props.FilterFields;
        if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
            props.loadFilterFields(); //  dispatching via MyHome.js
        }
        const element = document.getElementById("MainBody");
        if (element) element.classList.add("scrollDisable");
        props.mainFrameState.setState({
            showAdvanceSearchDropDown: {
                ...props.mainFrameState.state.showAdvanceSearchDropDown,
                visible: !props.mainFrameState.state.showAdvanceSearchDropDown.visible,
            },
        });
    };


    // copiped from HomeHeader\index.js, used as onChagne handler for textbox in modal window
    const handleSaveOnchange = (event) => {
        setState({
            saveQueryName: event.target.value,
            saveModalError: "",
        });
    };

    const handleSaveResult = () => {
        // TODO: Multiple dateFilter support
        if (!state.saveQueryName.trim()) {
            setState({ saveModalError: "empty" });
            return;
        }

        setState({ loadingResults: true });
        const payload = createSaveResultPayload({
            props: { search: props.search },
            state: {
                saveQueryName: state.saveQueryName
            }
        });
        props.handleSavedResults(payload, (data) =>
            handleSaveCallBack(data)
        );
    };

    const handleSaveCallBack = (data) => {
        if (data?.error) {
            setState({ loadingResults: false, saveModalError: data.message });
        }
        else {
            props.handleModal(); // closes the modal
            notify.success(state.saveQueryName + " Added Successfully", true);
            setState({ loadingResults: false, saveQueryName: "" });
        }
    };

    const handleCloseModal = () => {
        debugger;
        props.handleModal();
        setState({ saveQueryName: "", saveModalError: "" });
    };

    const handleLoad = (value) => {
        setState({ showLoadMenu: value });
        if (value === true) {
            getSavedSearchList(); // naming !
        }
    };

    const handleSetSaved = (savedSearchRow) => {
        debugger;
        let data = savedSearchRow?.searchHistory?.metadata;
        data = JSON.parse(data);
        const tab = getTabNameFromSearchCriteria(data?.searchCriterias);
        props.setSearchData({ searchTab: tab, ...data });
        handleLoad(false);
    };

    const handleDelete = (record) => {
        setState({
            selectedTitle: record.searchKeyword,
            showSavedSearchDeleteModal: true,
            selectedData: record,
        });
    };

    const getGroupDefaultQuery = async (emailid) =>{
        try {
            const res = await getDefaultQuery({ emailid, projectId });
            setState({defaultQueryId: res.data?.id});
            return res.data;
        } catch (error) {
            handleApiError(error, () => {
                notify.error(`Failed to get default query for the group: ${error.response?.data}`);
            });
        }
    }

    const setAsGroupDefaultQuery = async (record) => {
        try {
            await setDefaultQuery({ emailId: record.emailid, queryId: record.id, projectId });
            const defaultQuery = await getGroupDefaultQuery(record.emailid);
            props.setDefaultQuery(defaultQuery);
            notify.success('Successfully set as the default query for this group');
        } catch (error) {
            handleApiError(error, () => {
                notify.error(`Failed to update user group: ${error.response?.data}`);
            });
        }
    };


    const exportHandler = (data) => {
        const metaData = JSON.parse(data.searchHistory.metadata);
        let textData = metaData.searchType == SEARCH_TYPE.NORMAL_SEARCH
            ? handleFormatTextData(metaData)
            : handleFormatAdvanceSearchTextData({
                data: metaData.advanceSearchPayloadData,
                searchWord: metaData.searchWords,
                value: metaData.value,
            });
        const element = document.createElement("a");
        const file = new Blob([textData], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "Saved Query " + data.searchKeyword + ".txt";
        document.body.appendChild(element);
        element.click();
        // TODO: remove the element !!!
    };




    const getSavedSearchList = async (pagination) => {
        const emailid = localStorage.getItem(KEYS.ACCESS_EMAIL);
        props.handleSavedSearchPaginate({
            ...pagination,
            emailid,
            isSavedResult: true,
            avoidDetails: true,
        });

       await getGroupDefaultQuery(emailid);
    };

    const deleteModalCloseHandler = () => {
        setState({
            selectedTitle: "",
            showSavedSearchDeleteModal: false,
            selectedData: {},
            savedSearchDeleteLoading: false,
        });
    };

    const deleteModalHandlerYes = () => {
        setState({
            savedSearchDeleteLoading: true,
        });

        props.handleDeleteSaved(state.selectedData, () => {
            deleteModalCloseHandler();
            if (props.search.saved.length == 0) {
                let pagination = { pageNumber: 1, pageSize: 50 };
                props.handleSavedSearchPaginate({
                    ...pagination,
                    emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
                    isSavedResult: true,
                    avoidDetails: true,
                });
            } else {
                getSavedSearchList();
            }
        });
    };

    const handleSavedSearchPagination = (pageNumber, pageSize) => {
        const pagination = {pageNumber, pageSize};
        setState({ pagination });
        getSavedSearchList(pagination);
    };



    return (<>
        <div className={style.headerControls}>
            <div className={style.controlsSearch}>
                <ul className={style.controlsSearch__list}>
                    <li>
                        <TooltipContainer title={isAdvanceSearch && FILTER_DISABLE_TOOLTIP} placement={"right"}>
                            <a
                                disabled={isAdvanceSearch || searchData.loading}
                                className={`${style.filtersearchButton} ${props.filterVisible.visible
                                    ? style["filterToggled"]
                                    : ""
                                    }`}
                                onClick={
                                    !searchData.loading && !isAdvanceSearch
                                        ? props.handleFilterVisible
                                        : undefined
                                }
                            >
                                <span>
                                    <SVGIcons type={"SVG-arrow"} />
                                </span>
                                <span>Filter</span>
                            </a>
                        </TooltipContainer>
                    </li>
                    <li>
                        <SearchBar className={style.text} />
                    </li>
                </ul>
            </div>
            <div className={style.controlsElement}>
                <ButtonElement
                    type={"primary"}
                    size={"small"}
                    onClick={!viewTabButtonVisible ? undefined : handleViewNewTab}
                    disabled={!viewTabButtonVisible}
                >
                    <TooltipContainer
                        title={!viewTabButtonVisible ? VIEW_INSIGHT_DISABLED_TOOLTIP : ""}
                        placement={"bottom"}
                    >
                        <span>View insights</span>
                    </TooltipContainer>
                </ButtonElement>

                <div className={style.advancDrawertrigger}>
                    <a
                        to={{}}
                        className={style.advancesearchButton}
                        onClick={handleAdvanceSearchClick}
                    >
                        <span>Advanced Search</span>
                    </a>
                </div>
                <MySortContent
                    listViewType={props.listViewType}
                    handleListView={props.handleListView}
                    searchData={searchData}
                />
                <ul className={style.controlsElement__list}>
                    <li>
                        <div className={style.controlsItem}>
                            <SaveResults
                                title={"Save Results"}
                                handleSave={handleSave} // comes all the way from MyHome.js 
                                disabled={
                                    !searchData.loading && searchData?.data?.length
                                        ? false
                                        : true
                                }
                            />
                        </div>
                    </li>
                    <li>
                        <div className={style.controlsItem}>
                            <LoadResults
                                toolTipTitle={"Saved Queries"}
                                handleLoad={(value) => handleLoad(value)}
                            />
                        </div>
                        <MyLoadDropdown
                            search={props.search}

                            showSavedSearchDeleteModal={state.showSavedSearchDeleteModal}
                            savedSearchDeleteLoading={state.savedSearchDeleteLoading}
                            exportLoader={state.exportLoader}

                            pagination={state.pagination}

                            deleteModalCloseHandler={deleteModalCloseHandler}
                            deleteModalHandlerYes={deleteModalHandlerYes}

                            showLoadMenu={state.showLoadMenu}
                            optionList={search?.fullSavedData}
                            handleLoad={handleLoad}

                            handleDelete={handleDelete}
                            handleSetSaved={handleSetSaved}
                            exportHandler={exportHandler}
                            setAsGroupDefaultQuery={setAsGroupDefaultQuery}
                            defaultQueryId={state.defaultQueryId}
                            userProfile={props.userProfile}

                            handleSavedSearchPagination={handleSavedSearchPagination}


                        />
                    </li>

                    <li>
                        <div className={style.controlsItem}>
                            <ExportsResults
                                handleExport={props.handleExport}
                                exportLoading={props.exportLoading}
                                disabled={
                                    !searchData.loading && searchData?.data?.length
                                        ? false
                                        : true
                                }
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <SaveResultsModal
            props={{
                showSaveModal: props.showSaveModal
            }}
            state={{
                loadingResults: state.loadingResults,
                saveModalError: state.saveModalError,
                saveQueryName: state.saveQueryName
            }}
            handleSaveResult={handleSaveResult}
            handleCloseModal={handleCloseModal}
            handleSaveOnchange={handleSaveOnchange}
        />
    </>);

}