import React, { useState, useRef, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import ModalWindow from "../../ModalWindow";
import CustomScrollbar from "../../CustomScrollbar";
import Loader from "../../Loader";
import TagsBlock from "./tagsBlock";
import {
  ARTICLE_DOCUMENT_API,
  SEARCH_RESULT_PDF_KEY,
} from "../../../constants";
import { articlePdfDownloadHandler, getRelativePathfromFileData, removeHtmlTagsFromFileExtension } from "../Helper";

import style from "./style.module.scss";
import { SEARCH_CARD_METADATA_ITEMS_BLACK_LIST } from "../../../Constants/Home/Home.constants";

const TAXONOMIES_VIEW_LESS_COUNT = 6;

function PreviewModal(props) {
  const [page, setPage] = useState(1);
  const [taxonomyLength, setTaxonomyLength] = useState(
    TAXONOMIES_VIEW_LESS_COUNT
  );

  let pdfRelativePath = getRelativePathfromFileData(props.data[SEARCH_RESULT_PDF_KEY]);
  pdfRelativePath = removeHtmlTagsFromFileExtension(pdfRelativePath);
  const pdfDocumentPath = ARTICLE_DOCUMENT_API + pdfRelativePath;
  const fileName =
    "Article-" +
    props.data.DocumentId +
    pdfDocumentPath.slice(pdfDocumentPath.length - 20);
  let docs = [];

  let fileContentType = pdfDocumentPath?.split(".");
  fileContentType = fileContentType[fileContentType.length - 1];

  if (fileContentType == "doc") {
    fileContentType = "docx";
  }
  docs = pdfDocumentPath
    ? [{ uri: pdfDocumentPath, fileType: fileContentType }]
    : [];

  const MyLoadingRenderer = () => {
    return (
      <div style={{ width: "100%" }}>
        <Loader
          hasWrapper={true}
          customHeight={"100%"}
          classList={["sm blue"]}
        />
      </div>
    );
  };

  const MyNoRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";
    if (fileText) {
      return <div className={style.noRenderer}>
        Unexpected file extension. Preview cannot be generated.
      </div>;
    }
    return <div>Error! Preview cannot be generated.</div>;
  };
  return (
    <ModalWindow
      title={"Article Preview"}
      width={"800px"}
      isNotClosable={true}
      isNonMaskable={true}
      okText={"Download"}
      onCancel={(e) => (pdfDocumentPath ? props.setShowPreview(false) : "")}
      onOk={(e) =>
        pdfRelativePath && articlePdfDownloadHandler(pdfDocumentPath, fileName)
      }
      disableOkButton={pdfRelativePath ? false : true}
      open={props.showModal}
    >
      <div className={style.articlePreview}>
        <div className={style.articlePdfblock}>
          <div
            className={style.pdfWrapper}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              overflowY: 'hidden' 
            }}
          >
            {!pdfRelativePath || !pdfDocumentPath ? (
              <div className={style.noPdf}>
                The article cannot be found on the path specified at the
                backend! Please contact your system admin.
              </div>
            ) : (
              <DocViewer
                className={style.docViewWrapper}
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    retainURLParams: true,
                  },
                  pdfZoom: {
                    defaultZoom: 1.1, // 1 as default,
                    zoomJump: 0.2, // 0.1 as default,
                  },
                  loadingRenderer: {
                    overrideComponent: MyLoadingRenderer,
                  },
                  noRenderer: {
                    overrideComponent: MyNoRenderer,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </ModalWindow>
  );
}

export default PreviewModal;
