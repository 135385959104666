export const onSearchBarEnter = (props, { searchString }) => {
  props.setInsightData({ searchValue: searchString });
};

export const formatOptionList = (data) => {
  let newArray = [];
  data.map((item) => {
    let dropDownObject = { value: item.id, label: item.name };
    newArray.push(dropDownObject);
  });
};
