import { connect } from "react-redux";
import {
  getAllUserDetails,
  updateUserDetails,
  AddUserDetails,
  UpdateUserDetails,
  DeleteUserDetails,
} from "../../../../Redux/Actions";
import RoleSettings from "./RoleSetting";

function mapStateToProps(state) {
  return {
    userDetails: state.UserProfiles.data,
    userListloading: state.UserProfiles.userListloading,
    error: state.UserProfiles.error,
    errorMessage: state.UserProfiles.errorMessage,
    userUpdateLoading: state.UserProfiles.loading,
    projectList: state.FetchProjectList,
    totalCount: state.UserProfiles.totalCount,
    addUserLoading: state.Settings.addUserLoading,
    addUserMessage: state.Settings.addUserMessage,
    updateUserLoading: state.Settings.updateUserLoading,
    updateUserMessage: state.Settings.updateUserMessage,
    deleteUserLoading: state.Settings.deleteUserLoading,
    deleteUserMessage: state.Settings.deleteUserMessage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserProfiles: (params) => {
      dispatch(getAllUserDetails(params));
    },
    updateUserDetails: (params, ApiHandler) => {
      dispatch(updateUserDetails(params, ApiHandler));
    },
    addUser: (params, callback) => {
      dispatch(AddUserDetails(params, callback));
    },
    updateUser: (params, callback) => {
      dispatch(UpdateUserDetails(params, callback));
    },
    deleteUser: (params, callback) => {
      dispatch(DeleteUserDetails(params, callback));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleSettings);
