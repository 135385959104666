import React, {Component} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4pluginsforceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import style from './style.module.scss';
import globel from '../../styles/globel/style.module.scss';
class TreeChart extends Component {
  componentDidMount = () => {
    this.initChart();
    this.setChartData();
  };
  initChart = () => {
    var chart = am4core.create(this.props.id, am4pluginsforceDirected.ForceDirectedTree);
    chart.zoomable = true;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom'; // Positionning your legend to the right of the chart
    chart.legend.maxHeight = 80;
    chart.legend.scrollable = true; // Make it scrollable
    chart.logo.disabled = true;
    // Create series
    var series = chart.series.push(new am4pluginsforceDirected.ForceDirectedSeries());
    this.series = series;
  };

  setChartData = () => {
    // Set data
    this.series.data = this.props.data;
    // Set up data fields
    this.series.dataFields.value = 'count';
    this.series.dataFields.name = 'nodename';
    this.series.dataFields.children = 'relData';
    this.series.dataFields.linkWith = 'linkWith';
    this.series.maxLevels = 2;
    this.series.nodes.template.togglable = true;
    this.series.dataFields.fixed = 'fixed';

    // Add labels
    this.series.nodes.template.label.text = '{name}';
    this.series.nodes.template.tooltipText = '{name}: [bold]{value}[/]';
    this.series.nodes.template.label.truncate = true;
    this.series.fontSize = 10;
    this.series.minRadius = 15;
    this.series.maxRadius = 40;
    this.series.centerStrength = 0.5;
    this.series.appear(1000, 100);
  };

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this?.root?.dispose?.();
    if (prevProps != this.props) {
      this.initChart();
      this.setChartData();
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return nextProps.config !== this.props.config || nextProps.data !== this.props.data;
  };

  render() {
    return (
      <div
        className={`${this.props.dashboard ? style['chart-root-container'] : globel.chartItem}  ${
          this.props.isDownload && globel.previewCharts
        }`}
        id={this.props.id}
      />
    );
  }
}

export default TreeChart;
