import { GetDashboardDocumentsById } from "../../../api/dashboard";
import SVGIcons from "Components/SVGIcons";
import TooltipContainer from "Components/Tooltip";
import { errorMEssageHandler } from "../../../utils/ErrorHandler";
import { KEYS } from "../../../dataKeys";
import { DATE_FILTER_KEY } from "../../Home/constants";
import {
  ARTICLE_DOCUMENT_API,
  SEARCH_RESULT_PDF_KEY,
} from "../../../constants";
import { handleApiError } from "../../../utils/commonUtils";
import globel from "../../../styles/globel/style.module.scss";
import { articlePdfDownloadHandler } from "Components/SearchCard/Helper";

const DASHBOARD_MATCHED_RESULT_FIELDS = [
  "user_tags",
  "Location",
  "ClippedPath",
  "DocumentId",
];

export const geDataSetDetails = async ({
  insightsData,
  setDashBoardData,
  setLoading,
  setError,
  setPagination,
  pagination,
  setErrorMessage,
  dateAndTitleFields,
}) => {
  try {
    setLoading(true);
    let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
    const { data } = await GetDashboardDocumentsById({
      ids: insightsData,
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      reqFields: [...DASHBOARD_MATCHED_RESULT_FIELDS, ...dateAndTitleFields],
      emailId: email,
    });
    setDashBoardData(data.result.result);
    setPagination({ ...pagination, totalCount: data.result.resultHits });
    setLoading(false);
    setError(false);
    setErrorMessage("");
  } catch (error) {
    handleApiError(error, () => {
      setErrorMessage(errorMEssageHandler(error?.response?.status));
      setLoading(false);
      setError(true);
      setDashBoardData([]);
    });
  }
};

export const TABLE_CONFIG = {
  COLUMNS: ({ dateField, titleField }) => [
    ...showTitle({ dateField, titleField }),
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: "right",
      width: 150,
      render: (text, record) => (
        <ul className={globel.tableActions}>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              placement={"bottom"}
              title={
                record.Location.includes("http")
                  ? "Navigate to source"
                  : "Not available"
              }
            >
              <span
                onClick={(e) => {
                  record.Location.includes("http") &&
                    window.open(record.Location);
                }}
              >
                <SVGIcons type={"SVG-redirect"} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer
              placement={"bottom"}
              title={
                record[SEARCH_RESULT_PDF_KEY]
                  ? "Download file"
                  : "Not available"
              }
              mouseLeaveDelay={0}
            >
              <span
                onClick={(e) => {
                  const pdfRelativePath = record[SEARCH_RESULT_PDF_KEY];
                  const downloadLink = ARTICLE_DOCUMENT_API + pdfRelativePath;
                  const fileName =
                    "Article-" + downloadLink.slice(downloadLink.length - 20);
                  pdfRelativePath &&
                    articlePdfDownloadHandler(downloadLink, fileName);
                }}
              >
                <SVGIcons type={"SVG-download"} />
              </span>
            </TooltipContainer>
          </li>
        </ul>
      ),
    },
  ],
};

function showTitle({ dateField, titleField }) {
  return [
    {
      title: "Insights",
      dataIndex: "Insights",
      key: "Insights",
      fixed: "left",
      width: 150,
      render: (title) => `${title}`,
    },
    {
      title: titleField,
      dataIndex: titleField,
      key: titleField,
      fixed: "left",
      width: 150,
      render: (title) => (
        <>
          <TooltipContainer
            placement={"bottom"}
            title={title}
            mouseLeaveDelay={0}
          >
            {title ? `${title?.substring(0, 25)}...` : "Not available"}
          </TooltipContainer>
        </>
      ),
    },
    {
      title: dateField,
      dataIndex: dateField,
      key: dateField,
      fixed: "left",
      width: 150,
      render: (title) => (title ? `${title}` : "not available"),
    },
    {
      title: "User Tags",
      dataIndex: "user_tags",
      key: "user_tags",
      fixed: "left",
      width: 150,
      render: (title) => (title ? `${title}` : "-"),
    },
  ];
}

export const formatId = (data) => {
  let ids = [];
  data.map((item) => {
    ids.push(item.id);
  });
  return ids.filter((item) => item && item);
};
