import { getActivityLogPageSize } from "../../../api/settings";
import { handleApiError } from "../../../utils/commonUtils";
import {
  ACTIVITY_LOG_FETCH_FAILED,
  ACTIVITY_LOG_FETCH_SUCCESS,
  ACTIVITY_LOG_FETCH_STARTED,
} from "../../Redux.constants";

export function FetchActivityLog({ pageNumber, pageSize }) {
  return async (dispatch) => {
    dispatch({
      type: ACTIVITY_LOG_FETCH_STARTED,
      payload: {},
    });
    try {
      const { data } = await getActivityLogPageSize({
        pageNumber,
        pageSize,
      });
      dispatch({
        type: ACTIVITY_LOG_FETCH_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: ACTIVITY_LOG_FETCH_FAILED,
          payload: error,
        })
      );
    }
  };
}
