import moment from 'moment';

export const publishDateFormatter = (date) => {
  let newDate = new Date(date)?.toDateString();
  newDate = newDate[2].concat('-', `${newDate[1]}-${newDate[3]}`);
newDate = newDate?.split(' ');
  if (newDate.length >0) {
    newDate = moment(newDate).format('MM DD YYYY');
    newDate = moment(newDate).format('D MMMM YYYY');
    return newDate;
  }
  return '-';
};

/*to change format of the data to DD/MM/YYYY*/
export const formatDateFromTag = (date) => {
  let newDate = date.split('-').reverse().join('/');
  return newDate;
};
