import {
  HEAT_MAP_HORIZONTAL_AXIS_LABEL,
  HORIZONTAL_LABEL,
  VERTICAL_AXIS_LABEL,
} from "../../../../Constants/Insights/CreateInsights.constants";

export const switchCharts = (params) => {
  if (params.chartName == "KEY_PHRASE_CLOUD") {
    return getKeyPhraseCloudConfig(params);
  } else if (params.chartName == "BAR_CHART") {
    return getBarChartConfig(params);
  } else if (params.chartName == "HEAT_MAP") {
    return getHeatMapConfig(params);
  }
};

const getKeyPhraseCloudConfig = (params) => {
  let newObject = {
    maxCount: params.parameters["Maximum word count"].value,
    minValue:
      params.parameters["Minimum word occurrence"]?.value ||
      params.parameters["Minimum word occurance"].value,
    minWordLength: 2,
  };
  return newObject;
};

const getBarChartConfig = (params) => {
  let newObject = {
    cursor: true,
    grid: false,
    legend: true,
    seriesTooltip: true,
    xAxis: "key",
    yAxis: "value",
    panX: false,
    panY: false,
    fieldValue: params.parameters[HORIZONTAL_LABEL].value,
  };
  return newObject;
};

const getHeatMapConfig = (params) => {
  let newObject = {
    panX: false,
    panY: false,
    categoryField: "xAxis",
    categoryXField: params.parameters[HEAT_MAP_HORIZONTAL_AXIS_LABEL].value,
    categoryYField: params.parameters[VERTICAL_AXIS_LABEL].value,
  };
  return newObject;
};
