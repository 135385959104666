/* eslint-disable max-params */

import React from 'react';
import { DEFAULT_PAGE_COUNT } from '../../constants';
import {template} from './template';

class UserLogs extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: {columnKey: 'timestamp', field: 'timestamp', order: 'descend'},
    pagination: {pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT},
  };

  componentDidMount() {
    this.getActivityLog();
  }

  getActivityLog() {
    this.props.fetchActivityLog(this.state.pagination);
  }

  handleChange = (pagination, filter, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  handleUserLogPagination = (pageNumber, pageSize) => {
    this.setState({pagination: {pageNumber: pageNumber, pageSize: pageSize}}, () => this.getActivityLog());
  };

  render() {
    return template.call(this);
  }
}
export default UserLogs;
