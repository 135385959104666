import {
  GET_USER_PROFILES_STARTED,
  GET_USER_PROFILES_SUCCESS,
  GET_USER_PROFILES_FAILED,
  UPDATE_USER_PROFILES_STARTED,
  UPDATE_USER_PROFILES_SUCCESS,
  UPDATE_USER_PROFILES_FAILED,
} from "../../Redux.constants";
import { getUsersProfilesPageSize } from "../../../api/users";
import { handleApiError } from "../../../utils/commonUtils";

export function getAllUserDetails({
  pageNumber,
  pageSize,
  sortField,
  sortOrder,
}) {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_PROFILES_STARTED,
      payload: {},
    });
    try {
      const { data } = await getUsersProfilesPageSize({
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      });
      dispatch({
        type: GET_USER_PROFILES_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_USER_PROFILES_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function updateUserDetails(payload, apiHandler) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROFILES_STARTED,
      payload: payload,
    });
    try {
      const { data } = await getUsersProfilesPageSize({ pageNumber, pageSize });
      dispatch({
        type: UPDATE_USER_PROFILES_SUCCESS,
        payload: { data: data.result, apiHandler },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: UPDATE_USER_PROFILES_FAILED,
          payload: { error, apiHandler },
        })
      );
    }
  };
}
