import React from 'react';
import {Radio} from 'antd';
import PropTypes from 'prop-types';
import BetweenDates from './Children/BetweenDates';
import LastNdays from './Children/LastNdays';
import UntilNow from './Children/UntilNow';
import style from './style.module.scss';

function DateSection(props) {
  return (
    <div className={style.datesBlock}>
      <ul className={style.datesBlock__list}>
        <li>
          <div className={style.datesHeader}>
            <Radio
              className={style.radio}
              onChange={this.handleDateSelectType}
              checked={this.props.state.filterDateType == 1 ? true : false}
              value={1}>
              <span>Select a date range</span>
            </Radio>
          </div>
          {this.props.state.filterDateType == 1 && (
            <BetweenDates
              date={this.props.dateFormat}
              filterResults={this.props.filterResults}
              handleChangeDate={this.props.handleChangeDate}
            />
          )}
        </li>
        <li>
          <div className={style.datesHeader}>
            <Radio
              className={style.radio}
              onChange={this.handleDateSelectType}
              value={2}
              checked={this.props.state.filterDateType == 2 ? true : false}>
              <span>From a date until now</span>
            </Radio>
          </div>
          {this.props.state.filterDateType == 2 && (
            <div className={this.props.state.filterDateType == 2 ? `${style['dateChoose']}` : ''}>
              <UntilNow
                date={this.props.dateFormat}
                filterResults={this.props.filterResults}
                handleChangeDate={this.props.handleChangeDate}
              />
            </div>
          )}
        </li>
        <li>
          <div className={style.datesHeader}>
            <Radio
              className={style.radio}
              onChange={this.handleDateSelectType}
              value={3}
              checked={this.props.state.filterDateType == 3 ? true : false}>
              <span>From last {this.state.lastNdays} day(s)</span>
            </Radio>
          </div>
          {this.props.state.filterDateType == 3 && (
            <div className={this.props.state.filterDateType == 3 ? `${style['dateChoose']}` : ''}>
              {/* <LastNdays {...this} /> */}
              <LastNdays 
                handleLastNdays={this.handleLastNdays}
                lastNdays={this.state.lastNdays}
                handleLastNDaysChange={this.handleLastNDaysChange}
                errorMessage={this.state.errorMessage}
               />
            </div>
          )}
        </li>
      </ul>
    </div>
  );
}
DateSection.propTypes = {
  /**
   * filterDateType
   */
  filterDateType: PropTypes.any,
};
export default DateSection;
