import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import SVGIcons from '../../SVGIcons';

import style from '../style.module.scss';

function InsightNewTabHeader(props) {
  const [showConfigs, setShowConfigs] = useState(false);
  const toggleConfigurationsUI = () => {
    if (props.loading && !showConfigs) return;
    setShowConfigs(!showConfigs);
  };
  return (
    <div className={style.headerControls}>
      <div className={style.controlsSearch}>
        <ul className={style.controlsSearch__list}>
          <li>
            <Link
              to={{}}
              className={`${style.filtersearchButton} ${props.filterVisible.visible ? style['filterToggled'] : ''}`}
              onClick={props.handleFilterVisible}>
              <span>
                <SVGIcons type={'SVG-arrow'} />
              </span>
              <span>Filter</span>
            </Link>
          </li>
          <li>
            <Link disabled={props.loading} onClick={toggleConfigurationsUI}>
              <span>Chart Configurations</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default InsightNewTabHeader;
