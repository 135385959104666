import React from "react";

import CheckBox from "../../../CheckBox";
import CustomScrollbar from "../../../../Components/CustomScrollbar";
import { ContentLoader } from "../../../ContentLoader";

import style from "./style.module.scss";

function FilterContents() {
  const items = this.searchHandler(this.props.data, this.props.headingName);

  return (
    <div className={style.selectFilter}>
      <div className={style.selectFilter__list}>
        <>
          <CheckBox
            showSelectAll={true}
            handleClearAll={this.handleClearAll}
            handleSelectAll={this.handleSelectAll}
            loading={this.props.loading}
            value={
              this.props.filterResults[this.props.name]
                ? this.props.filterResults[this.props.name]
                : []
            }
            items={items}
            handleChange={(selectedValues) => {
              this.props.handleCheckbox({
                name: this.props.name,
                selectedValues,
                items,
              });
            }}
            placeholderSearch={selectPlaceholder(this.props.name)}
          />
        </>
      </div>
    </div>
  );
}

const selectPlaceholder = (item) => item.split("_").join(" ");

export default FilterContents;
