import React from "react";

import FilterSection from "../../Components/FilterSection";
import SearchTab from "./components/SearchTabElements";
import HeaderSection from "../../Components/HeaderSection";

import globel from "../../styles/globel/style.module.scss";
import ModalWindow from "../../Components/ModalWindow";
import { INSIGHT_OPENING_MESSAGE } from "./constants";
import {MyFilterSection} from "Components/MyFilter/MyFilterSection/MyFilterSection";

export function template() {
  return (
    <>
    
      <div
        className={`${globel.layoutBlock} ${
          this.props.mainFrameState.state.filterVisible.visible
            ? globel[this.props.mainFrameState.state.filterVisible.className]
            : ""
        } `}
      >
         <div className={globel.pageAside}>
          {/*<div style={{width: '50%', backgroundColor: 'blue'}}> */}
            
          {/* <MyFilterSection
              handleChangeCheckBox={this.handleChangeCheckBox}
              handleChangeDate={this.props.handleChangeDate}
              loading={this.props.FilterFields.loading}
              error={this.props.FilterFields.error}
              errorMessage={this.props.FilterFields.errorMessage}
              filter={this.props.search.filter}
              dateFilter={this.props.search.dateFilter}
              searchTab={this.state.tab}
          /> */}
          {/* </div>
          <div style={{width: '50%', backgroundColor: 'green'}}> */}
            
            <FilterSection
              {...this}
              loading={this.props.FilterFields.loading}
              error={this.props.FilterFields.error}
              errorMessage={this.props.FilterFields.errorMessage}
              filter={this.props.search.filter}
              dateFilter={this.props.search.dateFilter}
              searchTab={this.state.tab}
              handleApplyFilterButtonClick={this.handleApplyFilterButtonClick}
            />
          {/* </div>*/}
        </div> 
        <div className={globel.pageContent}>
          <div className={globel.resultWrapper}>
            <HeaderSection
              {...this.props}
              exportLoading={this.state.exportLoading}
              handleLoad={this.handleLoad}
              handleExport={this.handleExport}
              handleSave={this.handleSave}
              handleModal={this.handleModal}
              showLoadMenu={this.state.showLoadMenu}
              showSaveModal={this.state.showSaveModal}
              handleFilterVisible={
                this.props.mainFrameState.handleFilterVisible
              }
              filterVisible={this.props.mainFrameState.state.filterVisible}
              showViewInNewTab={this.state.showViewInNewTab}
              handleViewNewTab={this.handleViewNewTab}
              isInsightModalOpen={this.state.isInsightModalOpen}
              searchData={{
                data: this.state.data,
                loading: this.state.loading,
                totalCount: this.state.totalCount,
              }}
            />
            <SearchTab
              {...this.props}
              handleFilterVisible={
                this.props.mainFrameState.handleFilterVisible
              }
              listViewType={this.state.listViewType}
              handleListView={this.handleListView}
              handleViewListClassName={this.handleViewListClassName}
              setFilterOnSavedResult={this.setFilterOnSavedResult}
              filterResults={this.state.filterResults}
              handleDeleteTags={this.handleDeleteTags}
              handleDeleteMainTag={this.handleDeleteMainTag}
              handleDeleteDate={this.handleDeleteDate}
              handleDeleteAllTags={this.handleDeleteAllTags}
              onTabChange={this.onTabChange}
              tab={this.state.tab}
              handleExpandAll={this.handleExpandAll}
              expandAllCard={this.state.expandAllCard}
              handleExpandCardActive={this.handleExpandCardActive}
              breadCrumbData={this.state.breadCrumbData}
              handleBreadCrumbArticles={this.handleBreadCrumbArticles}
              handleClearBreadCrumbArticles={this.handleClearBreadCrumbArticles}
              searchData={{
                data: this.state.data,
                loading: this.state.loading,
                totalCount: this.state.totalCount,
              }}
              setSearchLocalData={(data) => this.setLocalState({ data })}
            />
          </div>
        </div>
        {this.state.isInsightModalOpen && (
          <ModalWindow
            open={true}
            title={"Opening Insights"}
            width={900}
            okText="Ok"
            cancelText="Cancel"
            onOk={this.handleInsightOpenModalOnOk}
            onCancel={this.handleInsightOpenModalOnClose}
            isNonMaskable={true}
            isNotClosable={true}
            // isHideCancelButton={true}
          >
            <h5>{INSIGHT_OPENING_MESSAGE} </h5>
          </ModalWindow>
        )}
      </div>
    </>
  );
}
