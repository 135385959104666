import { errorMEssageHandler } from "../../../../utils/ErrorHandler";

export function fetchActivityLogSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    data: payload.result,
    totalCount: payload.resultHits,
  };
}

export function fetchActivityLogStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function fetchActivityLogFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}
