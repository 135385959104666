import React from "react";
import ModalWindow from "../../Components/ModalWindow";
import { HEADER_CONSTANTS } from "../../Constants/Header/Header.constants";
import ConfirmationMessage from "../../Components/ConfirmationMessage";

const TimeOutModal = (props) => {
  return (
    <ModalWindow
      title={false}
      open={props.logoutModal}
      footer={false}
      isNotClosable={true}
    >
      <ConfirmationMessage
        cancelButtonLabel="Log off"
        confirmButtonLabel="Stay Active"
        heading={HEADER_CONSTANTS.LOGOUT_TIMEOUT_MODAL_HEADER}
        message={HEADER_CONSTANTS.LOGOUT_TIMEOUT_MODAL_BODY}
        onOk={props.handleModal}
        onCancel={props.handleIslogout}
      />
    </ModalWindow>
  );
};

export default TimeOutModal;
