import { APIInstance } from "./apiInstance";
import { BASE_URL } from "./constants";

/**
 *
 * @param {{emailId: string}} param
 */
export const getCurrentUserDetails = ({ emailId }) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;

  return api.post("/users/GetUserbyEmail", { emailid: emailId });
};

export const getUsersProfiles = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.get("/users");
};

export const getUsersProfilesPageSize = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/users/GetUserbyPage", payload);
};

export const changeUserProjects = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/account/updateUserProject", payload);
};

export const checkPassword = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/Account/checkpassword", payload);
};

export const resetPassword = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/Account/changepassword", { ...payload });
};


export const saveUserProjectPettings = payload=>{
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/UserProfile/save-user-project-settings",  payload );
}