import { DEFAULT_DATE_FILTER_VALUE, SEARCH_TYPE } from "../../../constants";

export const INITIAL_STATE = {
  config: {
    KEY_PHRASE_CLOUD: {
      maxCount: 300,
      minValue: 1,
      minWordLength: 1,
      searchWords: [],
    },
  },
  loading: false,
  error: false,
  chartName: "KEY_PHRASE_CLOUD",
  breadCrumbData: [],
  buckets: [],
  dateFilter: DEFAULT_DATE_FILTER_VALUE,
  filter: {},
  searchWords: [],
  totalCount: 0,
  payload: {
    "Maximum word count": {
      value: 300,
      index: "",
    },
    "Minimum word occurrence": {
      value: 1,
      index: "",
    },
    Datafield: {
      value: "Keywords",
      index: "",
    },
  },
  requestPayload: {
    wordCount: 300,
    minCount: 1,
    resType: 1,
    emailId: null,
  },
  advanceSearchPayloadData: {},
  searchValue: "",
  isFromArticles: false,
  searchType: SEARCH_TYPE.NORMAL_SEARCH,
  dataChanges: [],
  searchCriterias: [],
  heatMapClicked: false,
};
