import { getDashboardArticlesHelper } from "./functions/search";
import {
  deleteSavedHandler,
  saveResultHandler,
  savedSearchPaginateDispatchHelper,
  getSavedSearchesHelper,
} from "./functions/saveSearch";
import { addTagsResultsHandler } from "./functions/addTagsHandler";
import { SET_SEARCH_DATA, ADVANCE_SEARCH_STARTED, ADVANCE_SEARCH_SUCCESS, ADVANCE_SEARCH_FAILED, ADD_TAGS_MODAL_TOGGLE } from "../../Redux.constants";

export function addSaveSearchResult(payload, callBack) {
  return async (dispatch) => {
    saveResultHandler({ dispatch, payload, callBack });
  };
}
export const getSavedSearchList = (payload) => async (dispatch) =>
  savedSearchPaginateDispatchHelper({ payload, dispatch });

export function deleteSavedResults(payload, callBack) {
  return async (dispatch) => {
    deleteSavedHandler({ dispatch, payload, callBack });
  };
}

export function addTagsResults(payload) {
  return async (dispatch) => {
    addTagsResultsHandler({ dispatch, payload });
  };
}
export function getDashboardArticles(payload, responceHandler) {
  return async (dispatch) =>
    getDashboardArticlesHelper({ dispatch, payload, responceHandler });
}

export function setSearchData(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_DATA,
      payload,
    });
  };
}

export function advanceSearchStarted(payload) {
  return (dispatch) => {
    dispatch({
      type: ADVANCE_SEARCH_STARTED,
      payload,
    });
  };
}

export function advanceSearchSuccess(payload) {
  return (dispatch) => {
    dispatch({
      type: ADVANCE_SEARCH_SUCCESS,
      payload,
    });
  };
}

export function advanceSearchFailed(payload) {
  return (dispatch) => {
    dispatch({
      type: ADVANCE_SEARCH_FAILED,
      payload,
    });
  };
}

export function addTagModalToggle(payload) {
  return (dispatch) => {
    dispatch({
      type: ADD_TAGS_MODAL_TOGGLE,
      payload
    });
  };
}

export function getSavedSearchesAction(callback) {
  return (dispatch) =>
    getSavedSearchesHelper({ dispatch, callback });
}