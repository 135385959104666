import React from 'react';
import InputField from '../../../Components/InputField';

function SelectOptions(props) {
  return (
    <InputField
      showSearch={true}
      name={'Search logic'}
      title={' Select Logic'}
      optionList={props.optionList}
      value={props.value}
      onChange={props.onChange}
      inputType='selectElement'
      htmlForName={'Search logic'}
    />

  );
}

export default SelectOptions;
