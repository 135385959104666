import React from 'react';
import PropTypes from 'prop-types';

import SVGIcons from '../../../SVGIcons';
import TooltipContainer from '../../../Tooltip';
import style from './style.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ExportsResults = ({ disabled, handleExport, exportLoading }) => {
  return (
    <TooltipContainer title={'Export as XLSX'} placement='bottomLeft'>
      {exportLoading ? (
        <Spin indicator={antIcon} />
      ) : (
        <span onClick={(e) => (disabled ? false : handleExport(true))}>
          <SVGIcons type='SVG-export' />
        </span>
      )}
    </TooltipContainer>
  );
};

ExportsResults.defaultProps = {
  disabled: false,
};

ExportsResults.propTypes = {
  disabled: PropTypes.bool,
  handleExport: PropTypes.func.isRequired,
  exportLoading: PropTypes.bool,
};

export default ExportsResults;
