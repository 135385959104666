import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { errorMEssageHandler } from "../../../../utils/ErrorHandler";

export function importInsightsStarted(state, payload) {
  return {
    ...state,
    loading: true,
    importError: false,
  };
}

export function importInsightsSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    importError: false,
    importedInsightsData: payload.newData,
    importedInsightsChartNames: payload.chartNames,
    importedInsightsConfig: payload.config,
    importedinsightsName: payload.insightsName,
    selectedList: payload.selectedList,
  };
}

export function importInsightsFailed(state, payload) {
  return {
    ...state,
    loading: false,
    importError: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function getDashboardStarted(state, payload) {
  return {
    ...state,
    getLoading: true,
    error: false,
  };
}
export function getDashboardSuccess(state, payload) {
  return {
    ...state,
    getLoading: false,
    dashboardData: payload.data.result.result,
    totalCount: payload.data.result.resultHits,
    error: false,
  };
}
export function getDashboardError(state, payload) {
  // notify.error('Internal Server Error', true);
  return {
    ...state,
    getLoading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function deleteDashboard(state, payload) {
  return {
    ...state,
    dashboardData: state.dashboardData.filter((i) => i.id != payload),
  };
}
export function saveDashboardStart(state, payload) {
  return {
    ...state,
    error: false,
  };
}
export function saveDashboardSuccess(state, payload) {
  return {
    ...state,
    error: false,
  };
}

export function saveDashboardError(state, payload) {
  return {
    ...state,
    loading: false,
    error: payload,
  };
}

export function editDashboardStart(state, payload) {
  return {
    ...state,
    error: false,
  };
}

export function editDashboardSuccess(state, payload) {
  return {
    ...state,
    loading: false,
  };
}

export function editDashboardError(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

export function viewDashboardStart(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}
export function viewDashboardError(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

export function clearDashboard(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    importedInsightsData: [],
    importedInsightsChartNames: [],
    importedInsightsConfig: [],
    importedinsightsName: [],
    selectedList: [],
  };
}

export function setDashboardFilter(state, payload) {
  if (payload.clear)
    return {
      ...state,
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
      filter: {},
    };
  return {
    ...state,
    filter: payload.filter ? payload.filter : state.filter,
    dateFilter: payload.dateFilter ? payload.dateFilter : state.dateFilter,
  };
}
