import React from "react";

import { Dropdown } from "../../../Dropdown";
import ModalWindow from "../../../ModalWindow";
import SavedSearches from "../../../SavedSearches";

function LoadDropdown(props) {
  return (
    <Dropdown addOnClass={props.state.showLoadMenu} customButton={<></>}>
      {props.state.showLoadMenu ? (
        <ModalWindow
          open={props.state.showLoadMenu}
          // title={'Saved Searches'}
          width={900}
          okText="Close"
          onOk={(e) => props.handleLoad(false)}
          isNonMaskable={true}
          isNotClosable={true}
          isHideCancelButton={true}
        >
          <SavedSearches {...props} search={props.props.search} />
        </ModalWindow>
      ) : (
        <></>
      )}
    </Dropdown>
  );
}

export default LoadDropdown;
