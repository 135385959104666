import {
  DATE_FIELDS_DATA_TYPE,
  SUMMARY_FIELDS_DATA_TYPE,
  TITLE_FIELDS_DATA_TYPE,
  WEBSITE_DOMAIN_DATA_TYPE,
} from "Components/FilterSection/FilterSection.constants";
import { sortDataInAccending } from "utils/commonUtils";
import { formatFilterLabel, formatTitleCase } from "utils/formatFilterLabel";
import {
  STREAM_CHART_HORIZONTAL_FIELD_LABEL,
  STREAM_CHART_PRIMARY_DATA_SERIES_LABEL,
} from "Constants/Insights/CreateInsights.constants";

export const allFieldsFormatter = (data) => {
  let formatArray = [];
  Object.keys(data).map((item) => {
    formatArray.push(item);
  });
  return formatArray;
};

/**
 *
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFields
 * @returns string[]
 */
const allFieldsFormatterNew = (allFields) => {
  let formatArray = allFields.filter(
    (item) =>
      ![SUMMARY_FIELDS_DATA_TYPE, TITLE_FIELDS_DATA_TYPE].includes(
        item.dataType
      )
  );
  return formatArray;
};

export const reFormatFilterLabel = (item) => {
  if (isNaN(item)) {
    let itemLabel = item.split(" ").join("_");
    let newItem = itemLabel.charAt(0).toLowerCase() + itemLabel.slice(1);
    return newItem;
  } else {
    return "";
  }
};

export const formatAvailableFieldsEdit = (selectedFields, allFields) => {
  let availableFields = allFields;
  Object.keys(selectedFields).map((item) => {
    let formattedString = reFormatFilterLabel(selectedFields[item].value);
    Object.keys(allFields).map((availableItem) => {
      if (availableItem == formattedString) {
        delete availableFields[availableItem];
      }
    });
  });
  return allFieldsFormatterNew(availableFields);
};

export const formatFilterDataSet = (data) => {
  let newArray = [];
  sortDataInAccending(data, "searchKeyword").map((item) => {
    let newObject = {
      value: item.id,
      label: formatTitleCase(item.searchKeyword),
    };
    newArray.push(newObject);
  });
  return newArray;
};

export const formatDraggableId = (value) => {
  let string = value.split("/");
  return string[0];
};

/**
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFieldsObject
 * @param {string} chartName
 * @param {string} fieldName
 * @param {{[key: string]: {value: string}}} selectedFields
 * @returns {{value: string, label: string}[]}
 */
export const formatAvailableFieldsDropdown = (
  allFieldsObject,
  chartName,
  fieldName,
  selectedFields
) => {
  // Filtering data fields based on dataType conditions (Client requirement)
  let filteredFields = allFieldsObject.filter((field) => {
    if (!field?.name) return false;
    if (
      [SUMMARY_FIELDS_DATA_TYPE, TITLE_FIELDS_DATA_TYPE].includes(
        field.dataType
      ) ||
      ["File Name", "Title 2"].includes(field.name)
    ) {
      return false;
    } else if (chartName === "STREAM_CHART") {
      if (fieldName === STREAM_CHART_HORIZONTAL_FIELD_LABEL) {
        return field.dataType === DATE_FIELDS_DATA_TYPE;
      } else if (fieldName === STREAM_CHART_PRIMARY_DATA_SERIES_LABEL) {
        return ![DATE_FIELDS_DATA_TYPE, WEBSITE_DOMAIN_DATA_TYPE].includes(
          field.dataType
        );
      } else {
        return true;
      }
    } else if (chartName === "BAR_CHART") {
      return field.dataType !== DATE_FIELDS_DATA_TYPE;
    } else return true;
  });

  // Removing already selected fields from current select options list
  const otherSelectedFields = Object.keys(selectedFields).filter(
    (fName) => fName !== fieldName
  );
  const alreadySelectedValues = otherSelectedFields.map(
    (fName) => selectedFields[fName].value
  );
  filteredFields = filteredFields.filter(
    (field) => !alreadySelectedValues.includes(field.name)
  );
  const formattedFields = filteredFields.map((item) => ({
    value: item.name,
    label: formatFilterLabel(item.name),
  }));

  return formattedFields;
};

export const filterSearchField = (fields, searchValue) => {
  let firstArray = [];
  let secondArray = [];
  sortDataInAccending(
    fields.filter((item) =>
      item.toUpperCase().includes(searchValue.toUpperCase())
    )
  ).map((item) => {
    if (
      item.substring(0, searchValue.length).toUpperCase() ===
      searchValue.toUpperCase()
    )
      firstArray.push(item);
    else secondArray.push(item);
  });
  return firstArray.concat(secondArray);
};
