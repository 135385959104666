export const SELECT_OPTION_LIST = [
  {value: 'All', label: 'Get all these matched'},
  {value: 'Any', label: 'Get any of these matched'},
  {value: 'Exact', label: 'Get this exactly matched'},
  {value: 'None', label: 'Get none of these matched'},
];
export const SELECT_DATE_PUBLISHED_TYPE = [
  {value: 'lastNdays', label: 'Within last N Days'},
  {value: 'Range', label: 'Select a date range'},
  {value: 'UntilNow', label: 'From a date until now'},
];

export const ADVANCED_SEARCH_DESCRIPTION =
  'Applying advanced search will reset the basic search and filters that are currently active in order to avoid conflicting filter logics';
