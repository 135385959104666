import React from 'react';

import {DatePicker} from 'antd';
import moment from 'moment';
import SelectValue from './SelectValueFacet';
import DatePickerElement from '../../../Components/DatePicker';
import style from './style.module.scss';

const {RangePicker} = DatePicker;

const dateFormat = 'YYYY/MM/DD';

export default function DateRangeBlock(props) {
  const disabledDateTodayAfter = (current) => {
    return current && moment(current).startOf('day') > moment().endOf('day');
  };
  return (
    <>
      {props.checkValue.data == 'lastNdays' && <LastDaysBlock {...props} />}
      {props.checkValue.data == 'UntilNow' && <UntilNow {...props} disabledDateTodayAfter={disabledDateTodayAfter} />}
      {props.checkValue.data == 'Range' && <RangeBlock {...props} disabledDateTodayAfter={disabledDateTodayAfter} />}
    </>
  );
}

function LastDaysBlock(props) {
  return (
    <>
      <SelectValue
        onChange={(e) =>
          props.handleSelectOption({
            result:
              e.value == ''
                ? ''
                : parseInt(e.target.value) > 1827
                ? '1827'
                : parseInt(e.target.value) < 1
                ? '1'
                : e.target.value,
            filter: props.filter,
            group: props.value,
            facet: 'facet',
            value: props.valueName,
          })
        }
        date={true}
        title={'Enter the days'}
        placeholder={'Days'}
        value={lastNDaysValue(props)}
        min={1}
        max={1827}
      />
    </>
  );
}

function UntilNow(props) {
  return (
    <div className={style.advanceDateblock}>
      <label>Select Date</label>
      <DatePicker
        value={
          props.group[props.value][props.filter]['facet'][props.valueName]?.option
            ? typeof props.group[props.value][props.filter]['facet'][props.valueName]?.option == 'object'
              ? moment(props.group[props.value][props.filter]['facet'][props.valueName].option[0])
              : moment(props.group[props.value][props.filter]['facet'][props.valueName].option)
            : null
        }
        onChange={(e, dateString) => {
          props.handleSelectOption({
            result: dateString,
            filter: props.filter,
            group: props.value,
            facet: 'facet',
            value: props.valueName,
          });
        }}
        format={dateFormat}
        disabledDate={props.disabledDateTodayAfter}
      />
    </div>
  );
}

function RangeBlock(props) {
  return (
    <div className={style.advanceDateblock}>
      <label>Select Date</label>
      <RangePicker
        onChange={(e, array) =>
          props.handleSelectOption({
            result: handleChangeBetweenRange(array),
            filter: props.filter,
            group: props.value,
            facet: 'facet',
            value: props.valueName,
          })
        }
        value={betweenRangeValue(props)}
        //defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
        format={dateFormat}
        disabledDate={props.disabledDateTodayAfter}
      />
    </div>
  );
}

const betweenRangeValue = (props) => {
  let value = props.group[props.value][props.filter]['facet'][props.valueName].option
    ? typeof props.group[props.value][props.filter]['facet'][props.valueName]?.option == 'object'
      ? [
          moment(props.group[props.value][props.filter]['facet'][props.valueName]?.option[0]),
          moment(props.group[props.value][props.filter]['facet'][props.valueName]?.option[1]),
        ]
      : []
    : [];
  return value;
};

const handleChangeBetweenRange = (value) => {
  let array = [];
  if (value.length > 0) {
    if (value[0] == '' && value[1] == '') {
      return null;
    } else {
      return value;
    }
  } else {
    return array;
  }
};

const lastNDaysValue = (props) => {
  let isValid =
    props.group[props.value][props.filter]['facet'][props.valueName]?.option &&
    typeof props.group[props.value][props.filter]['facet'][props.valueName]?.option != 'object' &&
    props.group[props.value][props.filter]['facet'][props.valueName]?.option.includes('/');
  let value =
    typeof props.group[props.value][props.filter]['facet'][props.valueName]?.option == 'object'
      ? null
      : isValid
      ? null
      : props.group[props.value][props.filter]['facet'][props.valueName]?.option;
  return value;
};
