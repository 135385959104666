import React from "react";
import { switchChartsInsights } from "../../../utils/switchChartsInsights";

function InsightsPreview(props) {
  return (
    <>
      {switchChartsInsights({
        config: props.config[0],
        chartName: props.chartNames[0],
        data: props.data[0],
      })}
    </>
  );
}

export default InsightsPreview;
