export const DASHBOARD_CONSTANTS = {
  HEADING: 'Saved Dashboards',
  BUTTON_LABEL: 'Add Dashboard',
  NO_TEXT_CONSTANT: 'No',
  YES_TEXT_CONSTANT: 'Yes',
  CANCEL_TEXT_CONSTANT: 'Cancel',
  ADD_MODAL_CONSTANT_TEXT:
    'Select the insights which are the key points of interest (KPIs)' +
    "for the dashboard to build and click on <b>' import '</b> button to proceed ",
  ITEMS: ['Insight 1', 'Insight 2', 'Insight 3', 'Insight 4', 'Insight 5', 'Insight 6'],
  SEARCH_PLACEHOLDER: 'Select Insights to import',
  COLLAPSE_CARD_HEADING: 'Select Insights to import',
  IMPORT_TEXT: 'IMPORT INSIGHTS',
  LOAD_DASHBOARD_TEXT: 'LOAD DASHBOARD',
  SAVE_MODAL_HEADING: 'Save Dashboard',
  SAVE_MODAL_SUB_HEADING: 'Save the dashboard as',
  SAVE_MODAL_INPUT_PLACEHOLDER: 'Save your dashboard with a name',
  SAVE_MODAL_EMPTY_ERROR: 'Please enter the name for saving the results',
  EMPTY_DASHBOARD_NAME_ERROR: 'Please save dashboard with name',
  EMPTY_INSIGHT_SELECTED_ERROR: ' Nothing selelcted. Please select at least one!',
  DASHBOARD_SAVED_SUCCESSFULLY_MESSAGE: 'Dashboard saved successfully',
  DASHBOARD_UPDATED_SUCCESSFULLY_MESSAGE: 'Dashboard updated successfully',
  DASHBOARD_MAXIMUM_ALLOWED_ERROR: 'Maximum allowed is 3',
  DASHBOARD_MAXIMUM_IMPORT_ERROR_ON_SAVE: 'Maximum 3 insight can be shown at a time',
  DASHBOARD_EMPTY_ERROR_MESSAGE: 'Dashboard is empty, Please select any insight!',
};

export const INFO_TABLE_REQUIRED_KEYS = ['date_published', 'user_tags'];
