import { errorMEssageHandler } from '../../../../utils/ErrorHandler';

export function addUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,

  };
}

export function addUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    modified: true,
  };
}

export function addUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function getUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,

  };
}

export function getUserGroupSuccess(state, payload) {
  const isAll = payload.pageSize === undefined;
  return {
    ...state,
    loading: false,
    error: false,
    userGroupStore: isAll ? payload.dataGroup: state.userGroupStore,
    userGroups: isAll ? state.userGroups : payload.dataGroup,
    totalCount: payload.totalCount,
    modified: isAll ? false : state.modified,
    savedSearches:   payload.dataSearch??state.savedSearches,
  };
}

export function getUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function updateUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,

  };
}

export function updateUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    modified: true,
  };
}

export function updateUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function deleteUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,

  };
}

export function deleteUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    modified: true,
  };
}

export function deleteUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}