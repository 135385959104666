/* eslint-disable max-params */
import React from "react";
import { notify } from "../../../../Components/Notification";
import { KEYS } from "../../../../dataKeys";
import template from "./template";

class SearchContent extends React.Component {
  state = {
    showDeleteModal: false,
    sortedInfo: { columnKey: "", field: "timestamp", order: "descend" },
    selectedTitle: "",
    loading: false,
    selectedData: {},
    exportLoad: false,
    pagination: { pageNumber: 1, pageSize: 5 },
    showLoadMenu: false,
  };

  /**
   * Method called when pagination changes
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  handleSearchPagination = (pageNumber, pageSize) => {
    this.props.setSearchData({ pageNumber, pageSize });
    this.scrollToTop();
  };
  scrollToTop = () => {
    this.scrollElementWrapper?.scrollTo(0, 0);
  };

  setScrollableElement = (element) => {
    if (element && element !== this.scrollElementWrapper) {
      this.scrollElementWrapper = element?.parentElement?.parentElement;
    }
  };

  handleChange = (pagination, filter, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  handleDelete = (record) => {
    this.setState({
      selectedTitle: record.searchKeyword,
      showDeleteModal: true,
      selectedData: record,
    });
  };

  deleteModalCloseHandler = () => {
    this.setState({
      selectedTitle: "",
      showDeleteModal: false,
      selectedData: {},
      loading: false,
    });
  };

  deleteModalHandlerYes = () => {
    this.setState({
      loading: true,
    });
    this.props.handleDeleteSaved(this.state.selectedData, () => {
      this.deleteModalCloseHandler();
      notify.success(this.state.selectedTitle + " Deleted Successfully", true);
      if (this.props.search.saved.length == 0) {
        let pagination = { pageNumber: 1, pageSize: 50 };
        this.props.handleSavedSearchPaginate({
          ...pagination,
          emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
          isSavedResult: true,
          avoidDetails: true,
        });
      } else {
        this.getSavedSearchList();
      }
    });
  };

  getSavedSearchList() {
    this.props.handleSavedSearchPaginate({
      ...this.state.pagination,
      emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
      isSavedResult: true,
      avoidDetails: true,
    });
  }

  handleSavedSearchPagination = (pageNumber, pageSize) =>
    this.setState(
      { pagination: { pageNumber: pageNumber, pageSize: pageSize } },
      () => this.getSavedSearchList()
    );

  render() {
    return template.call(this);
  }
}
export default SearchContent;
