import {
  getMappingPayloadSuccess,
  getMappingPayloadStarted,
  getMappingPayloadFailed,
  getInsightError,
  getInsightSuccess,
  getInsightStarted,
  deleteInsights,
  getInsightDataSetFailed,
  getInsightDataSetSuccess,
  getInsightDataSetStart,
  getAllInsightsStarted,
  getAllInsightsSuccess,
  getAllInsightsFailed,
} from './Helper';
import {INITIAL_STATE} from './initialState';
import {
  DELETE_INSIGHTS,
  GET_INSIGHTS_ERROR,
  GET_INSIGHTS_START,
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHT_DATASET_FAILED,
  GET_INSIGHT_DATASET_STARTED,
  GET_INSIGHT_DATASET_SUCCESS,
  INSIGHTS_GET_MAPPING_PAYLOAD_FAILED,
  INSIGHTS_GET_MAPPING_PAYLOAD_STARTED,
  INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS,
  GET_ALL_INSIGHTS_STARTED,
  GET_ALL_INSIGHTS_FAILED,
  GET_ALL_INSIGHTS_SUCCESS,
} from '../../Redux.constants';

export default function InsightsPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS:
      return getMappingPayloadSuccess(state, action.payload);
    case INSIGHTS_GET_MAPPING_PAYLOAD_STARTED:
      return getMappingPayloadStarted(state, action.payload);
    case INSIGHTS_GET_MAPPING_PAYLOAD_FAILED:
      return getMappingPayloadFailed(state, action.payload);
    case GET_INSIGHTS_START:
      return getInsightStarted(state, action.payload);
    case GET_INSIGHTS_SUCCESS:
      return getInsightSuccess(state, action.payload);
    case GET_INSIGHTS_ERROR:
      return getInsightError(state, action.payload);
    case DELETE_INSIGHTS:
      return deleteInsights(state, action.payload);
    case GET_INSIGHT_DATASET_STARTED:
      return getInsightDataSetStart(state, action.payload);
    case GET_INSIGHT_DATASET_SUCCESS:
      return getInsightDataSetSuccess(state, action.payload);
    case GET_INSIGHT_DATASET_FAILED:
      return getInsightDataSetFailed(state, action.payload);
    default:
      return {...state};
  }
}

export function AllInsightsList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_INSIGHTS_STARTED:
      return getAllInsightsStarted(state, action.payload);
    case GET_ALL_INSIGHTS_SUCCESS:
      return getAllInsightsSuccess(state, action.payload);
    case GET_ALL_INSIGHTS_FAILED:
      return getAllInsightsFailed(state, action.payload);
    default:
      return {...state};
  }
}
