import globel from "styles/globel/style.module.scss";

export const MyTotalCount = (props) =>{
    const {totalCount, data} = props;
    let currentCount = data?.length;

    return (
        <div className={globel.searchresultCount}>
            <span>
                {totalCount === 0
                ? "Empty "
                : "Showing " + currentCount + " out of " + totalCount + " "}{" "}
                Results
            </span>
        </div>
    );
};