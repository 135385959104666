import { CLEAR_INSIGHTS_DATA, SET_INSIGHT_DATA } from "../../Redux.constants";

export function setInsightData(payload) {
  return async (dispatch) => {
    dispatch({
      type: SET_INSIGHT_DATA,
      payload,
    });
  };
}
export function clearInsightData(payload) {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_INSIGHTS_DATA,
      payload: payload ?? "",
    });
  };
}
