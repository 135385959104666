import React from "react";

import InputField from "../../../../../Components/InputField";
import Loader from "../../../../../Components/Loader";
import ModalWindow from "../../../../../Components/ModalWindow";
import { SelectElement } from "../../../../../Components/SelectElement";
import { ROLE_SETTINGS_CONSTANTS } from "../RoleSettings.constants";

import style from "./style.module.scss";

export function ShowUserModalWindow(props) {
  return (
    <ModalWindow
      title={props.title}
      isNotClosable={true}
      okText={props.buttonTitle}
      cancelText={"Cancel"}
      onCancel={(e) => {
        props.onCancel();
      }}
      confirmLoading={props.loader}
      onOk={(e) => {
        props.onSubmit();
      }}
      open={props.visible}
    >
      <ul>
        <li>
          Unique Identifier:
          <InputField
            placeholder={"ADFS mail"}
            onChange={(e) => {
              props.onChange("emailid", e.target.value.trim());
            }}
            value={props.userDetails.emailid}
          />
        </li>
        <li>
          User Role:
          <InputField
            inputType={"selectElement"}
            optionList={[
              { label: "analyst", value: "analyst" },
              { label: "admin", value: "admin" },
            ]}
            defaultValue={"analyst"}
            value={props.userDetails.userRole}
            onChange={(e) => {
              props.onChange("userRole", e);
            }}
          />
        </li>
        <li>
          First name:
          <InputField
            placeholder={"Enter the first name"}
            onChange={(e) => {
              props.onChange("firstName", e.target.value.trim());
            }}
            value={props.userDetails.firstName}
          />
        </li>
        <li>
          Last name:
          <InputField
            placeholder={"Enter the last name"}
            onChange={(e) => {
              props.onChange("lastName", e.target.value.trim());
            }}
            value={props.userDetails.lastName}
          />
        </li>
      </ul>
    </ModalWindow>
  );
}
