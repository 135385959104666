import {SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILED, SEND_EMAIL_STARTED} from '../../Redux.constants';
import {SendEmail} from '../../../api/resetPassword';
import {errorHandler} from '../../../utils/ErrorHandler';

export function sendEmail(payload, validateSubmitMail) {
  return async (dispatch) => {
    dispatch({
      type: SEND_EMAIL_STARTED,
      payload: {},
    });
    try {
      const data = await SendEmail(payload);
      validateSubmitMail(data);
      dispatch({
        type: SEND_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleApiError(error, () => {
        if (error && error.response) {
          validateSubmitMail('Fail');
        } else {
          errorHandler(error);
        }
        dispatch({
          type: SEND_EMAIL_FAILED,
          payload: error,
        });
      });
    }
  };
}
