import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import { seriesInitialization } from "./Helper/seriesInitialization";
import { legendHover } from "./Helper/legendHover";
import { xAxisSetting } from "./Helper/xAxisSetting";
import { renderSettingX, renderSettingY } from "./Helper/renderSetting";
import { CHART_SECTION } from "./LineCharts.constants";
import style from "./style.module.scss";
import globel from "../../styles/globel/style.module.scss";

const LINE_CHART = "LINE_CHART";

export class LineChart extends Component {
  animationDuration = 4000;
  showingDelay = 200;

  componentDidMount = () => {
    this.initConfig();
    this.initChart();
    this.setChartSetups();
    this.setChartData();
    this.chart.appear(this.animationDuration, this.showingDelay);
  };

  componentWillUnmount = () => {
    this?.root?.dispose?.();
  };

  componentDidUpdate(prevProps) {
    this?.chart?.dispose?.();
    if (prevProps != this.props) {
      this.initConfig();
      this.setChartSetups();
      this.setChartData();
      this.chart.appear(this.animationDuration, this.showingDelay);
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return (
      nextProps.config !== this.props.config ||
      nextProps.sortedData !== this.props.sortedData
    );
  };

  initConfig = () => {
    this.config = this.props.config;
    this.lineChart = this.config.lineChart;
  };

  initChart = () => {
    const root = am5.Root.new(this.props.id);
    this.root = root;
    root.setThemes([AnimatedTheme.new(root)]);
    var myTheme = am5.Theme.new(root);
    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 1,
    });
    root.setThemes([myTheme]);
  };

  setChartData = () => {
    if (this.props.sortedData.length != 0) {
      this.xAxis.data.setAll(this.props.sortedData);
      const seriesLine = seriesInitialization({
        root: this.root,
        chart: this.chart,
        yAxis: this.yAxis,
        xAxis: this.xAxis,
        xAxisLabel: this.lineChart.xAxis,
        yAxisLabel: this.lineChart.yAxis,
      });
      seriesLine.data.setAll(this.props.sortedData);
    }
    this.callFunction();
  };

  callFunction = () => {
    this.setScroll();
    if (this.lineChart.legend) {
      const legend = this.chart.children.push(
        am5.Legend.new(this.root, {
          y: am5.percent(0),
          centerY: am5.percent(0),
          x: am5.percent(85),
        })
      );
      legend.data.setAll(this.chart.series.values);
    }

    if (this.lineChart.cursor) {
      this.chart.set(CHART_SECTION.CURSOR, am5xy.XYCursor.new(this.root, {}));
    }
  };

  setBullets = (seriesLine) => {
    seriesLine.bullets.push(() => {
      const cirle = am5.Circle.new(this.root, {
        radius: 3,
        fill: am5.color(CHART_SECTION.COLOR),
      });
      return am5.Bullet.new(this.root, {
        sprite: cirle,
      });
    });
  };

  setScroll = () => {
    if (this.lineChart.scrollX)
      this.chart.set(
        CHART_SECTION.SCROLLX,
        am5.Scrollbar.new(this.root, {
          orientation: CHART_SECTION.HORIZONTAL,
        })
      );
    if (this.lineChart.scrollY)
      this.chart.set(
        CHART_SECTION.SCROLLY,
        am5.Scrollbar.new(this.root, {
          orientation: CHART_SECTION.VERTICAL,
        })
      );
  };

  setChartSetups = () => {
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: this.lineChart.panX,
        panY: this.lineChart.panY,
        wheelX: CHART_SECTION.WHEELX,
        wheelY: CHART_SECTION.WHEELY,
        layout: this.root.verticalLayout,
      })
    );
    var xRenderer = renderSettingX(this.root);
    var yRenderer = renderSettingY(this.root);
    const xAxis = xAxisSetting({
      chart,
      root: this.root,
      xRenderer,
      xAxis: this.lineChart.xAxis,
    });
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, this.yAxisInfo(yRenderer))
    );
    this.chart = chart;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
  };

  yAxisInfo = (yRenderer) => {
    return {
      maxDeviation: 0,
      strokeOpacity: 1,
      strokeWidth: 2,
      max: this.props?.config?.lineChart?.max,
      min: 0,
      renderer: yRenderer,
    };
  };

  render() {
    return (
      <div
        className={`${
          this.props.dashboard
            ? style["chart-root-container"]
            : globel.chartItem
        } ${this.props.isDownload && globel.previewCharts}`}
        id={this.props.id}
      />
    );
  }
}

LineChart.defaultProps = {
  id: LINE_CHART,
};
