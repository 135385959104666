export const ROLE_SETTINGS_CONSTANTS = {
  NO_TEXT_CONSTANT: 'No',
  YES_TEXT_CONSTANT: 'Yes',
  CANCEL_TEXT_CONSTANT: 'Cancel',
  UPDATE_TEXT: 'Update',
  CLOSE_TEXT: 'Close',
  ADD_MODAL_CONSTANT_TEXT:
    'Select the insights which are the key points of interest (KPIs)' +
    'for the dashboard to build and click on Save button to proceed ',
  OPTION_LIST: [
    {value: 1, label: 'Insights admin'},
    {value: 2, label: 'Insights analyst'},
    // {value: 4, label: 'Insights user'},
  ],
  SELECT_PLACEHOLDER: 'Choose the role',
  COLLAPSE_CARD_HEADING: 'Select Insights to import',
  SELECT_ROLE: 'Current role:',
  USER_LIST: {
    3311561961: false,

    5856470605: false,

    6375385032: false,

    7470641890: false,

    7033037104: false,

    1035557487: false,

    5643875489: false,
  },
};
