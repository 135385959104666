import React from 'react';
import PropTypes from 'prop-types';

import SVGIcons from '../../../SVGIcons';
import TooltipContainer from '../../../Tooltip';
import style from './style.module.scss';

const SaveResults = ({ disabled, handleSave, title, isEdit }) => {
  return (
    <TooltipContainer title={title ? title : 'Save Search'} placement='bottom'>
      <span className={style.resultSavebutton} onClick={disabled ? false : handleSave}>
        <SVGIcons type={isEdit ? 'SVG-edit' : 'SVG-save'} />
      </span>
    </TooltipContainer>
  );
};

SaveResults.defaultProps = {
  disabled: false,
};

SaveResults.propTypes = {
  disabled: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default SaveResults;
