import { connect } from "react-redux";
import {
  DashboardAction,
  FilterFields,
  deleteDashboardData,
  getDashboardData,
  getInsightData,
  setDashboardFilterValues,
  setInsightList,
} from "../../Redux/Actions/";
import Dashboard from "./Dashboard";

function mapStateToProps(state) {
  return {
    insightsList: state.InsightsPage,
    importedInsights: state.Dashboard,
    projectListInfo: state.FetchProjectList,
    FilterFields: state.FilterFields,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInsightData: (params) => {
      dispatch(getInsightData(params));
    },
    importInsights: ({ payload, handleImportSuccess, clear }) => {
      dispatch(DashboardAction({ payload, handleImportSuccess, clear }));
    },
    getDashboardData: (params) => {
      dispatch(getDashboardData(params));
    },
    handleDeleteDashboardData: (params, callback) => {
      dispatch(deleteDashboardData(params, callback));
    },
    setFilterValuesHandler: (params) =>
      dispatch(setDashboardFilterValues(params)),
    setInsightList: (params) => dispatch(setInsightList(params)),
    loadFilterFields: () => dispatch(FilterFields()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
