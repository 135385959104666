import { APIInstance } from "./apiInstance";
import { BASE_URL } from "./constants";

export function getMappingPayload() {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;

  return api.get("/getMappingForInsights");
}

export const InsightsRequest = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;

  return api.post("/InsightsRequest", { ...payload });
};

export const InsightsRequestDataCount = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/insightsRequestDataCount", { ...payload });
};

export const saveInsights = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/AddInsights", { ...payload });
};

/**
 * API to Get insight list as paginated
 * @param {{ emailid: string | null, pageNumber: number, pageSize: number }} payload
 * @returns {Promise<{ data: { result: [], resultHits: number }}>}
 */
export const getInsightsList = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetInsightsByEmail", payload);
};

export const deleteInsight = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/deleteInsights", payload);
};

export const updateSaveInsights = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/updateInsights", { ...payload });
};

export const getInsightDataSet = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetInsightDataSet", { ...payload });
};

export const getFieldsByDataSet = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/InsightsRequestFromSavedResults", { ...payload });
};

export const insightTreeMapRequest = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/InsightsRequestForTreeMap", payload);
};

export const getAllInsights = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetInsights", payload);
};

export const getInsightsById = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetInsightsById", payload);
};

export const getFullInsightsDetails = () => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.get("/GetAllInsights");
};

export const getFullInsightsDetailsUser = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetAllInsightsByUser", payload);
};

export const getKeyphraseCloudData = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });
  const api = instance.api;
  return api.post("/Getkeyphrasecloud", payload);
};

/**
 * @param {{ searchWords: string[], searchFields: string[], pageNumber: number, pageSize: number, emailId: string,
 * filtersforData?: [{[key: string]: string[]}], dateFilters?: {key: string, value: {from: string, to: string}}[]
 * buckets?: [{field: string}] }} payload
 * @returns
 */
export function getInsightReferenceData(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetInsightRefnc", payload);
}

export function getHeatmapData(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetHeatMapData", payload);
}

export function addExcludeListItem(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/AddtoExcludeList", payload);
}

export function deleteExcludeListItem(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/deleteExcludeItem", payload);
}

export function getExcludeListItem(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.INSIGHT,
  });

  const api = instance.api;
  return api.post("/GetExcludeItemByUserAsync", payload);
}
