import { notify } from "../../../Components/Notification";

const HOME_INITIAL_STATE = {
    forceRunSearchFromRedux: false,
    selectedDocuments: [], //store the document Id of all documents currently selected
    selectedTags: [], //store the tags currently selected
};


const HOME_ACTION_TYPES = {
    HOME_REFRESH_SEARCH: 'HOME_REFRESH_SEARCH',
    HOME_REFRESH_SEARCH_COMPLETE: 'HOME_REFRESH_SEARCH_COMPLETE',
    HOME_DOCUMENT_SELECTION_CHANGE: 'HOME_DOCUMENT_SELECTION_CHANGE',
    HOME_TAG_SELECTION_CHANGE: 'HOME_TAG_SELECTION_CHANGE',
};

export const HomeReducer = (state = HOME_INITIAL_STATE, action) => {
    //debugger;
    switch (action.type) {
        case HOME_ACTION_TYPES.HOME_REFRESH_SEARCH:
            return { ...state, forceRunSearchFromRedux: true };
        case HOME_ACTION_TYPES.HOME_REFRESH_SEARCH_COMPLETE:
            return { ...state, forceRunSearchFromRedux: false };

        case HOME_ACTION_TYPES.HOME_DOCUMENT_SELECTION_CHANGE:
            if (action.payload.toClear) {
                return { ...state, selectedDocuments: [], userTags: [], selectedTags: [] };
            }

            const ids = action.payload.documentIds ?? [];
            if (ids && ids.length > 1) {
                let list = state.selectedDocuments ?? [];
                ids.forEach(d => {
                    const index = list.indexOf(d);
                    if (index > -1) {
                        list.splice(index, 1);
                    } else {
                        list.push(d);
                    }
                });
                notify.success(`${list.length || "No"} article(s) selected`, true);
                return { ...state, selectedDocuments: list }
            } else {
                const id = action.payload.documentIds[0];
                const checked = action.payload.checked;
                const userTags = action.payload.userTags;
                let list = state.selectedDocuments;
                const idx = list.indexOf(id);
                if (!checked && idx !== -1)
                    list.splice(idx, 1);
                else if (checked && idx === -1)
                    list.push(id);
                notify.success(`${list.length || "No"} article(s) selected`, true);
                if (checked && list[0] === id)
                    return { ...state, selectedDocuments: list, userTags }
                else
                    return { ...state, selectedDocuments: list }
            }

        case HOME_ACTION_TYPES.HOME_TAG_SELECTION_CHANGE:
            const tag = action.payload.tag;
            let list = state.selectedTags;
            const idx = list.indexOf(tag);
            if (idx !== -1)
                list.splice(idx, 1);
            else
                list.push(tag);
            const labelNum = (list.length && list.length > 0) ? list.length : "No";
            notify.success(`${list.length || "No"} tag(s) selected`, true);
            return { ...state, selectedTags: list }

        default:
            return { ...state, forceRunSearchFromRedux: false };
    }
};

export const RefreshSearch = () => {
    return { type: HOME_ACTION_TYPES.HOME_REFRESH_SEARCH };
};

export const RefreshSearchComplete = () => {
    return { type: HOME_ACTION_TYPES.HOME_REFRESH_SEARCH_COMPLETE };
};

export const documentSelectionChangeAction = (payload) => {
    return { type: HOME_ACTION_TYPES.HOME_DOCUMENT_SELECTION_CHANGE, payload: payload };
};

export const tagSelectionChangeAction = (payload) => {
    return { type: HOME_ACTION_TYPES.HOME_TAG_SELECTION_CHANGE, payload: payload };
};
