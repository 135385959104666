import env from "react-dotenv";

import {
  DATE_FIELDS_DATA_TYPE,
  TITLE_FIELDS_DATA_TYPE,
} from "../Components/FilterSection/FilterSection.constants";
import { capitalizeFirstLetter, formatChartData } from "./formatFilterLabel";
import { KEYS } from "dataKeys";
import { SEARCH_TYPE } from "constants";

/**
 *
 * @param {string | string[]} content
 * @returns {string}
 */
export const arrayToString = (content) =>
  Array.isArray(content) ? content.join(" ") : content;

/**
 *
 * @param {string} content
 * @returns {string}
 */
export const removeEMTag = (content) => content.replace(/(<([^>]+)>)/gi, "");

/**
 * @param {*} data
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFieldsDataType
 * @returns {string}
 */
export const getCardTitle = (data, allFieldsDataType) => {
  let title;
  if (
    data["Source Type"] &&
    env.ARTICLE_EXTERNAL_REPORT_KEY?.toLowerCase()
      .split(",")
      .includes(removeEMTag(arrayToString(data["Source Type"]))?.toLowerCase())
  )
    title = data?.["File Name"];
  else {
    const titleFieldName =
      allFieldsDataType.find(
        (field) => field.dataType === TITLE_FIELDS_DATA_TYPE
      )?.name || "Title";
    title = data[titleFieldName];
  }
  return arrayToString(title);
};

/**
 * @param {*} data
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFieldsDataType
 * @returns {string}
 */
export const getDatePublished = (data, allFieldsDataType) => {
  const datePublishedFieldName =
    allFieldsDataType.find(
      (field) =>
        field.dataType === DATE_FIELDS_DATA_TYPE &&
        field.name.toLowerCase().includes("published")
    )?.name || "Date Published";
  return data[datePublishedFieldName];
};

export const chartDataFormatter = (payload) => {
  if (payload.chartName === "HEAT_MAP") {
    return payload.data;
  } else if (payload.chartName == "KEY_PHRASE_CLOUD") {
    return wordClodDataFormatter(payload.data);
  } else {
    if (Object.keys(payload.data).length > 0) {
      let data = payload.data[Object.keys(payload.data)[0]];
      data = formatChartData(data);
      return data;
    }
  }
};

export const wordClodDataFormatter = (data) => {
  let newData = [];
  let list =
    JSON.parse(sessionStorage?.getItem(KEYS.WORD_CLOUD_TEMPORARY_LIST)) ?? [];
  let permittedValues = [];
  for (let i = 0; i < list.length; i++) {
    permittedValues[i] = list[i]["keyword"].toLowerCase();
  }
  data.map((item) => {
    let isExcluded = permittedValues.includes(item.key);
    if (!isExcluded) {
      let newObject = {
        text: wordCloudChildExtractor(item.key),
        key: item.key,
        value: item.value,
        type: item.ktype,
        parent: parentExtractor(item.key) ?? undefined,
      };
      newData.push(newObject);
    }
  });
  return newData;
};

export const parentExtractor = (data) => {
  let matches = data.match(/\[(.*?)\]/);
  let subMatch;
  if (matches) {
    subMatch = matches[1];
  }
  return subMatch;
};

export const wordCloudChildExtractor = (value) => {
  return capitalizeFirstLetter(value.split("[")[0]);
};

export const getAdvancedSearchSearchObject = ({ searchValue, prevData }) => {
  let newData = { ...prevData };
  delete newData.group0;
  newData = {
    group0: {
      filter1: {
        facet: {
          value1: { data: searchValue, option: "All" },
        },
        isOR: false,
      },
      // isOR: false,
    },
    ...newData,
  };
  if (searchValue === "") {
    delete newData.group0;
    const firstElementKey = Object.keys(newData)[0];
    let firstElementObject = { ...newData[firstElementKey] };
    delete firstElementObject.isOR;
    newData = {
      ...newData,
      [firstElementKey]: { ...firstElementObject },
    };
  } else {
    const firstElementKey = Object.keys(newData)[1];
    newData = {
      ...newData,
      [firstElementKey]: { ...newData[firstElementKey], isOR: false },
    };
  }
  return newData;
};
export const checkAdvancedSearchValue = (data) =>
  data?.group0?.filter1["key"]
    ? ""
    : data?.group0?.filter1?.facet?.value1?.data || "";

export const setAdvancedSearchStateObject = (searchValue) => ({
  group0: {
    filter1: {
      facet: {
        value1: { data: searchValue, option: "All" },
      },
      isOR: false,
    },
  },
});

export const setAdvancedSearchKeyword = ({ data, payload, key }) =>
  (data.searchType === SEARCH_TYPE.ADVANCED_SEARCH ||
    payload?.searchType == SEARCH_TYPE.ADVANCED_SEARCH) &&
  payload.advanceSearchPayloadData
    ? checkAdvancedSearchValue(payload.advanceSearchPayloadData)
    : payload[key]
    ? payload[key]
    : data[key];

export const setAdvanceSearchPayload = ({ data, payload, key }) =>
  (data.searchType === SEARCH_TYPE.ADVANCED_SEARCH ||
    payload?.searchType == SEARCH_TYPE.ADVANCED_SEARCH) &&
  (payload.advanceSearchPayloadData || payload[key] || payload[key] === "")
    ? {
        ...getAdvancedSearchSearchObject({
          searchValue:
            payload[key] || payload[key] === "" ? payload[key] : data[key],
          prevData: payload.advanceSearchPayloadData
            ? payload.advanceSearchPayloadData
            : data.advanceSearchPayloadData,
        }),
      }
    : data.searchType === SEARCH_TYPE.ADVANCED_SEARCH ||
      payload?.searchType == SEARCH_TYPE.ADVANCED_SEARCH
    ? data.advanceSearchPayloadData
    : {};
