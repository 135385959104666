import AdvanceSearch from "Routes/AdvanceSearch";
import { SearchAcrossConceptsCombined } from "./SearchAcrossConceptsCombined";
import { Collapse } from "antd";
import SVGIcons from "../../Components/SVGIcons";
import { onCancelHelper } from "./controller";
import style from "./style.module.scss";

const { Panel } = Collapse;

export const AdvanceSearchContainer = props=>{

    const propsForAdvancedSearch = {
        mainFrameState: props.mainFrameState,
        isInsights: props.isInsights
    };

    const handleOnCancel = (type) => {
        debugger;
        const ths = {
            props: {
                clearAdvanceSearchPayload: props.clearAdvanceSearchPayload, 
                mainFrameState: props.mainFrameState
            }
        };
        onCancelHelper(type, ths);
    };

    return (<>
        <div style={{minHeight: '3rem'}}>
            <span className={style.searchClose} onClick={() => handleOnCancel("close")}>
                <SVGIcons type={"SVG-closeIcon"}/>
            </span>
        </div>
        <AdvanceSearch {...propsForAdvancedSearch}/> 
    </>);
};

