import { encode } from "string-encode-decode";

import { adfsLogin } from "../../../api/login";
import { notify } from "../../../Components/Notification";
import { ADFS_LOGIN_FAILED } from "../../../Constants/Login/Login.constants";
import { ROUTES } from "../../../Constants/Routes/Routes.constants";
import { KEYS } from "../../../dataKeys";
import { getStorageLocation } from "../../../utils/commonUtils";

export const storeUserAuthKeys = (data, history) => {
  if (data.data.token) {
    let loginTime = JSON.stringify({ loginTime: new Date(), modalCount: 0 });
    localStorage.getItem(KEYS.RememberMe)
      ? null
      : localStorage.setItem(KEYS.RememberMe, "sessionStorage");
    document.cookie = `${KEYS.ACCESS_TOKEN_FIELD}=${data.data.token};`;
    getStorageLocation()?.setItem(KEYS.ACCESS_TOKEN_FIELD, data.data.token);
    let role = encode(data.data.profileName);
    localStorage.removeItem(KEYS.POP_UP_BLOCKED);
    localStorage.setItem(KEYS.ACCESS_EMAIL, data.data.emailid);
    localStorage.setItem(KEYS.LOGIN_TIMEOUT, loginTime);
    localStorage.setItem(KEYS.FIRST_NAME, data.data?.firstname);
    localStorage.setItem(KEYS.LAST_NAME, data.data?.lastname);
  }
  if (
    getStorageLocation()?.getItem(KEYS.ACCESS_TOKEN_FIELD) &&
    localStorage.getItem(KEYS.ACCESS_EMAIL)
  ) {
    // history.push(ROUTES.HOME);
    window.location.href = ROUTES.HOME;
  }
};

export const adfsLoginApi = async ({ data, history, instance, self }) => {
  try {
    const response = await adfsLogin(createAdfsPayload(data));
    if (response.status == 200) {
      storeUserAuthKeys(response, history);
      self.setState({ loginInProgress: false, loginFailed: false });
      return;
    } else throw new Error("");
  } catch (error) {
    notify.error(ADFS_LOGIN_FAILED);
    self.setState({ loginInProgress: false, loginFailed: true });
  }
};

const createAdfsPayload = (data) => {
  const role = data.account.idTokenClaims.role === "Administrator" ? 1 : 2;
  const payload = {
    emailId: data.account.idTokenClaims.email,
    firstName: data.account.idTokenClaims["First Name"],
    lastName: data.account.idTokenClaims["Last Name"],
    userProfile: role,
  };
  return payload;
};

export const checkUserAdminFields = (data) => {
  if (data.emailid.length == 0) {
    return { message: "Please enter super admin email", error: true };
  } else if (data.password.length == 0) {
    return { message: "Please enter super admin password", error: true };
  } else {
    return { message: "", error: false };
  }
};
