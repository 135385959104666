import React, { useState, useEffect } from "react";
import ModalWindow from "../../ModalWindow";
import { ExcludeTags } from "./ExcludeTags";
import {
  deleteExcludeListItem,
  getExcludeListItem,
} from "../../../api/insights";
import LoaderComponent from "../../LoaderComponent";
import style from "../style.module.scss";
import { setInsightData } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";

import { notify } from "../../Notification";
import { EXCLUSIOM_INFO } from "../../../Constants/Insights/Insights.constants";

function ExcludeModal(props) {
  const dispatch = useDispatch();

  const [permanentList, setPermanentList] = useState([]);
  const [delTagList, setDelTagLsit] = useState([]);
  const [excludeListLoading, setExcludeListLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [excludeListError, setExcludeListError] = useState({
    add: false,
    remove: false,
    message: "",
  });

  const handleRemovePermanentExclusionList = async (id) => {
    let delTags = delTagList;
    permanentList.filter((items) => items.id == id && delTags.push(items));
    setDelTagLsit(delTags);
    setPermanentList(permanentList.filter((tag) => tag.id !== id));
  };

  useEffect(() => {
    getExcludeListItemListPermanent();
  }, []);

  const getExcludeListItemListPermanent = async () => {
    try {
      setExcludeListLoading(true);
      let { data } = await getExcludeListItem({ pageNumber: 1, pageSize: 100 });
      setPermanentList(data.result);
      setDelTagLsit([]);
      setModalLoading(false);
      setExcludeListError({ add: false, remove: false });
      setExcludeListLoading(false);
    } catch (error) {
      notify.error(error.message);
    }
  };

  const applyDeleteTags = async () => {
    setModalLoading(true);
    try {
      let delId = delTagList.map((items) => items.id);
      await deleteExcludeListItem({ id: delId });
      setModalLoading(false);
      setDelTagLsit([]);
      dispatch(setInsightData({ dataChanges: delTagList }));
      props.onClose();
    } catch (error) {
      notify.error(error.message);
      setModalLoading(false);
    }
  };

  return (
    <ModalWindow
      open={props.isModalOpen}
      title={"Exclusion List"}
      width={900}
      okText="Update"
      onOk={() => applyDeleteTags()}
      disableOkButton={delTagList.length == 0 ? true : false}
      onCancel={() => props.onClose()}
      cancelText={"Cancel"}
      isNonMaskable={true}
      confirmLoading={modalLoading}
      isNotClosable={true}
      isHideCancelButton={false}
    >
      {excludeListLoading ? (
        <LoaderComponent
          type={"linear"}
          classList={["xs blue"]}
          customHeight={30}
          hasWrapper={true}
        />
      ) : (
        <>
          <span className={style.alertInfo}>{EXCLUSIOM_INFO}</span>
          <ExcludeTags
            tags={permanentList}
            handleRemoveExclusionList={handleRemovePermanentExclusionList}
          />
          {excludeListError?.add && (
            <p className={style.errorInfo}>
              {excludeListError?.message ?? "Exclude list addition failed"}
            </p>
          )}
          {excludeListError?.remove && (
            <p className={style.errorInfo}>Exclude list delete failed</p>
          )}
        </>
      )}
    </ModalWindow>
  );
}
export default ExcludeModal;
