import { saveAs } from "file-saver";
import { getAccessToken } from "utils/commonUtils";
import { notify } from "Components/Notification";
import { DOWNLOAD_CSV_API_URL } from "Routes/Home/constants";

export const handleExportAsCsv = async (payload, setLoaderOff) => {
  const token = getAccessToken();
  let headers = {
    Authorization: "",
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    const response = await fetch(DOWNLOAD_CSV_API_URL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    if (response.status == 200) {
      const binaryData = await response.arrayBuffer();
      const blob = new Blob([binaryData], { type: "application/vnd.ms-excel" });
      saveAs(blob, "DataSheet.xlsx");
      setLoaderOff();
    } else {
      notify.error(
        response.status === 404
          ? "File not found, Download failed."
          : response.statusText
      );
      setLoaderOff();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("ee", error);
    notify.error("Failed to download");
    setLoaderOff();
  }
};
