/* eslint-disable max-params */
import React from 'react';
import {notify} from '../../../../Components/Notification';
import {DEFAULT_PAGE_COUNT} from '../../../../constants';
import {
  blockUserApiHandlerHelper,
  blockUserPayloadGenerator,
  findUserRole,
  unBlockUserApiHandlerHelper,
  updateUserInfo,
  validateUserDetails,
} from './helper';
import template from './template';
import {message} from 'antd';

class RoleSettings extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: {columnKey: 'id', field: 'id', order: 'asc'},
    editModalWindow: false,
    deleteModalWindow: false,
    suspendUser: '',
    userList: [],
    suspendUserModalWindow: false,
    editUserRecord: '',
    firstName: '',
    lastName: '',
    userRole: 'analyst',
    // emailid: {value: '', errorMessage: ''},
    emailid: '',
    pagination: {pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT},
    modalVisibility: false,
    deleteUserPayload: {},
    Id: '',
  };

  handleModalVisibility = () => {
    this.setState(
      {
        firstName: '',
        lastName: '',
        emailid: '',
        userRole: 'analyst',
      },
      () => this.setState({modalVisibility: !this.state.modalVisibility})
    );
  };

  handleModalEditVisibility = () => {
    this.setState({
      editModalWindow: !this.state.editModalWindow,
    });
  };
  handleChange = (pagination, filter, sorter) => {
    let sortedInfo = {
      columnKey: sorter.columnKey,
      field: sorter.field,
      order: sorter.order == 'descend' ? 'desc' : 'asc',
    };
    this.setState(
      {
        sortedInfo: sortedInfo,
      },
      () => {
        this.props.getUserProfiles(this.getpayload());
      }
    );
  };
  getpayload = () => {
    let {pagination, sortedInfo} = this.state;
    let data = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      sortField: sortedInfo.field,
      sortOrder: sortedInfo.order,
    };
    return data;
  };
  cancelDelete = () => {
    this.setState({
      deleteModalWindow: false,
      deleteUserPayload: {},
    });
  };
  handleDelete = (event, record) => {
    let deleteUserPayload = {
      emailid: event.emailid,
      firstname: event.firstname,
      lastname: event.lastname,
      isActive: false,
      userProfile: event.userProfile,
      Id: event.id,
    };
    this.setState({
      deleteModalWindow: !this.state.deleteModalWindow,
      deleteUserPayload,
    });
  };

  onConfirmDelete = () => {
    this.props.deleteUser(this.state.deleteUserPayload, () => {
      notify.success('Successfully deleted user');
      this.setState({deleteModalWindow: false, deleteUserPayload: {}});
      this.props.getUserProfiles(this.getpayload());
    });
  };

  handleEdit = (event, record) => {
    this.setState(
      {
        viewUserModal: false,
        // firstName: this.state.editUserRecord.firstname,
        // lastName: this.state.editUserRecord.lastname,
        // userRole: this.state.editUserRecord.userProfile,
        firstName: event.firstname,
        lastName: event.lastname,
        userRole: event.profilename,
        emailid: event.emailid,
        Id: event.id,
      },
      () => this.setState({editModalWindow: true})
    );
  };

  handleUserPagination = (pageNumber, pageSize) => {
    this.setState({pagination: {pageNumber: pageNumber, pageSize: pageSize}}, () =>
      this.props.getUserProfiles(this.getpayload())
    );
  };

  editModalHandler = (value, event) => {
    let payload = updateUserInfo({userRecord: this.state.editUserRecord, state: this.state});
    this.props.updateUserDetails(payload, this.updateUserApiHandler);
  };

  updateUserApiHandler = (event) => {
    if (event == 'success') {
      this.editUserRoleHandler();
      this.editUserNameHandler();
      this.setState({
        editModalWindow: false,
        userRole: '',
        firstName: '',
        lastName: '',
        viewUserModal: false,
      });
      notify.success('Updated user successfully', true);
    } else {
      notify.error('Network error', true);
    }
  };

  editUserName = (event, name) => {
    if (name == 'firstName') {
      this.setState({firstName: event.target.value});
    } else if (name == 'email') {
      this.setState({emailid: {value: event.target.value, errorMessage: null}});
    } else {
      this.setState({lastName: event.target.value});
    }
  };

  onChangeUserDetails = (name, event) => {
    this.setState({[name]: event});
  };

  onSubmitUserDetails = () => {
    let {firstName, lastName, userRole, emailid} = this.state;
    let userProfile = userRole == 'analyst' ? 2 : 1;
    let userDetailsObject = {firstname: firstName, lastname: lastName, userProfile, emailid, isActive: true};
    let errorMessage = validateUserDetails(userDetailsObject);
    !errorMessage.valid
      ? notify.error(errorMessage.message)
      : this.props.addUser(userDetailsObject, () => {
          this.setState({modalVisibility: false, firstName: '', lastName: '', userRole: 'analyst', emailid: ''});
          notify.success('Successfully added user');
          this.props.getUserProfiles(this.getpayload());
        });
  };

  onUpdateUserDetails = () => {
    let {firstName, lastName, userRole, emailid, Id} = this.state;
    let userProfile = userRole == 'analyst' ? 2 : 1;
    let userDetailsObject = {firstname: firstName, lastname: lastName, userProfile, emailid, Id, isActive: true};
    let errorMessage = validateUserDetails(userDetailsObject);
    !errorMessage.valid
      ? notify.error(errorMessage.message)
      : this.props.updateUser(userDetailsObject, () => {
          this.setState({editModalWindow: false, firstName: '', lastName: '', userRole: 'analyst', emailid: ''});
          notify.success('Successfully updated user');
          this.props.getUserProfiles(this.getpayload());
        });
  };

  editUserRole = (value) => this.setState({userRole: value});

  viewModalHandler = () => this.setState({viewUserModal: false});

  showViewUserModal = (value, record) => {
    this.setState({
      viewUserModal: true,
      editUserRecord: record,
    });
  };

  editUserRoleHandler = (value) => {
    let newUserDetails;
    let newUserList = [];
    let userRole = findUserRole(this.state.userRole);
    this.state.userList.map((item) => {
      if (item.id == this.state.editUserRecord.id) {
        newUserDetails = item;
        newUserDetails.profilename = userRole;
        newUserList.push(newUserDetails);
      } else {
        newUserList.push(item);
      }
    });
    return newUserList;
  };

  editUserNameHandler = (value) => {
    let newUserDetails;
    let newUserList = [];
    this.state.userList.map((item) => {
      if (item.id == this.state.editUserRecord.id) {
        newUserDetails = item;
        newUserDetails.firstname = this.state.firstName == '' ? item.firstname : this.state.firstName;
        newUserDetails.lastname = this.state.lastName == '' ? item.lastname : this.state.lastName;
        newUserDetails.emailid = this.state.emailid.value == '' ? item.emailid : this.state.emailid;
        newUserList.push(newUserDetails);
      } else {
        newUserList.push(item);
      }
    });
    return newUserList;
  };

  suspendModalHandler = (value, event) => {
    if (event == 'modalOff') {
      this.setState({
        suspendUserModalWindow: false,
      });
    }
  };

  unblockBlockHandler = (value) => {
    let payload = blockUserPayloadGenerator(this.state.suspendUser, 'unBlock');
    this.props.updateUserDetails(payload, this.unBlockUserApiHandler);
  };

  unBlockUserApiHandler = (event) => {
    unBlockUserApiHandlerHelper({state: this, event});
  };

  deactivateUser = (value) => {
    let newUserDetails;
    let newUserList = [];
    this.state.userList.map((item) => {
      if (item.id == this.state.suspendUser.id) {
        newUserDetails = item;
        newUserDetails.isActive = value;
        newUserList.push(newUserDetails);
      } else {
        newUserList.push(item);
      }
    });
    return newUserList;
  };

  suspendModalHandlerYes = (value) => {
    let payload = blockUserPayloadGenerator(this.state.suspendUser, 'block');
    this.props.updateUserDetails(payload, this.blockUserApiHandler);
  };

  blockUserApiHandler = (event) => {
    blockUserApiHandlerHelper({state: this, event});
  };

  editModalHandlerYes = () => {
    this.setState({
      editModalWindow: false,
    });
  };

  handleSuspendUser = (event, record) => {
    this.setState({
      suspendUserModalWindow: true,
      suspendUser: record,
    });
  };

  componentDidMount() {
    this.props.getUserProfiles(this.getpayload());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userDetails != this.props.userDetails) {
      this.setState({
        userList: this.props.userDetails,
      });
    }
  }

  render() {
    return template.call(this);
  }
}
export default RoleSettings;
