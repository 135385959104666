import React, { useState } from "react";
import { Button, Divider, Select } from "antd";
import "./style.scss";
export const SelectElement = (props) => {
  
  const [value, setValue] = useState('');

  const handleSelectAll = ()=>{
    props.handleSelectAll(value);
  };

  const handleClearAll = ()=>{
    props.handleClearAll();
  };

  let customStyle = {};
  if (props.width) {
    customStyle = {
      width: props.width + 'px',
      ...props.style,
    };
  }


  
  let customRenderer;
  if (props.showSelectAllButtons){
    customRenderer = (originalOptions)=>{
      return (<>
        <div style={{display: 'flex'}}>
          <div>
              <Button type="link" size="small" onClick={handleSelectAll}>Select All</Button>
          </div>      
          <div>
            <Button type="link" size="small" onClick={handleClearAll}>Clear All</Button>
          </div>
        </div>

        <Divider style={{ margin: '0' }} />

        {originalOptions}
      </>);
    }
  }

  return (
    <Select
      dropdownRender={customRenderer}
      autoClearSearchValue={props.autoClearSearchValue}
      showArrow={props.showArrow}
      allowClear={props.allowClear}
      showSearch={props.showSearch}
      tokenSeparators={props.tokenSeparators}
      style={props.style ? props.style : customStyle}
      mode={props.mode}
      placeholder={props.placeholder || 'Select option'}
      optionFilterProp='children'
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      size={props.size}
      shape={props.shape}
      maxTagCount={props.maxTagCount || 10}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
      onSearch={(x)=>{
        setValue(x);
        props.showSearch && props.onSearch ? props.onSearch(x) : undefined;
      }}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      value={props.value}
      className={`${props.className || ''} ${!props.isValid ? ' error-field' : ''}`}
      filterOption={filterOptions}
      options={props.optionList.map(({ value, label }) => ({
        value,
        label,
      }))}
      suffixIcon={props.suffixIcon}
      loading={props.loading}
      onPopupScroll={props.onPopupScroll}
      onClick={props.onClick}
    />
  );
};

function filterOptions(input = '', option = {}) {
  if (option.props && option.props.label && option.props.value)
    return (
      option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  else if (option.value)
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return false;
}
