import React from 'react';
import ReactDOM from 'react-dom';

import ReduxProvider from './configureRedux';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import App from './app';

import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import './index.scss';

// const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      {/* <MsalProvider instance={msalInstance}><App msalInstance={msalInstance} /></MsalProvider> */}
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
