import { notify } from "../../../../Components/Notification";
import { errorMEssageHandler } from "../../../../utils/ErrorHandler";
import { INITIAL_STATE } from "../initialState";

export function addFilterFields(state, payload) {
  return {
    ...state,
    settings: payload.newSettings,
    loading: false,
    error: false,
    allFields: payload.allFields ? payload.allFields : state.allFields,
    advanceSearchFilter: payload.advanceSearchFilter
      ? payload.advanceSearchFilter
      : state.advanceSearchFilter,
    allFieldsWithMetadata: payload.allFieldsWithMetadata,
  };
}

export function addFilterFieldsStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function addFilterFieldsFailed(state, payload) {
  notify.error(errorMEssageHandler(payload?.response?.status), true);
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function addFilterFieldValuesStarted(state, payload) {
  return {
    ...state,
    settings: {
      ...state.settings,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        // loading: true,
        // data: [],
        // value: [],
      },
    },
    advanceSearchFilter: {
      ...state.advanceSearchFilter,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        loading: true,
        data: [],
        value: [],
      },
    },
  };
}

export function addFilterFieldValues(state, payload) {
  return {
    ...state,
    settings: {
      ...state.settings,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        loading: false,
        data: payload.fieldValues,
        value: payload.fieldValues,
      },
    },
    advanceSearchFilter: {
      ...state.advanceSearchFilter,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        loading: false,
        data: payload.fieldValues,
        value: payload.fieldValues,
      },
    },
  };
}

export function addFilterFieldValuesFailed(state, payload) {
  return {
    ...state,
    settings: {
      ...state.settings,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        loading: false,
        data: [],
        value: [],
      },
    },
    advanceSearchFilter: {
      ...state.advanceSearchFilter,
      [payload.fieldName]: {
        ...state.settings[payload.fieldName],
        loading: false,
        data: [],
        value: [],
      },
    },
  };
}

export function clearFilterFieldData() {
  return { ...INITIAL_STATE };
}
