import React, { Fragment } from "react";

import ButtonElement from "../../../Components/ButtonElement";
import ToggleSwitch from "../../../Components/ToggleSwitch";
import TooltipContainer from "../../../Components/Tooltip";
import Facet from "./Facet";

import { isEmptyFields, showFilterAddButton } from "../helperFunctions";

import style from "./style.module.scss";

function AdvanceFilter(props) {
  return (
    <>
      {Object.keys(props.group[props.value])
        .filter((i) => i != "isOR")
        .map((item, index) => {
          return (
            <Fragment key={item + index}>
              <div
                className={style.advanceSwitch}
                hidden={index == 0 ? true : false}
              >
                <span>And</span>
                <span>
                  <ToggleSwitch
                    size={"small"}
                    handleChange={(e) =>
                      props.handleFilterToggle({
                        status: e,
                        group: props.value,
                        filter: item,
                      })
                    }
                    value={props.group[props.value][item]?.isOR}
                  />
                </span>
                <span>OR</span>
              </div>
              <FilterSection props={props} index={index} item={item} />
            </Fragment>
          );
        })}
    </>
  );
}

export default AdvanceFilter;

function FilterSection({ props, index, item }) {
  return (
    <div className={style.advanceFilter}>
      <div className={style.advanceFilter__facetgroup}>
        <div className={style.advanceFacet}>
          <Facet
            {...props}
            filter={item}
            filterKey={index}
            value={props.value}
          />
        </div>
      </div>
      <div className={style.advanceFilter__facetadd}>
        <div
          className={style.facetaddButton}
          hidden={
            index == 0 &&
            Object.keys(props.group[props.value]).filter((i) => i != "isOR")
              .length < 2
              ? true
              : false
          }
        >
          <ButtonElement
            size="small"
            className={style.facetButtons}
            children={"- Filter"}
            onClick={() =>
              props.handleFilterDelete({
                group: props.value,
                filter: item,
              })
            }
          />
        </div>
        <div className={style.facetaddButton}>
          <TooltipContainer
            placement={"top"}
            title={
              isEmptyFields(props.state)
                ? "Please select value for filter block"
                : ""
            }
          >
            <span>
              <ButtonElement
                size="small"
                className={style.facetButtons}
                children={"+ Filter"}
                type="primary"
                onClick={() => props.handleFilterAdd(props.value, item)}
                disabled={isEmptyFields(props.state)}
                styleProps={
                  isEmptyFields(props.state) ? { pointerEvents: "none" } : {}
                }
              />
            </span>
          </TooltipContainer>
        </div>
      </div>
    </div>
  );
}
