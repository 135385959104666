import { Select } from "antd";
import { searchConcepts2 } from "api/search";
import { Fragment, useEffect, useState } from "react";

const {Option, OptGroup} = Select;

let timeout;
let currentValue; 

const fetch = (value, callback)=>{
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    currentValue = value; 

    const doApiCall = ()=>{
        searchConcepts2(value).then(res=>{
            if (currentValue === value){
                const {data} = res;

                const conceptNames = Object.keys(data);
                const selectOptions = [];
                for (let i = 0; i < conceptNames.length; i++){
                    const conceptName = conceptNames[i];
                    const group = {
                        label: conceptName, 
                        options: data[conceptName].map(x=>({label: x, value: JSON.stringify({conceptName, keyword: x})}))
                    };
                    selectOptions.push(group);
                }
                
                callback(selectOptions);
            }
        }).catch(ex=>{
            console.log(ex);
        });
    };

    timeout = setTimeout(doApiCall, 300);
};

export const SearchAcrossConcepts2 = props=>{
    const index = props.index || 0;

    const [value, setValue] = useState(null);
    const [data, setData] = useState([]);

    const handleSearch = newValue => {
        if (newValue){
           fetch(newValue, setData);
        }
        else {
            setData([]);
        }
    };

    const handleChange = (newValue)=>{
        setValue(newValue || null);

        if (newValue){
            const objValue = JSON.parse(newValue);
            if (objValue.isGroup){
                props.onSelectAll(objValue.groupName, objValue.valuesInGroup);
            }
            else{
                props.onSetValue(objValue, index);
            }
        }
        else {
            props.onSetValue(null, index);
        }
    };

    const selectedValue = value ? JSON.parse(value) : null;

    return (<>
    
    {selectedValue ? <> 
        <b>{selectedValue.conceptName}</b>: {selectedValue.keyword}
    </> : null}
        <Select 
            allowClear={true}
            showSearch 
            value={value} 
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            onClear={()=>{
                setData([]);
            }}
        >
            {data.map((group, ixGroup) => {

                return (<Fragment key={`fragment-${ixGroup}`}>
                    <Option 
                        style={{fontWeight: 'bold', cursor: 'pointer', borderRadius: '8px', backgroundColor: '#1d39c4', color: 'white'}} 
                        value={JSON.stringify({isGroup: true, groupName: group.label, valuesInGroup: group.options})}
                        key={`group-${ixGroup}`}
                    >
                        {group.label}
                    </Option>
                    
                    {group.options.map((op, ixOp)=> {
                        return <Option 
                            value={op.value}
                            key={`group-${ixGroup}-option-${ixOp}`}
                        >{op.label}</Option>;
                    })}    
                </Fragment>);
            })}
        </Select>  
    </>);
};