import React from "react";
import ButtonElement from "Components/ButtonElement";
import ModalWindow from "Components/ModalWindow";
import { OVERWRITE_MESSAGE } from "Constants/Insights/Insights.constants";

export function OverWriteWindow(props) {
  return (
    <ModalWindow
      title={"Warning: File already exists!"}
      okText={"Save a copy"}
      cancelText={"Overwrite"}
      width={"500px"}
      isNotClosable={true}
      isNonMaskable={true}
      //   onCancel={}
      //   onOk={props.SaveModalOkHandler}
      //   confirmLoading={props.state.SaveLoading}
      open={true}
      footer={[
        <ButtonElement
          children={"Yes"}
          onClick={props.onOverWriteHandler}
          loading={props.state.SaveLoading}
        />,
        <ButtonElement
          children={"No"}
          type="primary"
          onClick={props.onSaveCopyHandler}
          loading={props.state.saveCopyLoading}
        />,
      ]}
    >
      <div>
        <p>{OVERWRITE_MESSAGE}</p>
      </div>
    </ModalWindow>
  );
}
