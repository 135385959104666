import { ROUTES } from "../../../../../Constants/Routes/Routes.constants";
import { handleEditInsightHelper } from "../helper";

/*This function is called when the user clicks on edit button and 
make changes to the imported insights */
export const handleLayoutSectionHelper = ({ state, layoutItems }) => {
  layoutItems = state.props.location.state.layout;
  if (state.state.onViewEdit) {
    if (
      state.state.selectedInsights.length >
      state.props.location.state.layout.length
    ) {
      let newCount = state.props.location.state.layout.length;
      state.state.selectedInsights.map((index) => {
        let newLayout = {
          i: newCount,
          x: (newCount * 6) % 12,
          y: 0,
          w: 6,
          h: 4,
          minW: 5,
          minH: 3,
          maxW: 20,
          maxH: 10,
        };
        if (state.state.selectedInsights.length > newCount) {
          layoutItems.push(newLayout);
          newCount = newCount + 1;
        }
      });
    }
    if (
      state.props.location.state.layout.length >
      state.state.selectedInsights.length
    ) {
      layoutItems.splice(
        state.state.selectedInsights.length,
        layoutItems.length
      );
    }
  }
  return layoutItems;
};

/*This function is called when the user click on the edit icon on the top of the insights*/
export const handleEditInsightsRouteHelper = ({ state, i }) => {
  let chartname = state.state.selectedInsights[i];
  let newObject = handleEditInsightHelper({
    chartname,
    insightList: state.props.insightsList.insightData,
  });
  state.props.history.push({
    pathname: "/createinsight",
    state: {
      isEdit: true,
      chartInfo: newObject.chartInfo,
      insightId: newObject.insightId,
      insightName: newObject.insightName,
      dataSet: newObject.dataSet,
      isEditDashboard: true,
      payload: state.state.insightsData,
      selectedList: state.state.selectedInsights,
      dashboardName: state.state.dashboardName,
      dashboardId:
        state.props.location.state && state.props.location.state.dashboardId,
      layout: state.state.layout,
    },
  });
};

export const editOnViewHandleHelper = ({ state }) => {
  state.props.history.push({
    pathname: ROUTES.DASHBOARD,
    state: {
      isEdit: true,
      payload: state.props.location.state?.payload,
      selectedList: state.state?.selectedInsights,
      dashboardName: state.props.location.state?.dashboardName,
      dashboardId: state.props.location.state?.dashboardId,
      layout: state.props.location.state?.layout,
    },
  });
  state.setState({ isView: false, isEdit: true, onViewEdit: true });
};
