import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const login = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.post('/login', {...payload});
};

export const adfsLogin = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.post('/ADFSlogin', payload);
};

export const logout = () => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.get('/logout');
};
