import { generatePayload } from "Routes/Insights/Helper/chartHandler";
import { setDateFilterData } from "../../../../../utils/commonUtils";
import {
  generateSavePayload,
  generateUpdateSavedPayload,
} from "./payloadGenerators";
import { createInsightsDataPayload } from "Routes/Insights/Helper";
import { createAdvanceSearchPayload } from "Redux/Actions/CreateInsights/Helper/AdvanceSearchHelper";

export const UpdateSaveModalOkHandlerHelper = ({
  state,
  handleEditUpdate,
  isEditDashboardUpdate,
}) => {
  state.setState({ SaveLoading: true });
  let apiPayload = generateUpdateSavedPayload({
    config:
      state.props.createInsights.config[state.props.createInsights.chartName],
    payload: state.state.selectedField,
    insightName: state.state.insightName,
    chartName: state.props.createInsights.chartName,
    insightId: state.props.location.state.insightId,
    dataSet: state.state.dataSetValue,
    requestPayload: state.props.createInsights.requestPayload,
  });
  if (
    state.props.createInsights &&
    state.props.createInsights.config &&
    state.props.createInsights.chartName &&
    state.props.createInsights.config[state.props.createInsights.chartName]
  ) {
    state.props.updateSaveInsights({
      params: apiPayload,
      handleEditUpdate,
      isEditDashboardUpdate,
    });
  }
};

export const saveModalOkHandlerHelper = ({ state, handleExisting }) => {
  state.setState({ SaveLoading: true });
  let { requestPayload } = generatePayload(
    createInsightsDataPayload(state.props.createInsights)
  );
  let apiPayload = generateSavePayload({
    config:
      state.props.createInsights.config[state.props.createInsights.chartName],
    requestPayload: {
      ...state.props.createInsights.requestPayload,
      buckets: requestPayload?.buckets,
      dateFilters: setDateFilterData(state.props.createInsights.dateFilter),
      filtersforData: [state.props.createInsights.filter],
      advanceSearchPayloadData:
        state.props.createInsights.advanceSearchPayloadData,
      searchCriterias: state.props.createInsights.searchCriterias,
      searchValue: state.props.createInsights.searchValue,
      field_Value: {
        key: "",
        value: state.props.createInsights.searchValue,
      },
      searchWords: state.props.createInsights.searchWords,
      filtersListforData:
        createAdvanceSearchPayload({
          data: state.props.createInsights.advanceSearchPayloadData ?? {},
        })?.filtersListforData || [],
    },
    payload: state.props.createInsights?.payload,
    insightName: state.state.insightName,
    chartName: state.props.createInsights.chartName,
  });
  if (
    state.props.createInsights &&
    state.props.createInsights.config &&
    state.props.createInsights.chartName &&
    state.props.createInsights.config[state.props.createInsights.chartName]
  ) {
    state.props.saveInsights(apiPayload, handleExisting);
  }
};

export const saveCopyHandler = ({ state, handleExisting }) => {
  state.setState({ saveCopyLoading: true });
  let apiPayload = generateSavePayload({
    config:
      state.props.createInsights.config[state.props.createInsights.chartName],
    requestPayload: state.props.createInsights.requestPayload,
    payload: state.props.createInsights?.payload,
    insightName: state.state.insightName + "(copy)",
    chartName: state.props.createInsights.chartName,
  });
  if (
    state.props.createInsights &&
    state.props.createInsights.config &&
    state.props.createInsights.chartName &&
    state.props.createInsights.config[state.props.createInsights.chartName]
  ) {
    state.props.saveInsights(apiPayload, handleExisting);
  }
};
