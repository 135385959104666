import {
  HORIZONTAL_LABEL,
  WORD_CLOUD_FIELD_LABEL,
  WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL,
  WORD_CLOUD_MINIMUM_OCCURANCE_LABEL,
  VERTICAL_AXIS_LABEL,
  STREAM_CHART_PRIMARY_DATA_SERIES_LABEL,
  TREE_MAP_PRIMARY_FIELD,
  TREE_MAP_SECONDARY_FIELD,
  TREE_MAP_TERITIARY_FIELD,
  STREAM_CHART_HORIZONTAL_FIELD_LABEL,
} from "../../../../../Constants/Insights/CreateInsights.constants";
import { validateStreamChartXField } from "../../../../../Components/StreamChart2/helper";

export const updateChartValidator = (selectedField, selectedChart) => {
  if (selectedChart == "BAR_CHART" || selectedChart == "LINE_CHART") {
    if (
      !checkParamsEmpty(selectedField) &&
      selectedField[HORIZONTAL_LABEL].value != ""
    ) {
      return false;
    } else {
      return true;
    }
  }
  if (selectedChart == "KEY_PHRASE_CLOUD") {
    return handleWordCloudValidator(selectedField);
  }
  if (selectedChart == "COLLAPSIBLE_TREE_MAP")
    return handleTreeMapValidation(selectedField);

  if (selectedChart == "HEAT_MAP") {
    return handleHeatMapValidator(selectedField);
  }
  if (selectedChart == "STREAM_CHART") {
    return handleStreamChartValidator(selectedField);
  }
};

const handleWordCloudValidator = (selectedField) => {
  if (
    !checkParamsEmpty(selectedField) &&
    selectedField[WORD_CLOUD_FIELD_LABEL] &&
    selectedField[WORD_CLOUD_FIELD_LABEL].value != "" &&
    selectedField[WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL].value != "" &&
    selectedField[WORD_CLOUD_MINIMUM_OCCURANCE_LABEL].value != ""
  ) {
    return false;
  } else {
    return true;
  }
};

const handleHeatMapValidator = (selectedField) => {
  if (
    !checkParamsEmpty(selectedField) &&
    selectedField[HORIZONTAL_LABEL] &&
    selectedField[HORIZONTAL_LABEL].value != "" &&
    selectedField[VERTICAL_AXIS_LABEL] &&
    selectedField[VERTICAL_AXIS_LABEL].value != ""
  ) {
    return false;
  } else {
    return true;
  }
};

const handleStreamChartValidator = (selectedField) => {
  if (
    !checkParamsEmpty(selectedField) &&
    selectedField[HORIZONTAL_LABEL] &&
    selectedField[HORIZONTAL_LABEL].value != "" &&
    selectedField[STREAM_CHART_PRIMARY_DATA_SERIES_LABEL] &&
    selectedField[STREAM_CHART_PRIMARY_DATA_SERIES_LABEL].value != ""
  ) {
    return false;
  } else {
    return true;
  }
};

function checkParamsEmpty(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

const handleTreeMapValidation = (selectedField) => {
  let count = 0;
  if (
    !selectedField[TREE_MAP_PRIMARY_FIELD] ||
    selectedField[TREE_MAP_PRIMARY_FIELD]?.value == ""
  )
    return true;
  if (
    selectedField[TREE_MAP_PRIMARY_FIELD] &&
    selectedField[TREE_MAP_PRIMARY_FIELD]?.value != ""
  )
    count = count + 1;
  if (
    selectedField[TREE_MAP_SECONDARY_FIELD] &&
    selectedField[TREE_MAP_SECONDARY_FIELD]?.value != ""
  )
    count = count + 1;
  if (
    selectedField[TREE_MAP_TERITIARY_FIELD] &&
    selectedField[TREE_MAP_TERITIARY_FIELD]?.value != ""
  )
    count = count + 1;
  if (count < 2) return true;
  else false;
};

export const isValidField = (state, value) => {
  if (
    state.state.selectedChart === "STREAM_CHART" &&
    value.destination.droppableId === STREAM_CHART_HORIZONTAL_FIELD_LABEL
  ) {
    return validateStreamChartXField(value.draggableId);
  } else {
    return true;
  }
};
