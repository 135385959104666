import {
  getUserProfileSuccess,
  getUserProfileStarted,
  getUserProfileFailed,
  updateUserProfileFailed,
  updateUserProfileSuccess,
  updateUserProfileStarted,
} from './Helper';
import {INITIAL_STATE} from './initialState';
import {
  GET_USER_PROFILES_SUCCESS,
  GET_USER_PROFILES_STARTED,
  GET_USER_PROFILES_FAILED,
  UPDATE_USER_PROFILES_SUCCESS,
  UPDATE_USER_PROFILES_FAILED,
  UPDATE_USER_PROFILES_STARTED,
} from '../../Redux.constants';

export default function UserProfiles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_PROFILES_SUCCESS:
      return getUserProfileSuccess(state, action.payload);
    case GET_USER_PROFILES_STARTED:
      return getUserProfileStarted(state, action.payload);
    case GET_USER_PROFILES_FAILED:
      return getUserProfileFailed(state, action.payload);
    case UPDATE_USER_PROFILES_STARTED:
      return updateUserProfileStarted(state, action.payload);
    case UPDATE_USER_PROFILES_SUCCESS:
      return updateUserProfileSuccess(state, action.payload);
    case UPDATE_USER_PROFILES_FAILED:
      return updateUserProfileFailed(state, action.payload);
    default:
      return {...state};
  }
}
