import React from "react";
import PropTypes from "prop-types";

import FilterOptions from "./Children/FilterOptions";
import CustomScrollbar from "../../Components/CustomScrollbar";
import { filterDataCreate } from "Routes/Home/Helper";

import style from "./style.module.scss";
import ErrorState from "../ErrorState";
import ButtonElement from "Components/ButtonElement";

function template() {
  
  return (
    <div className={style.filterBlock}> 
      <div className={style.filterBlock__options}>
        {this.props.error ? (
          <ErrorState
            content="ERROR"
            // type={'errorImage'}
            errorMessage={this.props?.errorMessage}
          />
        ) : (
          <div className={style.filterContainer}>
            <CustomScrollbar className="filter-scroller">
              <FilterOptions
                {...this.props}
                dateFormat={this.state.dateFormat}
                handleCheckbox={this.props.handleChangeCheckBox}
                filterResults={filterDataCreate({
                  filtersForData: this.props?.filter,
                  dateFilters: this.props?.dateFilter,
                })}
              />
            </CustomScrollbar>
          </div>
        )}
      </div>
    </div>
  );
}
template.propTypes = {
  /**
   * handleChangeCheckBox
   */
  handleChangeCheckBox: PropTypes.func,
  /**
   * search
   */
  search: PropTypes.any,
};
export default template;
