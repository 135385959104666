import { COMMUNICATION_KEYS } from "constants";
import { formatInsightData } from "./common";

// communication handler
export const onMessageHandler = (xxx) => {
  const {
    data,
    setState,
    mainFrameState,
    setInsightData,
    channel,
    createInsights,
    clearInsightData,
    setProjectSwitchLoading,
  } = xxx;
  
  const queryParams = new URLSearchParams(window.location.search);
  const fromTerm = queryParams.get("from");
  if (fromTerm == "newtab") {
    const { type, payload, from } = data;
    console.log(new Date().toLocaleTimeString(), "Insights - Communication <<<<<<<<", { type, payload, from });
    switch (type) {
      case COMMUNICATION_KEYS.PROJECT_CHANGE:
        if (payload.data.projectSwitched) {
          setState({ switchProjectLoading: true });
          setProjectSwitchLoading({ showProjectSwitchingModal: true });
          clearInsightData();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        return;
      case COMMUNICATION_KEYS.START_FROM_ARTICLE:
        return postMessageHandler({
          channel,
          createInsights,
          fromArticle: true,
        });
      case COMMUNICATION_KEYS.PARENT_TERMINATE:
        if (data?.isLogout) {
          //closing the channel to prevent further communication.
          channel.close();
          window.close();
        } else {
          channel.close();
          setState({ notInteractive: true });
        }
        return;
      case COMMUNICATION_KEYS.PARENT_CHOOSE_STAY:
        mainFrameState.handleStayActive();
      case COMMUNICATION_KEYS.CHANGE:
        if (from === "article"){
          setInsightData({
            dataChanges: [Math.random()],
            isFromArticles: true,
            ...formatInsightData(payload),
          });
        }
        return;
      default:
        return;
    }
  }
};

export const postMessageHandler = ({
  createInsights,
  channel,
  fromArticle = false,
}) => {
  if (createInsights.isFromArticles && !fromArticle) return;

  console.log(new Date().toLocaleTimeString(), "Insights - Communication >>>>>>>>", createInsights);
  channel.postMessage({
    type: COMMUNICATION_KEYS.CHANGE,
    from: "insight",
    payload: {
      searchWords: createInsights.searchWords,
      dateFilter: createInsights.dateFilter,
      filter: createInsights.filter,
      searchValue: createInsights.searchValue,
      searchType: createInsights.searchType,
      advanceSearchPayload: createInsights.advanceSearchPayloadData,
      searchCriterias: createInsights.searchCriterias,
      buckets: createInsights.buckets,
    },
  });
};
