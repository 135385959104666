import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DatePicker} from 'antd';
import moment from 'moment';

import style from '../style.module.scss';
import { DATE_FILTER_KEY } from '../../../../../Routes/Home/constants';

function UntilNow(props) {
  const [date, setDate] = useState();

  const disabledDateTodayAfter = (current) => {
    return current && moment(current).startOf('day') > moment().endOf('day');
  };

  const onDateChange = (event) => {
    setDate(event);
    props.handleChangeDate({e: event, name: 'from', type: 'untilNow'});
  };

  useEffect(() => {
    if (!props.filterResults?.[DATE_FILTER_KEY]) {
      setDate('');
    }
  }, [props.filterResults]);
  return (
    <>
      <div className={style.dateChoose__items}>
        <label>From</label>
        <DatePicker
          size={'small'}
          className='filter-picker'
          onChange={onDateChange}
          value={date}
          format={props.dateFormat}
          placeholder={props.dateFormat}
          suffixIcon={false}
          disabledDate={disabledDateTodayAfter}
        />
      </div>
    </>
  );
}

UntilNow.propTypes = {
  /**
   * pickerType
   */
  pickerType: PropTypes.any,
  /**
   * dateFormat
   */
  dateFormat: PropTypes.any,
  /**
   * showTime
   */
  showTime: PropTypes.any,
  /**
   * filterResults
   */
  filterResults: PropTypes.any,
  /**
   * handleChangeDate
   */
  handleChangeDate: PropTypes.func,
};
export default UntilNow;
