import React from "react";
import { Tabs } from "antd";
import "./style.scss";
export function TabElement(props) {
  return (
    <Tabs
      className={props.className}
      activeKey={props.activeKey}
      onChange={props.onChange}
      tabBarExtraContent={props.tabBarExtraContent}
      destroyInactiveTabPane={props.destroyInactiveTabPane}
      defaultActiveKey={props.default}
      items={props.items}
    />
  );
}
