import { deleteDashboard, getDashboardsByEmail } from "../../../api/dashboard";
import { DEFAULT_PAGE_COUNT } from "../../../constants";
import { KEYS } from "../../../dataKeys";
import { handleApiError } from "../../../utils/commonUtils";
import {
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_START,
  GET_DASHBOARD_SUCCESS,
  IMPORT_INSIGHTS_FAILED,
} from "../../Redux.constants";
import { DELETE_DASHBOARD } from "../../Redux.constants";
import {
  editDashboardsDispatch,
  saveDashboardsDispatch,
  viewDashboardDispatch,
} from "./Helper/functionHelper";
import { handleInsightData } from "./Helper/helper";

export function DashboardAction({ payload, handleImportSuccess }) {
  return async (dispatch) => {
    try {
      const {
        newData,
        chartNames,
        config,
        insightsName,
      } = await handleInsightData(payload);
      handleImportSuccess({ newData, chartNames, config, insightsName });
    } catch (error) {
      handleApiError(error, () => {
        handleImportSuccess({ error: true });
        dispatch({
          type: IMPORT_INSIGHTS_FAILED,
          payload: error,
        });
      });
    }
  };
}

/**
 *
 * @param {{emailId?: string, pageNumber?: number, pageSize?: number}} param
 * @returns
 */
export function getDashboardData({ emailId, pageNumber, pageSize }) {
  return async (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_START,
      payload: {},
    });
    try {
      emailId = emailId || localStorage.getItem(KEYS.ACCESS_EMAIL);
      pageNumber = pageNumber || 1;
      pageSize = pageSize || DEFAULT_PAGE_COUNT;
      let { data } = await getDashboardsByEmail({
        emailId,
        pageNumber,
        pageSize,
      });
      dispatch({
        type: GET_DASHBOARD_SUCCESS,
        payload: { data: data },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_DASHBOARD_ERROR,
          payload: error,
        })
      );
    }
  };
}

export function deleteDashboardData(id, callBack) {
  return async (dispatch) => {
    try {
      await deleteDashboard({ id });
      dispatch({
        type: DELETE_DASHBOARD,
        payload: id,
      });
      callBack();
    } catch (error) {}
  };
}
export const saveDashboardsAction = (
  payload,
  handleSuccess,
  loadDashboardList
) => async (dispatch) =>
  saveDashboardsDispatch({
    dispatch,
    payload,
    handleSuccess,
    loadDashboardList,
  });

export const editDashboardsAction = (payload, handleSuccess) => async (
  dispatch
) =>
  editDashboardsDispatch({
    dispatch,
    payload,
    handleSuccess,
  });

export const viewDashboardAction = (payload, handleSuccess) => async (
  dispatch
) => viewDashboardDispatch({ dispatch, payload, handleSuccess });
