import React from "react";
import Chart from "react-apexcharts";

import { formatFilterLabel } from "../../utils/formatFilterLabel";

import style from "./style.module.scss";
import globel from "../../styles/globel/style.module.scss";

export class BarChart extends React.Component {
  formatDataCategory = (data) => {
    let categoryKeys = [];
    data.map((item) => {
      categoryKeys.push(item.key);
    });
    return categoryKeys;
  };

  formatDataCount = (data) => {
    let categoryCount = [];
    data.map((item) => {
      categoryCount.push(item.value);
    });
    return categoryCount;
  };
  constructor(props) {
    super(props);

    this.state = {
      /** @type {ApexAxisChartSeries | ApexNonAxisChartSeries} */
      series: [
        {
          data: this.formatDataCount(this.props.data) ?? [],
        },
      ],
      /** @type {import('apexcharts').ApexOptions} */
      options: {
        chart: {
          offsetX: 0,
          type: "bar",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true, // '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          onItemClick: {
            toggleDataSeries: true,
          },
          height: 50,
          formatter: function (seriesName, opts) {
            return opts.w?.globals.lastXAxis?.categories[opts.seriesIndex];
          },
        },
        xaxis: {
          categories: this.formatDataCategory(this.props.data),
          tickPlacement: "on",
          labels: {
            show: false,
          },
          title: {
            text: `${formatFilterLabel(this.props?.config?.fieldValue)} `,
            offsetY: 55,
          },
        },
        yaxis: {
          // offsetX: '10%',
          title: {
            text: ` Count of ${formatFilterLabel(
              this.props?.config?.fieldValue
            )}`,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              "<span>" +
              w.globals.lastXAxis.categories[dataPointIndex] +
              ": " +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "</div>"
            );
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateChartData();
  };

  componentDidUpdate(prevProps) {
    prevProps.data !== this.props.data && this.updateChartData();
  }

  shouldComponentUpdate = (nextProps) => {
    return nextProps.data !== this.props.data;
  };

  updateChartData = () => {
    this.setState({
      data: this.formatDataCount(this.props.data),
    });
  };

  render() {
    return (
      <div
        className={`${
          this.props.dashboard
            ? style["chart-root-container"]
            : globel.chartItem
        }  ${this.props.isDownload && globel.previewCharts}`}
        id={this.props.id}
      >
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width={"90%"}
          height={"95%"}
        />
      </div>
    );
  }
}
