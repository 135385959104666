import { notify } from "../../../../Components/Notification";
import { errorMEssageHandler } from "../../../../utils/ErrorHandler";
import { INITIAL_STATE } from "../initialState";

export function getMappingPayloadSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    dataSetLoading: false,
    error: false,
    data: payload.data,
  };
}

export function getMappingPayloadStarted(state, payload) {
  return {
    ...state,
    loading: true,
    dataSetLoading: true,
    error: false,
  };
}

export function getMappingPayloadFailed(state, payload) {
  // notify.error('Failed to fetch the available fields', true);
  return {
    ...state,
    loading: false,
    dataSetLoading: false,
    data: [],
  };
}

export function getInsightStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}
export function getInsightSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    insightData: payload.result.result,
    totalCount: payload.result.resultHits,
    error: false,
  };
}
export function getInsightError(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function deleteInsights(state, payload) {
  return {
    ...state,
    insightData: state.insightData.filter((i) => i.id != payload),
  };
}

export function getInsightDataSetFailed(state, payload) {
  return {
    ...state,
    insightDataSetLoading: false,
    dataSetError: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}
export function getInsightDataSetSuccess(state, payload) {
  return {
    ...state,
    insightDataSetLoading: false,
    error: false,
    dataSet: formatDataSetList(payload.data.data.result),
    dataSetError: false,
  };
}

export function getInsightDataSetStart(state, payload) {
  return {
    ...state,
    insightDataSetLoading: true,
    error: false,
    dataSetError: false,
  };
}

export function getAllInsightsFailed(state, payload) {
  return {
    ...state,
    loading: false,
  };
}
export function getAllInsightsSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    allInsightsList: payload.data.data.result,
    fullInsightsDetails: payload.insightDetails,
  };
}

export function getAllInsightsStarted(state, payload) {
  return {
    ...state,
    loading: true,
  };
}

const formatDataSetList = (data) => {
  let dataSetItems = [];
  data.map((item) => {
    if (item.searchKeyword !== "all articles") {
      dataSetItems.push(item);
    }
  });
  return dataSetItems;
};
