import {combineReducers} from 'redux';
import Search from './Search';

import Login from './Login';
import SendEmail from './SendEmail';
import {FilterFields} from './FilterOptions';
import ResetPasswordReducer from './ResetPassword';
import CreateInsights from './CreateInsights';
import InsightsPage from './InsightsPage';
import SaveInsights from './SaveInsights';
import FetchProjectList from './ProjectList';
import UpdateSaveInsights from './UpdateSaveInsights';
import UserProfiles from './UserProfiles';
import Settings from './Settings';
import {AllInsightsList} from './InsightsPage';
import Dashboard from './Dashboards';
import UserLogs from './UserLogs';
import { HomeReducer } from './Home/HomeState';
import UserGroups from './UserGroups';

export default combineReducers({
  Home: HomeReducer,
  Login,
  FilterFields,
  ResetPasswordReducer,
  SendEmail,
  Search,
  CreateInsights,
  InsightsPage,
  SaveInsights,
  FetchProjectList,
  UpdateSaveInsights,
  UserProfiles,
  Settings,
  AllInsightsList,
  Dashboard,
  UserLogs,
  UserGroups
});
