import React from 'react';
import {Breadcrumb} from 'antd';
import PropTypes from 'prop-types';

import style from './style.module.scss';

export const InsightBreadCrumb = ({crumbs, handleClick, isClearable, clearButtonText}) => {
  const handleCrumbs = (crumb) => {
    handleClick(crumb);
  };

  return (
    <div className={style.breadCrumbWrapper}>
      <div className={style.breadCrumbWrapper__elements}>
        <Breadcrumb className={style.cloudLabel} separator='AND'>
          {crumbs.map((crumb, index) => (
            <Breadcrumb.Item key={index + crumb.label} onClick={() => handleCrumbs(crumb)}>
              <a> {handleLabelFormatter(crumb.label)}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <div className={style.breadCrumbWrapper__item}>
        {crumbs.length && isClearable && (
          <a className={style.clearButton} onClick={() => handleCrumbs({value: -1, label: '', clear: true})}>
            {clearButtonText}
          </a>
        )}
      </div>
    </div>
  );
};

InsightBreadCrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
      tab: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
};

const handleLabelFormatter = (label) => {
  if (label.includes('[')) {
    let value = label.split('[')[0];
    return value;
  } else {
    return label;
  }
};
