import React from 'react';
import { useSelector } from 'react-redux';
import SVGIcons from '../../SVGIcons';
import style from '../style.module.scss';

const LogoutButton = (props) => {
  return (
    <div className={style.logoutButton}>
      <a onClick={props.handleModalVisible}>
        <span>
          <SVGIcons type={'SVG-logout'} />
        </span>
      </a>
    </div>
  );
};

export default LogoutButton;
