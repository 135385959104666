import { connect } from "react-redux";
import { setInsightData } from "../../Redux/Actions";
import { ApexHeatMap } from "./ApexHeatMap";

function mapStateToProps(state) {
  return { createInsights: state.CreateInsights };
}
function mapDispatchToProps(dispatch) {
  return {
    setInsightData: (params) => dispatch(setInsightData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApexHeatMap);
