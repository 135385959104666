import React, { Component } from 'react';
import style from './style.module.scss';
export default class PageBody extends Component {
  render() {
    return <article className={style.pageBody}>{this.props.children}</article>;
  }
}
PageBody.defaultValue = {
  childern: null,
};
