import React from "react";
import PropTypes from "prop-types";

import SVGIcons from "../../../../SVGIcons";
import TooltipContainer from "../../../../Tooltip";

import style from "../style.module.scss";
import Loader from "../../../../Loader";

export const Tag = (props) => {
  return (
    <li>
      <div className={style.tagElement}>
        <TooltipContainer
          title={props.text}
          overlayClassName={style[props.text === "..." ? "tooltip-hide" : ""]}
        >
          <span className={style.tagText}>{props.text}</span>
        </TooltipContainer>
        {props.closable && (
          <a
            onClick={() => props.onClose(props.text)}
            className={style.closeIcon}
          >
            <SVGIcons type={"SVG-cross"} />
          </a>
        )}
        {props?.loading?.[props.value] && (
          <span>
            <Loader
              hasWrapper={true}
              classList={["xs white"]}
              customHeight={5}
            />
          </span>
        )}
      </div>
    </li>
  );
};

Tag.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * text to be rendered
   */
  text: PropTypes.string,
  /**
   * is closable
   */
  closable: PropTypes.bool,
  /**
   * onClose handler
   */
  onClose: PropTypes.func,
};

Tag.defaultProps = {
  className: "",
  text: "",
  closable: false,
};
