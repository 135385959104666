import { connect } from "react-redux";
import style from "./style.module.scss";
import PingarEmblem from "../../../public/img/pinger-emblem.svg";
import ProfileAvatar from "../../../public/img/avatar.png";
import NavigationLinks from "./Children/NavigationLinks";
import { useState } from "react";
import { imageUploadhelper, scrollHelper, logoutHelper } from "./Helper";
import LogoutButton from "./Children/LogoutButton";
import Showlogoutmodal from "./ShowLogoutModal";
import ShowProfilePicModal from "./ShowProfilePicModal";


const MySideBarComponent= props=>{

    const [state, setLocalState] = useState({
        activeClass: "",
        showDropdown: false,
        showLogoutModal: false,
        addOnClass: false,
        profileModal: false,
        profileImg: ProfileAvatar,
        updatedProfile: ProfileAvatar,
        loading: false,
        isSearchClear: false,
        openSandWich: false
    });

    const setState = data=>{
        setLocalState((prevState)=>{
            return {...prevState, ...data};
        });
    };

    const isClearSearch = value=>{
        setState({isSearchClear: value});
    };

    const toggleSandwich =()=>{
        setState({openSandWich: !state.openSandWich});
    };

    const handleProfile = ()=>{
        setState({showDropdown: true});
    };

    const handleModalVisible = () => {
        setState({ showLogoutModal: true });
    };

    const handleModalHide = () => {
        setState({ showLogoutModal: false });
    };

    const handleIsLogout = () => {
        logoutHelper({ 
            state: {
                setState: setState, 
                props: props
            } 
        });
    };

    const showProfile = ()=>{
        setState({addOnClass: !state.addOnClass});
    };

    const onScroll = ()=>{
        debugger;
        scrollHelper({
            state:
            {
                state: state, 
                setState: setState
            }
        }); 
    };

    const handleChangeProfile = ()=>{
        setState({ profileModal: true });
    };

    const handleProfileCancel = () =>{
        setState({
            profileModal: false,
            profileImg: state.updatedProfile
        });
    };

    const handleUpload = e =>{
        imageUploadhelper({
            event: e, 
            state: {
                setState: setState
            }
        });
    };

    const handleUploadOk = ()=>{
        setState({
            updatedProfile: this.state.profileImg,
            profileModal: false,
        });
    };

    const propsForNavigationLinks = {
        location: props.location, 
        CurrentUserProfile: props.CurrentUserProfile, 
        menuActive: style.menuActive, 
        isClearSearch: isClearSearch,
        userRole: "Admin" 
    };

    const propsForLogoutButton = {
        handleModalVisible
    };

    const propsForShowLogoutModal = {
        handleModal: handleModalHide,
        handleIsLogout: handleIsLogout, 
        showLogoutModal: state.showLogoutModal,
        loading: state.loading
    };

    const propsForShowProfilePicModal = {
        handleProfileCancel: handleProfileCancel,
        handleUploadOk: handleUploadOk, 
        state: {
            profileModal: state.profileModal, 
            profileImg: state.profileImg,
            updatedProfile: state.updatedProfile,
        },
        handleUpload: handleUpload,
    };



    return (<>
        <aside className={style.mainSidebar}>
            <div className={style.headerBrand}>
                <div className={style.headerBrand__emblem}>
                    <span>
                        <img src={PingarEmblem}/>
                    </span>
                </div>
            </div>

            {props.showSideBarNav && (<ul className={style.sidebarMenu}>
                <NavigationLinks 
                    {...propsForNavigationLinks}
                />
            </ul>)}

            <LogoutButton {...propsForLogoutButton} />
        </aside>

        <Showlogoutmodal {...propsForShowLogoutModal} />

        <ShowProfilePicModal {...propsForShowProfilePicModal} />

    </>);
};

const mapStateToProps = state=>{
    return {...state.Search, 
        CurrentUserProfile: state.Settings.profile
    };
};

const mapDispatchToProps = dispatch=>{
    return {
        ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
        setSearchData: (params) => dispatch(setSearchData(params)),
    };
};

const MySideBar = connect(mapStateToProps, mapDispatchToProps)(MySideBarComponent);
export default MySideBar;

