import CollapseElement from "Components/CollapseElement";
import DateSection from "Components/FilterSection/Children/DateSection";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { formatFilterLabel } from "utils/formatFilterLabel";
import { MyFilterContents } from "./MyFilterContents/MyFilterContents";
import { findPayloadData, findSearchType } from "Components/FilterSection/Children/helper";
import { DATE_FILTER_KEY } from "Routes/Home/constants";

export const MyFilter = ({ item, props, index, openItemsHandler, openItems })=>{

    const location = useLocation();

    const loadFilterValues = (item) => {
        if (props.settings[item].loading !== true) {
            props.loadFilterFieldValues({
                fieldName: item,
                searchType: 1,
                searchPayload: findPayloadData({
                    type: findSearchType(location.pathname),
                    self: props,
                }),
            });
        }
    };

    const handleOpenItems = (item, index) => {
        openItemsHandler(item, index);
    };

    useEffect(() => {
        if (openItems[item] && item != DATE_FILTER_KEY) {
            loadFilterValues(item);
        }
    }, 
    [
        props.search.filter,
        props.search.dateFilter,
        props.search.value,
        props.search.searchWords,
        props.search.searchCriterias,
        props.createInsights.filter,
        props.createInsights.dateFilter,
        props.createInsights.dataChanges,
        props.createInsights.searchWords,
        props.createInsights.searchValue,
        props.createInsights.searchCriterias,
    ]);

    if (item?.toLowerCase().includes("date")) {
      const propsForDateSection={
        state:{
          numberOfDays: props.numberOfDays,
          filterDateType: props.filterDateType // all the way from home..
        },
        dateFormat: props.dateFormat,
        handleChangefilterDateType: props.handleChangefilterDateType,
        handleChangeDate: props.handleChangeDate
      };

        return (
          <CollapseElement
            index={`${index}`}
            className="filter-colapse"
            heading={formatFilterLabel(item)}
            key={index}
            item={item}
            handleOpenItems={() => handleOpenItems(item, "null")}
          >
            <DateSection 
              {...propsForDateSection}
              // dateFormat= ''
              // state = {{}}
              // filterResults = {{}}
              // handleChangeDate = {props.handleChangeDate}
              
              
            />
          </CollapseElement>
        );
      } else
        return (
          <CollapseElement
            index={`${index}`}
            heading={formatFilterLabel(item)}
            className="filter-colapse"
            onOpenElement={() => {
              loadFilterValues(item);
            }}
            key={index}
            item={item}
            handleOpenItems={() => handleOpenItems(item, index)}
          >
            <MyFilterContents
                filterResults={props.filterResults}
                handleCheckbox={props.handleCheckbox}
                handleSelectAll={props.handleSelectAll}
                handleClearAll={props.handleClearAll}
                {...props}
                headingName={item}
                name={item}
                data={props.settings[item].value}
                loading={props.settings[item].loading}
            />
          </CollapseElement>
        );
    }