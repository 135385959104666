import { array } from "prop-types";

export const wordCloudSquareBracketRemoveFormatter = (value) => {
  return value.replace(/\[\[/g, "[").replace(/\]\]/g, "]");
};

export const getRandomHex = () => {
  const [r, g, b] = [
    Math.floor(Math.random() * 200),
    Math.floor(Math.random() * 200),
    Math.floor(Math.random() * 200),
  ];
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

/**
 * @param {{link: string, label: string, tab: string}[]} breadCrumb
 * @param {string} clickedWord
 * @returns {boolean}
 */
export const checkPreviousValue = (breadCrumb, clickedWord) => {
  return breadCrumb?.find((crumb) => crumb.label === clickedWord)
    ? false
    : true;
};

export const setKeyworldCloudColor = (intervalSize, value) => {
  if (value >= intervalSize[0].start && value <= intervalSize[0].end) {
    return "#BF40BF";
  } else if (value >= intervalSize[1].start && value <= intervalSize[1].end) {
    return "#008080";
  } else if (value >= intervalSize[2].start && value <= intervalSize[2].end) {
    return "#0000FF";
  } else if (value >= intervalSize[3].start && value <= intervalSize[3].end) {
    return "#4B0082";
  } else if (value >= intervalSize[4].start && value <= intervalSize[4].end) {
    return "#008000";
  } else if (value >= intervalSize[5].start && value <= intervalSize[5].end) {
    return "#FF8C00";
  } else if (value >= intervalSize[6].start && value <= intervalSize[6].end) {
    return "#FF6700 ";
  } else if (value >= intervalSize[7].start && value <= intervalSize[7].end) {
    return "#800000";
  } else if (value >= intervalSize[8].start && value <= intervalSize[8].end) {
    return "#FF00FF";
  } else if (value >= intervalSize[9].start && value <= intervalSize[9].end) {
    return "#800080";
  } else {
    return "#000000";
  }
};
