import React from 'react';
import PropType from 'prop-types';

import { TextInput } from '../TextInput';
import { SelectElement } from '../SelectElement';
import DatePickerElement from '../DatePicker';
import SVGIcons from '../SVGIcons';
import TextArea from '../TextArea';
import PasswordField from '../PasswordField';

import style from './style.module.scss';

export default function InputField(props) {
  return (
    <div className={style.formFeild}>
      <div className={`${style.formElement} ${props.className}`}>
        {props.htmlForName ? (
          <label className={style.feildlabel} htmlFor={props.htmlForName}>
            <span className={style.labelBlock}>{props.title}</span>
            {props.isRequired ? (
              <span className={style.starIcon}>
                <SVGIcons type='SVG-asterisks' />
              </span>
            ) : (
              ''
            )}
          </label>
        ) : null}
        <InputTypes className={props.className} {...props} />
      </div>
      {props.hint ? (
        <hint
          className={`${style.formHint} ${
            props.inputType === 'textArea' ? style.textRight : ''
          }`}
        >
          {props.hint}
        </hint>
      ) : null}
      {props.error ? <span className={style.error}>{props.error}</span> : null}
    </div>
  );
}

InputField.propTypes = {
  className: PropType.string,
  type: PropType.string,
  placeholder: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.array]),
  name: PropType.string,
  inputType: PropType.string,
  onPressEnter: PropType.func,
  onChange: PropType.func,
  onBlur: PropType.func,
  autoFocus: PropType.bool,
  error: PropType.string,
  showSearch: PropType.bool,
  title: PropType.string,
  optionList: PropType.array,
  htmlForName: PropType.string,
  disabled: PropType.bool,
  allowClear: PropType.bool,
  hint: PropType.string,
  style: PropType.object,
  size: PropType.string,
  htmlType: PropType.any,
  loading: PropType.bool,
  mode: PropType.string,
  maxTagCount: PropType.oneOfType([PropType.string, PropType.number]),
};

function InputTypes(props) {
  const { inputType } = props;
  switch (inputType) {
    case 'selectElement':
      return selectInputType(props);
    case 'date':
      return <DatePickerElement {...props} handleChange={props.onChange} />;
    case 'textArea':
      return <TextArea {...props} handleChange={props.onChange} />;
    case 'password':
      return passwordInputType(props);
    default:
      return textInputType(props);
  }
}

function selectInputType(props) {
  return (
    <SelectElement
      {...props}
      showArrow={props.showArrow}
      allowClear={props.allowClear}
      mode={props.mode}
      id={props.id}
      htmlForName={props.htmlForName}
      disabled={props.disabled}
      placeholder={props.placeholder}
      optionList={props.optionList}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      tokenSeparators={props.tokenSeparators}
      size={props.size}
      shape={props.shape}
      loading={props.loading}
      maxTagCount={props.maxTagCount}
      onPopupScroll={props.onPopupScroll}
    />
  );
}

function passwordInputType(props) {
  return (
    <PasswordField
      max={props.max}
      maxLength={props.maxLength}
      onChange={props.onChange}
      disabled={props.disabled}
      placeholder={props.placeholder}
      value={props.value}
      htmlType={props.htmlType}
      onPressEnter={props.onPressEnter}
      name={props.htmlForName}
      id={props.id}
      min={props.min}
      className={!props.isValid ? 'error-field' : ''}
      onBlur={props.onBlur}
      size={props.size}
      shape={props.shape}
    />
  );
}

function textInputType(props) {
  return (
    <TextInput
      max={props.max}
      maxLength={props.maxLength}
      onChange={props.onChange}
      disabled={props.disabled}
      placeholder={props.placeholder}
      value={props.value}
      htmlType={props.htmlType}
      onPressEnter={props.onPressEnter}
      name={props.htmlForName}
      id={props.id}
      min={props.min}
      className={!props.isValid ? 'error-field' : ''}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      size={props.size}
      shape={props.shape}
      showCount={props.showCount}
      style={props.style}
      allowClear={props.allowClear}
    />
  );
}
