import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { handleInsightsDataHelper } from "Routes/Dashboard/Components/BuildDashboard/Helper/helper";

export const dashboardMountHelper = ({ importInsights, setState, data }) => {
  importInsights({
    payload: data.insightsPresent,
    handleImportSuccess: async ({
      newData,
      chartNames,
      config,
      insightsName,
      error,
    }) => {
      if (error) return setState({ dataLoading: false });
      let insightsData = await handleInsightsDataHelper({
        selectedValues: data.insightsPresent,
      });
      setState({
        dataLoading: false,
        data: newData,
        chartNames,
        config,
        labels: insightsName,
        selectedInsights: data.insightsPresent,
        dashboardName: data.dashboardName,
        layout: JSON.parse(data.layout),
        isInsight: false,
        insightsData,
      });
    },
  });
};
export async function downloadPdfHelper({ state }) {
  state.setState({ exportPdfLoading: true });
  const doc = new jsPDF("p", "px");
  // const input = document.getElementById('download-preview-div');
  try {
    // const elements = document.getElementById('download-preview-div');
    const elements = state.previewRef.current;
    await creatPdf({ doc, elements });
    doc.save(
      `${state.state.dashboardName || state.state.insightName || "chart"}.pdf`
    );
    state.setState({ exportPdfLoading: false });
  } catch (error) {
    state.setState({ exportPdfLoading: false });
  }
}

async function creatPdf({ doc, elements }) {
  const padding = 20;
  const marginTop = 20;
  let top = marginTop;
  let startTime = new Date();
  const imgData = await htmlToImage.toPng(elements);
  let elHeight = elements.offsetHeight;
  let elWidth = elements.offsetWidth;

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  if (elWidth > pageWidth) {
    const ratio = pageWidth / elWidth;
    const heightRatio = pageHeight / elWidth;
    elHeight = elHeight * ratio - padding;
    elWidth = elWidth * ratio - padding * 2;
  }
  doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${1}`);
  top += elHeight + marginTop;
}

export const handleInsightResponceData = ({
  dataLoading,
  data,
  config,
  chartName,
  setlocalState,
}) => {
  if (dataLoading) setlocalState({ dataLoading });
  else {
    setlocalState({
      dataLoading,
      data: [data],
      config: [config],
      chartNames: [chartName],
      layout: [
        {
          i: 0,
          x: (0.5 * 6) % 12,
          y: 0,
          w: 6,
          h: 4,
          minW: 5,
          minH: 3,
          maxW: 20,
          maxH: 10,
        },
      ],
      items: [0],
    });
  }
};
