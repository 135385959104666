import React from "react";
import InputField from "../../Components/InputField";
import ModalWindow from "../../Components/ModalWindow";
import style from "./style.module.scss";
import ButtonElement from "../../Components/ButtonElement";

export function PasswordResetModal(props) {
  return (
    <ModalWindow
      title={"Reset Password"}
      isNotClosable={true}
      footer={false}
      open={props.visible}
    >
      <ul className={style.container}>
        {props?.currentProfile?.changePwdCount == 0 && (
          <p>Please change your default password immediately!</p>
        )}

        <li>
          <InputField
            placeholder={"Current Password"}
            inputType={"password"}
            onChange={(e) => props.onChange("currentPass", e.target.value)}
            value={props.state.currentPass}
          />
        </li>
        <li>
          <InputField
            placeholder={"New Password"}
            inputType={"password"}
            onChange={(e) => props.onChange("newPass", e.target.value)}
            value={props.state.newPass}
          />
        </li>
        <li>
          <InputField
            placeholder={"Confirm New Password"}
            inputType={"password"}
            onChange={(e) => props.onChange("confirmPass", e.target.value)}
            value={props.state.confirmPass}
          />
        </li>
        <li>
          {props?.currentProfile?.changePwdCount != 0 && (
            <ButtonElement onClick={() => props.cancel()}>Cancel</ButtonElement>
          )}
          <ButtonElement
            type={"primary"}
            onClick={() => props.onSubmit()}
            loading={props.state.resetLoader}
            disabled={
              props.state.confirmPass &&
              props.state.newPass &&
              props.state.currentPass
                ? false
                : true
            }
          >
            Update Password
          </ButtonElement>
        </li>
      </ul>
    </ModalWindow>
  );
}
