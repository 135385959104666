/* eslint-disable */
import React from 'react';

import { Card } from '../Card';
import { ContentLoader } from '../ContentLoader';

import globel from '../../styles/globel/style.module.scss';

export const SearchCardLoader = () => {
    return (
        <Card className={globel.resultCard}>
            <div className={globel.resultCard__header}>
                <ContentLoader width={100} height={1.2} spaceBetween={0} />
                <div className={globel.headerBottom}>
                    <div className={globel.headerBottom__searchcontent}>
                        <ul className={globel.searchitemList}>
                            <li>
                                <ContentLoader height={1} spaceBetween={0} />
                            </li>
                            <li>
                                <ContentLoader height={1} spaceBetween={0} />
                            </li>
                        </ul>
                    </div>
                    <div className={globel.headerBottom__controlsicon}>
                        <ul className={globel.controlsList}>
                            <li>
                                <ContentLoader height={2} spaceBetween={0} />
                            </li>
                            <li>
                                <ContentLoader height={2} spaceBetween={0} />
                            </li>
                            <li>
                                <ContentLoader height={2} spaceBetween={0} />
                            </li>
                            <li>
                                <ContentLoader height={2} spaceBetween={0} />
                            </li>
                            <li>
                                <ContentLoader height={2} spaceBetween={0} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={globel.resultCard__content}>
                <div className={globel.tagsBlock}>
                    <ContentLoader width={20} height={1} spaceBetween={0.5} />
                    <ul className={globel.tagList}>
                        <li>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                            <span><ContentLoader width={100} height={1} spaceBetween={0} /></span>
                        </li>
                        <li>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                        </li>
                        <li>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                        </li>
                        <li>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                        </li>
                        <li>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                            <span>
                                <ContentLoader width={100} height={1} spaceBetween={0} />
                            </span>
                        </li>
                    </ul>
                </div>
                <div className={globel.articleText}>
                    <ContentLoader hasWrapper height={0.75} spaceBetween={0.5} />
                </div>
                <div className={globel.resultMetatag}>
                    <ul className={globel.metaTagList}>
                        <li>
                            <div className={globel.metaListTagElement}>
                                <ContentLoader width={100} height={2} spaceBetween={0} />
                            </div>

                        </li>
                        <li>
                            <div className={globel.metaListTagElement}>
                                <ContentLoader width={100} height={2} spaceBetween={0} />
                            </div>

                        </li>
                        <li>
                            <div className={globel.metaListTagElement}>
                                <ContentLoader width={100} height={2} spaceBetween={0} />
                            </div>

                        </li>
                        <li>
                            <div className={globel.metaListTagElement}>
                                <ContentLoader width={100} height={2} spaceBetween={0} />
                            </div>

                        </li>
                        <li>
                            <div className={globel.metaListTagElement}>
                                <ContentLoader width={100} height={2} spaceBetween={0} />
                            </div>

                        </li>
                    </ul>
                </div>

            </div>
        </Card>
    );
};
