import React from "react";

import { TabElement } from "Components/TabElement";
import SearchContent from "../SearchContent";
import { GridIconSection } from "../SearchControls/GridIconSection";
import {
  ALL_ARTICLE_TAB,
  EXTERNAL_TAB,
  NEWS_TAB,
  EXPAND_ALL_TEXT,
  MINIMIZE_ALL,
} from "Constants/Home/Home.constants";

import style from "./style.module.scss";
import ButtonElement from "Components/ButtonElement";

const SearchTab = (props) => {
  const renderSearchContent = () => (
    <div className={style.serachResult}>
      <SearchContent
        {...props}
        handleViewListClassName={props.handleViewListClassName}
        setFilterOnSavedResult={props.setFilterOnSavedResult}
        filterResults={props.filterResults}
        handleDeleteTags={props.handleDeleteTags}
        handleDeleteMainTag={props.handleDeleteMainTag}
        handleDeleteDate={props.handleDeleteDate}
        listViewType={props.listViewType}
        breadCrumbData={props.breadCrumbData}
        handleBreadCrumbArticles={props.handleBreadCrumbArticles}
      />
    </div>
  );
  const tabItems = [
    {
      key: ALL_ARTICLE_TAB,
      label: ALL_ARTICLE_TAB,
      disabled: props.searchData.loading && props.tab !== ALL_ARTICLE_TAB,
      children: renderSearchContent(),
    },
    {
      key: NEWS_TAB,
      label: NEWS_TAB,
      disabled: props.searchData.loading && props.tab !== NEWS_TAB,
      children: renderSearchContent(),
    },
    {
      key: EXTERNAL_TAB,
      label: EXTERNAL_TAB,
      disabled: props.searchData.loading && props.tab !== EXTERNAL_TAB,
      children: renderSearchContent(),
    },
  ];
  return (
    <>
      <TabElement
        default={props.tab}
        activeKey={props.tab}
        className="search-tab"
        onChange={props.onTabChange}
        items={tabItems}
        tabBarExtraContent={
          <div className={style.tabRightBar}>
            {props.listViewType == "gridTwo" && (
              <ButtonElement
                size={"small"}
                onClick={props.handleExpandAll}
                disabled={props.searchData.loading}
              >
                {props.expandAllCard ? MINIMIZE_ALL : EXPAND_ALL_TEXT}
              </ButtonElement>
            )}
            <GridIconSection
              handleListView={props.handleListView}
              listViewType={props.listViewType}
              isSearchLoading={props.searchData.loading}
            />
          </div>
        }
      />
    </>
  );
};

export default SearchTab;
