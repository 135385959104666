import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';

export function seriesInitialization({root, chart, yAxis, xAxis, xAxisLabel, yAxisLabel}) {
  return chart.series.push(
    am5xy.LineSeries.new(root, {
      name: yAxisLabel,
      yAxis: yAxis,
      xAxis: xAxis,
      valueYField: yAxisLabel,
      categoryXField: xAxisLabel,
      legendLabelText: 'Count',
      tooltip: am5.Tooltip.new(root, {labelText: '{valueY}'}),
    })
  );
}
