import {
  editDashboard,
  saveDashboards,
  viewDashboardApi,
} from "../../../../api/dashboard";
import {
  EDIT_DASHBOARD_ERROR,
  EDIT_DASHBOARD_START,
  EDIT_DASHBOARD_SUCCESS,
  SAVE_DASHBOARD_ERROR,
  SAVE_DASHBOARD_START,
  SAVE_DASHBOARD_SUCCESS,
  VIEW_DASHBOARD_ERROR,
  VIEW_DASHBOARD_START,
} from "../../../Redux.constants";
import { notify } from "../../../../Components/Notification";
import { handleApiError } from "../../../../utils/commonUtils";

export const saveDashboardsDispatch = async ({
  dispatch,
  payload,
  handleSuccess,
  loadDashboardList,
}) => {
  dispatch({
    type: SAVE_DASHBOARD_START,
    payload: {},
  });
  try {
    let data = await saveDashboards(payload);
    dispatch({
      type: SAVE_DASHBOARD_SUCCESS,
      payload: data.data.result,
    });
    handleSuccess({ error: false });
    loadDashboardList();
  } catch (error) {
    handleApiError(error, () => {
      handleSuccess({ error: true, message: error.response.data });
      dispatch({
        type: SAVE_DASHBOARD_ERROR,
        payload: {},
      });
    });
  }
};

export const editDashboardsDispatch = async ({
  dispatch,
  payload,
  handleSuccess,
}) => {
  dispatch({
    type: EDIT_DASHBOARD_START,
    payload: {},
  });
  try {
    const data = await editDashboard(payload);
    dispatch({
      type: EDIT_DASHBOARD_SUCCESS,
      payload: data.data.result,
    });
    handleSuccess({ error: false });
  } catch (error) {
    handleApiError(error, () => {
      handleSuccess({ error: true, message: error.response.data });
      dispatch({
        type: EDIT_DASHBOARD_ERROR,
        payload: error,
      });
    });
  }
};

export const viewDashboardDispatch = async ({
  dispatch,
  payload,
  handleSuccess,
}) => {
  dispatch({
    type: VIEW_DASHBOARD_START,
    payload: {},
  });
  try {
    const { data } = await viewDashboardApi({ id: payload });
    handleSuccess({ data: data.result });
  } catch (error) {
    handleApiError(error, () => {
      handleSuccess({ error: true });
      notify.error(error.message);
      dispatch({ type: VIEW_DASHBOARD_ERROR, payload: {} });
    });
  }
};
