export const TAGS_OPTIONS_LIST = [
  {value: 'public', label: 'Public'},
  {value: 'private', label: 'Private'},
];

export const SEARCH_CARD_METADATA_ITEMS_BLACK_LIST = [
  'title',
  'Title',
  'Document Title',
  'website_domain',
  'Website Domain',
  'date_published',
  'Date Published',
  'Published Date',
  'summary',
  'Summary',
  'Content',
  'id',
  'DocumentId',
  'ProjectId',
  'ProjectName',
  'textlength',
  'TextLength',
  'user_tags',
  'location',
  'Location',
  'article_pdf',
  'page_title',
  'Timestamp',
  'ClippedPath',
];

export const ALL_ARTICLE_TAB = 'All Articles';
export const NEWS_TAB = 'News';
export const EXTERNAL_TAB = 'External Reports';
export const EXPAND_ALL_TEXT = 'View full content';
export const MINIMIZE_ALL = 'Hide full content';

export const EXPAND_TAGS = 'View All Tags';
export const COLLAPSE_TAGS = 'Collapse Tags'; // TODO: confirm the wording ?
