import React from "react";

import EmptyState from "../../../Components/EmptyState";
import Loader from "../../../Components/Loader";
import ResizeDrag from "../../../Components/ResizeDrag";
import InsightsPreview from "./InsightsPreview";

import style from "./style.module.scss";

const Dashboardpreview = (props) => {
  return (
    <div className={style.downloadPreview} ref={props.previewRef}>
      {props.state.dataLoading ? (
        <Loader hasWrapper={true} classList={["sm blue"]} />
      ) : (
        <>
          <div className={style.downloadPreview__search}>
            {props.state.data.length > 0 && (
              <DashboardsaveInput {...props} disabled={true} />
            )}
          </div>
          {props.state.data.length > 0 ? (
            <>
              {props.state.previewType == "dashboard" ? (
                <div className={style.downloadPreview__chart}>
                  <ResizeDrag
                    data={props.state.data}
                    chartNames={props.state.chartNames}
                    config={props.state.config}
                    labels={props.state.labels}
                    removeItem={props.removeItem}
                    handleEditInsight={props.handleEditInsight}
                    isView={true}
                    isDownload={true}
                    isEdit={false}
                    onLayoutChange={props.onLayoutChange}
                    layout={props.state.layout}
                    items={props.state.items}
                  />
                </div>
              ) : (
                <div className={style.downloadPreview__insight}>
                  {props.state.data[0].length > 0 && (
                    <InsightsPreview
                      data={props.state.data}
                      chartNames={props.state.chartNames}
                      config={props.state.config}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <EmptyState type="welcomeImage" showButton={false} />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboardpreview;

function DashboardsaveInput(props) {
  return (
    // <input maxLength={50} className={style.downloadInput} value={props.state.dashboardName || props.state.insightName} disabled={props.disabled} />
    <div className={style.downloadInput}>
      {props.state.dashboardName || props.state.insightName}
    </div>
  );
}

function InformationResource(props) {
  return (
    <div className={style.downloadPreview__resources}>
      <h4>Information Resources</h4>
      <ul className={style.resourcesList}>
        {props.props.websiteDomain?.map((item) => {
          return <li>{item.key}</li>;
        })}
      </ul>
    </div>
  );
}
