import {RESET_PASSWORD_FAILED, RESET_PASSWORD_STARTED, RESET_PASSWORD_SUCCESS} from '../../Redux.constants';
import {resetPassword} from '../../../api/resetPassword';
import {errorHandler} from '../../../utils/ErrorHandler';
import {handleApiError} from '../../../utils/commonUtils';

export function resetPasswordAction(payload, modalClose) {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_STARTED,
      payload: {},
    });
    try {
      const data = await resetPassword(payload);
      modalClose('success', 'modalCancel');
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleApiError(error, () => {
        if (error && error.response && error.response.status == 400) {
          modalClose('Fail', error);
        } else {
          errorHandler(error);
        }
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: error,
        });
      });
    }
  };
}
