import { Tooltip } from "antd";
import React from "react";
import PropTypes from "prop-types";

export default function TooltipContainer(props) {
  return (
    <Tooltip
      placement={props.placement}
      // visible={props.visible}
      open={props.visible}
      mouseLeaveDelay={props.mouseLeaveDelay}
      title={props.title}
      destroyTooltipOnHide
      overlayStyle={props.overlayStyle}
      color={props.backgroundColor}
      overlayInnerStyle={props.overlayInnerStyle}
      overlayClassName={props.overlayClassName}
    >
      {props.children}
    </Tooltip>
  );
}

TooltipContainer.propTypes = {
  placement: PropTypes.oneOf([
    "top",
    "left",
    "right",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "leftTop",
    "leftBottom",
    "rightTop",
    "rightBottom",
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.element.isRequired,
  mouseLeaveDelay: PropTypes.number,
  visible: PropTypes.bool,
  overlayStyle: PropTypes.object,
  backgroundColor: PropTypes.string,
  overlayClassName: PropTypes.string,
  overlayInnerStyle: PropTypes.object,
};

TooltipContainer.defaultProps = {
  placement: "top",
};
