import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { formatFilterLabel } from "../../../../utils/formatFilterLabel";
import CollapseElement from "../../../CollapseElement";
import { ContentLoader } from "../../../ContentLoader";
import DateSection from "../DateSection";
import FilterContents from "../FilterContents";
import {
  findPayloadData,
  findSearchType,
  handlePublishDatePosition,
} from "../helper";
import ErrorState from "../../../ErrorState";
import { useLocation } from "react-router-dom";
import { DATE_FILTER_KEY } from "../../../../Routes/Home/constants";

function template(props) {
  let arr = [...Array(15).keys()];

  return (
    <>
      {this.props.filterLoad && (
        <>
          {arr.map((index) => (
            <ContentLoader
              key={index}
              height={3}
              width={100}
              spaceBetween={0.375}
            />
          ))}
        </>
      )}
      {!this.props.filterLoad &&
      Object.keys(this.props.settings).length == 0 ? (
        <ErrorState
          content="ERROR"
          type={"errorImage"}
          errorMessage={{ title: "Empty", description: "its all empty here !" }}
          customHeight={"500"}
        />
      ) : (
        handlePublishDatePosition(
          this.props.settings,
          this.props.searchTab
        ).map((item, index) => {
          return this.props.settings[item].checked ? (
            <Filter
              item={item}
              props={this.props}
              index={index}
              key={index}
              openItemsHandler={this.handleOpenItems}
              openItems={this.state.openItems}
            />
          ) : (
            <></>
          );
        })
      )}
    </>
  );
}
export default template;

/**
 *
 * @param {{item: string, props: any, index: any}} param
 * @returns
 */
function Filter({ item, props, index, openItemsHandler, openItems }) {
  const location = useLocation();

  const loadFilterValues = (item) => {
    if (props.settings[item].loading !== true) {
      props.loadFilterFieldValues({
        fieldName: item,
        searchType: 1,
        searchPayload: findPayloadData({
          type: findSearchType(location.pathname),
          self: props,
        }),
      });
    }
  };

  const handleOpenItems = (item, index) => {
    openItemsHandler(item, index);
  };

  useEffect(() => {
    if (openItems[item] && item != DATE_FILTER_KEY) {
      loadFilterValues(item);
    }
  }, [
    props.search.filter,
    props.search.dateFilter,
    props.search.value,
    props.search.searchWords,
    props.search.searchCriterias,
    props.createInsights.filter,
    props.createInsights.dateFilter,
    props.createInsights.dataChanges,
    props.createInsights.searchWords,
    props.createInsights.searchValue,
    props.createInsights.searchCriterias,
  ]);

  if (item?.toLowerCase().includes("date")) {
    return (
      <CollapseElement
        index={`${index}`}
        className="filter-colapse"
        heading={formatFilterLabel(item)}
        key={index}
        item={item}
        handleOpenItems={() => handleOpenItems(item, "null")}
      >
        <DateSection {...props} />
      </CollapseElement>
    );
  } else
    return (
      <CollapseElement
        index={`${index}`}
        heading={formatFilterLabel(item)}
        className="filter-colapse"
        onOpenElement={() => {
          loadFilterValues(item);
        }}
        key={index}
        item={item}
        handleOpenItems={() => handleOpenItems(item, index)}
      >
        <FilterContents
          {...props}
          headingName={item}
          name={item}
          data={props.settings[item].value}
          loading={props.settings[item].loading}
        />
      </CollapseElement>
    );
}

template.propTypes = {
  /**
   * filterLoad
   */
  filterLoad: PropTypes.any,
  /**
   * settings
   */
  settings: PropTypes.any,
  /**
   *
   */
};
