import { SEARCH_TYPE } from "constants";
import { ALL_ARTICLE_TAB } from "../../../Constants/Home/Home.constants";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";

export const INITIAL_STATE = {
  search: {
    loading: false,
    error: false,
    data: [],
    buckets: [],
    value: "",
    filter: {},
    dateFilter: DEFAULT_DATE_FILTER_VALUE,
    selectedField: "",
    allFields: [],
    searchDone: false,
    saved: [], 
	savedSearches: null, //all saved searches so one can be selected as a group default query
	savedSearchesModified: true, //if so must reload the above when next use
    fullSavedData: [],
    totalResultCount: 0,
    pageNumber: 1,
    pageSize: 12,
    searchType: SEARCH_TYPE.NORMAL_SEARCH,
    advanceSearchPayloadData: {},
    savedSearchLoading: false,
    savedSearchTotalCount: 0,
    emptySearch: false,
    loadResultId: "",
    filtersCount: 0,
    sortorder: "DESC",
    sortby: null,
    insightIds: [],
    searchTab: ALL_ARTICLE_TAB,
    searchCriterias: [],
    searchWords: [],
    isFromInsights: false,
  },
  tag: {
    loading: false,
    error: false,
    data: [],
  },
};
