import { SEARCH_TYPE } from "constants";
import { ROUTES } from "Constants/Routes/Routes.constants";
import moment from "moment";
import { DATE_FILTER_KEY } from "Routes/Home/constants";
import { formatDate, setDateFilterData } from "utils/commonUtils";
import { generatePayload, handleApi } from "./chartHandler";
import { chartDataFormatter } from "utils/dataFormatter";
import { createAdvanceSearchPayload } from "Redux/Actions/CreateInsights/Helper/AdvanceSearchHelper";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { generateAdvanceSearchPayload } from "Routes/Home/Helper";

export const downloadInsightHelper = ({ history, record }) => {
  history.push({
    pathname: ROUTES.DOWNLOAD_PREVIEW,
    search: "?insight",
    state: {
      isInsight: true,
      id: record.id,
      record,
      breadCrumbs: [
        { label: "Insights", link: "/insights", tab: "Saved Insights" },
        { label: "Download Insights", link: "" },
      ],
    },
  });
};

export const handleDateFilterInsightHelper = ({
  setInsightData,
  event,
  type,
}) => {
  const value =
    type === "UntilNow"
      ? {
          from: formatDate(event),
          to: formatDate(moment(new Date())),
        }
      : { from: event.from, to: event.to };

  setInsightData({
    dateFilter: { key: DATE_FILTER_KEY, value },
  });
};

export const handleInsightLastNDaysChangeHelper = ({ state, value }) => {
  state.setState({
    filterResults: {
      ...state.state.filterResults,
      ["Date"]: { from: value.from, to: value.to },
    },
  });
  state.props.setInsightData({
    dateFilter: {
      key: DATE_FILTER_KEY,
      value: { from: value.from, to: value.to },
    },
  });
};

export const handleInsightUntilNowChangeHelper = ({ state, value }) => {
  state.setState({
    filterResults: {
      ...state.state.filterResults,
      ["Date"]: {
        from: formatDate(value),
        to: formatDate(moment(new Date())),
      },
    },
  });
  state.props.setInsightData({
    dateFilter: {
      key: DATE_FILTER_KEY,
      value: {
        from: formatDate(value),
        to: formatDate(moment(new Date())),
      },
    },
  });
};

export const handleInsightDeleteMainTagHelper = async ({ state, tag }) => {
  const selectedTags = state.state.filterResults;
  delete selectedTags[tag];
  state.setState({
    filterResults: selectedTags,
  });
  if (tag != "Date") {
    let newFilterResults = Object.assign({}, selectedTags);
    delete newFilterResults["Date"];
    delete newFilterResults["Date Published"];
    await state.props.setInsightData({
      filter: {
        ...newFilterResults,
      },
    });
  } else {
    await state.props.setInsightData({
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });
  }
};

export const handleFilterTagsformatter = (data) => {
  let searchPayload = { ...data };
  // TODO: multi date filter support need to be implemented.
  /** @type {{[key: string]: string[] | {from: string, to: string}}} */
  let filterList = {};
  const dateFilter = Array.isArray(searchPayload.dateFilter)
    ? searchPayload.dateFilter[0]
    : searchPayload.dateFilter;
  filterList =
    dateFilter && dateFilter?.value?.from != "" && dateFilter?.value?.to != ""
      ? {
          [dateFilter.key]: {
            from: dateFilter?.value?.from,
            to: dateFilter?.value?.to,
          },
        }
      : {};
  Object.keys(searchPayload.filter).map((item) => {
    filterList = { ...filterList, [item]: searchPayload.filter[item] };
  });
  return filterList;
};

export const handleDeleteTagHelperInsights = ({ state, removedItem, tag }) => {
  const tags = state.state.filterResults[tag].filter(
    (item) => item !== removedItem
  );
  state.props.setInsightData({
    filter: {
      ...state.props.createInsights.filter,
      [tag]: tags,
    },
  });
  state.setState({
    filterResults: { ...state.state.filterResults, [tag]: tags },
  });
  if (tags.length === 0) {
    state.handleDeleteMainTag(tag);
  }
};

// format insight redux data
export const formatInsightData = (updatingData) => {
  const formatFunctions = { breadCrumbs: setBreadCrumbData };
  let newData = {};
  Object.keys(updatingData).forEach((key) => {
    if (formatFunctions[key]) {
      newData = { ...newData, ...formatFunctions[key](updatingData[key]) };
    } else {
      newData = { ...newData, [key]: updatingData[key] };
    }
  });
  return newData;
};

const setBreadCrumbData = (value) => {
  if (value?.length === 0) return { breadCrumbData: [], searchWords: [] };

  const breadCrumbData =
    typeof value[0] === "string"
      ? value.map((item, index) => ({ value: index, label: item }))
      : value;
  const searchWords =
    typeof value[0] === "string" ? value : value.map((item) => item.label);
  return { breadCrumbData, searchWords };
};

export const createInsightsDataPayload = (data) => {
  const isAdvanceSearch = data.searchType === SEARCH_TYPE.ADVANCED_SEARCH;
  const advancedPayload = isAdvanceSearch
    ? createAdvanceSearchPayload({ data: data.advanceSearchPayloadData })
        ?.filtersListforData
    : generateAdvanceSearchPayload(data, true);

  const payload = {
    request: {
      // ...data?.requestPayload,
      searchWords: data.searchWords,
      searchCriterias: data.searchCriterias,
      filtersListforData: advancedPayload,
      // field_Value: {
      //   key: "",
      //   value: !isAdvanceSearch ? data.searchValue : "",
      // },
      // filtersforData: isAdvanceSearch ? [] : [{ ...data.filter }],
      // dateFilters: isAdvanceSearch
      //   ? [DEFAULT_DATE_FILTER_VALUE]
      //   : setDateFilterData(data.dateFilter),
    },
    chartName: data.chartName,
    parameters: data.payload,
  };
  return payload;
};

export const handleInsightApiCall = async ({ setlocalState, payload }) => {
  setlocalState({ dataLoading: true, data: [] });
  let { requestPayload } = generatePayload(payload);
  // let keysLength = payload.request?.filtersListforData ?? {};
  // let newFilterList =
  //   Object.keys(keysLength).length > 0
  //     ? createAdvanceSearchPayload({
  //         data: payload.request.filtersListforData ?? {},
  //       })
  //     : { filtersListforData: [] };
  // payload.request.filtersListforData = newFilterList["filtersListforData"];
  // TODO: dateFilter payload object to array - not checked because no response in filterFields API
  const { result } = await handleApi(
    { ...payload.request, ...requestPayload },
    payload.chartName
  );

  const formattedData =
    result.results && result?.results?.length !== 0
      ? chartDataFormatter({
          chartName: payload.chartName,
          data: result.results,
        })
      : [];

  setlocalState({
    data: formattedData,
    dataLoading: false,
    totalCount: result.uniqueDocuments,
  });
};
