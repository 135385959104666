import React from "react";
import PropTypes from "prop-types";

import ErrorImage from "../../../public/img/ERROR.svg";
import { ERROR, WELCOME_STATE } from "../../Routes/Home/constants";

import style from "./style.module.scss";

function ErrorState(props) {
  const {
    customHeight,
    type,
    content,
    showButton,
    handleClick,
    errorMessage,
    customMargin,
  } = props;
  let customStyle = {};

  if (customHeight) {
    customStyle = {
      height: customHeight + "px",
      marginTop: customMargin + "%",
    };
  }

  return (
    <div className={style.errorState} style={customStyle}>
      <div className={style.errorState__content}>
        <span className={style.errorImage}>
          {IMAGES[type] ? <img src={IMAGES[type]} /> : ""}
        </span>
        <h3>{errorMessage.title}</h3>
        <div className={style.stateContent}>
          <p>{errorMessage.description}</p>
        </div>
      </div>
    </div>
  );
}

export default ErrorState;

ErrorState.propTypes = {
  customHeight: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  handleClick: PropTypes.func,
};

ErrorState.defaultProps = {
  showButton: false,
};

export const TYPES = {
  WELCOME_IMAGE: "welcomeImage",
  DASHBOARD_WELCOME_IMAGE: "dashboardWelcomeImage",
  ERROR_IMAGE: "errorImage",
};

const IMAGES = {
  [TYPES.ERROR_IMAGE]: ErrorImage,
};

const CONTENTVALUE = {
  ERROR: ERROR,
};
