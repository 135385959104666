import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../../../../Dropdown';
import SVGIcons from '../../../../../SVGIcons';
import TooltipContainer from '../../../../../Tooltip';

import style from './style.module.scss';

function FilterSortButton(props) {
  const ref = useRef(null);
  const { handleSortingVisible } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleSortingVisible && handleSortingVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleSortingVisible]);

  return (
    <div ref={ref}>
      <SortDropdown {...props} />
    </div>
  );
}

export default FilterSortButton;

function SortDropdown(props) {
  return (
    <Dropdown
      customButton={
        <div className={style.sortTrigger}>
          <TooltipContainer title={'Change sort method'} placement='top'>
            <span onClick={() => {
              props.handleSortingVisible(!props.showSorting);
            }}>Sort By:</span>
          </TooltipContainer>
          {props.sortingType === 'Relevance' && (
            <a
              to={{}}
              className={`${style.linkButton} ${style[props.relevance.className]}`}
              onClick={props.handleRelevance}>
              <TooltipContainer
                title={props.relevance.className === 'activeDown' ? 'Descending' : 'Ascending'}
                placement='bottom'>
                <span>
                  <SVGIcons
                    type={props.relevance.className === 'activeDown' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'}
                  />
                </span>
              </TooltipContainer>
              <span>Relevance</span>
            </a>
          )}
          {props.sortingType === 'Title' && (
            <a to={{}} className={`${style.linkButton} ${style[props.title.className]} `} onClick={props.handleTitle}>
              <TooltipContainer
                title={props.title.className === 'activeDown' ? 'Descending' : 'Ascending'}
                placement='bottom'>
                <span>
                  <SVGIcons type={props.title.className === 'activeDown' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'} />
                </span>
              </TooltipContainer>
              <span>Title</span>
            </a>
          )}
          {props.sortingType === 'PublishDate' && (
            <a
              to={{}}
              className={`${style.linkButton} ${style[props.publishDate?.className]}`}
              onClick={props.handlePublish}>
              <TooltipContainer
                title={props.publishDate.className === 'activeDown' ? 'Descending' : 'Ascending'}
                placement='bottom'>
                <span>
                  <SVGIcons
                    type={props.publishDate.className === 'activeDown' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'}
                  />
                </span>
              </TooltipContainer>
              <span>Publish Date</span>
            </a>
          )}

          {props.sortingType === 'ConceptName' && (
            <a
              to={{}}
              className={`${style.linkButton} ${style[props.conceptName?.className]}`}
              onClick={props.handleConceptName}>
              <TooltipContainer
                title={props.conceptName.className === 'activeDown' ? 'Descending' : 'Ascending'}
                placement='bottom'>
                <span>
                  <SVGIcons
                    type={props.conceptName.className === 'activeDown' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'}
                  />
                </span>
              </TooltipContainer>
              <span>Concept Name</span>
            </a>
          )}

        


        </div>
      }
      addOnClass={props.showSorting}>
      <ul className={style.sortDroplist}>
        {props.isRelevanceVisible && (
          <li
            onClick={() => {
              props.handleChangeSortingType('Relevance');
              props.handleRelevance();
            }}>
            Relevance
          </li>
        )}
        <li
          onClick={() => {
            props.handleChangeSortingType('Title');
            props.handleTitle();
          }}>
          Title
        </li>
        <li
          onClick={() => {
            props.handleChangeSortingType('PublishDate');
            props.handlePublish();
          }}>
          Publish Date
        </li>

        <li
          onClick={() => {
            props.handleChangeSortingType('ConceptName');
            props.handleConceptName();
          }}>
          Concept Name
        </li>

       
      </ul>
    </Dropdown>
  );
}
