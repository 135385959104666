import { connect } from "react-redux";
import Search from "./SearchContent";
import {
  addTagsResults,
  deleteSavedResults,
  setSearchData,
} from "../../../../Redux/Actions";

function mapStateToProps(state) {
  return {
    ...state.Search,
    ...state.Tags,
    dateFormat: state.Settings.format.dateFormat,
    metaDataList: state.FilterFields?.allFieldsWithMetadata
      ?.filter((item) => item?.dataType === 5)
      ?.map((item) => item?.name),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addTagsHandler: (params) => dispatch(addTagsResults(params)),
    handleDeleteSaved: (params, callBack) =>
      dispatch(deleteSavedResults(params, callBack)),
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
