import React from "react";
import { Modal } from "antd";

import "./style.scss";

/**
 * @param {import('antd').ModalProps & {isNonMaskable?: boolean, isNotClosable?: boolean, isHideOkButton?: boolean,
 * disableOkButton?: boolean, isHideCancelButton?: boolean}} props
 */
const ModalWindow = (props) => {
  return (
    <Modal
      cancelText={props.cancelText}
      centered
      footer={props.footer}
      title={props.title}
      width={props.width}
      open={props.open}
      onCancel={props.onCancel}
      mask={props.mask ? props.mask : false}
      maskStyle={{ backgroundColor: "black" }}
      onOk={props.onOk}
      okText={props.confirmLoading ? " " : props.okText}
      className={props.className || ""}
      maskClosable={!props.isNonMaskable}
      closable={!props.isNotClosable}
      okButtonProps={{
        style: { display: props.isHideOkButton ? "none" : "" },
        disabled: props.disableOkButton,
      }}
      cancelButtonProps={{
        style: { display: props.isHideCancelButton ? "none" : "" },
      }}
      confirmLoading={props.confirmLoading}
      bodyStyle={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      {props.children}
    </Modal>
  );
};
export default ModalWindow;

// ModalWindow.propTypes = {
//   cancelText: PropTypes.string,
//   footer: PropTypes.node,
//   title: PropTypes.node,
//   width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
//   visible: PropTypes.bool,
//   onCancel: PropTypes.any,
//   mask: PropTypes.any,
//   onOk: PropTypes.any,
//   confirmLoading: PropTypes.bool,
//   okText: PropTypes.string,
//   className: PropTypes.any,
//   isNonMaskable: PropTypes.any,
//   isNotClosable: PropTypes.any,
//   isHideOkButton: PropTypes.any,
//   disableOkButton: PropTypes.any,
//   isHideCancelButton: PropTypes.any,
//   children: PropTypes.any,
// };
