import React from "react";
import PropTypes from "prop-types";

import WelcomeImage from "../../../public/img/welcome-illustration.svg";
import DashboardWelcomeImage from "../../../public/img/SalyWelcome .svg";
import ErrorImage from "../../../public/img/ERROR.svg";
import ButtonElement from "../ButtonElement";
import {
  ADD_INSIGHT,
  ARTICLES_EMPTY_STATE_MESSAGE,
  CHART_NO_DATA_FOUND,
  CREATE_INSIGHT,
  DASHBOARD_EMPTY_STATE_TAB,
  DASHBOARD_INFO_TABLE_EMPTY_STATE_TAB,
  DASHBOARD_INITIAL_STATE_TAB,
  ERROR,
  INSIGHT_DELETED_MESSAGE,
  SEARCH_NO_DATA_FOUND,
  WELCOME_STATE,
} from "../../Routes/Home/constants";

import style from "./style.module.scss";

function EmptyState(props) {
  const {
    customHeight,
    type,
    content,
    showButton = false,
    handleClick = () => {},
    customMargin,
  } = props;
  let customStyle = {};

  if (customHeight) {
    customStyle = {
      minHeight: customHeight + "px",
      marginTop: customMargin + "px",
    };
  }

  return (
    <div className={style.emptyState} style={customStyle}>
      <div className={style.emptyState__content}>
        <span className={style.emptyImage}>
          {IMAGES[type] ? <img src={IMAGES[type]} /> : ""}
        </span>
        <h3>
          {CONTENTVALUE[content]
            ? CONTENTVALUE[content].NOTHING_DISPLAY_MESSAGE
            : WELCOME_STATE.NOTHING_DISPLAY_MESSAGE}
        </h3>
        <div className={style.stateContent}>
          <p>
            {CONTENTVALUE[content]
              ? CONTENTVALUE[content].INFO_ONE
              : WELCOME_STATE.INFO_ONE}
          </p>
          <p>
            {CONTENTVALUE[content]
              ? CONTENTVALUE[content].INFO_TWO
              : WELCOME_STATE.INFO_TWO}
          </p>
        </div>
      </div>
      {showButton && <EmptyStateButtons handleClick={handleClick} />}
      <div className={style.emptyState__external}>{props.children}</div>
    </div>
  );
}

export default EmptyState;

EmptyState.propTypes = {
  customHeight: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  handleClick: PropTypes.func,
};

EmptyState.defaultProps = {
  showButton: false,
};

export const TYPES = {
  WELCOME_IMAGE: "welcomeImage",
  DASHBOARD_WELCOME_IMAGE: "dashboardWelcomeImage",
  ERROR_IMAGE: "errorImage",
};

const IMAGES = {
  [TYPES.WELCOME_IMAGE]: WelcomeImage,
  [TYPES.DASHBOARD_WELCOME_IMAGE]: DashboardWelcomeImage,
  [TYPES.ERROR_IMAGE]: ErrorImage,
};

const CONTENTVALUE = {
  WELCOME_STATE: WELCOME_STATE,
  SEARCH_NO_DATA_FOUND: SEARCH_NO_DATA_FOUND,
  ADD_INSIGHT: ADD_INSIGHT,
  CREATE_INSIGHT: CREATE_INSIGHT,
  CHART_DATA: CHART_NO_DATA_FOUND,
  DASHBOARD_INITIAL_STATE_TAB: DASHBOARD_INITIAL_STATE_TAB,
  DASHBOARD_EMPTY_STATE_TAB: DASHBOARD_EMPTY_STATE_TAB,
  DASHBOARD_INFO_TABLE_EMPTY_STATE_TAB: DASHBOARD_INFO_TABLE_EMPTY_STATE_TAB,
  ARTICLES_EMPTY_STATE_MESSAGE: ARTICLES_EMPTY_STATE_MESSAGE,
  ERROR: ERROR,
  INSIGHT_DELETED: INSIGHT_DELETED_MESSAGE,
};

function EmptyStateButtons({ handleClick }) {
  return (
    <div className={style.emptyState__button}>
      <ButtonElement
        onClick={handleClick}
        type="primary"
        icon={"SVG-addinsight"}
        children={WELCOME_STATE.ADD_INSIGHT}
      />
    </div>
  );
}
