import {KEYS} from '../../../../dataKeys';

export function sendEmailStarted(state, payload) {
  return {
    ...state,
    sendEmailData: {
      loading: true,
      error: false,
    },
  };
}

export function sendEmailSuccess(state, payload) {
  return {
    ...state,
    sendEmailData: {
      loading: false,
      error: false,
    },
  };
}

export function sendEmailFailed(state, payload) {
  return {
    ...state,
    sendEmailData: {
      loading: false,
      error: payload,
    },
  };
}
