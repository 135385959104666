import { connect } from "react-redux";

import {
  clearInsightData,
  deleteInsightData,
  FilterFields,
  getInsightData,
  InsightsDataset,
  InsightsMappingPayload,
  setInsightData,
} from "../../Redux/Actions/";
import Insights from "./insights";

function mapStateToProps(state) {
  return {
    InsightsPage: state.InsightsPage,
    createInsights: state.CreateInsights,
    ...state.Search,
    FilterFields: state.FilterFields,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAvailableFields: (params) => dispatch(InsightsMappingPayload(params)),
    getInsightData: (params) => dispatch(getInsightData(params)),
    handleDeleteInsightData: (params, callback) =>
      dispatch(deleteInsightData(params, callback)),
    clearInsightData: () => dispatch(clearInsightData()),
    getInsightsDataSet: (params) => dispatch(InsightsDataset(params)),
    loadFilterFields: () => dispatch(FilterFields()),
    setInsightData: (params) => dispatch(setInsightData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
