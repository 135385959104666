/* eslint-disable */
import {
  IMPORT_INSIGHTS_FAILED,
  IMPORT_INSIGHTS_SUCCESS,
  IMPORT_INSIGHTS_STARTED,
  GET_DASHBOARD_START,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  DELETE_DASHBOARD,
  SAVE_DASHBOARD_START,
  SAVE_DASHBOARD_SUCCESS,
  SAVE_DASHBOARD_ERROR,
  EDIT_DASHBOARD_START,
  EDIT_DASHBOARD_SUCCESS,
  EDIT_DASHBOARD_ERROR,
  VIEW_DASHBOARD_START,
  VIEW_DASHBOARD_ERROR,
  CLEAR_DASHBOARD_DATA,
  SET_DASHBOARD_FILTERS,
} from '../../Redux.constants';

import {
  clearDashboard,
  deleteDashboard,
  editDashboardError,
  editDashboardStart,
  editDashboardSuccess,
  getDashboardError,
  getDashboardStarted,
  getDashboardSuccess,
  importInsightsFailed,
  importInsightsStarted,
  importInsightsSuccess,
  saveDashboardError,
  saveDashboardStart,
  saveDashboardSuccess,
  setDashboardFilter,
  viewDashboardError,
  viewDashboardStart,
} from './Helper';
import {INITIAL_STATE} from './initialState';

export default function DashboardPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IMPORT_INSIGHTS_SUCCESS:
      return importInsightsSuccess(state, action.payload);
    case IMPORT_INSIGHTS_STARTED:
      return importInsightsStarted(state, action.payload);
    case IMPORT_INSIGHTS_FAILED:
      return importInsightsFailed(state, action.payload);
    case GET_DASHBOARD_START:
      return getDashboardStarted(state, action.payload);
    case GET_DASHBOARD_SUCCESS:
      return getDashboardSuccess(state, action.payload);
    case GET_DASHBOARD_ERROR:
      return getDashboardError(state, action.payload);
    case DELETE_DASHBOARD:
      return deleteDashboard(state, action.payload);
    case SAVE_DASHBOARD_START:
      return saveDashboardStart(state, action.payload);
    case SAVE_DASHBOARD_SUCCESS:
      return saveDashboardSuccess(state, action.payload);
    case SAVE_DASHBOARD_ERROR:
      return saveDashboardError(state, action.payload);
    case EDIT_DASHBOARD_START:
      return editDashboardStart(state, action.payload);
    case EDIT_DASHBOARD_SUCCESS:
      return editDashboardSuccess(state, action.payload);
    case EDIT_DASHBOARD_ERROR:
      return editDashboardError(state, action.payload);
    case VIEW_DASHBOARD_START:
      return viewDashboardStart(state, action.payload);
    case VIEW_DASHBOARD_ERROR:
      return viewDashboardError(state, action.payload);
    case CLEAR_DASHBOARD_DATA:
      return clearDashboard(state, action.payload);
      case SET_DASHBOARD_FILTERS:
      return setDashboardFilter(state, action.payload);
    default:
      return {...state};
  }
}
