import { notify } from "../../../../Components/Notification";
import { errorHandler } from "../../../../utils/ErrorHandler";

export function addTagStart(state, payload) {
  return {
    ...state,
    tag: {
      ...state.tag,
      loading: true,
    },
  };
}
export function addTagFailed(state, payload) {
  errorHandler(payload.error);
  return {
    ...state,
    tag: {
      ...state.tag,
      loading: false,
      error: true,
      visible: false,
      modal: {
        ...state.tag.modal,
        userTagsLast: payload.user_tags,
        uidsLast: payload.uids,
      }
    },
  };
}
export function addTagSuccess(state, payload) {
  notify.success(`Tags updated for ${payload.uids.length || "No"} article(s)`, true);
  return {
    ...state,
    tag: {
      ...state.tag,
      loading: false,
      error: false,
      visible: false,
    },
  };
}
export function addTagModalToggle(state, payload) {
  return {
    ...state,
    tag: {
      ...state.tag,
      modal: {
        ...state.tag.modal,
        visible: payload.visible,
        uids: payload.uids,
        allArticleList: payload.allArticleList,
        setSearchLocalData: payload.setSearchLocalData,
        userTags: payload.userTags,
        append: payload.append,
        clearSelection: payload.clearSelection,
      }
    },
  };
}
