import { ROUTES } from "Constants/Routes/Routes.constants";
import { COMMUNICATION_KEYS } from "constants";
import { logoutHelper } from "./handlers";
import { getTabNameFromSearchCriteria } from "Components/HeaderSection/HomeHeader/helper";
export const onMessageHandler = ({
  event,
  location,
  history,
  setSearchData,
  setState,
  handleStayActive,
}) => {
  const { type, payload, from, isLogout } = event.data;
  console.log(new Date().toLocaleTimeString(), "Article - Communication <<<<<<<<", { type, payload, from });
  if (
    location.pathname == ROUTES.HOME &&
    type === COMMUNICATION_KEYS.START_FROM_INSIGHT
  ) {
    history.push({
      pathame: ROUTES.HOME,
      search: `?page=homePage`,
    });
  }
  const queryParams = new URLSearchParams(window.location.search);
  const queryterm = queryParams.get("page");
  if (queryterm == "homePage") {
    switch (type) {
      case COMMUNICATION_KEYS.START_FROM_INSIGHT:
        setState({ insightStarted: true });
        break;
        
      case COMMUNICATION_KEYS.CHILD_TERMINATE:
        //Check if logout from child tab and do the logout actions
        if (isLogout) {
          logoutHelper(setSearchData);
        } else {
          history.push(ROUTES.HOME);
          setSearchData({ searchWords: [], buckets: [] });
          setState({ breadCrumbData: [], insightStarted: false});
        }
        return;
      case COMMUNICATION_KEYS.CHILD_CHOOSE_STAY:
        return handleStayActive();
      case COMMUNICATION_KEYS.CHANGE:
        if (from === "insight") {
          setState({
            breadCrumbData: payload.breadCrumbData,
            tab: getTabNameFromSearchCriteria(payload?.searchCriterias),
          });
          setSearchData({
            isFromInsights: true,
            searchType: payload.searchType,
            searchWords: payload.searchWords,
            filter: payload.filter,
            dateFilter: payload?.dateFilter,
            value: payload?.searchValue,
            searchCriterias: payload?.searchCriterias,
            advanceSearchPayloadData: payload.advanceSearchPayload,
            buckets: payload.buckets,
          });
        }
    }
  }
};

export const postMessageHandler = ({ channel, data }) => {
  if (data.isFromInsights) return;

  console.log(new Date().toLocaleTimeString(), "Article - Communication >>>>>>>>", data);
  channel.postMessage({
    type: COMMUNICATION_KEYS.CHANGE,
    from: "article",
    payload: {
      searchValue: data.value,
      searchType: data.searchType,
      dateFilter: Array.isArray(data.dateFilter)
        ? data.dateFilter[0]
        : data.dateFilter,
      filter: data.filter,
      breadCrumbs: data.searchWords,
      advanceSearchPayloadData: data.advanceSearchPayloadData,
      searchCriterias: data.searchCriterias,
    }
  });
}


