export const CHART_CONSTANTS = {
  DATASET_OPTIONS: [
    { value: "BAR_CHART", label: "Bar chart" },
    { value: "KEY_PHRASE_CLOUD", label: "Key phrase cloud" },
    { value: "HEAT_MAP", label: "Heat map" },
  ],
  SELECT_CHART_PLACEHOLDER: "Select chart type",
};

export const HORIZONTAL_LABEL = "Data on horizontal axis";

export const WORD_CLOUD_MINIMUM_OCCURANCE_LABEL = "Minimum word occurrence";
export const WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL = "Maximum word count";
export const WORD_CLOUD_FIELD_LABEL = "Datafield";
export const WORD_CLOUD_PERMANENT_EXCLUSION_LIST = "Permanent exclusion list";
export const WORD_CLOUD_TEMPORARY_EXCLUSION_LIST = "Temporary exclusion list";
export const WORD_CLOUD_INFO_LABEL =
  "Maximum words allowed in word cloud is 300";

export const TREE_MAP_PRIMARY_FIELD = "Primary data";
export const TREE_MAP_PRIMARY_FIELD_AGGREGATION_LABEL =
  "Aggregation for primary data";
export const TREE_MAP_SECONDARY_FIELD = "Secondary data";
export const TREE_MAP_SECONDARY_FIELD_AGGREGATION_LABEL =
  "Aggregation for secondary data";
export const TREE_MAP_TERITIARY_FIELD = "Tertiary data";

export const STREAM_CHART_HORIZONTAL_FIELD_LABEL = "Data on horizontal axis";
export const STREAM_CHART_PRIMARY_DATA_SERIES_LABEL =
  "Primary data series to plot";
export const STREAM_CHART_PRIMARY_FIELD_AGGREGATION_LABEL =
  "Aggregation for primary data series";
export const STREAM_CHART_HORIZONTAL_FIELD_HINT =
  "Only date-time fields are accepted";

export const HEAT_MAP_HORIZONTAL_AXIS_LABEL = "Data on horizontal axis";
export const VERTICAL_AXIS_LABEL = "Data on vertical axis";
export const HEAT_MAP_DATA_SERIES_TO_PLOT = "Data series to plot";

export const CHART_FIELDS = {
  BAR_CHART: [
    { label: HORIZONTAL_LABEL, draggable: true },
    // 'Data on vertical axis',
    //{label: 'Breakdown by', draggable: true},
  ],
  LINE_CHART: [
    { label: HORIZONTAL_LABEL, draggable: true },
    //'Data on vertical axis',
    // {label: 'Breakdown by', draggable: true},
  ],
  KEY_PHRASE_CLOUD: [
    // {label: WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL, draggable: false, editable: true},
    { label: WORD_CLOUD_INFO_LABEL, info: true, draggable: false },
    // {label: WORD_CLOUD_PERMANENT_EXCLUSION_LIST, draggable: false, editable: true, type: 'tag', isPErmanent: true},
    // {label: WORD_CLOUD_TEMPORARY_EXCLUSION_LIST, draggable: false, editable: true, type: 'tag', isPErmanent: false},

    // {label: WORD_CLOUD_MINIMUM_OCCURANCE_LABEL, draggable: false, editable: true},
    // {label: WORD_CLOUD_FIELD_LABEL, draggable: true},
  ],
  COLLAPSIBLE_TREE_MAP: [
    { label: TREE_MAP_PRIMARY_FIELD, draggable: true },
    // {label: TREE_MAP_PRIMARY_FIELD_AGGREGATION_LABEL, draggable: false, editable: false},
    { label: TREE_MAP_SECONDARY_FIELD, draggable: true },
    // {label: TREE_MAP_SECONDARY_FIELD_AGGREGATION_LABEL, draggable: false, editable: false},
    { label: TREE_MAP_TERITIARY_FIELD, draggable: true },
  ],
  STREAM_CHART: [
    {
      label: HORIZONTAL_LABEL,
      draggable: true,
      hint: STREAM_CHART_HORIZONTAL_FIELD_HINT,
    },
    { label: STREAM_CHART_PRIMARY_DATA_SERIES_LABEL, draggable: true },
    // {label: STREAM_CHART_PRIMARY_FIELD_AGGREGATION_LABEL, draggable: false, editable: false},
    // {label: 'Secondary data series to plot', draggable: true},
  ],
  HEAT_MAP: [
    { label: HORIZONTAL_LABEL, draggable: true },
    // {label: 'Aggregation for horizontal axis', draggable: false},
    { label: VERTICAL_AXIS_LABEL, draggable: true },
    // {label: HEAT_MAP_DATA_SERIES_TO_PLOT, draggable: false, editable: false},
  ],
};

export const SAVE_INSIGHT_ALREADY_EXISTS = "The insight name already exists";
export const EMPTY_SAVE_NAME = "Please save insight with a name";
