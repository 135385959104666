import React from "react";

import ModalWindow from "../../../../../Components/ModalWindow";
import SVGIcons from "../../../../../Components/SVGIcons";
import TooltipContainer from "../../../../../Components/Tooltip";
import { ROLE_SETTINGS_CONSTANTS } from "../RoleSettings.constants";

import style from "./style.module.scss";

function ViewUserInfoWindow(props) {
  return (
    <>
      <ModalWindow
        title={false}
        isNotClosable={true}
        okText={ROLE_SETTINGS_CONSTANTS.CLOSE_TEXT}
        onOk={(e) => props.viewModalHandler(e, "modalOk")}
        isHideCancelButton={true}
        open={props.state.viewUserModal}
      >
        <div className={style.viewUserblock}>
          <div className={style.viewUserblock__header}>
            <h3>View User</h3>
            <TooltipContainer title={"Edit User"} placement="bottom">
              <span
                className={style.userEdit}
                onClick={(e) => props.handleEdit(e, props.record)}
              >
                <SVGIcons type={"SVG-edit"} />
              </span>
            </TooltipContainer>
          </div>
          <ul className={style.viewUserblock__list}>
            <li>
              <span>First Name</span>
              <span>{props.state.editUserRecord.firstname}</span>
            </li>
            <li>
              <span>Last Name</span>
              <span> {props.state.editUserRecord.lastname}</span>
            </li>
            <li>
              <span>Email</span>
              <span> {props.state.editUserRecord.emailid}</span>
            </li>
            <li>
              <span>Role</span>
              <span>
                {" "}
                {props.state.editUserRecord.userProfile == 1
                  ? " Insight Admin"
                  : "Insight Analyst"}
              </span>
            </li>
            <li>
              <span>Created at</span>
              <span>
                {" "}
                {props.state.editUserRecord.created &&
                  props.state.editUserRecord.created.split(" ")[0]}
              </span>
            </li>
            <li>
              <span>Updated at</span>
              <span>
                {props.state.editUserRecord.lastActive &&
                  props.state.editUserRecord.lastActive.split(" ")[0]}
              </span>
            </li>
          </ul>
        </div>
      </ModalWindow>
    </>
  );
}

export default ViewUserInfoWindow;
