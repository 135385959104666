import CustomScrollbar from "Components/CustomScrollbar";
import style from "./style.module.scss";
import ErrorState from "Components/ErrorState";
import FilterOptions from "../../FilterSection/Children/FilterOptions";
import { useEffect, useState } from "react";
import { filterDataCreate } from "Routes/Home/Helper";
import { DATE_FILTER_KEY } from "Routes/Home/constants";
import { FilterFieldValues } from "Redux/Actions";
import { connect } from "react-redux";
import { MyFilterOptions } from "../MyFilterOptions/MyFilterOptions";


const mapStateToProps = state=>{
    //debugger;
    return {
        settings: state.FilterFields.settings,
        ...state.search,
        filterLoad: state.FilterFields.loading,
        ...state.Search,
        createInsights: state.CreateInsights,
        dashboard: state.Dashboard,
    };
};

const mapDispatchToProps = dispatch=>{
    return {
        loadFilterFieldValues: payload =>{
            dispatch(FilterFieldValues(payload));
        }
    };
};


const MyFilterSectionComponent = props=>{

    const {filter, dateFilter, error, errorMessage, handleChangeCheckBox, settings } = props;

    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY');

    useEffect(()=>{
        const newDateFormat = (settings 
            && settings[DATE_FILTER_KEY] 
            && settings[DATE_FILTER_KEY].format) 
        ?  settings[DATE_FILTER_KEY].format : 'DD/MM/YYYY'; 

        setDateFormat(newDateFormat);
    }, [settings[DATE_FILTER_KEY]]);

    const filterResults = filterDataCreate({
        filtersForData: filter,
        dateFilters: dateFilter,
    });
    
    return(<div className={style.filterBlock}>
        <div className={style.filterBlock__options}>
            {error ? 
            <ErrorState content="ERROR" errorMessage={errorMessage} /> : 
            <div className={style.filterContainer}>
                <CustomScrollbar className="filter-scroller">

                    <MyFilterOptions 
                        loadFilterFieldValues={props.loadFilterFieldValues}
                        handleChangeDate={props.handleChangeDate}
                        search={props.search}
                        filterLoad={props.filterLoad}
                        settings={props.settings}
                        searchTab={props.searchTab}
                        dateFormat={dateFormat}
                        handleCheckbox={handleChangeCheckBox}
                        handleSelectAll={props.handleSelectAll}
                        handleClearAll={props.handleClearAll}
                        filterResults={filterResults}
                        createInsights={props.createInsights}
                        numberOfDays={props.numberOfDays}
                        handleChangefilterDateType={props.handleChangefilterDateType}
                        filterDateType={props.filterDateType}
                    />
                    {/* <FilterOptions 
                        filterLoad={filterLoad}

                        dateFormat={dateFormat} 
                        handleCheckbox={handleChangeCheckBox} 
                        filterResults={filterResults}
                    /> */}
                </CustomScrollbar>
            </div>}
        </div>
    </div>);
};


export const MyFilterSection = connect(mapStateToProps, mapDispatchToProps)(MyFilterSectionComponent);