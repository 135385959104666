import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const resetPassword = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.patch('/userpwd', {...payload});
};

export const SendEmail = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.post('/SendMail', null, {
    params: payload,
  });
};

export const verifyOTP = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });

  const api = instance.api;
  return api.post('/verifyOTP', {...payload});
};
