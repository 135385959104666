import React from "react";
import env from "react-dotenv";

import {
  COMMUNICATION_KEYS,
  DATA_FIELDS,
  DEFAULT_DATE_FILTER_TYPE,
  DEFAULT_DATE_FILTER_VALUE,
  DEFAULT_NUMBER_OF_DAYS,
  DEFAULT_VIEW_TYPE,
  SEARCH_TYPE,
} from "../../constants";
import { ALL_ARTICLE_TAB } from "../../Constants/Home/Home.constants";
import { DATE_FILTER_KEY } from "./constants";
import {
  formatBreadCrumbData,
  handleDeleteMainTagHelper,
  handleDeleteTagHelper,
  homeMountHelper,
  postMessageHandler,
  searchApiHandler,
} from "./Helper";
import {
  formatSearchPayload,
  getFilteredValues,
  handleDateFilterSearchHelper,
  handleDateFilterSearchHelperUntilNow,
  handleExportHelper,
  handleFilterReformatter,
  handleLastNDaysChangeHelper,
  handleLoadHelper,
  handleModalHelper,
  handleSaveHelper,
  handleUntilNowChangeHelper,
} from "./Helper";
import { template } from "./template";
import { ROUTES } from "../../Constants/Routes/Routes.constants";
import { checkDataChange, formatDate } from "utils/commonUtils";
import { getTabNameFromSearchCriteria } from "Components/HeaderSection/HomeHeader/helper";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.channel = new BroadcastChannel("selectedList");
  }

  state = {
    filterResults: {},
    savedSearches: [],
    listViewType: DEFAULT_VIEW_TYPE,
    filterDateType: DEFAULT_DATE_FILTER_TYPE,
    numberOfDays: DEFAULT_NUMBER_OF_DAYS,
    showSaveModal: false,
    showLoadMenu: false,
    tab: ALL_ARTICLE_TAB,
    expandAllCard: false,
    showViewInNewTab: false,
    breadCrumbData: [],
    exportLoading: false,
    notInteractive: false,
    isInsightModalOpen: false,
    data: [],
    loading: false,
    totalCount: 0,
  };
  window = window;

  setLocalState = (data) =>
    this.setState((prevState) => ({ ...prevState, ...data }));

  componentDidMount = () =>
    homeMountHelper({
      setState: this.setLocalState,
      channel: this.channel,
      handleStayActive: this.props.mainFrameState.handleStayActive,
      history: this.props.history,
      location: this.props.location,
      search: this.props.search,
      setSearchData: this.props.setSearchData,
      FilterFields: this.props.FilterFields,
      loadFilterFields: this.props.loadFilterFields,
      handlePostMessage: this.handlePostMessage,
      handleGetDashboardPreview: this.props.handleGetDashboardPreview,
    });
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.search.pageSize !== prevProps.search.pageSize &&
      this.props.search.pageSize !== 4
    )
      this.setState({ expandAllCard: false });

    if (this.state.loading !== prevState.loading)
      this.props.mainFrameState.handleProjectSelectDisable(this.state.loading);
    if (this.props.search.searchWords !== prevProps.search.searchWords) {
      this.setState({
        breadCrumbData: this.props.search.searchWords.map((item, index) => ({
          label: item,
          value: index,
        })),
      });
    }
    if (this.props.search.searchCriterias !== prevProps.search.searchCriterias)
      this.setState({
        tab: getTabNameFromSearchCriteria(this.props.search.searchCriterias),
      });
    if (this.props.CurrentProject?.name != prevProps.CurrentProject?.name) {
      this.props.ClearFilterFieldData();
      this.setState({ filterResults: {}, numberOfDays: 10 });
    }

    if (
      !this.props.search.error &&
      checkDataChange({
        currentData: this.props.search,
        previousData: prevProps.search,
        fields: DATA_FIELDS.ARTICLE,
      })
    ) {
      this.handlePostMessage();
      searchApiHandler({
        payload: formatSearchPayload(this.props.search),
        setState: this.setLocalState,
        setSearchData: this.props.setSearchData,
        projectSwitching: this.props.mainFrameState.state
          .showProjectSwitchingModal,
      });
    }
    if (
      prevProps.search.searchType !== this.props.search.searchType &&
      this.props?.search?.searchType === SEARCH_TYPE.ADVANCED_SEARCH
    ) {
      this.setState({
        filterResults: {},
        numberOfDays: 10,
        expandAllCard: false,
      });
    }

    Object.keys(this.state.filterResults).map((item) => {
      if (this.state.filterResults[item].length === 0) {
        this.handleDeleteMainTag(item);
      }
    });
    this.handleCheckDate();

    if (prevProps.search.value !== this.props.search.value)
      this.setState({ expandAllCard: false });

    if (
      checkDataChange({
        currentData: this.props.search,
        previousData: prevProps.search,
        fields: ["filter", "dateFilter"],
      }) ||
      ((Object.keys(this.props.search.filter).length > 0 ||
        this.props.search?.dateFilter?.value?.from) &&
        Object.keys(this.state?.filterResults)?.length === 0)
    ) {
      this.setState({
        filterResults: handleFilterReformatter(this.props.search),
      });
    }
  }
  handlePostMessage = () =>
    postMessageHandler({ channel: this.channel, data: this.props.search });

  handleChangeCheckBox = ({ name, selectedValues, items }) => {
    const newList = this.state.filterResults[name] ?? [];
    const appliedFilter = getFilteredValues(newList, items);
    const appendedList = [...appliedFilter, ...selectedValues];

    this.props.setSearchData({
      filter: {
        ...this.props.search.filter,
        [name]: appendedList,
      },
    });
    this.setState({
      filterResults: { ...this.state.filterResults, [name]: appendedList },
    });
  };

  handleChangefilterDateType = (type) => {
    this.setState({ filterDateType: type });
    if (
      this.state.filterResults[DATE_FILTER_KEY]?.from &&
      this.state.filterResults[DATE_FILTER_KEY]?.to
    ) {
      handleDeleteMainTagHelper({
        setSearchData: this.props.setSearchData,
        state: this.state,
        setState: this.setLocalState,
        tag: DATE_FILTER_KEY,
      });
    }
  };

  handleCheckDate = () => {
    const dateFilter = this.state.filterResults[DATE_FILTER_KEY];
    if (dateFilter != undefined && Object.keys(dateFilter).length == 0) {
      this.handleDeleteMainTag(DATE_FILTER_KEY);
    }
  };
  handleDeleteTags = async (removedItem, tag) =>
    handleDeleteTagHelper({
      state: this.state,
      setState: this.setLocalState,
      search: this.props.search,
      setSearchData: this.props.setSearchData,
      removedItem,
      tag,
    });
  handleDeleteMainTag = async (tag) => {
    if (tag.toLowerCase().includes("date"))
      this.setState({
        filterResults: {},
        filterDateType: 1,
        numberOfDays: DEFAULT_NUMBER_OF_DAYS,
      });
    handleDeleteMainTagHelper({
      state: this.state,
      setSearchData: this.props.setSearchData,
      setState: this.setLocalState,
      tag,
    });
  };
  /**
   * For clear all tag elements in filter section
   */
  handleDeleteAllTags = async () => {
    this.props.setSearchData({
      filter: {},
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });
    this.setState({ filterResults: {}, numberOfDays: 10 });
  };

  setDateSearchValue = (e) => {
    if (e == null) {
      this.props.setSearchData({
        dateFilter: {
          key: DATE_FILTER_KEY,
          value: { to: "", from: "" },
        },
      });
    } else {
      this.props.setSearchData({
        dateFilter: {
          key: DATE_FILTER_KEY,
          value: {
            ...this.props.search.dateFilter.value,
            to: formatDate(e.to),
            from: formatDate(e.from),
          },
        },
      });
    }
  };
  handleChangeDate = ({ e, name, type, numberOfDays }) => {
    if (type == "lastNDays") {
      this.setState({ filterDateType: 3, numberOfDays });
      this.handleLastNDaysChange(e, name);
      handleDateFilterSearchHelper({
        setSearchData: this.props.setSearchData,
        e,
      });
    } else if (type == "untilNow") {
      this.setState({ filterDateType: 2 });
      this.handleUntilNowChange(e, name);
      handleDateFilterSearchHelperUntilNow({
        setSearchData: this.props.setSearchData,
        e,
      });
    } else {
      this.setDateSearchValue(e);
      if (e == null) {
        handleDeleteMainTagHelper({
          state: this.state,
          setState: this.setLocalState,
          setSearchData: this.props.setSearchData,
          tag: DATE_FILTER_KEY,
        });
      } else {
        this.setState({
          filterResults: {
            ...this.state.filterResults,
            [DATE_FILTER_KEY]: {
              from: formatDate(e?.from),
              to: formatDate(e?.to),
            },
          },
        });
      }
    }
  };
  handleLastNDaysChange = (value, name) =>
    handleLastNDaysChangeHelper({ state: this, value });
  handleUntilNowChange = (value, name) =>
    handleUntilNowChangeHelper({ state: this, value });
  handleLoad = (value) => handleLoadHelper(value, this);
  handleExport = () => {
    this.setState({ exportLoading: true });
    handleExportHelper(this.props.search, () =>
      this.setState({ exportLoading: false })
    );
  };
  handleSave = () => handleSaveHelper(this);
  handleModal = () => handleModalHelper(this);

  /**
   * Function used for clearing date filters from {TagFilter} component
   * Will make the following changes to the local state
   * - clear the specified date filter,
   * - date-filter-type set to first one
   * - numberOfDays of 3rd date filter set to it's default value
   * and trigger search function
   * @param {string} tag
   */
  handleDeleteDate = (tag) => {
    let newTag = this.state.filterResults[DATE_FILTER_KEY];
    delete newTag[tag];
    this.setState({
      filterResults: { ...this.state.filterResults, [DATE_FILTER_KEY]: newTag },
      filterDateType: 1,
      numberOfDays: DEFAULT_NUMBER_OF_DAYS,
    });
    this.props.setSearchData({
      dateFilter: {
        key: DATE_FILTER_KEY,
        value: { ...this.props.search.dateFilter.value, [tag]: "" },
      },
    });
  };
  setFilterOnSavedResult = (data) => {
    this.props.setSearchData({
      filter: data.searchHistory.filtersforData[0],
      dateFilter: data.searchHistory.dateFilters,
    });
  };

  handleViewListClassName = () => {
    switch (this.state.listViewType) {
      case "":
        return "";
      case "gridTwo":
        return "gridTwo";
      case "gridThree":
        return "gridThree";
    }
  };
  handleListView = (value) => {
    const pageSize = value === "gridTwo" ? 4 : value === "gridThree" ? 12 : 50;
    const pageNumber = 1;
    this.props.setSearchData({ pageSize, pageNumber });
    this.setState({ listViewType: value });
    if (value == "gridTwo") this.setState({ expandAllCard: false });
  };

  componentWillUnmount() {
    this.props.setSearchData({ clear: true });
    this.channel.postMessage({ type: COMMUNICATION_KEYS.PARENT_TERMINATE });
    this.channel.close();
  }

  onTabChange = (tab) => {
    if (this.state.loading) return;
    this.setState({ expandAllCard: false });
    const fileSystemSource = env.ARTICLE_EXTERNAL_REPORT_KEY?.split(
      ","
    )?.filter((item) => item && item);
    switch (tab) {
      case "News":
        this.handleSearchOnTabChange({
          searchCriteria: {
            include: false,
            field: "Source Type",
            values: fileSystemSource,
          },
          searchTab: tab,
        });
        return;
      case "External Reports":
        this.handleSearchOnTabChange({
          searchCriteria: {
            include: true,
            field: "Source Type",
            values: fileSystemSource,
          },
          searchTab: tab,
        });
        return;
      default:
        this.handleSearchOnTabChange({ searchTab: tab });
        return;
    }
  };

  /** @param {{include: boolean, field: string, values: string[]}} [searchCriteria] */
  handleSearchOnTabChange({ searchCriteria, searchTab }) {
    this.props.setSearchData({
      searchCriterias: searchCriteria ? [searchCriteria] : [],
      searchTab,
    });
  }

  handleExpandAll = () => {
    this.setState({ expandAllCard: !this.state.expandAllCard });
  };

  handleExpandCardActive = () => {
    this.setState({ expandAllCard: false });
  };

  handleViewNewTab = () => {
    this.setState({ isInsightModalOpen: true });
  };

  handleInsightOpenModalOnOk = () => {
    if (this.props.history.location.pathname == "/home") {
      let pathname = window.location.href;
      let newUrl = pathname.split("/");
      newUrl[newUrl.length - 1] = "insights";
      let updatedUrl = newUrl.join("/");
      window.open(updatedUrl + "?from=" + "newtab");
      this.props.history.push({
        pathame: ROUTES.HOME,
        search: `?page=homePage`,
      });
    }
    this.setState({ showViewInNewTab: true });
    this.setState({ isInsightModalOpen: false });
  };
  handleInsightOpenModalOnClose = () => {
    this.setState({ isInsightModalOpen: false });
  };

  handleBreadCrumbArticles = (event) => {
    if (event.clear) {
      this.setState({ breadCrumbData: [] });
      this.props.setSearchData({ searchWords: [] });
      return;
    }
    this.setState({
      breadCrumbData: this.state.breadCrumbData.slice(0, event?.value + 1),
    });
    let breadCrumbs = this.state.breadCrumbData.slice(0, event?.value + 1);
    this.props.setSearchData({
      searchWords: formatBreadCrumbData(breadCrumbs),
    });
  };

  handleTabCloseWindow = () => {
    this.setState({ notInteractive: false });
    window.close();
  };

  render() {
    return template.call(this);
  }
}
export default Home;
