import React from "react";
import UserActions from "./UserActions";

export const TABLE_COLUMNS = {
  COLUMNS: ({ sortedInfo, handleUpdate, handleDelete, currentProfileId }) => [
    {
      title: "User Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "id"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
    },
    displayFirstName(sortedInfo),
    displayLastName(sortedInfo),
    displayEmail(sortedInfo),
    {
      title: "Role",
      dataIndex: "profilename",
      key: "profilename",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "profilename"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => <span> Insights {text}</span>,
     
    },
    {
      title: "Group",
      dataIndex: "groupName",
      key: "group",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "group"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Is Group Admin",
      dataIndex: "isGroupAdmin",
      key: "isGroupAdmin",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "isGroupAdmin"
          ? sortedInfo.order == "desc"
            ? "descend"
            : "ascend"
          : "",
      render: (text, record) => {return <span>{record.isGroupAdmin?"Yes":"No"}</span>},
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) =>
        record.userProfile != 3 && record.emailid != currentProfileId ? (
          <UserActions
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            record={record}
          />
        ) : null,
    },
  ],
};

function displayFirstName(sortedInfo) {
  return {
    title: "First Name",
    dataIndex: "firstname",
    key: "firstname",
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === "firstname"
        ? sortedInfo.order == "desc"
          ? "descend"
          : "ascend"
        : "",
    render: (text, record) => <span> {text}</span>,
  };
}

function displayLastName(sortedInfo) {
  return {
    title: "Last Name",
    dataIndex: "lastname",
    key: "lastname",
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === "lastname"
        ? sortedInfo.order == "desc"
          ? "descend"
          : "ascend"
        : "",
    render: (text, record) => <span> {text}</span>,
  };
}

function displayEmail(sortedInfo) {
  return {
    title: "Unique Identifier",
    dataIndex: "emailid",
    key: "emailid",
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === "emailid"
        ? sortedInfo.order == "desc"
          ? "descend"
          : "ascend"
        : "",
  };
}
