export const CHART_SECTION = {
  CURSOR: 'cursor',
  COLOR: '#e68a00',
  SCROLLX: 'scrollbarX',
  SCROLLY: 'scrollbarY',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  WHEELX: 'panX',
  WHEELY: 'zoomX',
};
