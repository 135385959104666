import moment from "moment";
import {
  ARTICLE_DOCUMENT_API,
  SEARCH_RESULT_PDF_KEY,
} from "../../../constants";
import { notify } from "../../Notification";

export const getRelativePathfromFileData = (fileData) => {
  return Array.isArray(fileData) ? fileData[0] : fileData;
}

export const removeHtmlTagsFromFileExtension = (filename) => {
  let parts = filename.split('.');
  // Extract the extension
  let extension = parts.pop();
  // Remove HTML tags from the extension
  let cleanExtension = extension.replace(/<[^>]*>/g, '');
  filename = parts.join('.') + '.' + cleanExtension;
  return filename;
}

export const trimLength = (selectedClassname) => {
  if (selectedClassname == "gridTwo") {
    return 40;
  } else if (selectedClassname == "gridThree") {
    return 25;
  } else {
    return 75;
  }
};

export const publishDateFormatter = (date) => {
  let newDate = new Date(date).toDateString();
  if (newDate) {
    newDate = newDate?.split(" ");
    newDate = newDate[2]?.concat("-", `${newDate[1]}-${newDate[3]}`);
    newDate = moment(newDate)?.format("MM DD YYYY");
    newDate = moment(newDate)?.format("D MMMM YYYY");
    return newDate;
  }
  return "-";
};

/**
 * Download article pdf helper
 * @param {string} downloadLink
 * @param {string} fileName
 */
export const articlePdfDownloadHandler = (downloadLink, fileName) => {
  fetch(downloadLink).then(
    (response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
        });
      } else
        notify.error(
          response.status === 404
            ? "File not found, Download failed."
            : "File not found"
        );
    },
    (error) => notify.error("Download failed.")
  );
};

export const handleDateFormatter = (date, format) => {
  if (!date) return "Publish date is not available";
  let newDate = date?.split("T");
  newDate = newDate[0].split("-");
  if (format == "DD MONTH YYYY") {
    return publishDateFormatter(date);
  } else if (format == "YYYY/MM/DD") {
    newDate = newDate[0].concat("/", `${newDate[1]}/${newDate[2]}`);
    return newDate;
  } else if (format == "DD/MM/YYYY") {
    newDate = newDate[2].concat("/", `${newDate[1]}/${newDate[0]}`);
    return newDate;
  } else if (format == "MM/DD/YYYY") {
    newDate = newDate[1].concat("/", `${newDate[2]}/${newDate[0]}`);
    return newDate;
  } else if (format == "MM/DD") {
    newDate = newDate[1].concat("/", `${newDate[2]}`);
    return newDate;
  } else if (format == "YYYY") {
    newDate = newDate[0];
    return newDate;
  } else if (format == "MM/DD") {
    newDate = newDate[1].concat("/", `${newDate[2]}`);
    return newDate;
  } else if (format == "YYYY/MM") {
    newDate = newDate[0].concat("/", `${newDate[1]}`);
    return newDate;
  } else {
    return publishDateFormatter(date);
  }
};
