export const createFilterData = (stateData, data) => {
  const { categoryXField, categoryYField } = stateData?.config[
    Object.keys(stateData?.config)[0]
  ];
  let newFilter = {
    ...stateData?.filter,
    [categoryXField]: [data?.xValue],
    [categoryYField]: [data?.yValue],
  };
  return newFilter;
};

export const createAdvancedFilterData = ({ stateData, data, filterGroup }) => {
  const { categoryXField, categoryYField } = stateData?.config[
    Object.keys(stateData?.config)[0]
  ];
  let newFilter = {};
  const filterGroupCount = Object.keys(filterGroup).filter(
    (item) => item !== "isOR"
  ).length;
  if (filterGroupCount == 0) {
    newFilter = {
      filter1: {
        facet: {
          value1: {
            data: data?.xValue,
            option: "All",
          },
        },
        key: categoryXField,
      },
      filter2: {
        facet: {
          value1: {
            data: data?.yValue,
            option: "All",
          },
        },
        isOR: false,
        key: categoryYField,
      },
    };
  } else {
    newFilter = {
      ["filter" + (filterGroupCount + 1)]: {
        facet: {
          value1: {
            data: data?.xValue,
            option: "All",
          },
        },
        key: categoryXField,
      },
      ["filter" + (filterGroupCount + 2)]: {
        facet: {
          value1: {
            data: data?.yValue,
            option: "All",
          },
        },
        isOR: false,
        key: categoryYField,
      },
    };
  }
  return newFilter;
};
