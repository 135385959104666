import React from 'react';
import PropTypes from 'prop-types';

import template from './template';
import { DATE_FILTER_KEY } from '../../Routes/Home/constants';

class FilterSection extends React.Component {
  state = {
    dateFormat: 'DD/MM/YYYY',
  };
  componentDidUpdate(prevProps) {
    if (prevProps.settings[DATE_FILTER_KEY] != this.props.settings[DATE_FILTER_KEY]) {
      this.setState({
        dateFormat:
          this.props.settings && this.props.settings[DATE_FILTER_KEY] && this.props.settings[DATE_FILTER_KEY].format
            ? this.props.settings[DATE_FILTER_KEY].format
            : 'DD/MM/YYYY',
      });
    }
  }
  render() {
    return template.call(this);
  }
}

FilterSection.propTypes = {
  /**
   * settings
   */
  settings: PropTypes.any,
};

export default FilterSection;
