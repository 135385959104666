import React, { useEffect, useState } from "react";

import ModalWindow from "../ModalWindow";
import {
  tagsModalCloseHelper,
  handleSaveHelper,
  handleTagAddHelper,
  handleTagRemoveHelper,
  tagInputHelper,
  tagSetHelper,
} from "./Helper/tagHelper";
import PreviewModal from "./Children/PreviewModal";
import TagBlock from "../TagBlock";
import DisplayResultCard from "./Children/ResultCard";

const MainCard = (props) => {
  const [tags, setTags] = useState([{ value: "", type: "public" }]);
  const [ListItems, setListItems] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [tagInfoMessage, setTagInfoMessage] = useState([""]);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    tagSetHelper({ setTags, userTags: props.data.user_tags });
    setListItems(props.data.user_tags?.filter((t) => t.length > 0) || []);
  }, [props.data.user_tags]);
  useEffect(() => {
    if (!props.tagState.loading) {
      setTagInfoMessage([]);
      setShowTagsModal(false);
    }
  }, [props.tagState.loading]);

  const handleTagsList = () => setShowTagsModal(true);
  const handleTagsModalClose = () =>
    tagsModalCloseHelper({
      setShowTagsModal,
      userTags: props.data.user_tags,
      setTags,
      setTagInfoMessage,
    });
  const handleSave = () =>
    handleSaveHelper({
      props,
      tagInfoMessage,
      tags,
      setTagInfoMessage,
      searchType: props.searchType,
    });
  const handleChange = ({ event, index, name }) =>
    tagInputHelper({
      event,
      index,
      name,
      tags,
      setTags,
      setTagInfoMessage,
      tagInfoMessage,
    });
  const handleTagAdd = () => handleTagAddHelper({ setTags, tags });
  const handleTagRemove = (index) =>
    handleTagRemoveHelper({
      setTags,
      tags,
      index,
      tagInfoMessage,
      setTagInfoMessage,
    });
  const handleTagEmptyAdd = () => setTags([{ value: "", type: "public" }]);
  return (
    <>
      {showTagsModal && (
        <DisplayTagsModal
          handleSave={handleSave}
          handleTagsModal={handleTagsModalClose}
          tags={tags}
          tagInfoMessage={tagInfoMessage}
          setTagInfoMessage={setTagInfoMessage}
          loading={props.tagState.loading}
          handleTagAdd={handleTagAdd}
          handleChange={handleChange}
          handleTagRemove={handleTagRemove}
          handleTagEmptyAdd={handleTagEmptyAdd}
        />
      )}
      {showPreview && (
        <PreviewModal
          {...props}
          showModal={showPreview}
          setShowPreview={setShowPreview}
        />
      )}
      <DisplayResultCard
        {...props}
        handleTagsList={handleTagsList}
        ListItems={ListItems}
        setShowPreview={setShowPreview}
        quickCodes={props.quickCodeList}
        setShowQuickCode={props.setShowQuickCode}
      />
    </>
  );
};

export default MainCard;

function DisplayTagsModal(props) {
  return (
    <ModalWindow
      open={true}
      title={"Add tags"}
      width={600}
      onOk={props.handleSave}
      isNotClosable={true}
      okText={"Save"}
      cancelText="Cancel"
      confirmLoading={props.loading}
      onCancel={props.handleTagsModal}
    >
      <TagBlock
        tags={props.tags}
        privateTags={props.privateTags}
        handleTagAdd={props.handleTagAdd}
        handleChange={props.handleChange}
        tagInfoMessage={props.tagInfoMessage}
        handleTagRemove={props.handleTagRemove}
        keyEnter={props.handleSave}
        handleTagEmptyAdd={props.handleTagEmptyAdd}
      />
    </ModalWindow>
  );
}
