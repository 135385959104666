import { addRecentSearch } from "../../../../api/search";
import { KEYS } from "../../../../dataKeys";
import {
  DATE_FILTER_KEY,
  PUBLISHED_DATE,
} from "../../../../Routes/Home/constants";
import { handleDatePublishedPayloadHelper } from "./advanceSearchDateHelper";

export function saveRecentApi(payload, isSave) {
  let emailid = localStorage.getItem(KEYS.ACCESS_EMAIL);
  if (!isSave) {
    return addRecentSearch({
      searchHistory: payload,
      emailid,
      searchKeyword: payload.field_Value.value.toLowerCase(),
      isSavedResult: false,
    });
  } else {
    const { searchHistory, searchKeyword, searchType } = payload;
    return addRecentSearch({
      searchType,
      searchHistory,
      emailid,
      searchKeyword,
      isSavedResult: true,
      savedResult: [],
    });
  }
}

//AdvanceSearch payload create ----
export const createAdvanceSearchPayload = ({ data, pagination }) => {
  let payloadData = {
    filtersListforData: [],
    pageNumber: pagination?.pageNumber ?? 1,
    pageSize: pagination?.pageSize ?? 12,
  };
  let groupData = [];
  Object.keys(data).map((group) => {
    groupData.push({
      key: {
        filterOperator: data[group]["isOR"] ?? true,
      },
      value: {
        filterGroupList: getFilterInAdvanceSearch(data[group]),
      },
    });
  });
  payloadData["filtersListforData"] = groupData;

  return payloadData;
};
const getFilterInAdvanceSearch = (data) => {
  let filterData = [];
  Object.keys(data)
    .filter((i) => i != "isOR")
    .map((filter) => {
      filterData.push({
        key: {
          filterOperator: data[filter]["isOR"] ?? true,
        },
        value: {
          soloFilter: getFacetInAdvanceSearch(data[filter]),
        },
      });
    });
  return filterData;
};
const getFacetInAdvanceSearch = (data) => {
  let facetData = [
    {
      key: {
        fieldName: data["key"],
      },
      value: {
        facetList: [],
      },
    },
  ];
  let facetValues = [];
  if (data?.key?.toLowerCase().includes("date")) {
    handleDatePublishedPayloadHelper({ data, facetData, facetValues });
  } else {
    Object.keys(data["facet"]).map((facet) => {
      facetValues.push({
        key: data["facet"][facet]["isOR"] ?? true,
        value: data["facet"][facet].data
          ?.split("*")
          .filter((item) => item !== " ") || [""],
        options: data["facet"][facet].option || "",
      });
    });
    facetData[0]["value"]["facetList"] = facetValues;
  }
  return facetData;
};
//------
