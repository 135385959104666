import React from 'react';
import PropTypes from 'prop-types';

import InputField from '../../../../InputField';
import style from '../style.module.scss';

function LastNdays(props) {
  return (
    <div className={style.dateChoose__items}>
      <InputField
        name='last-N-days'
        htmlForName='last-N-days'
        placeholder='Enter number'
        onChange={props.handleLastNdays}
        onBlur={props.handleLastNDaysChange}
        value={props.lastNdays}
        autoFocus={false}
        onPressEnter={props.handleLastNDaysChange}
        error={props.errorMessage != '' ? props.errorMessage : null}
      />
    </div>
  );
}

LastNdays.propTypes = {
  /**
   * handleLastNdays
   */
  handleLastNdays: PropTypes.func,
  /**
   * handleLastNDaysChange
   */
  handleLastNDaysChange: PropTypes.func,
  /**
   * lastNdays
   */
  lastNdays: PropTypes.any,
  /**
   * errorMessage
   */
  errorMessage: PropTypes.string,
};
export default LastNdays;
