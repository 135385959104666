import {getInsightsById} from '../../../../api/insights';
import {capitalizeFirstLetter, formatChartData} from '../../../../utils/formatFilterLabel';
import {KEYS} from '../../../../dataKeys';
import {generatePayload, handleApi} from 'Routes/Insights/Helper/chartHandler';
import {createInsightsDataPayload} from 'Routes/Insights/Helper';
import {SEARCH_TYPE} from 'constants';
import {createAdvanceSearchPayload} from 'Redux/Actions/CreateInsights/Helper/AdvanceSearchHelper';

export const handleInsightData = async (payload) => {
  let newData = [];
  let chartNames = [];
  let config = [];
  let insightsName = [];
  // TODO: converting dateFilter object to array for multiple dateFilter API integration

  const newPayload = await Promise.all(
    payload.map(async (item, i) => {
      let {data} = await getInsightsById({id: item});
      const {insightsContentbyte} = data.result;
      let {requestPayload, paramsPayload} = generatePayload({
        chartName: insightsContentbyte.chartName,
        parameters: insightsContentbyte.payload,
      });
      const newPayload = createInsightsDataPayload({
        ...insightsContentbyte.requestPayload,
        searchType:
          Object.keys(insightsContentbyte.requestPayload?.advanceSearchPayloadData).length > 0
            ? SEARCH_TYPE.ADVANCED_SEARCH
            : SEARCH_TYPE.NORMAL_SEARCH,
        filter: insightsContentbyte.requestPayload?.filtersforData[0] || {},
        dateFilter: insightsContentbyte.requestPayload?.dateFilters,
      });
      let keysLength = newPayload.request?.filtersListforData ?? {};
      let newFilterList =
        Object.keys(keysLength).length > 0
          ? createAdvanceSearchPayload({
              data: newPayload.request.filtersListforData ?? {},
            })
          : {filtersListforData: []};
      // newPayload.request.filtersListforData =
      //   newFilterList["filtersListforData"];
      let dataUpdate = await handleApi({...newPayload.request, ...requestPayload}, insightsContentbyte.chartName);
      chartNames[i] = insightsContentbyte.chartName;
      config[i] = insightsContentbyte.config;
      newData[i] = !data.result?.inActive
        ? dataFormatter({
            data: dataUpdate.result.results,
            payload: insightsContentbyte,
            fieldValue: paramsPayload,
            chartName: insightsContentbyte.chartName,
          })
        : undefined;
      insightsName[i] = data.insightsName;
    })
  );
  return {newData, chartNames, config, insightsName};
};

const heatMapDataFormatter = ({data}) => {
  let finalData = data;
  return finalData;
};

export const dataFormatter = ({data, payload, fieldValue, chartName}) => {
  if (chartName === 'HEAT_MAP') {
    return heatMapDataFormatter({data, payload, fieldValue});
  } else if (chartName == 'KEY_PHRASE_CLOUD') {
    return wordClodDataFormatter(data, payload);
  } else {
    if (Object.keys(data).length > 0 && fieldValue) {
      let newData = data[fieldValue];
      newData = formatChartData(newData);
      return newData;
    }
  }
};

export const wordClodDataFormatter = (data) => {
  let newData = [];
  let list = JSON.parse(sessionStorage?.getItem(KEYS.WORD_CLOUD_TEMPORARY_LIST)) ?? [];
  let permittedValues = [];
  for (let i = 0; i < list.length; i++) {
    let word = list[i]['keyword'].toLowerCase();
    permittedValues[i] = list[i]['keyword'].toLowerCase();
  }
  data.map((item) => {
    let isExcluded = permittedValues.includes(item.key);
    if (!isExcluded) {
      let newObject = {
        text: wordCloudChildExtractor(item.key),
        key: item.key,
        value: item.value,
        type: item.ktype,
        parent: parentExtractor(item.key) ?? undefined,
      };
      newData.push(newObject);
    }
  });
  return newData;
};

export const parentExtractor = (data) => {
  let matches = data.match(/\[(.*?)\]/);
  let subMatch;
  if (matches) {
    subMatch = matches[1];
  }
  return subMatch;
};

export const wordCloudChildExtractor = (value) => {
  return capitalizeFirstLetter(value.split('[')[0]);
};

export const wordCloudSquareBracketFormatter = (value) => {
  return value.replace(/\[/g, '[[').replace(/\]/g, ']]');
};
