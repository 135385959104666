import React from "react";

import InputField from "../../../../Components/InputField";
import ModalWindow from "../../../../Components/ModalWindow";
import {
  EMAIL_PLACEHOLDER,
  FORGOT_PASSWORD_MESSAGE,
  MODAL_MESSAGE,
  SUBMIT_TITLE,
} from "../../../../Constants/Login/Login.constants";
import style from "./style.module.scss";
import ValidationForm from "./ValidationForm";
import Loader from "../../../../Components/Loader";

function ShowModal(props) {
  return (
    <ModalWindow
      okText={SUBMIT_TITLE}
      width={"500px"}
      className={style.forgetModel}
      isNotClosable={true}
      isNonMaskable={true}
      onCancel={(e) => props.forgetPasswordModalHandler(e, "modalCancel")}
      onOk={(e) => props.forgotPasswordOkHandler()}
      open={true}
    >
      <div className={style.forgetBlock}>
        {props.props.sendEmailData.loading ? (
          <>
            <Loader
              hasWrapper={true}
              customHeight={"180px"}
              loading={props.props.sendEmailData.loading}
              classList={["sm blue"]}
            />
          </>
        ) : (
          <>
            {!props.state.status ? (
              <div className={style.passwordEmail}>
                <h3>{FORGOT_PASSWORD_MESSAGE}</h3>
                <p>{MODAL_MESSAGE}</p>
                <InputField
                  value={props.state.email}
                  className="forget-input"
                  type="text"
                  onPressEnter={(e) => props.forgotPasswordOkHandler()}
                  autoFocus={true}
                  placeholder={EMAIL_PLACEHOLDER}
                  onChange={props.handleEmail}
                  error={
                    props.state.validityCheck.email
                      ? props.state.errorMessage
                      : null
                  }
                />
              </div>
            ) : (
              <ValidationForm {...props} />
            )}
          </>
        )}
      </div>
    </ModalWindow>
  );
}

export default ShowModal;
