/* eslint-disable max-params */
import React from 'react';
import {Pagination as PageComponent} from 'antd';
import PropTypes from 'prop-types';

import './style.scss';

export default function Pagination(props) {
  const sizeChanger = (current, pageSize) => {};
  return (
    <div className='pagination-block'>
      <PageComponent
        // id='result-pagination-component' no use for this
        current={props.current}
        pageSize={props.pageSize}
        total={props.total}
        onChange={props.onChange}
        showSizeChanger={props.showSizeChanger}
        onShowSizeChange={sizeChanger}
        pageSizeOptions={props.pageSizeOptions}
        showTitle={false}
      />
    </div>
  );
}

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Prev</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  if (type === 'page') {
    return <a>{Number(current).toLocaleString()}</a>;
  }
  return originalElement;
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  showSizeChanger: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};

Pagination.defaultProps = {
  showSizeChanger: false,
  pageSizeOptions: [50, 100, 200],
};
