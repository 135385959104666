import React from 'react';

import ButtonElement from '../ButtonElement';
import InputField from '../InputField';
import TooltipContainer from '../Tooltip';

import style from './style.module.scss';

export function template(props) {
  return (
    <div className={style.addtagBlock}>
      {this.props.tags.length === 0 && (
        <div className={style.addtagBlock__nodata}>
          <span>No tags added yet .Click the</span>
          <a onClick={this.props.handleTagEmptyAdd}>+tags</a>
          <span>button to add tag.</span>
        </div>
      )}
      <ul className={style.addtagBlock__list}>
        {this.props.tags.map((item, index) => (
          <>
            <li>
              <div className={style.tagItems}>
                <div className={style.tagItems__fields}>
                  <InputField
                    value={this.props.tags[index].value}
                    placeholder='Enter tag'
                    onChange={(e) => this.props.handleChange({event: e, index, name: 'value'})}
                    onBlur={(e) => this.props.handleChange({event: e, index, name: 'value'})}
                    autoFocus={true}
                    error={this.props.tagInfoMessage[index] !== '' ? this.props.tagInfoMessage[index] : null}
                    onPressEnter={this.props.keyEnter}
                  />
                </div>
              </div>
              <div className={style.tagButtonpane}>
                <div className={style.tagButtonpane__element}>
                  <ButtonElement onClick={(e) => this.props.handleTagRemove(index)} children={'-tag'} />
                </div>
                <TooltipContainer
                  placement={'top'}
                  title={this.props.tags[index].value == '' ? 'Enter a value for the tags' : ''}>
                  <div
                    className={style.tagButtonpane__element}
                    hidden={this.props.tags.length !== index + 1? true : false}>
                    <ButtonElement
                      onClick={this.props.handleTagAdd}
                      children={'+tag'}
                      type='primary'
                      disabled={this.props.tags[index].value == '' ? true : false}
                      styleProps={this.props.tags[index].value == '' ? {pointerEvents: 'none'} : {}}
                    />
                  </div>
                </TooltipContainer>
              </div>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
