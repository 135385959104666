import React, { useState } from "react";

import ButtonElement from "Components/ButtonElement";
import EmptyState from "Components/EmptyState";
import { DASHBOARD_CONSTANTS } from "../../../Constants/Dashboard/Dashboard.constants";
import { ShowInsightsImportModal } from ".";

import style from "./style.module.scss";

export function DashboardCanvas(props) {
  return (
    <div className={style.dashboardWrapper}>
      <EmptyState
        type="dashboardWelcomeImage"
        content={"DASHBOARD_INITIAL_STATE_TAB"}
      >
        <div className={style.dashboardForm}>
          <div className={style.dashboardForm__inner}>
            <div className={style.dashboardSearch} id="dashboardSearch">
              <div className={style.dashboardSearch__button}>
                <ButtonElement
                  children={DASHBOARD_CONSTANTS.IMPORT_TEXT}
                  onClick={props.addDashboardModal}
                />
              </div>
              <div className={style.dashboardSearch__button}>
                <ButtonElement
                  children={DASHBOARD_CONSTANTS.LOAD_DASHBOARD_TEXT}
                  type="primary"
                  onClick={(e) => props.handleSavedModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </EmptyState>
      {props.state.showAddModal && <ShowInsightsImportModal {...props} />}
    </div>
  );
}
