import { notify } from "../../Notification";

export const handleSaveHelper = async ({
  uids,
  allArticleList,
  setSearchLocalData,
  addTagsHandler,
  tagInfoMessage,
  tags,
  setTagInfoMessage,
  searchType,
  append,
  clearSelection,
}) => {
  const tagList =
    tags.length == 0 ? [] : tags.map((item) => item.value.trim().toLowerCase());
  let emptyTagExists = false;
  let duplicateTagExists = false;
  let lengthExceed = false;
  let tagsWarning = [...tagInfoMessage];
  tagList.forEach((item, index) => {
    if (item.length === 0) {
      emptyTagExists = true;
      tagsWarning[index] = "Tags cannot be empty";
    }
    if (item.length > 25) {
      lengthExceed = true;
      tagsWarning[index] = "Maximum allowed characters is 25";
    }
    if (tagList.indexOf(item) !== index) {
      duplicateTagExists = true;
      tagsWarning[index] = "Duplicate tag is not allowed";
    }
    setTagInfoMessage(tagsWarning);
  });
  if (!emptyTagExists && !duplicateTagExists && !lengthExceed) {
    await addTagsHandler({
      uids,
      tags,
      allArticleList,
      searchType,
      setSearchLocalData,
      append,
      clearSelection,
    });
  }
};

/**
 *
 * @param {{setShowTagsModal: Function, userTags: string[], setTags: Function, setTagInfoMessage: Function}} param
 */
export const tagsModalCloseHelper = ({
  userTags,
  setTags,
  setTagInfoMessage,
}) => {
  tagSetHelper({ setTags, userTags });
  setTagInfoMessage([]);
};

export const handleTagAddHelper = ({ setTags, tags }) => {
  setTags([
    ...tags,
    {
      value: "",
      type: "public",
    },
  ]);
};

export const handleTagRemoveHelper = ({
  setTags,
  tags,
  index,
  tagInfoMessage,
  setTagInfoMessage,
}) => {
  const tagsWarning = [...tagInfoMessage];
  tagsWarning[index] = "";
  setTagInfoMessage(tagsWarning);
  let newTags = [...tags];
  newTags.splice(index, 1);
  setTags(newTags);
};

export const tagInputHelper = ({
  event,
  setTagInfoMessage,
  index,
  name,
  tags,
  setTags,
  tagInfoMessage,
}) => {
  let REGEX_TEST = /[^\\\/:\*\?\"\<\>\|]+$/gi;
  let valid =
    REGEX_TEST.test(event.target.value) || event.target.value.length == 0;
  if (event.target.value.length > 25) {
    let tagsWarning = [...tagInfoMessage];
    tagsWarning[index] = "Maximum allowed characters is 25";
    setTagInfoMessage(tagsWarning);
  } else if (!valid) {
    let tagsWarning = [...tagInfoMessage];
    tagsWarning[index] = "Character is not allowed";
    setTagInfoMessage(tagsWarning);
  } else {
    tagHandleChangeHelper({ event, index, name, tags, setTags });
    let tagsWarning = [...tagInfoMessage];
    tagsWarning[index] = "";
    setTagInfoMessage(tagsWarning);
  }
};

/**
 *
 * @param {{setTags: Function, userTags: string[]}} param
 */
export const tagSetHelper = ({ setTags, userTags }) => {
  let tagArray = userTags ? userTags.filter((t) => t.length > 0) : [];
  let tagObjectArray =
    tagArray.length > 0
      ? tagArray.map((item) => {
        return { value: item, type: "public" };
      })
      : [{ value: "", type: "public" }];
  setTags(tagObjectArray);
};

const tagHandleChangeHelper = ({ event, index, name, tags, setTags }) => {
  let newObject = tags[index];
  newObject = { ...newObject, [name]: event.target.value };
  let newTags = [...tags];
  newTags[index] = newObject;
  setTags(newTags);
};

export const sameArray = (array1, array2) => {
  if (!array1 && !array2) return true;
  if (!array1 || !array2) return false;
  if (array1.length != array2.length) return false;
  return array1.sort().join(',') === array2.sort().join(",");
};