import React from "react";
import moment from "moment";

import SVGIcons from "../SVGIcons";
import { publishDateFormatter } from "../SearchCard/Helper";
import TooltipContainer from "../Tooltip";

import globel from "../../styles/globel/style.module.scss";

export const SAVED_SEARCHES_CONFIG = {
  COLUMNS: ({ deleteHandler, loadHandler, exportHandler, setAsGroupDefaultQuery, defaultQueryId, userProfile }) => {
    return ([
      {
        title: "Query Name",
        dataIndex: "searchKeyword",
        key: "searchKeyword",
        sorter: (a, b) => a.searchKeyword.localeCompare(b.searchKeyword),
        onCell: (record) => ({
          className: record.id === defaultQueryId ? globel.defaultGroupQuery : "",
        }),
      },
      {
        title: "Created By",
        dataIndex: "emailid",
        key: "emailid",
        sorter: (a, b) => a.emailid.localeCompare(b.emailid),
        onCell: (record) => ({
          className: record.id === defaultQueryId ? globel.defaultGroupQuery : "",
        }),
      },
      {
        title: "Created Date",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (date) => `${publishDateFormatter(date)}`,
        sorter: (a, b) =>
          moment(publishDateFormatter(a.timestamp)).unix() -
          moment(publishDateFormatter(b.timestamp)).unix(),
        defaultSortOrder: "descend",
        onCell: (record) => ({
          className: record.id === defaultQueryId ? globel.defaultGroupQuery : "",
        }),
      },
      {
        title: "Actions",
        dataIndex: "action",
        render: (text, record) => {
          const canSetDefault = userProfile.isGroupAdmin || userProfile.userProfile===3;
          const canDelete = userProfile.isGroupAdmin || (record.id != defaultQueryId && (userProfile.userProfile===3||record.emailid===userProfile.emailid));
          return (
            <ul className={globel.tableActions}>
              <li>
                <TooltipContainer
                  mouseLeaveDelay={0}
                  placement={"bottom"}
                  title={"Load Result"}
                >
                  <span onClick={(e) => loadHandler(record)}>
                    <SVGIcons type={"SVG-import"} />
                  </span>
                </TooltipContainer>
              </li>
              <li>
                <TooltipContainer
                  mouseLeaveDelay={0}
                  placement={"bottom"}
                  title={"Export Query"}
                >
                  <span onClick={(e) => exportHandler(record)}>
                    <SVGIcons type={"SVG-export"} />
                  </span>
                </TooltipContainer>
              </li>
              {canSetDefault && (
                <li>
                  <TooltipContainer
                    mouseLeaveDelay={0}
                    placement={"bottom"}
                    title={"Set As Group Default Query"}
                  >
                    <span onClick={(e) => setAsGroupDefaultQuery(record)}>
                      <SVGIcons type={"SVG-set-as-default-group-search"} />
                    </span>
                  </TooltipContainer>
                </li>)
              }
              {canDelete && (
                <li>
                  <TooltipContainer
                    mouseLeaveDelay={0}
                    placement={"bottom"}
                    title={"Delete Search"}
                  >
                    <span onClick={(e) => deleteHandler(record)}>
                      <SVGIcons type={"SVG-delete"} />
                    </span>
                  </TooltipContainer>
                </li>)
              }
            </ul>
          )
        },
        onCell: (record) => ({
          className: record.id === defaultQueryId ? globel.defaultGroupQuery : "",
        }),
      },
    ]);
  }
}
