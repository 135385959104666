import { connect } from "react-redux";
import {
  DashboardAction,
  InsightsMappingPayload,
  clearInsightData,
  viewDashboardAction,
} from "../../Redux/Actions";
import Downloadpreview from "./DownloadPreview";

function mapStateToProps(state) {
  return {
    importedInsights: state.Dashboard,
    insightsList: state.InsightsPage,
    projectListInfo: state.FetchProjectList,
    createInsights: state.CreateInsights,
    websiteDomain: state.InsightsPage.data.website_domain,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getInsightData: (params) => dispatch(getInsightData(params)),
    importInsights: ({ payload, handleImportSuccess, clear }) => {
      dispatch(
        DashboardAction({
          payload,
          handleImportSuccess,
          clear,
        })
      );
    },
    getAvailableFields: (params) => dispatch(InsightsMappingPayload(params)),
    getViewDashboardData: (params, handleSuccess) =>
      dispatch(viewDashboardAction(params, handleSuccess)),
    clearInsightData: (params) => dispatch(clearInsightData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Downloadpreview);
