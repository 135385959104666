import { notify } from "../../../../../Components/Notification";
import { INSIGHT_UPDATED_SUCCESSFULLY_MESSAGE } from "../../../../../Constants/Insights/Insights.constants";
import { ROUTES } from "../../../../../Constants/Routes/Routes.constants";
import { formatDraggableId, reFormatFilterLabel } from "./formatter";
import { saveModalOkHandlerHelper } from "./modalWindowHelper";
import { generateEditDashboardPayload } from "./payloadGenerators";

export const checkIsValueFieldController = ({ state, value }) => {
  let newallFieldsArray = state.state.allFields;

  newallFieldsArray.push(
    reFormatFilterLabel(
      state.state.selectedField[value.destination.droppableId].value
    )
  );

  state.setState({
    allFields: newallFieldsArray.filter(
      (i) => i != reFormatFilterLabel(value.draggableId.split("/")[0])
    ),
    selectedField: {
      ...state.state.selectedField,
      [value.destination.droppableId]: {
        value: formatDraggableId(value.draggableId),
        index: value.source.index,
      },
    },
  });
};

export const saveModalWindowOkHelper = ({ state }) => {
  saveModalOkHandlerHelper({
    state: state,
    handleExisting: state.handleExisting,
  });
};

export const handleEditHelper = async ({ value, state }) => {
  if (value) {
    let updatedPayload = await generateEditDashboardPayload({
      newPayload: state.props.createInsights.payload,
      currentPayload: state.props.location.state.payload,
      insightName: state.state.insightName,
      selectedList: state.props.location.state.selectedList,
    });
    state.props.history.push({
      pathname: ROUTES.BUILD_DASHBOARD,
      state: {
        isEdit: true,
        payload: updatedPayload,
        selectedList: state.props.location.state.selectedList,
        dashboardName: state.props.location.state.dashboardName,
        dashboardId: state.props.location.state.dashboardId,
        layout: state.props.location.state.layout,
      },
    });
  } else {
    notify.success(INSIGHT_UPDATED_SUCCESSFULLY_MESSAGE, true);
  }
  state.setState({
    saveModalWindow: false,
    SaveLoading: false,
  });
};

export const handleCancelInsightHelper = ({ state }) => {
  if (state.props?.location?.state?.isEdit) {
    state.props.history.push({
      pathname: ROUTES.INSIGHTS,
      state: {
        isEdit: false,
        cancel: true,
      },
    });
    state.props.clearInsightData();
    state.setState({
      selectedChart: "",
      selectedField: "",
      insightName: "",
      dataSetValue: "All articles",
    });
  } else {
    state.props.clearInsightData();
    state.setState({
      selectedChart: "",
      selectedField: "",
      insightName: "",
      dataSetValue: "All articles",
    });
  }
};

export const dataSetController = ({ state }) => {
  let dataSet;
  state.props?.dataSet.map((item) => {
    if (item.searchKeyword == "all articles") {
      dataSet = item.id;
      if (
        state.props.location &&
        state.props.location.state &&
        state.props.location.state.isEdit
      )
        state.setState({
          dataSetValue: state.props.location.state.chartInfo.dataSet,
        });
      else state.setState({ dataSetValue: item.id });
    }
  });
  if (dataSet) state.props.getAvailableFields(dataSet);
};
