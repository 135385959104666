import {notify} from '../../../../Components/Notification';
import { ALERT_MESSAGE } from '../../../../Constants/Login/Login.constants';
import {KEYS} from '../../../../dataKeys';
import {errorHandler} from '../../../../utils/ErrorHandler';

export function loginStarted(state, payload) {
  return {
    ...state,
    login: {
      loading: true,
      error: false,
    },
  };
}

export function loginSuccess(state, payload) {
  // localStorage.setItem(KEYS.ACCESS_TOKEN_FIELD, payload.data.token);
  return {
    ...state,
    login: {
      loading: false,
      error: false,
    },
  };
}

export function loginFailed(state, payload) {
  if (payload && payload.response) {
    notify.error(payload?.response?.data?.title || ALERT_MESSAGE , true);
  } else {
    errorHandler(payload);
  }
  return {
    ...state,
    login: {
      loading: false,
      error: payload,
    },
  };
}