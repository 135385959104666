import { publishDateFormatter as dateFormater } from 'Components/SearchCard/Helper';
import moment from 'moment';
import { DATE_FILTER_KEY } from '../constants';
import { handleExportAsCsv } from '../components/SearchControls/helper';
import { KEYS } from 'dataKeys';
import * as XLSX from 'xlsx';
import { notify } from 'Components/Notification';
import { SAML_LOGOUT_URL } from 'Constants/Login/Login.constants';
import { createAdvanceSearchPayload } from 'Redux/Actions/Search/Helper/helper';
import { formatDate, handleApiError } from 'utils/commonUtils';
import { SEARCH_TYPE } from 'constants';
import { generateAdvanceSearchPayload } from './common';
import { advancedSearch } from 'api/search';
import { errorMEssageHandler } from 'utils/ErrorHandler';
import { DEFAULT_DATE_FILTER_VALUE } from 'constants';

export const publishDateFormatter = (date) => {
  let newDate = date.split(' ')[0];
  newDate = newDate.split('/');
  newDate = newDate[1].concat('/', `${newDate[0]}-${newDate[2]}`);
  return newDate;
};
let objectToCSVRow = function (dataObject, headers) {
  var dataArray = new Array();

  headers.map((item) => {
    var innerValue;
    if (item === DATE_FILTER_KEY)
      innerValue = !dataObject[item] ? '-' : dateFormater(dataObject[item]);
    else innerValue = !dataObject[item] ? '-' : dataObject[item].toString();
    var result = innerValue.replace(/"/g, '""');
    result = '"' + result + '"';
    dataArray.push(result);
  });
  return dataArray.join(' ') + '\r\n';
};
const formatArrayData = (data) => {
  let text = '';
  data.map((item) => {
    text += `${item.replaceAll('<em>', '').replaceAll('</em>', '')} `;
  });
  return text;
};
const removeArrayValue = (data) => {
  let newData = [];
  data.map((item) => {
    let newItem = {};
    Object.keys(item).map((key) => {
      newItem[key] = Array.isArray(item[key])
        ? formatArrayData(item[key])
        : item[key];
    });
    newData.push(newItem);
  });
  return newData;
};

/**
 * @param {{arrayOfObjects: {}[], headers: string[], fileName: string}} param
 * @returns
 */
export const exportToCSV = function ({ arrayOfObjects, headers, fileName }) {
  if (!arrayOfObjects?.length || !headers?.length) return;
  const filteredObjectArray = arrayOfObjects.map((item) =>
    Object.fromEntries(
      Object.entries(item).filter(([key]) => headers.includes(key))
    )
  );
  try {
    const worksheet = XLSX.utils.json_to_sheet(
      removeArrayValue(filteredObjectArray)
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName ? fileName + '.xlsx' : 'DataSheet.xlsx');
  } catch (error) {
    notify.error(error.message);
  }
};

export const filterDataCreate = (data, callBack) => {
  let filterData = {};
  let loopData = data.filtersForData;

  // if (data.filtersForData.length !== 0)
  Object.keys(loopData).map((key) => (filterData[key] = loopData[key]));

  data.dateFilters = Array.isArray(data.dateFilters)
    ? data.dateFilters
    : [data.dateFilters];
  data.dateFilters?.forEach((dateFilter) => {
    if (dateFilter?.value?.from || dateFilter?.value?.to) {
      let to = dateFilter?.value.to.split('-').reverse().join('/');
      let from = dateFilter?.value.from.split('-').reverse().join('/');
      if (to) filterData[dateFilter.key] = { to };
      if (from)
        filterData[dateFilter.key] = { ...filterData[dateFilter.key], from };
    }
  });

  if (typeof callBack == 'function') callBack(filterData);
  else return filterData;
};

export const checkFilterApplied = (data, callBack) => {
  const { filter, date, state } = data;
  let empty = false;
  if (Object.keys(filter).length !== 0)
    for (let key in filter) if (filter[key].length !== 0) return false;

  if (date.key) return false;
  return true;
};

export const checkFilterAppliedPayload = (state) => {
  return {
    filter: state.props.search.filter,
    date: state.props.search.dateFilter,
  };
};

export const handleDateFilterSearchHelper = ({ setSearchData, e }) => {
  setSearchData({
    dateFilter: {
      key: DATE_FILTER_KEY,
      value: { from: e.from, to: e.to },
    },
  });
};

export const handleDateFilterSearchHelperUntilNow = ({ setSearchData, e }) => {
  if (e == null) {
    setSearchData({
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });
  } else {
    setSearchData({
      dateFilter: {
        key: DATE_FILTER_KEY,
        value: {
          from: formatDate(e),
          to: formatDate(moment(new Date())),
        },
      },
    });
  }
};

export const handleLastNDaysChangeHelper = ({ state, value }) => {
  state.setState({
    filterResults: {
      ...state.state.filterResults,
      [DATE_FILTER_KEY]: { from: value.from, to: value.to },
    },
  });
  const searchState = state.props.search;
};

export const handleUntilNowChangeHelper = ({ state, value }) => {
  if (value == null) {
    let newTag = state.state.filterResults[DATE_FILTER_KEY];
    delete newTag[DATE_FILTER_KEY];
    state.setState({
      filterResults: {
        ...state.state.filterResults,
        [DATE_FILTER_KEY]: newTag,
      },
    });
  } else {
    state.setState({
      filterResults: {
        ...state.state.filterResults,
        [DATE_FILTER_KEY]: {
          from: formatDate(value),
          to: formatDate(moment(new Date())),
        },
      },
    });
  }
};

export const handleLoadHelper = (value, state) => {
  state.setState({ showLoadMenu: value });
};
export const handleExportHelper = (search, setLoaderOff) => {
  let newPayload = { ...formatSearchPayload(search) };
  delete newPayload.pageNumber;
  delete newPayload.pageSize;
  handleExportAsCsv(newPayload, setLoaderOff);
};
export const handleSaveHelper = (state) =>
  state.setState({ showSaveModal: true });
export const handleModalHelper = (state) =>
  state.setState({ showSaveModal: false });

export const handleFilterReformatter = (data) => {
  let searchPayload = { ...data };
  // TODO: multi date filter support need to be implemented.
  /** @type {{[key: string]: string[] | {from: string, to: string}}} */
  let filterList = {};
  // Array.isArray(searchPayload.dateFilter) ?
  const dateFilter = Array.isArray(searchPayload.dateFilter)
    ? searchPayload.dateFilter[0]
    : searchPayload.dateFilter;
  filterList =
    dateFilter && dateFilter?.value?.from != '' && dateFilter?.value?.to != ''
      ? {
          [dateFilter.key]: {
            from: dateFilter?.value?.from,
            to: dateFilter?.value?.to,
          },
        }
      : {};
  Object.keys(searchPayload.filter).map((item) => {
    filterList = { ...filterList, [item]: searchPayload.filter[item] };
  });
  return filterList;
};

/**
 *
 * @param {string[]} appliedFilter
 * @param {{value:string,count:number}} resultItems
 * @returns
 */
export const getFilteredValues = (appliedFilter, resultItems) => {
  const resultItemsArray = resultItems.map((filter) => filter.value);
  let newArray = [];
  appliedFilter.forEach((item) => {
    if (!resultItemsArray.includes(item)) {
      newArray.push(item);
    }
  });
  return newArray;
};

export const createAdvanceSearchQuery = (data) => {
  const selectOption = {
    All: 'Get all these matched',
    Any: 'Get any of these matched',
    Exact: 'Get this exactly matched',
    None: 'Get none of these matched',
    lastNdays: 'Within last N Days',
    Range: 'Select a date range',
    UntilNow: 'From a date until now',
  };
  let Query = '';
  Object.keys(data)
    ?.filter((item) => item !== 'group0')
    .map((group, index) => {
      Query += `${
        data[group]['isOR'] == undefined ||
        Object.keys(data)?.filter((item) => item !== 'group0').length === 1
          ? ' ('
          : data[group]['isOR']
          ? 'OR ('
          : 'AND ('
      }  `;
      Object.keys(data[group])
        .filter((item) => item != 'isOR')
        .map((filter, index) => {
          const filterData = data[group][filter];
          Query += ` ${
            filterData.isOR == undefined || index === 0
              ? ''
              : filterData.isOR
              ? 'OR '
              : 'AND '
          } (${filterData.key ? `filter:${filterData.key} ;` : ''} options:${
            filterData.key?.includes('Date')
              ? filterData.facet?.value1?.option
              : filterData.facet?.value1?.data.trim().replaceAll('*', ',')
          } ; search logic: ${
            filterData.key?.includes('Date')
              ? selectOption[filterData.facet?.value1?.data]
              : selectOption[filterData.facet?.value1?.option]
          } ) `;
        });
      Query += ' ) ';
    });
  return Query;
};

export const formatSearchPayload = (payload) => {
  const newPayload =
    payload.searchType === SEARCH_TYPE.ADVANCED_SEARCH
      ? createAdvanceSearchPayload({ data: payload.advanceSearchPayloadData })
          ?.filtersListforData
      : generateAdvanceSearchPayload(payload);
  return {
    filtersListforData: newPayload,
    pageNumber: payload.pageNumber,
    pageSize: payload.pageSize,
    searchCriterias: payload.searchCriterias,
    sortby: payload.sortby,
    sortorder: payload.sortorder,
    searchWords: payload.searchWords,
    buckets: payload.buckets,
  };
};

export const logoutHelper = async (setSearchData) => {
  // setSearchData({ clear: true });
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = SAML_LOGOUT_URL;
};

export const searchApiHandler = async ({
  payload,
  setState,
  setSearchData,
  projectSwitching = false,
}) => {
  try {
    setState({ loading: true, data: [], totalCount: 0 });
    const { data } = await advancedSearch(payload);
    setState({
      loading: false,
      data: data?.result?.result || [],
      totalCount: data.result?.resultHits || 0,
      failed: false
    });
  } catch (error) {
    !projectSwitching &&
      handleApiError(error, () => {
        setState({ loading: false, totalCount: 0, failed:true });
        setSearchData({
          error: true,
          errorMessage: errorMEssageHandler(error?.response?.status),
        });
      });
  }
};
