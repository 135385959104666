import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import { SwitchChartsDashboard } from "../../utils/switchCharts";
import { Card } from "../Card";
import "./style.scss";
import SVGIcons from "../SVGIcons";
import RemoveDashboardMOdal from "./removeDashboardMOdal";
import EmptyState from "../EmptyState";
import CustomScrollbar from "../CustomScrollbar";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class ResizeDrag extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 100,
    showRemoveModal: false,
    selectedDashboard: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      items: this.props.layout,
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
  }

  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols,
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
    this.setState({ layout: layout });
  }

  onRemoveItem(i) {
    let dataLength = this.state.items.length;
    this.setState({
      items: _.reject(this.state.items, function (info) {
        return info.i == dataLength - 1;
      }),
    });
    this.props.removeItem(i, this);
  }
  onRemoveHandler = (i) => {
    this.onRemoveItem(i);
    this.removeModalHandlerNo();
  };
  removeModalHandlerNo = () => {
    this.setState({
      showRemoveModal: false,
      selectedDashboard: "",
    });
  };
  handleEditInsight(i) {
    this.props.handleEditInsight(i);
  }
  onLayoutChange = (value) => {
    this.props.onLayoutChange(value);
  };
  createElement(el) {
    const removeStyle = {
      cursor: "pointer",
    };
    const i = el.add ? "+" : el.i;
    return (
      <div className="grid-item layouts" key={i} data-grid={el}>
        <div className="grid-item__list">
          <h4>{this.props?.labels[i]}</h4>
          {!this.props.isView && (
            <a
              className="chart-delete"
              style={removeStyle}
              onClick={() => {
                this.setState({
                  selectedDashboard: i,
                  showRemoveModal: true,
                });
              }}
            >
              <SVGIcons type={"SVG-closeIcon"} />
            </a>
          )}
        </div>
        {this.props?.data?.[i] ? (
          <>
            {SwitchChartsDashboard({
              data: this.props.data,
              chartName: this.props.chartNames,
              config: this.props.config,
              count: i,
              isDownload: this.props.isDownload,
            })}
          </>
        ) : (
          <>
            <EmptyState
              type="errorImage"
              content={"INSIGHT_DELETED"}
              customMargin={"100"}
              customHeight={"500"}
            />
          </>
        )}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.isView || this.props.isEdit) {
      this.setState({ items: this.props.layout });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data != this.props.data) {
      let itemLength = this.props.data.length;
      if (itemLength > this.state.items.length) {
        this.props.data.map((item) => {
          this.setState({
            items: this.props.layout,
          });
        });
      } else {
        this.setState({
          items: this.props.layout,
        });
      }
    }
  }
  render() {
    return (
      <>
        <CustomScrollbar className="resize-drag-scroller">
          <ResponsiveReactGridLayout
            className="layout"
            layouts={
              this.props?.isView ? this.props?.layout : this.state?.items
            }
            onLayoutChange={this.onLayoutChange}
            isDraggable={
              this.props.isView || this.props.isDownload ? false : true
            }
            isResizable={
              this.props.isView || this.props.isDownload ? false : true
            }
          >
            {_.map(this.state.items, (el) => this.createElement(el))}
          </ResponsiveReactGridLayout>
        </CustomScrollbar>
        <RemoveDashboardMOdal
          showRemoveModal={this.state.showRemoveModal}
          onRemoveHandler={this.onRemoveHandler}
          removeModalHandlerNo={this.removeModalHandlerNo}
          selectedDashboard={this.state.selectedDashboard}
        />
      </>
    );
  }
}
