import React from "react";

import { notify } from "../../Notification";
import { KEYS } from "../../../dataKeys";
import {
  createSaveResultPayload,
  getTabNameFromSearchCriteria,
  handleFormatAdvanceSearchTextData,
  handleFormatTextData,
} from "./helper";
import template from "./template";
import { SEARCH_TYPE } from "constants";

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveQueryName: "",
      loadingResults: false,
      saveModalError: "",
      sortedInfo: { columnKey: "", order: "" },
      pagination: { pageNumber: 1, pageSize: 5 },
      savedSearchDeleteLoading: false,
      showSavedSearchDeleteModal: false,
      selectedTitle: "",
      selectedData: {},
      showLoadMenu: false,
      exportLoader: false,
    };
    this.wrapperRef = React.createRef();
    this.loadRef = React.createRef();
  }
  setLoadRef = (node) => {
    this.loadRef = node;
  };

  handleSaveOnchange = (event) => {
    this.setState({
      saveQueryName: event.target.value,
      saveModalError: "",
    });
  };
  handleSaveCallBack = (data) => {
    if (data?.error) {
      this.setState({ loadingResults: false, saveModalError: data.message });
    } else {
      this.props.handleModal();
      notify.success(this.state.saveQueryName + " Added Successfully", true);
      this.setState({ loadingResults: false, saveQueryName: "" });
    }
  };
  deleteModalCloseHandler = () => {
    this.setState({
      selectedTitle: "",
      showSavedSearchDeleteModal: false,
      selectedData: {},
      savedSearchDeleteLoading: false,
    });
  };
  deleteModalHandlerYes = () => {
    this.setState({
      savedSearchDeleteLoading: true,
    });
    this.props.handleDeleteSaved(this.state.selectedData, () => {
      this.deleteModalCloseHandler();
      notify.success(this.state.selectedTitle + " Deleted Successfully", true);
      if (this.props.search.saved.length == 0) {
        let pagination = { pageNumber: 1, pageSize: 50 };
        this.props.handleSavedSearchPaginate({
          ...pagination,
          emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
          isSavedResult: true,
          avoidDetails: true,
        });
      } else {
        this.getSavedSearchList();
      }
    });
  };
  handleSaveResult = () => {
    // TODO: Multiple dateFilter support
    if (!this.state.saveQueryName.trim())
      return this.setState({ saveModalError: "empty" });
    this.setState({ loadingResults: true });
    const payload = createSaveResultPayload(this);
    this.props.handleSavedResults(payload, (data) =>
      this.handleSaveCallBack(data)
    );
  };
  handleCloseModal = () => {
    this.props.handleModal();
    this.setState({ saveQueryName: "", saveModalError: "" });
  };
  handleAdvanceSearchClick = () => {
    const filterFields = this.props.FilterFields;
    if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
      this.props.loadFilterFields();
    }
    const element = document.getElementById("MainBody");
    if (element) element.classList.add("scrollDisable");
    this.props.mainFrameState.setState({
      showAdvanceSearchDropDown: {
        ...this.props.mainFrameState.state.showAdvanceSearchDropDown,
        visible: !this.props.mainFrameState.state.showAdvanceSearchDropDown
          .visible,
      },
    });
  };
  getSavedSearchList() {
    this.props.handleSavedSearchPaginate({
      ...this.state.pagination,
      emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
      isSavedResult: true,
      avoidDetails: true,
    });
  }
  handleSavedSearchPagination = (pageNumber, pageSize) =>
    this.setState(
      { pagination: { pageNumber: pageNumber, pageSize: pageSize } },
      () => this.getSavedSearchList()
    );

  handleSetSaved = async (savedSearchRow) => {
    let data = savedSearchRow?.searchHistory?.metadata;
    data = JSON.parse(data);
    const tab = getTabNameFromSearchCriteria(data?.searchCriterias);
    this.props.setSearchData({ searchTab: tab, ...data });
    this.handleLoad(false);
  };

  handleDelete = (record) => {
    this.setState({
      selectedTitle: record.searchKeyword,
      showSavedSearchDeleteModal: true,
      selectedData: record,
    });
  };

  handleLoad = (value) => {
    this.setState({ showLoadMenu: value });
    if (value === true) {
      this.getSavedSearchList();
    }
  };
  exportHandler = (data) => {
    const metaData = JSON.parse(data.searchHistory.metadata);
    let textData =
      metaData.searchType == SEARCH_TYPE.NORMAL_SEARCH
        ? handleFormatTextData(metaData)
        : handleFormatAdvanceSearchTextData({
            data: metaData.advanceSearchPayloadData,
            searchWord: metaData.searchWords,
            value: metaData.value,
          });
    const element = document.createElement("a");
    const file = new Blob([textData], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "Saved Query " + data.searchKeyword + ".txt";
    document.body.appendChild(element);
    element.click();
  };

  render() {
    return template.call(this);
  }
}
export default HomeHeader;
