import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ROUTES } from "../../Constants/Routes/Routes.constants";
import style from "./style.module.scss";

function MainLogo() {
  const history = useHistory();
  return (
    <Link
      className={style.logoElement}
      onClick={() => {
        history.push(ROUTES.HOME);
        window.location.reload();
      }}
      to={ROUTES.HOME}
    >
      <span>
        <svg
          className="c-logo"
          width="169"
          height="60"
          viewBox="0 0 169 60"
          fill="rgba(var(--foreground-r), var(--foreground-r), var(--foreground-r), 1)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M41.1292 35.6477C41.098 35.4819 41.0772 35.3161 41.0772 35.1192V14.6425H31.6426V36.4249C31.6426 42.1762 35.6682 44.7876 40.7859 44.7876C40.9003 44.7876 41.0148 44.7876 41.1292 44.7876V35.6477Z"></path>
          <path d="M16.9447 13.8135C12.1911 13.8135 9.38253 15.2332 7.31255 17.0259V14.6528H0V60H9.50736V43.2539C11.2861 44.3212 13.6681 45.1606 16.9447 45.1606C24.6526 45.1606 28.4597 40.4145 28.4597 29.4922C28.4597 18.5699 24.663 13.8135 16.9447 13.8135ZM13.7305 37.5026C11.3589 37.5026 9.51776 36.2591 9.51776 36.2591V22.8497C9.51776 22.8497 11.3693 21.5959 13.7305 21.5959C18.1201 21.5959 19.0771 24.3938 19.0771 29.4922C19.0771 34.5907 18.1097 37.5026 13.7305 37.5026Z"></path>
          <path d="M71.7004 17.8756C70.265 15.3782 67.4877 13.8135 62.5988 13.8135C59.3222 13.8135 55.5775 14.7668 52.2593 16.7876V14.6528H45.3732V44.3316H54.8909V23.0674C55.7751 22.4767 57.8555 21.5233 60.1647 21.5233C62.734 21.5233 63.4933 23.0052 63.4933 25.2021V44.3212H73.0007V23.7824C73.0007 21.5337 72.647 19.5026 71.7108 17.8756"></path>
          <path d="M107.202 48.5181C107.15 46.0829 106.62 44.114 105.215 42.6736C103.978 41.399 102.064 40.5492 99.2135 40.1658L87.7298 38.6839C86.8976 38.5699 86.5648 38.2073 86.5648 37.6166C86.5648 37.0881 86.7936 36.7357 86.9809 36.487C87.917 36.601 88.9364 36.601 89.9974 36.601C99.2239 36.601 103.374 32.6839 103.374 25.2021C103.374 24.0725 103.249 22.943 103.062 21.9378L105.226 21.3679L106.64 20.9948V14.6425H96.9667C94.8239 13.9275 92.7435 13.8031 90.0078 13.8031C80.8021 13.8031 76.6414 17.7202 76.6414 25.2021C76.6414 29.7824 78.1808 32.9223 81.4574 34.8187C79.5643 36.1865 78.3161 38.2694 78.3161 39.9275C78.3161 41.9378 79.2106 43.772 81.0414 44.7357C78.004 45.3886 74.8626 47.8756 74.8626 51.9171C74.8626 57.6684 81.4574 59.9896 88.9468 59.9896C98.0381 59.9896 107.233 56.9016 107.233 48.8808C107.233 48.7565 107.233 48.6321 107.233 48.5078M90.0078 20.8083C92.9204 20.8083 94.3558 22.2902 94.3558 25.2021C94.3558 28.114 92.9308 29.5959 90.0078 29.5959C87.0849 29.5959 85.6702 28.114 85.6702 25.2021C85.6702 22.2902 87.1057 20.8083 90.0078 20.8083M89.9558 52.8601C85.2541 52.8601 83.6731 51.5026 83.6731 49.3575C83.6731 48.0518 84.6612 46.8083 85.9199 46.342L96.197 47.6373C97.3932 47.8238 97.8613 48.4145 97.8613 49.3575C97.8613 51.3161 94.4806 52.8601 89.9558 52.8601"></path>
          <path d="M120.423 13.8135C116.21 13.8135 111.748 14.8808 109.74 15.772V23.7202C112.341 22.5907 116.678 21.4715 120.423 21.4715C122.992 21.4715 123.939 22.1865 123.939 23.5544V25.4508L118.53 25.9793C115.108 26.3212 111.477 26.9326 109.293 29.4197C108.055 30.8186 107.296 32.829 107.296 35.7202C107.296 37.8342 107.868 40.0725 109.293 41.8446C110.853 43.7927 113.443 45.1606 117.406 45.1606C120.964 45.1606 123.97 43.9689 126.134 42.0725V44.3316H133.134V25.0363C133.134 18.3731 131.127 13.8135 120.413 13.8135M123.928 37.2021C122.992 38.1554 121.38 38.6218 119.643 38.6218C117.635 38.6218 116.2 37.3161 116.2 35.1192C116.2 32.8083 117.271 31.4922 119.945 31.1399L123.928 30.6114V37.2021V37.2021Z"></path>
          <path d="M143.807 16.7876V14.6528H136.92V44.3316H146.438V23.3679C147.187 22.6528 149.808 21.6477 152.721 21.6477C153.969 21.6477 155.165 21.772 155.634 21.886V14.2383C155.217 14.0518 154.156 13.8135 152.96 13.8135C148.519 13.8135 145.294 15.3679 143.807 16.7876"></path>
          <path d="M160.907 18.7668H161.739V13.9793H163.185V13.2228H159.472V13.9793H160.907V18.7668Z"></path>
          <path d="M164.652 14.601L166.025 18.0104H166.816L168.199 14.601L168.168 18.7668H169V13.2228H167.929L166.42 17.0052L164.933 13.2228H163.841V18.7668H164.673L164.652 14.601Z"></path>
          <path
            d="M31.4658 4.81865H36.3027V0L31.4658 4.81865Z"
            fill="#57FB00"
            className={style.logoSpote}
          ></path>
          <path d="M36.3027 0V4.81865H31.4658V9.63731H36.3027H41.1292V4.81865V0H36.3027Z"></path>
        </svg>
      </span>
    </Link>
  );
}

export default MainLogo;
