import { connect } from "react-redux";
import {
  FilterFields,
  updatePreferenceSettings,
} from "../../../../Redux/Actions";
import Component from "./SystemSettings";

function mapStateToProps(state) {
  return {
    Format: state.Settings.format,
    sortOrder: state.Settings.sortOrder.type,
    preferenceSettingLoading: state.Settings.preferenceSettingLoading,
    FilterSettingsList: state.FilterFields.settings,
    FilterSettingsListLoading: state.FilterFields.loading,
    hideResultsZeroCount: state.Settings.hideResultsZeroCount,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updatePreferenceSettings: (params) => {
      dispatch(updatePreferenceSettings(params));
    },
    loadFilterFields: () => dispatch(FilterFields()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
