import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { connect, useSelector } from "react-redux";

import { Card } from "../../../Components/Card";
import ButtonBlock from "../Children/buttonBlock";
import TooltipContainer from "../../Tooltip";
import { MetadataBlock } from "./MetadataBlock";
import { handleDateFormatter } from "../Helper";
import {
  removeEMTag,
  arrayToString,
  getCardTitle,
  getDatePublished,
} from "../../../utils/dataFormatter";
import Loader from "../../Loader";
import { getFullContent } from "../../../api/search";

import SVGIcons from "../../SVGIcons";

import global from "../../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import { handleWebsiteDomain } from "../../../utils/commonUtils";
import { notify } from "Components/Notification";
import { useMemo } from "react";
import { Checkbox, Space } from 'antd';
import {documentSelectionChangeAction , tagSelectionChangeAction} from '../../../Redux/Reducers/Home/HomeState'

const DisplayResultCardComponent = props => {
  const allFieldsWithDatatype = useSelector(
    (state) => state.FilterFields.allFieldsWithMetadata
  );
  const cardTitle = getCardTitle(props.data, allFieldsWithDatatype);
  const checked = props.selectedDocuments.indexOf(props.data?.DocumentId)!==-1;
  const onCheckboxChange = (e)=>{
    props.documentSelectionChange({documentIds:[props.data?.DocumentId], checked: e.target.checked, userTags:props.data?.user_tags})
  }

  const handleTagSelection = (tag)=>{
    props.tagSelectionChange({tag})
  }

  return (
    <Card className={global.resultCard}>
      <div className={global.resultCard__header}>
         <div className={global.resultCard__header__titleCheckbox}>
            <TooltipContainer title={removeEMTag(cardTitle || "")} placement="bottom">
              <h3>
                {parse(cardTitle ||"Title not available")}
              </h3>
            </TooltipContainer>
            <TooltipContainer title="Select this artcile" placement="bottom" >
              <Checkbox checked = {checked} onChange={onCheckboxChange}>
              </Checkbox>
            </TooltipContainer>
          </div>
        <div className={global.headerBottom}>
          <div className={global.headerBottom__searchcontent}>
            <SearchCardContent
              websiteDomain={handleWebsiteDomain(props.data || {})}
              datePublished={getDatePublished(
                props.data,
                allFieldsWithDatatype
              )}
              dateFormat={props.dateFormat}
            />
          </div>
          <div className={global.headerBottom__controlsicon}>
            <ul className={global.controlsList}>
              <ButtonBlock
                handleTagsList={props.handleTagsList}
                showPreview={props.setShowPreview}
                article={props.data}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className={global.resultCard__content}>
        {props.ListItems?.length > 0 && (
          <div className={global.tagsBlock}>
            <ul className={global.tagList}>
              <UserTagsView tags={props.ListItems} handleTagSelection={handleTagSelection} selectedTags = {props.selectedTags}  />
            </ul>
          </div>
        )}

        <div className={global.articleText}>
          {/* {props.data.summary && <ArticleContent articleContent={arrayToString(props.data.summary)} />} */}
          {/* {props.data.Summary && <ArticleContent articleContent={arrayToString(props.data.Summary)} />} */}
          {props.data.Content && (
            <ArticleContent
              listViewType={props.listViewType}
              articleContent={props.data.Content}
              documentId={props.data.DocumentId}
              expandAllCard={props.expandAllCard}
              handleExpandCardActive={props.handleExpandCardActive}
              contentLength={props.data.TextLength}
              canvasContext={props.canvasContext}
            />
          )}
        </div>

        <div className={global.resultMetatag}>
          <MetadataBlock
            metaDataList={props.metaDataList}
            data={props.data}
            currentTabType={props.currentTabType}
            expandAllTags={props.expandAllTags}
          />
        </div>
      </div>
    </Card>
  );
}

function SearchCardContent({ websiteDomain, datePublished, dateFormat }) {
  let filepath = websiteDomain?.testFile ? true : false;
  let url = websiteDomain?.url ? websiteDomain.url : websiteDomain;
  return (
    <ul className={global.searchitemList}>
      <li>
        <TooltipContainer
          title={filepath ? "File path" : "Website domain"}
          placement={"left"}
        >
          <span>
            {filepath ? (
              <SVGIcons type="SVG-folder" />
            ) : (
              <SVGIcons type="SVG-websource" />
            )}
          </span>
        </TooltipContainer>
        <span>{parse(arrayToString(url) || "Website is not available")}</span>
      </li>
      <li>
        <TooltipContainer title={"Publish date"} placement="bottom">
          <span>
            <SVGIcons type="SVG-publisheddate" />
          </span>
        </TooltipContainer>
        <span>{handleDateFormatter(datePublished, dateFormat)}</span>
      </li>
    </ul>
  );
}

/**
 *
 * @param {{tags: string[]}} param
 * @returns {JSX.Element}
 */
function UserTagsView({ tags, handleTagSelection, selectedTags }) {
  return (
    <>
      {tags.map((item, i) => (
        <li key={i + item}>
          <div className={style.userTagElement}>
            <span>
              <SVGIcons type="SVG-tags" />
            </span>
            <span
              onClick={() => handleTagSelection(item)}
              className={selectedTags?.includes(item) ? style.selectedTag  : style.normalTag}
              style={{ cursor: 'pointer' }} 
            >{parse(item)}</span>
          </div>
        </li>
      ))}
    </>
  );
}

/**
 * @param {{articleContent: string | string[], documentId: number, contentLength: number,
 * expandAllCard: boolean, handleExpandCardActive: any, listViewType: string }} props
 * @returns {JSX.Element}
 */
function ArticleContent({
  articleContent,
  documentId,
  expandAllCard,
  handleExpandCardActive,
  contentLength,
  listViewType,
  canvasContext,
}) {

  const getWindowDimensions = () => {
    const res = window.innerWidth;
    return res;
  };

  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

  const [content] = useState(
    formatContent(
      articleContent,
      isActualContentGTCurrent(articleContent, contentLength)
    )
  );

  function getTextWidth(text) {
    const metrics = canvasContext.measureText(text);
    return metrics.width;
  }

  const ARTICLE_VIEW_LESS_COUNT = useMemo(() => {
    //Accroding to chatgpt, 70-75% of all characters in an English article are lowercase, 5-10% is upper case , 1-5% is number, so use 15:26:26*8
    const refContent = "0123456789012345ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz";
    const refWidth = getTextWidth(refContent);
    const refLength = refContent.length;
    const baseWidth = getTextWidth(content.substring(0, refLength));
    const adjustment = 0.85 * refWidth / baseWidth; //added 0.85 to further adjust as both English and Chinese article displayed one line more than required

    if (listViewType == "gridOne") { // listview
      return Math.floor(adjustment * (windowWidth - 60) / 1.65) //900
    }

    if (listViewType == "gridTwo") {
      return Math.floor(adjustment * windowWidth / 3)
    }
    return Math.floor(adjustment * windowWidth / 6)// 250;

  }, [listViewType, windowWidth]);

  /** values : 'viewLess' || 'viewMore' || 'viewFullArticle' || 'loading' || 'show' || 'hide' */
  const [viewType, setViewType] = useState(
    expandAllCard ? "viewMore" : "viewLess"
  );
  const [trimmedContent, setTrimmedContent] = useState("");
  const [fullContent, setFullContent] = useState("");
  const [showFullContent, setShowFullContent] = useState(false);

  const searchWord = useSelector((state) => state.Search.search.value);

  const toggleViewMore = (type) => async () => {
    if (type == "viewFullArticle") {
      if (fullContent === "") {
        setViewType("loading");
        const { data } = await getFullContent({ searchId: documentId, advanceSearchKeyword: searchWord }).catch(
          () => {
            notify.error("Failed to get full article");
            setViewType("viewLess");
          }
        );
        setFullContent(data.result.content);
      }
      setShowFullContent(true);
      setViewType(type);
    } else if (type == "viewLess" && searchWord) {
      setShowFullContent(false);
      setViewType(type);
    } else setViewType(type);
  };
  useEffect(() => {
    let trimmedText = content;
    if (viewType == "viewLess" && content.length > ARTICLE_VIEW_LESS_COUNT) {
      trimmedText = content.substring(0, ARTICLE_VIEW_LESS_COUNT).concat("...");
    }
    setTrimmedContent(trimmedText);
    if (viewType == "viewLess") {
      //handleExpandCardActive();
    }
  }, [viewType, ARTICLE_VIEW_LESS_COUNT]);

  useEffect(() => {
    if (expandAllCard && searchWord) {
      toggleViewMore("viewFullArticle")();
    } else if (expandAllCard && !searchWord) {
      setViewType("viewMore");
    } else if (!expandAllCard) {
      toggleViewMore("viewLess")();
    }
  }, [expandAllCard]);

  const renderToggleViewMore = () => {
    switch (viewType) {
      case "viewLess":
        return <a onClick={toggleViewMore("viewMore")}>View More</a>;
      case "viewMore":
        return searchWord &&
          isActualContentGTCurrent(articleContent, contentLength) ? (
          <a onClick={toggleViewMore("viewFullArticle")}>View full Article</a>
        ) : (
          <a onClick={toggleViewMore("viewLess")}>View Less</a>
        );
      case "viewFullArticle":
        return <a onClick={toggleViewMore("viewLess")}>View Less</a>;
      case "loading":
        return (
          <a>
            <Loader type="circular" classList={["xs blue"]} />
          </a>
        );
    }
  };



  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return content.length ? (
    <p>
      {parse(fullContent && showFullContent ? fullContent : trimmedContent)}

      {content.length > ARTICLE_VIEW_LESS_COUNT ? (
        renderToggleViewMore()
      ) : searchWord &&
        isActualContentGTCurrent(content, contentLength) &&
        viewType === "viewFullArticle" ? (
        <a onClick={toggleViewMore("viewLess")}>{<>View Less</>}</a>
      ) : searchWord &&
        isActualContentGTCurrent(content, contentLength) &&
        viewType === "loading" ? (
        <a>
          <Loader type="circular" classList={["xs blue"]} />
        </a>
      ) : searchWord &&
        isActualContentGTCurrent(content, contentLength) &&
        viewType !== "viewFullArticle" ? (
        <a onClick={toggleViewMore("viewFullArticle")}>
          {<>View full Article</>}
        </a>
      ) : (
        <></>
      )}
    </p>
  ) : (
    <p>Content not available</p>
  );
}

/**
 * @param {string | string[]} content
 * @param {boolean} isActualContentGTCurrent
 * @returns {string}
 */
function formatContent(content, isActualContentGTCurrent) {
  return Array.isArray(content)
    ? isActualContentGTCurrent
      ? content.reduce((acc, curr) => acc + "..." + curr + "...<br />", "")
      : content.join(" ")
    : content;
}

/**
 * @param {string| string[]} articleContent
 * @param {number} actualContentLength
 */
function isActualContentGTCurrent(articleContent, actualContentLength) {
  return Array.isArray(articleContent)
    ? actualContentLength > removeEMTag(articleContent.join(" ")).length + 1
    : actualContentLength > articleContent.length + 1;
}

const mapStateToProps = state => {
  const fromState = {
      selectedDocuments: state.Home.selectedDocuments,
      selectedTags: state.Home.selectedTags,
  };
  return fromState;
};

const mapDispatchToProps = dispatch => {
  return {
      documentSelectionChange: (payload) => dispatch(documentSelectionChangeAction(payload)),
      tagSelectionChange: (payload) => dispatch(tagSelectionChangeAction(payload)),
  };
};

const DisplayResultCard = connect(mapStateToProps, mapDispatchToProps)(DisplayResultCardComponent);
export default DisplayResultCard;