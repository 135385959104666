import React from "react";
import SVGIcons from "../SVGIcons";
import Loader from "../Loader";
import { Button } from "antd";
import PropTypes from "prop-types";
import "./style.scss";
const ButtonElement = (props) => {
  return (
    <Button
      style={props.styleProps || {}}
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      variant={props.variant}
      className={props.className}
      danger={props.danger}
      size={props.size}
      shape={props.shape}
      type={props.type || null}
    >
      {props.loading ? (
        <span className="button-loader-item">
          <Loader type="circular" classList={props.loaderClassName || ["xs"]} />
        </span>
      ) : props.icon ? (
        <span className="button-icon-pane">
          <SVGIcons className={props.iconClassName} type={props.icon} />
        </span>
      ) : (
        ""
      )}
      {props.children}
    </Button>
  );
};
ButtonElement.propTypes = {
  className: PropTypes.string,
  styleProps: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  danger: PropTypes.string,
  size: PropTypes.string,
  shape: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  loaderClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  iconClassName: PropTypes.string,
};
export default ButtonElement;
