import {notify} from '../../../../Components/Notification';
import {INITIAL_STATE} from '../initialState';

export function UpdateSaveInsightsSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
  };
}

export function UpdateSaveInsightsStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function UpdateSaveInsightsFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}
