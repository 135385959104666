import {ACTIVITY_LOG_FETCH_FAILED, ACTIVITY_LOG_FETCH_SUCCESS, ACTIVITY_LOG_FETCH_STARTED} from '../../Redux.constants';
import {fetchActivityLogFailed, fetchActivityLogStarted, fetchActivityLogSuccess} from './Helper';
import {INITIAL_STATE} from './initialState';

export default function UserLogs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVITY_LOG_FETCH_SUCCESS:
      return fetchActivityLogSuccess(state, action.payload);
    case ACTIVITY_LOG_FETCH_STARTED:
      return fetchActivityLogStarted(state, action.payload);
    case ACTIVITY_LOG_FETCH_FAILED:
      return fetchActivityLogFailed(state, action.payload);
    default:
      return {...state};
  }
}
