import React from "react";
import { random } from "lodash";
import { LineChart } from "../Components/LineChart";
import WordCloud from "../Components/WordCloud";
import StreamChart from "../Components/StreamChart";
import TreeChart from "../Components/TreeChart/TreeChart";
import { BarChart } from "../Components/Barchart";
import ApexHeatMap from "../Components/ApexHeatMap";

export function switchChartsInsights({
  data,
  chartName,
  config,
  WordCloudClickAction,
  breadCrumbData,
  isPreview,
}) {
  switch (chartName) {
    case "LINE_CHART":
      return (
        <LineChart
          sortedData={data || []}
          config={config}
          id={"Line" + random(0, 1e6)}
        />
      );
    case "BAR_CHART":
      return (
        <BarChart
          data={data || []}
          config={config}
          id={"Column" + random(0, 1e6)}
          isPreview={isPreview}
        />
      );
    case "KEY_PHRASE_CLOUD":
      return (
        <WordCloud
          data={data}
          config={config}
          id={"key_phrase" + random(0, 1e6)}
          WordCloudClickAction={WordCloudClickAction}
          breadCrumbData={breadCrumbData}
        />
      );
    case "HEAT_MAP":
      return (
        <ApexHeatMap
          data={data}
          config={config}
          id={"HeatMap" + random(0, 1e6)}
        />
      );
    case "STREAM_CHART":
      return (
        <StreamChart
          data={data}
          config={config}
          id={"Stream" + random(0, 1e6)}
          yAxisKeys={[]}
        />
      );
    case "COLLAPSIBLE_TREE_MAP":
      return (
        <TreeChart
          data={data}
          config={config}
          id={"TreeMap" + random(0, 1e6)}
        />
      );
    default:
      return null;
  }
}

/**
 * Function for Stream chart - to get y axis keys
 * extracts the keys for y Axis from data and remove duplicates
 * @param {Object} data - formatted data which is to render in graph
 * @param {Object} config
 * @returns
 */
const getAxisKeys = (data, config) => {
  let yAxisKeys = data?.map((item) =>
    Object.keys(item).filter((key) => key !== config.categoryField)
  );

  yAxisKeys = yAxisKeys.flat();
  yAxisKeys = [...new Set(yAxisKeys)];

  return yAxisKeys;
};
