import React from "react";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import ProfileAvatar from "../../../public/img/avatar.png";
import { ContentLoader } from "Components/ContentLoader";

const ProfileOptions = (props) => {
  const CurrentUserProfile = useSelector((state) => state.Settings.profile);
  const showCurrentUser = () => {
    return CurrentUserProfile.userProfile == 2
      ? "Analyst"
      : CurrentUserProfile.userProfile == 1
      ? "Admin"
      : CurrentUserProfile.userProfile == 3
      ? "Super Admin"
      : "";
  };
  return (
    <div
      className={style.profileBlock}
      onClick={
        CurrentUserProfile.userProfile == 3
          ? props.superAdminCredChange
          : undefined
      }
    >
      <div className={style.profileBlock__image}>
        <span>
          <img src={ProfileAvatar} />
        </span>
      </div>
      <div className={style.profileBlock__name}>
        {CurrentUserProfile?.profileLoading ? (
          <div className={style.loaderDiv}>
            {" "}
            <ContentLoader width={100} height={1.2} spaceBetween={0} />
          </div>
        ) : (
          <>
            {CurrentUserProfile?.firstname && (
              <h4>{`${CurrentUserProfile.firstname} ${CurrentUserProfile.lastname}`}</h4>
            )}
            <p>{showCurrentUser()}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileOptions;
