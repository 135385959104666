import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from "moment";

import style from "../style.module.scss";
import { checkFromDateIsGreater, checkToDateIsLesser } from "./helper";
import { DATE_FILTER_KEY } from "../../../../../Routes/Home/constants";

function BetweenDates(props) {
  const [date, setDate] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [keyRenderFrom, setKeyRenderFrom] = useState(
    Math.floor(Math.random() * 1e5)
  );
  const [keyRenderTo, setKeyRenderTo] = useState(
    Math.floor(Math.random() * 1e5)
  );

  const disabledDateTodayAfter = (current) => {
    return current && moment(current).startOf("day") > moment().endOf("day");
  };

  const onDateChangeFrom = (event) => {
    setDate({ ...date, from: event });
    if (event == null) {
      setKeyRenderFrom(Math.floor(Math.random() * 1e5));
    }
    if (date.to == null && event == null)
      return props.handleChangeDate({ e: null, name: "to", type: "between" });
    checkFromDateIsGreater({ from: event, to: date.to, setErrorMessage }) &&
      date?.to &&
      props.handleChangeDate({
        e: { from: event, to: date.to },
        name: "from",
        type: "between",
      });
  };

  const onDateChangeTo = (event) => {
    setDate({ ...date, to: event });
    if (event == null) {
      setKeyRenderTo(Math.floor(Math.random() * 1e5));
    }
    if (date.from == null && event == null)
      return props.handleChangeDate({ e: null, name: "to", type: "between" });
    checkToDateIsLesser({
      from: date.from.format("YYYY-MM-DD"),
      to: event.format("YYYY-MM-DD"),
      setErrorMessage,
    }) &&
      date?.from &&
      props.handleChangeDate({
        e: { to: event, from: date.from },
        name: "to",
        type: "between",
      });
  };

  useEffect(() => {
    if (!props.filterResults?.[DATE_FILTER_KEY]?.from) {
      setDate({ to: date.to, from: null });
      setErrorMessage("");
    }
    if (!props.filterResults?.[DATE_FILTER_KEY]?.to) {
      setDate({ from: date.from, to: "" });
      setErrorMessage("");
    }
    if (
      !props.filterResults?.[DATE_FILTER_KEY]?.to &&
      !props.filterResults?.[DATE_FILTER_KEY]?.from
    ) {
      setDate({ from: "", to: "" });
      setErrorMessage("");
    }
  }, [props.filterResults]);

  return (
    <>
      <div className={`${style["dateChoose"]}`}>
        <div className={style.dateChoose__items}>
          <label>From</label>
          <DatePicker
            size={"small"}
            className="filter-picker"
            onChange={onDateChangeFrom}
            value={date?.from}
            format={props.dateFormat}
            placeholder={props.dateFormat}
            suffixIcon={false}
            disabledDate={disabledDateTodayAfter}
            showNow={true}
            key={keyRenderFrom}
          />
        </div>
        <div className={style.dateChoose__items}>
          <label>To</label>
          <DatePicker
            size={"small"}
            className="filter-picker"
            onChange={onDateChangeTo}
            value={date?.to}
            format={props.dateFormat}
            placeholder={props.dateFormat}
            suffixIcon={false}
            disabledDate={disabledDateTodayAfter}
            key={keyRenderTo}
          />
        </div>
      </div>
      {((!date.from && date.to) || (date.from && !date.to)) && (
        <label className={style.dateWarning}> * Both dates are required</label>
      )}
      {errorMessage != "" && date?.from != null && date?.to != null && (
        <label className={style.dateWarning}> * {errorMessage}</label>
      )}
    </>
  );
}
BetweenDates.propTypes = {
  /**
   * pickerType
   */
  pickerType: PropTypes.any,
  /**
   * dateFormat
   */
  dateFormat: PropTypes.any,
  /**
   * showTime
   */
  showTime: PropTypes.any,
  /**
   * filterResults
   */
  filterResults: PropTypes.any,
  /**
   * handleChangeDate
   */
  handleChangeDate: PropTypes.func,
  /**
   *
   */
};
export default BetweenDates;
