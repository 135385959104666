import {
  UPDATE_SAVE_INSIGHT_FAILED,
  UPDATE_SAVE_INSIGHT_SUCCESS,
  UPDATE_SAVE_INSIGHT_STARTED,
} from '../../Redux.constants';
import {updateSaveInsights} from '../../../api/insights';
import {handleApiError} from '../../../utils/commonUtils';

export function UpdateSaveInsights({params, handleEditUpdate, isEditDashboardUpdate}) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SAVE_INSIGHT_STARTED,
      payload: {},
    });
    try {
      const data = await updateSaveInsights(params);
      dispatch({
        type: UPDATE_SAVE_INSIGHT_SUCCESS,
        payload: data,
      });
      handleEditUpdate(isEditDashboardUpdate);
    } catch (error) {
      handleApiError(error, () => {
        handleEditUpdate('true');
        dispatch({
          type: UPDATE_SAVE_INSIGHT_FAILED,
          payload: error,
        });
      });
    }
  };
}
