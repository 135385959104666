import React from "react";

import TableElement from "../../../../Components/TableElement";
import ShowModalwindowSuspend from "./TableActionModal/ShowModalWindowSuspend";
import { ROLE_SETTINGS_CONFIG } from "./RoleSettings.config";
import { ContentLoader } from "../../../../Components/ContentLoader";
import ViewUserInfoWindow from "./TableActionModal/ViewUserInfoWindow";
import Pagination from "../../../../Components/Pagination";
import ContentBody from "../../../../Components/ContentBody";

import globel from "../../../../styles/globel/style.module.scss";
import ErrorState from "../../../../Components/ErrorState";
import { DEFAULT_PAGE_COUNT } from "../../../../constants";
import ButtonElement from "../../../../Components/ButtonElement";
import { ShowUserModalWindow } from "./TableActionModal/ShowUserModalWindow";
import { ShowDeleteUserModalwindow } from "./TableActionModal/ShowDeleteUserModalWindow";
import style from "./style.module.scss";

function template(props) {
  return (
    <>
      <ContentBody>
        {this.props.userListloading ? (
          showContentLoader()
        ) : (
          <>
            {this.props.error ? (
              <ErrorState
                customHeight={"600"}
                content="ERROR"
                type={"errorImage"}
                errorMessage={this.props.errorMessage}
              />
            ) : (
              <>
                {this.props.userDetails.length > 0 && (
                  <>
                    <div className={globel.paginationWrapper}>
                      <div className={style.addButtonWrapper}>
                        <ButtonElement
                          size={"small"}
                          onClick={this.handleModalVisibility}
                          children={"Add User"}
                        />
                      </div>
                      <ShowUserModalWindow
                        title={"Add User"}
                        buttonTitle={"Add"}
                        onCancel={() => this.handleModalVisibility()}
                        visible={this.state.modalVisibility}
                        onChange={this.onChangeUserDetails}
                        onSubmit={() => this.onSubmitUserDetails()}
                        loader={this.props.addUserLoading}
                        userDetails={this.state}
                      />
                    </div>
                    <div className={globel.paginationWrapper}>
                      <div className={globel.searchresultCount}>
                        <span>
                          {this.props.userDetails.length > 0
                            ? "Showing " +
                              this.props.userDetails.length +
                              " out of " +
                              this.props.totalCount +
                              " Results"
                            : ""}
                        </span>
                      </div>
                      <div className={globel.searchresultPage}>
                        {this.props.totalCount > DEFAULT_PAGE_COUNT
                          ? UserPagination({
                              ...this.state.pagination,
                              totalCount: this.props.totalCount,
                              onChange: this.handleUserPagination,
                            })
                          : ""}
                      </div>
                    </div>
                    <TableElement
                      columns={ROLE_SETTINGS_CONFIG.COLUMNS({
                        sortedInfo: this.state.sortedInfo,
                        handleEdit: this.handleEdit,
                        handleSuspendUser: this.handleSuspendUser,
                        showViewUserModal: this.showViewUserModal,
                        handleDelete: this.handleDelete,
                        currentProfileId: this.props?.CurrentUserProfile
                          ?.emailid,
                      })}
                      data={this.props.userDetails.map((item, key) => ({
                        ...item,
                        key,
                      }))}
                      onChange={this.handleChange}
                      scrollY={"calc(100vh - 270px)"}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentBody>
      {/* <ShowModalwindowEdit {...this} /> */}
      <ShowUserModalWindow
        title={"Update User"}
        buttonTitle={"Update"}
        onCancel={() => this.handleModalEditVisibility()}
        visible={this.state.editModalWindow}
        onChange={this.onChangeUserDetails}
        onSubmit={() => this.onUpdateUserDetails()}
        userDetails={this.state}
        loader={this.props.updateUserLoading}
      />
      <ShowDeleteUserModalwindow
        visible={this.state.deleteModalWindow}
        onCancel={this.cancelDelete}
        confirmDelete={this.onConfirmDelete}
        loader={this.props.deleteUserLoading}
      />
      <ShowModalwindowSuspend {...this} />
      {this.state.viewUserModal && <ViewUserInfoWindow {...this} />}
    </>
  );
}

export default template;

function showContentLoader() {
  return <ContentLoader height={2} width={100} number={10} hasWrapper={true} />;
}

function UserPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return (
    <Pagination
      pageSize={pageSize}
      current={pageNumber}
      total={totalCount}
      onChange={onChange}
      showSizeChanger={true}
      pageSizeOptions={["10", "20", "50", "100"]}
    />
  );
}
