import { reFormatFilterLabel } from "./formatter";
import { generateInsightsPayloadEdit } from "./payloadGenerators";
import {
  WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL,
  WORD_CLOUD_MINIMUM_OCCURANCE_LABEL,
  STREAM_CHART_PRIMARY_FIELD_AGGREGATION_LABEL,
  TREE_MAP_PRIMARY_FIELD_AGGREGATION_LABEL,
  TREE_MAP_SECONDARY_FIELD_AGGREGATION_LABEL,
  HEAT_MAP_DATA_SERIES_TO_PLOT,
  WORD_CLOUD_FIELD_LABEL,
} from "../../../../../Constants/Insights/CreateInsights.constants";
import {
  WORD_CLOUD_DEFAULT_WORD_COUNT,
  WORD_CLOUD_MINIMUM_OCCURRENCE_COUNT_DEFAULT,
} from "../../../../../Constants/Insights/Insights.constants";

export const clearInputHandler = ({ event, state, fields }) => {
  if (event.allowClear) {
    let newallFieldsArray = state.state.allFields;
    newallFieldsArray[
      state.state.selectedField[fields].index
    ] = reFormatFilterLabel(state.state.selectedField[fields].value);
    state.setState({
      allFields: newallFieldsArray,
      selectedField: {
        ...state.state.selectedField,
        [fields]: {
          value: "",
          index: "",
        },
      },
    });
  }
};

export const getChartParamsDefaultValue = (value) => {
  let parameterDefaultValues;
  if (value == "KEY_PHRASE_CLOUD") {
    parameterDefaultValues = {
      [WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL]: {
        value: WORD_CLOUD_DEFAULT_WORD_COUNT,
        index: "",
      },
      [WORD_CLOUD_MINIMUM_OCCURANCE_LABEL]: {
        value: WORD_CLOUD_MINIMUM_OCCURRENCE_COUNT_DEFAULT,
        index: "",
      },
      [WORD_CLOUD_FIELD_LABEL]: { value: "Keywords", index: "" },
    };
  } else if (value == "COLLAPSIBLE_TREE_MAP") {
    parameterDefaultValues = {
      [TREE_MAP_PRIMARY_FIELD_AGGREGATION_LABEL]: { value: "count", index: "" },
      [TREE_MAP_SECONDARY_FIELD_AGGREGATION_LABEL]: {
        value: "count",
        index: "",
      },
    };
  } else if (value == "STREAM_CHART") {
    parameterDefaultValues = {
      [STREAM_CHART_PRIMARY_FIELD_AGGREGATION_LABEL]: {
        value: "count",
        index: "",
      },
    };
  } else if (value == "HEAT_MAP") {
    parameterDefaultValues = {
      [HEAT_MAP_DATA_SERIES_TO_PLOT]: { value: "count", index: "" },
    };
  } else {
    parameterDefaultValues = {};
  }
  return parameterDefaultValues;
};

export const createInsigtsDidMountHelper = (self) => {
  if (
    self.props.location &&
    self.props.location.state &&
    self.props.location.state.isEditDashboard
  ) {
    self.setState({ isEditDashboard: true });
  }
  if (
    self.props.location &&
    self.props.location.state &&
    self.props.location.state.isEdit
  ) {
    self.setState({
      selectedChart: self.props.location.state.chartInfo?.chartName,
      selectedField: self.props.location.state.chartInfo?.payload,
      insightName: self.props.location.state.insightName,
      dataSetValue: self.props.location.state.chartInfo?.dataSet,
    });
    let payload = generateInsightsPayloadEdit(
      self.props.location.state.chartInfo
    );
  }
};

export const wordOccuranceHandler = ({ state, data }) => {
  if (state.state.selectedChart == "KEY_PHRASE_CLOUD") {
    if (state.props.createInsights.chartName == "KEY_PHRASE_CLOUD") {
      let maxValue =
        state.props.createInsights.config["KEY_PHRASE_CLOUD"].maximumCountValue;
      if (
        state.state.selectedField[WORD_CLOUD_MINIMUM_OCCURANCE_LABEL].value >
        maxValue
      ) {
        state.setState({
          maximumOccurance: maxValue,
          errorMessage: {
            [WORD_CLOUD_MINIMUM_OCCURANCE_LABEL]: `Minimum  word occurrence should be less than ${
              maxValue + 1
            }`,
          },
        });
      }
    }
  }
};

export const insightsInputResetHelper = (self) => {
  self.props.clearInsightData("");
  let SelectedFieldContent = getChartParamsDefaultValue(
    self.state.selectedChart
  );
  self.setState({
    dataSetValue: "",
    selectedChart: "",
    selectedField: SelectedFieldContent,
    allFields: self.props.payloadFields,
    errorMessage: {},
    insightName: "",
  });
  self.props.history.push({
    pathname: "/insights",
    state: {
      isEdit: false,
    },
  });
};
