import { getUserGroups, addUserGroup, updateUserGroup, deleteUserGroup } from "../../../api/userGroups";
import { notify } from '../../../Components/Notification';
import { handleApiError } from "../../../utils/commonUtils";
import {
  ADD_USER_GROUP_START,
  ADD_USER_GROUP_SUCCESS,
  ADD_USER_GROUP_FAILED,
  GET_USER_GROUP_START,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAILED,
  UPDATE_USER_GROUP_START,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAILED,
  DELETE_USER_GROUP_START,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAILED,
} from "../../Redux.constants";

export function addUserGroupAction(payload, callback) {
  return async (dispatch) => {
    dispatch({
      type: ADD_USER_GROUP_START,
      payload: {},
    });
    try {
      const { data } = await addUserGroup(payload);
      dispatch({
        type: ADD_USER_GROUP_SUCCESS,
        payload: data.result,
      });
      notify.success('Successfully added user group');
    } catch (error) {
      handleApiError(error, () => {
        dispatch({
          type: ADD_USER_GROUP_FAILED,
          payload: error,
        })
        notify.error(`Failed to add user group : ${error.response?.data}`);
      });

    }
    if (callback) callback();
  };
}

export function getUserGroupAction(payload, callback) {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_GROUP_START,
      payload: {},
    });
    try {
      const dataGroup = await getUserGroups(payload);
      dispatch({
        type: GET_USER_GROUP_SUCCESS,
        payload: {
          dataGroup: dataGroup.data.result,
          totalCount: dataGroup.data.totalCount,
          pageSize: payload.pageSize||undefined,
        }
      });
    } catch (error) {
      handleApiError(error, () => {
        dispatch({
          type: GET_USER_GROUP_FAILED,
          payload: error,
        })
        notify.error(`Failed to get user group: ${error.response?.data}`);
      });
    }
    if (callback) callback();
  };
}

export function updateUserGroupAction(payload, callback) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_GROUP_START,
      payload: {},
    });

    try {
      await updateUserGroup(payload);
      dispatch({
        type: UPDATE_USER_GROUP_SUCCESS,
      });
      notify.success('Successfully updated user group');
    } catch (error) {
      handleApiError(error, () => {
        dispatch({
          type: UPDATE_USER_GROUP_FAILED,
          payload: error,
        })
        notify.error(`Failed to update user group: ${error.response?.data}`);
      });
    }
    if (callback) callback();
  };
}

export function deleteUserGroupAction(payload, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_USER_GROUP_START,
      payload: {},
    });
    try {
      await deleteUserGroup(payload);
      dispatch({
        type: DELETE_USER_GROUP_SUCCESS,
      });
      notify.success('Successfully deleted user group');
    } catch (error) {
      handleApiError(error, () => {
        dispatch({
          type: DELETE_USER_GROUP_FAILED,
          payload: error,
        })
        notify.error(`Failed to delete user group : ${error.response?.data}`);
      });
    }
    if (callback) callback();
  };
}
