import React from "react";
import InputField from "../../../InputField";
import ModalWindow from "../../../ModalWindow";
import { FILTER_SEARCH } from "../constants";
import style from "./style.module.scss";
const SaveResultsModal = (props) => {
  return (
    <>
      <ModalWindow
        title={FILTER_SEARCH.SAVE_SEARCH}
        open={props.props.showSaveModal}
        okText="Save"
        cancelText="Cancel"
        confirmLoading={props.state.loadingResults}
        onOk={props.handleSaveResult}
        isNonMaskable={true}
        isNotClosable={true}
        onCancel={props.handleCloseModal}
      >
        <div className={style.saveResult}>
          <div className={style.saveResult__input}>
            <InputField
              onPressEnter={props.handleSaveResult}
              title={FILTER_SEARCH.SAVE_YOUR_SEARCH_RESULTS_SUB_HEADING}
              htmlForName={FILTER_SEARCH.SAVE_YOUR_SEARCH_RESULTS_SUB_HEADING}
              name={"Save-Results-Modal-Block"}
              placeholder={FILTER_SEARCH.SAVE_YOUR_SEARCH_RESULTS}
              onChange={props.handleSaveOnchange}
              autoFocus={true}
              value={props.state.saveQueryName}
              error={
                props.state.saveModalError != "" &&
                props.state.saveModalError === "empty"
                  ? "Please enter the name for saving the results"
                  : props.state.saveModalError || ""
              }
            />
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

export default SaveResultsModal;
