import { connect } from "react-redux";
import { FilterFieldValues } from "../../Redux/Actions";
import FilterSection from "./FilterSection";

function mapStateToProps(state) {
  return {
    settings: state.FilterFields.settings,
    ...state.search,
    filterLoad: state.FilterFields.loading,
    ...state.Search,
    createInsights: state.CreateInsights, //
    dashboard: state.Dashboard,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    loadFilterFieldValues: (payload) => {
      dispatch(FilterFieldValues(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
