import { clearInsightsData, setInsightDataState } from "./Helper";
import { INITIAL_STATE } from "./initialState";
import { CLEAR_INSIGHTS_DATA, SET_INSIGHT_DATA } from "../../Redux.constants";

export default function CreateInsights(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_INSIGHTS_DATA:
      return clearInsightsData(state, action.payload);
    case SET_INSIGHT_DATA:
      return setInsightDataState(state, action.payload);
    default:
      return { ...state };
  }
}
