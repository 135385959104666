import env from 'react-dotenv';

export const WELCOME_STATE = {
  NOTHING_DISPLAY_MESSAGE: 'Nothing yet to display here!',
  INFO_ONE: 'Looks like there was nothing saved previously.',
  INFO_TWO: ' Never Mind, lets start afresh!',
  ADD_INSIGHT: 'Add Insight',
};

export const SEARCH_NO_DATA_FOUND = {
  NOTHING_DISPLAY_MESSAGE: 'No matched results!',
  INFO_ONE: 'Please retry after making changes in the filter selections.',
  // INFO_TWO: '',
};

export const ADD_INSIGHT = {
  NOTHING_DISPLAY_MESSAGE: "Let's do some data juggling!",
  INFO_ONE: 'Select a dataset to start creating visualizations with the available data fields !',
};

export const CREATE_INSIGHT = {
  NOTHING_DISPLAY_MESSAGE: 'Create visualisations and discover new insights!',
  INFO_ONE: 'Start by selecting the dataset and the chart type, and then configure the chart with the parameters.',
  // INFO_TWO: 'Drag and drop the available fields to the chart parameters for creating and modifying the insights.',
};

export const CHART_NO_DATA_FOUND = {
  NOTHING_DISPLAY_MESSAGE: 'Need an optimization!',
  INFO_ONE: 'The chart could not be created with the selected parameters.',
  INFO_TWO: 'Please try changing the parameter values.',
};

export const DASHBOARD_INITIAL_STATE_TAB = {
  // NOTHING_DISPLAY_MESSAGE: 'Import the saved insights to start building a new dashboard!',
  // INFO_ONE: 'Drag and resize the insights to change layouts of charts in the dashboard. ',
  // INFO_TWO: 'The dashboard can include a maximum of 3 insights only.',
  NOTHING_DISPLAY_MESSAGE: "Let's start creating dashboards!",
  INFO_ONE: 'Create a new dashboard by importing the saved insights, or by editing the saved dashboards',
};

export const DASHBOARD_EMPTY_STATE_TAB = {
  NOTHING_DISPLAY_MESSAGE: 'Its all empty here!',
  INFO_ONE: 'Start creating custom dashboards by importing some of the saved insights.',
  INFO_TWO: 'The dashboard can include a maximum of 3 insights only.',
};

export const DASHBOARD_INFO_TABLE_EMPTY_STATE_TAB = {
  NOTHING_DISPLAY_MESSAGE: 'The data cannot be fetched now!',
  INFO_ONE: 'Unable to fetch the matching documents at the moment.',
};

export const PUBLISHED_DATE = 'Published Date';
export const DATE_FILTER_KEY = 'Date Published';
export const DATE_SORT_KEY = 'Date Published';
export const TITLE_SORT_KEY = 'Title';
export const LAST_USED_DATE = 'Last Used Date';
export const DATE = 'Date';
export const CREATED_DATE = 'Created Date';

export const ADVANCE_SEARCH_INFO = 'Multiple filters are selected in different logical combinations';

export const EMPTY_TABS = {
  NEWS: 'No news article available yet!',
  EXTERNAL_REPORT: ' No external report available yet!',
  INTERNAL_REPORT: ' No internal report available yet!',
};

export const ARTICLES_EMPTY_STATE_MESSAGE = {
  NOTHING_DISPLAY_MESSAGE: 'No matched results!',
  // INFO_ONE: 'Drag and resize the insights to change layouts of charts in the dashboard. ',
  INFO_TWO: 'Looks like nothing of this segregation is included in the articles available! ',
};

export const ERROR = {
  NOTHING_DISPLAY_MESSAGE: 'Hmm... Something is not right!',
  INFO_ONE: 'Internal Server Error ',
};

export const INSIGHT_OPENING_MESSAGE =
  'You are opening up a screen that is synced to render Insights derived from the articles listed here. Please note that the syncing will end if you move away from Articles on this screen.';

export const INSIGHT_DELETED_MESSAGE = {
  // NOTHING_DISPLAY_MESSAGE: 'This insight was removed.Try exporting another insight here',
  INFO_ONE: 'You are viewing at an insight that no longer exists! ',
  INFO_TWO: 'You can import another insight in this place after closing this. ',
};

export const DOWNLOAD_CSV_API_URL = `${env.API_DOMAIN_URL}/api/Search/AdvanceSearchXLS`;
