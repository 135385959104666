import React from "react";
import ButtonElement from "Components/ButtonElement";

export function ParameterButtonBlock({ handleUpdate, handleClose }) {
  return (
    <>
      <ButtonElement children="Cancel" size="small" onClick={handleClose} />
      <ButtonElement
        children="Apply"
        type="primary"
        size="small"
        onClick={handleUpdate}
      />
    </>
  );
}
