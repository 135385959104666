/* eslint-disable max-params */
import React from "react";
import { getInsightsById } from "../../api/insights";
import { notify } from "Components/Notification";
import {
  DEFAULT_DATE_FILTER_TYPE,
  DEFAULT_NUMBER_OF_DAYS,
  DEFAULT_PAGE_COUNT,
} from "constants";
import { DATE_FILTER_KEY } from "../Home/constants";
import { getFilteredValues } from "Routes/Home/Helper";
import {
  createInsightsDataPayload,
  downloadInsightHelper,
  formatInsightData,
  handleDateFilterInsightHelper,
  handleDeleteTagHelperInsights,
  handleFilterTagsformatter,
  handleInsightApiCall,
  handleInsightDeleteMainTagHelper,
  handleInsightLastNDaysChangeHelper,
  handleInsightUntilNowChangeHelper,
  onMessageHandler,
  postMessageHandler,
} from "./Helper";
import template from "./template";
import {
  checkDataChange,
  formatDate,
  handleApiError,
} from "../../utils/commonUtils";
import { COMMUNICATION_KEYS, DATA_FIELDS, SEARCH_TYPE } from "constants";
import { errorMEssageHandler } from "utils/ErrorHandler";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { generatePayload } from "./Helper/chartHandler";

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.channel = new BroadcastChannel("selectedList");
  }

  state = {
    showDeleteModal: false,
    selectedData: {},
    filterResults: {},
    sortedInfo: {
      columnKey: "updatedDate",
      field: "updatedDate",
      order: "descend",
    },
    loading: false,
    showViewInsight: false,
    chartContent: "",
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
    filterDateType: DEFAULT_DATE_FILTER_TYPE,
    numberOfDays: DEFAULT_NUMBER_OF_DAYS,
    saveModalWindow: false,
    showSavedInsight: false,
    showViewInNewTabDisabled: false,
    isFromArticles: true,
    notInteractive: false,
    data: [],
    totalCount: 0,
    dataLoading: true,
    switchProjectLoading: false,
  };
  setlocalState = (data) => {
    this.setState((prevState) => ({ ...prevState, ...data }));
  };
  componentDidMount() {
    this.channel.postMessage({
      type: COMMUNICATION_KEYS.START_FROM_INSIGHT,
    });
    this.channel.onmessage = (event) =>{
      onMessageHandler({
        clearInsightData: this.props.clearInsightData,
        data: event.data,
        setState: this.setlocalState,
        setProjectSwitchLoading: this.props.mainFrameState.setState,
        history: this.props.history,
        mainFrameState: this.props.mainFrameState,
        setInsightData: this.props.setInsightData,
        channel: this.channel,
        createInsights: this.props.createInsights,
      });
    };

    window.addEventListener("unload", (event) => {
      event.preventDefault();
      const queryParams = new URLSearchParams(window.location.search);
      const fromTerm = queryParams.get("from");
      if (fromTerm == "newtab") {
        this.channel.postMessage({
          type: COMMUNICATION_KEYS.CHILD_TERMINATE,
        });
        this.channel.close();
      }
      // To avoid confirmation dialog return; is mandatory
      return;
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.createInsights.error &&
      checkDataChange({
        currentData: this.props.createInsights,
        previousData: prevProps.createInsights,
        fields: DATA_FIELDS.INSIGHTS,
      })
    ) {
      this.callGetDataApi();
    }

    if (
      checkDataChange({
        currentData: this.props.createInsights,
        previousData: prevProps.createInsights,
        fields: ["filter", "dateFilter"],
      })
    ) {
      this.setState({
        filterResults: handleFilterTagsformatter(this.props.createInsights),
      });
    }
  }
  callGetDataApi = () => {
    postMessageHandler({
      createInsights: this.props.createInsights,
      channel: this.channel,
    });
    handleInsightApiCall({
      setlocalState: this.setlocalState,
      payload: createInsightsDataPayload(this.props.createInsights),
    }).catch((error) => {
      this.setState({ dataLoading: false });
      !this.state.switchProjectLoading &&
        handleApiError(error, () => {
          this.props.setInsightData({
            error: true,
            errorMessage: errorMEssageHandler(error?.response?.status),
          });
        });
    });
  };

  componentWillUnmount() {
    this.props.clearInsightData();
    this.channel.postMessage({
      type: COMMUNICATION_KEYS.CHILD_TERMINATE,
    });
    // this.channel.close();
  }

  getInsightsList = () => this.props.getInsightData(this.state.pagination);

  handleInsightsPagination = (pageNumber, pageSize) => {
    this.setState(
      { pagination: { pageNumber: pageNumber, pageSize: pageSize } },
      () => this.getInsightsList()
    );
  };

  handleChangeSorter = (pagination, filter, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  handleDelete = (record) => {
    this.setState({ showDeleteModal: true, selectedData: record });
  };

  deleteModalHandler = (value, event) => {
    if (event == "modalOff") {
      this.setState({
        showDeleteModal: false,
        loading: false,
        selectedData: {},
      });
    }
  };

  deleteModalHandlerYes = () => {
    this.setState({
      loading: true,
    });
    this.props.handleDeleteInsightData(this.state.selectedData?.id, () => {
      notify.success(
        this.state.selectedData?.insightsName + " Deleted Successfully",
        true
      );
      this.deleteModalHandler("", "modalOff");
      if (this.props.InsightsPage?.insightData?.length === 0) {
        this.handleInsightsPagination(1, this.state.pagination.pageSize);
      } else {
        this.handleInsightsPagination(
          this.state.pagination.pageNumber,
          this.state.pagination.pageSize
        );
      }
    });
  };
  handleSavedInsightModal = (value) => {
    this.getInsightsList();
    this.setState({ showSavedInsight: value });
  };

  handleViewInsights = async (record) => {
    this.setState({
      showSavedInsight: false,
      savedInsightName: record.insightsName,
      insightId: record.id,
      insightName: record.insightsName,
      loading: true,
    });
    let { data } = await getInsightsById({ id: record.id });
    const { insightsContentbyte } = data.result;
    const { requestPayload } = insightsContentbyte;
    const isAdvanceSearch = requestPayload?.advanceSearchPayloadData["group1"]
      ? true
      : false;
    const chartName = insightsContentbyte?.chartName;
    this.setState({
      loading: false,
      chartContent: insightsContentbyte,
    });
    this.props.setInsightData(
      formatInsightData({
        dateFilter: requestPayload?.dateFilters,
        filter: requestPayload?.filtersforData[0] || {},
        requestPayload: requestPayload,
        advanceSearchPayloadData: isAdvanceSearch
          ? requestPayload?.advanceSearchPayloadData
          : {},
        breadCrumbs: requestPayload?.searchWords || [],
        searchCriterias: requestPayload?.searchCriterias || [],
        searchValue: requestPayload?.searchValue || "",
        chartName,
        searchType: isAdvanceSearch
          ? SEARCH_TYPE.ADVANCED_SEARCH
          : SEARCH_TYPE.NORMAL_SEARCH,
        config: { [chartName]: insightsContentbyte.config },
        payload: insightsContentbyte?.payload,
        buckets:
          generatePayload({
            parameters: insightsContentbyte?.payload,
            chartName,
          })?.requestPayload?.buckets || [],
      })
    );
  };

  downloadInsightHandler = (record) =>
    downloadInsightHelper({ history: this.props.history, record });

  viewInsightCancelHandler = () => {
    this.props.clearInsightData();
    this.setState({ showViewInsight: false });
  };
  handleChangeCheckBox = ({ name, selectedValues, items }) => {
    let newList = this.state.filterResults[name] ?? [];
    const appliedFilter = getFilteredValues(newList, items);

    let appendedList = [...appliedFilter, ...selectedValues];
    this.setState({
      filterResults: { ...this.state.filterResults, [name]: appendedList },
    });

    this.props.setInsightData({
      filter: {
        ...this.props.createInsights.filter,
        [name]: selectedValues,
      },
    });
  };

  handleSelectAll = ({name, items})=>{
    const newFilterValues = items.map(x=>x.value);

    this.setState({
      filterResults: { ...this.state.filterResults, [name]: newFilterValues },
    });

    this.props.setInsightData({
      filter: {
        ...this.props.createInsights.filter,
        [name]: newFilterValues,
      },
    });

  }

  handleClearAll = ({name})=>{
    const newFilter = {...this.props.createInsights.filter};
    delete newFilter[name];

    const newFilterResults = { ...this.state.filterResults};
    delete newFilterResults[name];
   
    this.setState({
      filterResults: newFilterResults
    });

    this.props.setInsightData({
      filter: newFilter
    });
  }



  handleChangefilterDateType = (type) => {
    this.setState({ filterDateType: type });
    if (
      this.state.filterResults["Date"]?.from &&
      this.state.filterResults["Date"]?.to
    ) {
      handleInsightDeleteMainTagHelper({ state: this, tag: "Date" });
    }
  };

  handleCheckDate = () => {
    if (
      this.state.filterResults.Date != undefined &&
      Object.keys(this.state.filterResults.Date).length == 0
    ) {
      this.handleDeleteMainTag(DATE_FILTER_KEY);
    }
  };

  handleDeleteMainTag = async (tag) => {
    if (tag == DATE_FILTER_KEY) {
      let newTag = this.state.filterResults[DATE_FILTER_KEY];
      delete newTag[tag];
      this.props.setInsightData({
        dateFilter: DEFAULT_DATE_FILTER_VALUE,
      });
    }
    handleInsightDeleteMainTagHelper({ state: this, tag });
  };

  handleSearchDate = (e, name) => {
    this.props.setInsightData({
      key: DATE_FILTER_KEY,
      value: {
        to: formatDate(e.to),
        from: formatDate(e.from),
      },
    });
  };
  handleChangeDate = ({ e, name, type, numberOfDays }) => {
    if (e == null) {
      this.props.setInsightData({
        dateFilter: DEFAULT_DATE_FILTER_VALUE,
      });
      return;
    }
    if (type == "lastNDays") {
      this.setState({ filterDateType: 3, numberOfDays });
      this.handleLastNDaysChange(e, name);
      handleDateFilterInsightHelper({
        setInsightData: this.props.setInsightData,
        event: e,
      });
    } else if (type == "untilNow") {
      this.setState({ filterDateType: 2 });
      this.handleUntilNowChange(e, name);
      handleDateFilterInsightHelper({
        setInsightData: this.props.setInsightData,
        event: e,
        type: "UntilNow",
      });
    } else {
      this.setDateInsightValue(e, name);
      this.setState({
        filterResults: {
          ...this.state.filterResults,
          ["Date"]: {
            from: formatDate(e.from),
            to: formatDate(e.to),
          },
        },
      });
      this.handleSearchDate(e, name);
    }
  };
  handleLastNDaysChange = (value, name) =>
    handleInsightLastNDaysChangeHelper({ state: this, value });
  handleUntilNowChange = (value, name) =>
    handleInsightUntilNowChangeHelper({ state: this, value });

  setDateInsightValue = (e, name) => {
    this.props.setInsightData({
      dateFilter: {
        key: DATE_FILTER_KEY,
        value: {
          ...this.props.createInsights.dateFilter.value,
          to: formatDate(e.to),
          from: formatDate(e.from),
        },
      },
    });
  };

  viewInsightOkHandler = () => {
    this.setState({ showViewInsight: false, showSavedInsight: false });
    let pathname = this.props.history.location.pathname;
    let search = this.props.history.location.search;
    this.props.history.push({
      pathname: pathname,
      state: {
        isEdit: true,
        chartInfo: this.state.chartContent,
        insightId: this.state.insightId,
        insightName: "",
        dataSet: this.state.dataSet,
      },
      search: search,
    });
  };

  SaveModalCancelHandler = () =>
    this.setState({
      saveModalWindow: false,
    });

  handleSave = () => {
    if (
      this.props.createInsights &&
      this.props.createInsights.config &&
      this.props.createInsights.chartName &&
      this.props.createInsights.config[this.props.createInsights.chartName]
    ) {
      this.setState({
        saveModalWindow: true,
      });
    } else {
      notify.error("Please update the graph", true);
    }
  };

  handleShowModalWindow = () => {
    this.setState({ saveModalWindow: true });
  };

  handleDeleteTags = async (removedItem, tag) =>
    handleDeleteTagHelperInsights({ state: this, removedItem, tag });

  handleDeleteAllTags = async () => {
    this.setState({ filterResults: {}, numberOfDays: DEFAULT_NUMBER_OF_DAYS });
    this.props.setInsightData({
      filter: {},
      dateFilter: [DEFAULT_DATE_FILTER_VALUE],
    });
  };

  handleDeleteDate = (tag) => {
    let newTag = this.state.filterResults[DATE_FILTER_KEY];
    delete newTag[tag];
    this.props.setInsightData({
      dateFilter: DEFAULT_DATE_FILTER_VALUE,
    });
  };

  handleTabCloseWindow = () => {
    window.close();
  };

  render() {
    return template.call(this);
  }
}

export default Insights;