import React from "react";
import Loader from "../../../../Components/Loader";
import NewPasswordForm from "./NewPasswordForm";

function ValidationForm(props) {
  return (
    <>
      {props.props.sendEmailData.loading ? (
        <Loader
          loading={props.props.sendEmailData.loading}
          classList={["sm blue"]}
        />
      ) : (
        <NewPasswordForm {...props} />
      )}
    </>
  );
}

export default ValidationForm;
