import React from 'react';

import RightSide from './Children/RightSide';
import LeftSide from './Children/LeftSide';
import AppLoader from '../../Components/AppLoder';

import style from './style.module.scss';
export function template() {
  return (
    <>
      {this.state.loginInProgress ? (
        <AppLoader />
      ) : (
        <section className={style.loginWrapper}>
          <div className={style.loginWrapper__left}>
            <LeftSide />
          </div>
          <div className={style.loginWrapper__right}>
            <RightSide {...this} />
          </div>
          {/* {this.state.adfsPopupError && <PopupErrorModal {...this} />} */}
          {/* <ShowPasswordSucessModal {...this} /> */}
        </section>
      )}
    </>
  );
}
