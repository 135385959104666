import React from "react";
import ButtonElement from "Components/ButtonElement";

import SVGIcons from "Components/SVGIcons";
import TooltipContainer from "Components/Tooltip";
import style from "./style.module.scss";

export const SelectionIconSection = (props) => {
  return (
    <ul className={style.cardSelecter}>
      <TooltipContainer
        visible={props.isSearchLoading ? false : undefined}
        title="Toggle the selection for all article(s) in current page"
        placement="bottom"
        mouseLeaveDelay={0}
      >
        <li>
          <ButtonElement
            className={`${style.listselectButton}`}
            onClick={() =>
              !props.isSearchLoading && props.selectAllInPage()
            }
            disabled={props.isSearchLoading}
          >
            <span>
              <SVGIcons type="SVG-multiple-selection3" />
            </span>
          </ButtonElement>
        </li>
      </TooltipContainer>
      <TooltipContainer
        visible={props.isSearchLoading ? false : undefined}
        title="Clear the selection of tag and article"
        placement="bottom"
        mouseLeaveDelay={0}
      >
        <li>
          <ButtonElement
            className={`${style.listselectButton}`}
            onClick={() =>
              !props.isSearchLoading && props.clearSelected()
            }
            disabled={props.isSearchLoading}
          >
            <span>
              <SVGIcons type="SVG-clear-multiple-selection" />
            </span>
          </ButtonElement>
        </li>
      </TooltipContainer>
    </ul>
  );
};
