import { ADVANCE_SEARCH_DYNAMIC_KEYS } from "../../Constants/Settings/Settings.constants";
import { isEmptyFields } from "./helperFunctions";

/**
 * @param {string | undefined} data
 * @returns {'text' | 'date' | boolean}
 */
export const checkIsTexOrDatetField = (data) => {
  if (checkIsTextField(data)) return "text";
  else if (data?.toLowerCase().includes("date")) return "date";
  else return false;
};
// Check is Dynamic field
export const checkIsTextField = (data) => {
  const isIncludes =
    ADVANCE_SEARCH_DYNAMIC_KEYS.filter((value) => value === data).length != 0
      ? true
      : false;
  return isIncludes;
};

export const onCancelHelper = (type, state) => {
  const element = document.getElementById("MainBody");
  element && element.classList.remove("scrollDisable");
  type !== "close" ? state.props.clearAdvanceSearchPayload() : "";
  state.props.mainFrameState.setState({
    showAdvanceSearchDropDown: {
      ...state.props.mainFrameState.state.showAdvanceSearchDropDown,
      visible: false,
    },
  });
};

export const handleFacetClearhelper = ({ item, state, setGroup }) => {
  const { filter, group, facet } = item;
  setGroup.setState({
    ...state,
    [group]: {
      ...state[group],
      [filter]: { facet: { value1: {} } },
    },
  });
};
