import React from 'react';
import {notify} from '../../../../Components/Notification';
import template from './template';

class SystemSettings extends React.Component {
  state = {
    dateFormat: this.props.Format.dateFormat,
    timeFormat: 'HH::MM:SS',
    languageFormat: 'English',
    sortOrder: this.props.sortOrder,
    disabled: true,
    hideResultsZeroCount: this.props.hideResultsZeroCount,
  };

  componentDidMount() {
    // this.props.loadFilterFields();
    this.setState({
      dateFormat: this.props.Format.dateFormat,
      timeFormat: this.props.Format.timeFormat,
      languageFormat: this.props.Format.languageFormat,
      hideResultsZeroCount: this.props.hideResultsZeroCount,
    });
  }

  /*   handleUpdate = () => {
    this.props.updateSystemSettings({
      dateFormat: this.state.dateFormat,
      timeFormat: this.state.timeFormat,
      languageFormat: this.state.languageFormat,
      handleNotify: (value) => {
        if (value) notify.success('Settings updated', true);
        else notify.error('Settings update failed', true);
      },
    });
  }; */

  dateHandleChange = (value) => {
    this.setState({dateFormat: value, disabled: false});
  };

  timeHandleChange = (value) => {
    this.setState({timeFormat: value});
  };

  languagueHandleChange = (value) => {
    this.setState({languageFormat: value});
  };

  sortOrderChange = (value) => {
    this.setState({sortOrder: value, disabled: false});
  };

  handleResultsZeroCount = (event) => {
    this.setState({hideResultsZeroCount: event.target.checked, disabled: false});
  };

  handleUpdate = () => {
    this.props.updatePreferenceSettings({
      sortOrder: this.state.sortOrder,
      dateFormat: this.state.dateFormat,
      hideResultsZeroCount: this.state.hideResultsZeroCount,
      handleNotify: (value) => {
        if (value) {
          notify.success('Settings updated', true);
        } else {
          notify.error('Settings update failed', true);
        }
        this.setState({disabled: true});
      },
    });
  };

  render() {
    return template.call(this);
  }
}
export default SystemSettings;
