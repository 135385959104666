import React from "react";

import ConfirmationMessage from "../../../../../Components/ConfirmationMessage";
import ModalWindow from "../../../../../Components/ModalWindow";
import { ROLE_SETTINGS_CONSTANTS } from "../RoleSettings.constants";

function ShowModalwindowSuspend(props) {
  return (
    <>
      <ModalWindow
        title={false}
        isNotClosable={true}
        footer={false}
        okText={ROLE_SETTINGS_CONSTANTS.YES_TEXT_CONSTANT}
        cancelText={ROLE_SETTINGS_CONSTANTS.NO_TEXT_CONSTANT}
        open={props.state.suspendUserModalWindow}
      >
        <ConfirmationMessage
          heading={
            !props.state.suspendUser.isActive
              ? "Do you want to unblock user?"
              : "Do you want to block user?"
          }
          onOk={(e) => props.suspendModalHandler(e, "modalOff")}
          onCancel={
            !props.state.suspendUser.isActive
              ? (e) => props.unblockBlockHandler("unBlock")
              : (e) => props.suspendModalHandlerYes("block")
          }
          cancelButtonLabel={"Confirm"}
          confirmButtonLabel={"Cancel"}
          loading={props.props.userUpdateLoading}
        />
      </ModalWindow>
    </>
  );
}

export default ShowModalwindowSuspend;
