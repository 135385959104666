import React from "react";

import EmptyState from "Components/EmptyState";
import SearchCard from "Components/SearchCard";
import { SearchCardLoader } from "Components/SearchCard/SearchcardLoader";
import TagFilter from "Components/TagFilter";
import AlertElement from "Components/AlertElement";
import Pagination from "Components/Pagination";
import CustomScrollbar from "Components/CustomScrollbar";
import ErrorState from "Components/ErrorState";

import globel from "styles/globel/style.module.scss";
import style from "./style.module.scss";
import { createAdvanceSearchQuery } from "Routes/Home/Helper";
import SVGIcons from "Components/SVGIcons";
import { ArticleBreadCrumb } from "./ArticleBreadCrumb";
import { SEARCH_TYPE } from "constants";
function template(props) {
  const arr = [...Array(15).keys()];
  return (
    <>
      <div className={style.articleHeader}>
        {this.props.breadCrumbData?.length > 0 &&
          !this.props?.searchData.loading && (
            <div className={style.breadcrumbBlock}>
              <div className={style.breadcrumbBlock__label}>
                <p>Word cloud drill-down: </p>
              </div>
              <ArticleBreadCrumb
                crumbs={this.props.breadCrumbData}
                handleClick={this.props.handleBreadCrumbArticles}
                isClearable
                clearButtonText={"Back to default"}
              />
            </div>
          )}
        {!this.props?.searchData.loading && (
          <TagFilter
            items={this.props.filterResults}
            handleDeleteTags={this.props.handleDeleteTags}
            handleDeleteMainTag={this.props.handleDeleteMainTag}
            handleDeleteDate={this.props.handleDeleteDate}
            handleDeleteAllTags={this.props.handleDeleteAllTags}
          />
        )}
        {this.props?.search?.searchType === SEARCH_TYPE.ADVANCED_SEARCH &&
          !this.props?.searchData.loading &&
          createAdvanceSearchQuery(
            this.props.search?.advanceSearchPayloadData
          ) != "" && (
            <div className={style.advancesearchQuery}>
              <div className={style.advancesearchQuery__item}>
                <AlertElement
                  information={true}
                  children={createAdvanceSearchQuery(
                    this.props.search?.advanceSearchPayloadData
                  )}
                  icon={"SVG-infoIcon"}
                />
              </div>

              <a
                className={style.advancesearchQuery__button}
                onClick={() => {
                  this.props.setSearchData({
                    advanceSearchPayloadData: {},
                    searchType: SEARCH_TYPE.NORMAL_SEARCH,
                  });
                }}
              >
                <span>
                  <SVGIcons type={"SVG-closeIcon"} />
                </span>
              </a>
            </div>
          )}{" "}
        <div className={style.resultPagination}>
          {!this.props.search ||
          this.props?.searchData.loading ||
          this.props.searchData.totalCount == 0 ? (
            <></>
          ) : (
            <TotalCount
              totalCount={this.props.searchData.totalCount}
              data={this.props.searchData.data}
            />
          )}
          {this.props.searchData?.totalCount > 4 &&
            !this.props?.searchData.loading && (
              <Pagination
                current={this.props.search?.pageNumber}
                pageSize={this.props.search?.pageSize}
                total={this.props.searchData?.totalCount}
                onChange={this.handleSearchPagination}
                showSizeChanger={this.props.listViewType == "gridOne" && true}
              />
            )}
        </div>
      </div>
      {this.props?.searchData.loading ? (
        <>
          <div className={style.resultContainer}>
            <CustomScrollbar className="result-view-scroller">
              <div
                className={style.resultInner}
                ref={this.setScrollableElement}
              >
                <div
                  className={`${globel.resultBlock} ${
                    globel[this.props.handleViewListClassName()]
                  }`}
                >
                  {arr.map((item, index) => (
                    <div
                      className={globel.resultBlock__element}
                      key={index + item}
                    >
                      <SearchCardLoader />
                    </div>
                  ))}
                </div>
              </div>
            </CustomScrollbar>
          </div>
        </>
      ) : this.props?.search?.error ? (
        <ErrorState
          content="ERROR"
          type={"errorImage"}
          customHeight={"500"}
          errorMessage={this.props?.search?.errorMessage}
        />
      ) : this.props?.searchData?.data?.length === 0 ? (
        <EmptyState type="welcomeImage" content={"SEARCH_NO_DATA_FOUND"} />
      ) : (
        <>
          <div className={style.resultContainer}>
            <CustomScrollbar className="result-view-scroller">
              <div
                className={style.resultInner}
                ref={this.setScrollableElement}
              >
                <div
                  className={`${globel.resultBlock} ${
                    globel[this.props.handleViewListClassName()]
                  }`}
                >
                  {this.props.searchData?.data?.map((data, i) => {
                    return (
                      <div
                        className={globel.resultBlock__element}
                        key={data.id || data.DocumentId || i}
                      >
                        <SearchCard
                          {...this.props}
                          handleFullContentData={
                            this.props.handleFullContentData
                          }
                          data={data}
                          addTagsHandler={this.props.addTagsHandler}
                          tagState={this.props.tag}
                          selectedClassname={this.props.handleViewListClassName()}
                          search={this.props.search}
                          dateFormat={this.props.dateFormat}
                          allArticleList={this.props.searchData?.data}
                          searchType={this.props.search?.searchType}
                          currentTabType={this.props.tab}
                          expandAllCard={this.props.expandAllCard}
                          handleExpandCardActive={
                            this.props.handleExpandCardActive
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </CustomScrollbar>
          </div>
        </>
      )}
    </>
  );
}

export default template;

/**
 * Render total count UI
 * if search from savedSearches list - current count will be calculated in another way because of client side pagination
 * @param {{ searchProp: Object }} searchProp - search state values
 * @returns {JSX.Element}
 */
function TotalCount({ totalCount, data }) {
  let currentCount = data?.length;

  return (
    <div className={globel.searchresultCount}>
      <span>
        {totalCount === 0
          ? "Empty "
          : "Showing " + currentCount + " out of " + totalCount + " "}{" "}
        Results
      </span>
    </div>
  );
}
