import React from 'react';

import InputField from '../../../../../Components/InputField';
import {
  PASSWORD_VALIDATION_MESSAGE,
  PASSWORD_FORM_HEADER,
  PASSWORD_FORM_BODY,
} from '../../../../../Constants/Login/Login.constants';

import style from './style.module.scss';

function PasswordForm(props) {
  return (
    <div className={style.resetBlock}>
      <h3>{PASSWORD_FORM_HEADER}</h3>
      <p>{PASSWORD_FORM_BODY}</p>
      <ul className={style.resetPassword}>
        <li>
          <InputField
            name={'new-password-block'}
            placeholder={'New-password'}
            onChange={props.handleNewPassword}
            value={props.state.newPassword}
            onBlur={props.handlePasswordValidity}
            autoFocus={true}
            type='password'
            inputType='password'
          />
        </li>
        <li className={style.passwordErrorBlock}>
          {props.state.validityCheck.password ? props.state.passWordValidityErrorMessage : null}
        </li>
        <li>
          <InputField
            name={'confirm-password-block'}
            placeholder={'Confirm-password'}
            type='password'
            inputType='password'
            onPressEnter={(e) => props.forgotPasswordOkHandler()}
            onChange={props.handleConfirmPassword}
            value={props.state.confirmPassword.value}
            error={props.state.confirmPassword.errorMessage != '' ? props.state.confirmPassword.errorMessage : ''}
          />
        </li>
      </ul>
    </div>
  );
}

export default PasswordForm;
