import React from "react";
import { getInsightsList } from "../../../../api/insights";
import { notify } from "../../../../Components/Notification";
import { DASHBOARD_CONSTANTS } from "../../../../Constants/Dashboard/Dashboard.constants";
import { ROUTES } from "../../../../Constants/Routes/Routes.constants";
import { KEYS } from "../../../../dataKeys";
import { exportMultipleChartsToPdf } from "../../../../utils/exportMultipleChartsToPdf";
import {
  createDashboardMountHelper,
  editDashboardMountHelper,
  handleChangeSelectedValuesHelper,
  handleSaveHelper,
  handleUpdateHelper,
  removeItemHelper,
  viewDashboardMountHelper,
} from "./helper";
import {
  editOnViewHandleHelper,
  handleEditInsightsRouteHelper,
  handleLayoutSectionHelper,
} from "./Helper/handleHelper";
import { handleInsightsDataHelper } from "./Helper/helper";
import template from "./template";

class BuildDashboard extends React.Component {
  state = {
    data: [],
    chartNames: [],
    config: [],
    labels: [],
    items: [],
    showAddModal: false,
    selectedInsights: [],
    insightsData: [],
    importLoading: false,
    dashboardName: "",
    dashboardId: "",
    saveModalWindow: false,
    saveLoading: false,
    isView: false,
    isEdit: false,
    layout: [],
    newCounter: 0,
    onViewEdit: false,
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    searchValue: "",
    saveError: "",
    importInsightDisabled: false,
    exportPdfLoading: false,
    isFromUrl: false,
    insightInfoMessage: "",
    fullInsightData: [],
    totalSize: 0,
    startPage: 1,
    insightPageSize: 10,
    listLoading: true,
    loadMoreLoading: false,
    dataLoading: false,
  };

  removeItem = (item, value) => {
    removeItemHelper({ item, state: this });
  };
  setLocalState = (data) =>
    this.setState((prevData) => ({ ...prevData, ...data }));

  componentDidMount() {
    this.getInsightData();

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isEdit
    ) {
      editDashboardMountHelper({
        importInsights: this.props.importInsights,
        location: this.props.location,
        setState: this.setLocalState,
        handleImportSuccess: this.handleImportSuccess,
      });
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isView
    ) {
      viewDashboardMountHelper({
        importInsights: this.props.importInsights,
        location: this.props.location,
        setState: this.setLocalState,
        handleImportSuccess: this.handleImportSuccess,
      });
    } else {
      createDashboardMountHelper({
        selectedList: this.props.location.state.selectedList || [],
        importInsights: this.props.importInsights,
        setState: this.setLocalState,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location?.state?.clickType !==
      prevProps.location?.state?.clickType
    ) {
      switch (this.props.location?.state?.clickType) {
        case "ClearAll":
          this.handleClearHistoryState();
          this.handleCancel({ type: "" });
          break;
        case "Save":
          this.handleClearHistoryState();
          this.handleSave();
          break;
        case "Edit":
          this.handleClearHistoryState();
          this.editOnView();
          break;
        case "Import":
          this.handleClearHistoryState();
          this.handleImportModal();
          break;
        case "Cancel":
          this.handleClearHistoryState();
          this.handleCancel({ type: "cancel" });
          break;
        default:
          break;
      }
    }
  }
  handleClearHistoryState = () => {
    this.props.history.replace({
      pathname: ROUTES.DASHBOARD,
      state: {
        clickType: "",
        isEdit: this.props?.location?.state?.isEdit ? true : false,
        selectedList: this.state.selectedInsights,
      },
    });
  };
  handleLayoutSelection = (index, layoutItems) => {
    if (this.props.location.state && this.props.location.state.isView) {
      layoutItems = this.props.location.state.layout;
    } else if (this.props.location.state && this.props.location.state.isEdit) {
      layoutItems = handleLayoutSectionHelper({
        state: this,
        layoutItems,
      });
    } else {
      let newLayout = {
        i: index,
        x: (index * 6) % 12,
        y: 0,
        w: 6,
        h: 4,
        minW: 5,
        minH: 3,
        maxW: 20,
        maxH: 10,
      };
      layoutItems.push(newLayout);
    }
    return layoutItems;
  };

  handleImportModal = () => {
    this.setState({ showAddModal: true });
  };
  handleChange = (selectedValues) => {
    handleChangeSelectedValuesHelper({ selectedValues, state: this });
  };
  addModalHandlerCancel = (value, event) => {
    if (event == "modalOff") {
      this.setState({
        showAddModal: false,
        insightInfoMessage: "",
        searchValue: "",
      });
    }
  };
  addModalHandlerOk = () => {
    if (this.state.selectedInsights.length == 0) {
      this.setState({
        insightInfoMessage: DASHBOARD_CONSTANTS.EMPTY_INSIGHT_SELECTED_ERROR,
      });
    } else {
      this.setState({
        importLoading: true,
        insightInfoMessage: "",
        importInsightDisabled: this.state.selectedInsights.length == 3,
      });
      this.props.importInsights({
        payload: this.state.selectedInsights,
        handleImportSuccess: this.handleImportSuccess,
      });
      this.props.history.push({
        pathname: ROUTES.DASHBOARD,
        state: {
          ...this.props?.location?.state,
          selectedList: this.state.selectedInsights,
        },
      });
    }
  };
  handleImportSuccess = async ({
    newData,
    chartNames,
    config,
    insightsName,
    error,
  }) => {
    if (error) return this.setState({ dataLoading: false });
    let layoutItems = [];
    let data = [];
    this.state.selectedInsights.map((_, index) => {
      layoutItems = this.handleLayoutSelection(index, layoutItems);
      data.push(index);
    });
    let insightsData = await handleInsightsDataHelper({
      selectedValues: this.state.selectedInsights,
    });
    this.setState({
      dataLoading: false,
      data: newData,
      chartNames: chartNames,
      config,
      labels: insightsName,
      layout:
        layoutItems.length !== 0
          ? layoutItems
          : this.props.location.state?.layout,
      items: data,
      insightsData,
      showAddModal: false,
      importLoading: false,
      importInsightDisabled: insightsData.length == 3,
      searchValue: "",
    });
  };
  handleCancel = ({ type }) => {
    this.props.history.push({
      pathname: ROUTES.DASHBOARD,
      state: {
        clear: true,
        changeTab: type == "cancel" ? true : false,
      },
    });
  };

  handleEditInsight = (i) => handleEditInsightsRouteHelper({ state: this, i });

  handleSave = () => {
    if (this.state.layout.length == 0) {
      notify.info(DASHBOARD_CONSTANTS.DASHBOARD_EMPTY_ERROR_MESSAGE, true);
    } else {
      this.setState({
        saveModalWindow: true,
        isEdit:
          this.state.dashboardName && this.state.dashboardId ? true : false,
      });
    }
  };

  handleDashboardName = (event) => {
    if (event.target.value.trim().length <= 30)
      this.setState({ dashboardName: event.target.value, saveError: "" });
    else
      this.setState({
        saveError:
          "Character limit reached! Name cannot be longer than 30 characters.",
      });
  };
  SaveModalCancelHandler = () => {
    this.setState({
      saveModalWindow: false,
      saveError: "",
    });
  };
  SaveModalOkHandler = () => {
    if (!this.state.dashboardName.trim())
      return this.setState({ saveError: "empty" });
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.isEdit &&
      this.state.dashboardId
    ) {
      handleUpdateHelper({ state: this });
    } else {
      handleSaveHelper({ state: this });
    }
  };
  editOnView = () => {
    editOnViewHandleHelper({ state: this });
  };
  onLayoutChange = (value) => {
    this.setState({ layout: value });
  };
  createPDF = () => {
    exportMultipleChartsToPdf({ state: this });
  };

  handleSearchChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  searchHandler = (data) => {
    return data.filter((item) =>
      item.name.toUpperCase().includes(this.state.searchValue.toUpperCase())
    );
  };

  loadMore = async () => {
    if (this.state.fullInsightData.length == this.state.totalSize) {
    } else {
      this.setState({ loadMoreLoading: true });
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      let payload = {
        emailId: email,
        pageNumber: 1,
        pageSize: this.state.insightPageSize + 10,
      };
      this.setState({ insightPageSize: this.state.insightPageSize + 10 });
      let { data } = await getInsightsList(payload);
      this.setState({
        fullInsightData: data.result.result,
        listLoading: false,
        totalSize: data.result.resultHits,
        loadMoreLoading: false,
      });
    }
  };

  getInsightData = async () => {
    this.setState({ listLoading: true });
    let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
    let payload = {
      emailId: email,
      pageNumber: this.state.startPage,
      pageSize: this.state.insightPageSize,
    };
    let { data } = await getInsightsList(payload);
    this.setState({
      fullInsightData: data.result.result,
      listLoading: false,
      totalSize: data.result.resultHits,
    });
  };

  render() {
    return template.call(this);
  }
}
export default BuildDashboard;
