import {
  setAdvanceSearchPayload,
  setAdvancedSearchKeyword,
} from "utils/dataFormatter";
import { DEFAULT_DATE_FILTER_VALUE, SEARCH_TYPE } from "../../../../constants";

export function clearInsightsData(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    chartName: "KEY_PHRASE_CLOUD",
    data: [],
    errorMessage: "",
    buckets: [],
    config: {
      KEY_PHRASE_CLOUD: {
        maxCount: 300,
        minValue: 1,
        minWordLength: 1,
        searchWords: [],
      },
    },
    searchValue: "",
    breadCrumbData: [],
    dataChanges: payload ? [payload] : [],
    searchCriterias: [],
    dateFilter: DEFAULT_DATE_FILTER_VALUE,
    filter: {},
    payload: {
      "Maximum word count": {
        value: 300,
        index: "",
      },
      "Minimum word occurrence": {
        value: 1,
        index: "",
      },
      Datafield: {
        value: "Keywords",
        index: "",
      },
    },
    requestPayload: {
      wordCount: 300,
      minCount: 1,
      resType: 1,
      emailId: null,
    },
    searchType: SEARCH_TYPE.NORMAL_SEARCH,
  };
}

export function setInsightDataState(state, payload) {
  return {
    ...state,
    error: payload.error || false,
    errorMessage: payload.errorMessage ?? "",
    isFromArticles: payload.isFromArticles ? true : false,
    searchValue: setAdvancedSearchKeyword({
      data: state,
      payload,
      key: "searchValue",
    }),
    advanceSearchPayloadData: setAdvanceSearchPayload({
      data: state,
      payload,
      key: "searchValue",
    }),
    ...payload,
  };
}
