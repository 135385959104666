import React from "react";
import moment from "moment";

import SVGIcons from "../../Components/SVGIcons";
import { publishDateFormatter } from "../../Components/SearchCard/Helper";
import TooltipContainer from "../../Components/Tooltip";
import { formatFilterLabel } from "../../utils/formatFilterLabel";

import globel from "../../styles/globel/style.module.scss";

let TableContents = [
  {
    title: "Title",
    dataIndex: "insightsName",
    key: "insightsName",
    sorter: (a, b) => a.insightsName.localeCompare(b.insightsName),
    render: (title) => (
      <TooltipContainer title={title} placement="bottom">
        {trimTitle(title)}
      </TooltipContainer>
    ),
  },
  {
    title: "Chart Type",
    dataIndex: "chartType",
    key: "chartType",
    sorter: (a, b) => a?.chartType.localeCompare(b?.chartType),
    render: (title) => `${formatFilterLabel(title)}`,
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (date) => `${publishDateFormatter(date)}`,
    sorter: (a, b) =>
      moment(a.createdDate).unix() - moment(b.createdDate).unix(),
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
    key: "updatedDate",
    render: (date) => `${publishDateFormatter(date)}`,
    sorter: (a, b) =>
      moment(a.updatedDate).unix() - moment(b.updatedDate).unix(),
    defaultSortOrder: "descend",
  },
];

export const INSIGHTS_CONFIG = {
  COLUMNS: ({
    deleteHandler,
    sortedInfo,
    handleViewInsights,
    downloadInsightsHandler,
  }) => [
    TableContents[0],
    TableContents[1],
    TableContents[2],
    TableContents[3],
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record) => (
        <ul className={globel.tableActions}>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              title={"View Insight"}
              placement="bottom"
            >
              <span onClick={() => handleViewInsights(record)}>
                <SVGIcons type={"SVG-viewicon"} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer
              title={"Download Insight"}
              placement="bottom"
              mouseLeaveDelay={0}
            >
              <span onClick={() => downloadInsightsHandler(record)}>
                <SVGIcons type={"SVG-download"} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              title={"Delete Insight"}
              placement="bottom"
            >
              <span onClick={() => deleteHandler(record)}>
                <SVGIcons type={"SVG-delete"} />
              </span>
            </TooltipContainer>
          </li>
        </ul>
      ),
    },
  ],
};

function trimTitle(text) {
  let trimmed = text.substring(0, 20);
  if (text.length > 20) {
    trimmed += "...";
  }
  return trimmed;
}
