import { DEFAULT_DATE_FILTER_VALUE } from "constants";

export const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {},
  importedInsightsData: [],
  importedInsightsChartNames: [],
  importedInsightsConfig: [],
  importedInsightsName: [],
  selectedList: [],
  dashboardData: [],
  getLoading: false,
  importError: false,
  dateFilter: DEFAULT_DATE_FILTER_VALUE,
  filter: {},
};
