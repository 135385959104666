import React, { useState } from "react";
import SortControls from "./Childern/SortControls";
import { connect } from "react-redux";

import { setSearchData } from "Redux/Actions";

function SortContent(props) {
  const [relevance, setRelevance] = useState({
    className: "activeDown",
    visible: false,
  });
  const [title, setTitle] = useState({ className: "", visible: false });
  const [publishDate, setPublishDate] = useState({
    className: "activeDown",
    visible: true,
  });

  return (
    <>
      <SortControls
        {...props}
        relevance={relevance}
        title={title}
        publishDate={publishDate}
        setPublishDate={setPublishDate}
        setRelevance={setRelevance}
        setTitle={setTitle}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    ...state.Search,
    allFieldsWithDatatype: state.FilterFields.allFieldsWithMetadata,
    ...state,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SortContent);
