import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const getUserGroups = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.get('', {params:payload});
};

export const addUserGroup = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.post('', payload, {params:{projectId:payload.projectId}});
};

export const updateUserGroup = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.put('', payload, {params:{projectId:payload.projectId}});
};

export const deleteUserGroup = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.delete('', {params:payload});
};

export const setDefaultQuery = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.put('DefaultQuery', {}, {params:payload});
};

export const getDefaultQuery = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_GROUPS,
  });
  const api = instance.api;
  return api.get('DefaultQuery', {params:payload});
};