import {connect} from 'react-redux';
import {FetchActivityLog} from '../../Redux/Actions/UserLogs';
import UserLogs from './UserLogs';

function mapStateToProps(state) {
  return {activityLog: state.UserLogs};
}
function mapDispatchToProps(dispatch) {
  return {
    fetchActivityLog: (params) => {
      dispatch(FetchActivityLog(params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogs);
