import React from "react";
import { random } from "lodash";
import WordCloud from "../Components/WordCloud";
import { BarChart } from "../Components/Barchart";
import ApexHeatMap from "../Components/ApexHeatMap";
import EmptyState from "../Components/EmptyState";
export function SwitchChartsDashboard({
  data,
  chartName,
  config,
  count,
  isDownload,
}) {
  switch (chartName[count]) {
    case "BAR_CHART":
      return (
        <>
          {data[count].length > 0 ? (
            <BarChart
              {...simpleChartProps({
                data,
                chartName,
                config,
                count,
                isDownload,
              })}
            />
          ) : (
            <EmptyState
              type="welcomeImage"
              content={"SEARCH_NO_DATA_FOUND"}
              customMargin={"100"}
              customHeight={"500"}
            />
          )}
        </>
      );
    case "KEY_PHRASE_CLOUD":
      return (
        <>
          {data[count].length > 0 ? (
            <WordCloud
              {...simpleChartProps({
                data,
                chartName,
                config,
                count,
                isDownload,
              })}
            />
          ) : (
            <EmptyState
              type="welcomeImage"
              content={"SEARCH_NO_DATA_FOUND"}
              customMargin={"100"}
              customHeight={"500"}
            />
          )}
        </>
      );
    case "HEAT_MAP":
      return (
        <>
          {data[count].length > 0 ? (
            <ApexHeatMap
              {...handleChartProps({
                data,
                chartName,
                config,
                count,
                isDownload,
              })}
            />
          ) : (
            <EmptyState
              type="welcomeImage"
              content={"SEARCH_NO_DATA_FOUND"}
              customMargin={"100"}
              customHeight={"500"}
            />
          )}
        </>
      );
    default:
      return null;
  }
}
export const handleSelectEditDashboard = (data, state) => {
  let selectedList = [];
  data.map((item) => {
    selectedList.push(item.id);
  });
  return selectedList;
};
const handleChartProps = ({ data, chartName, config, count, isDownload }) => {
  switch (chartName[count]) {
    case "HEAT_MAP":
      return {
        data: data[count],
        config: config[count],
        id: "Heatmap" + random(0, 1e6),
        dashboard: true,
        isDownload: isDownload,
      };
    default:
      return null;
  }
};
const simpleChartProps = ({ data, chartName, config, count, isDownload }) => {
  switch (chartName[count]) {
    case "BAR_CHART":
      return {
        data: data[count],
        isDownload: isDownload,
        dashboard: true,
        config: config[count],
        id: "Column" + random(0, 1e6),
      };
    case "KEY_PHRASE_CLOUD":
      return {
        data: data[count],
        isDownload: isDownload,
        dashboard: true,
        config: config[count],
        id: "key_phrase" + random(0, 1e6),
      };
    default:
      return null;
  }
};
