import React from "react";
import ButtonElement from "Components/ButtonElement";

import SVGIcons from "Components/SVGIcons";
import TooltipContainer from "Components/Tooltip";
import style from "./style.module.scss";

export const GridIconSection = (props) => {
  return (
    <ul className={style.cardSelecter}>
      <TooltipContainer
        visible={props.isSearchLoading ? false : undefined}
        title={"2x2 Grid View"}
        placement={"bottom"}
        mouseLeaveDelay={0}
      >
        <li>
          <ButtonElement
            className={`${style.listselectButton} ${
              props.listViewType === "gridTwo" ? style.selectedCard : ""
            }`}
            to={{}}
            onClick={() =>
              !props.isSearchLoading && props.handleListView("gridTwo")
            }
            disabled={props.isSearchLoading}
          >
            <span>
              <SVGIcons type="SVG-gridtwo" />
            </span>
          </ButtonElement>
        </li>
      </TooltipContainer>
      <TooltipContainer
        visible={props.isSearchLoading ? false : undefined}
        title={"3x4 Grid View"}
        placement={"bottom"}
        mouseLeaveDelay={0}
      >
        <li>
          <ButtonElement
            className={`${style.listselectButton} ${
              props.listViewType === "gridThree" ? style.selectedCard : ""
            }`}
            to={{}}
            onClick={() =>
              !props.isSearchLoading && props.handleListView("gridThree")
            }
            disabled={props.isSearchLoading}
          >
            <span>
              <SVGIcons type="SVG-gridthree" />
            </span>
          </ButtonElement>
        </li>
      </TooltipContainer>
      <TooltipContainer
        visible={props.isSearchLoading ? false : undefined}
        title={"List View"}
        placement={"bottom"}
        mouseLeaveDelay={0}
      >
        <li>
          <ButtonElement
            className={`${style.listselectButton} ${
              props.listViewType === "gridOne" ? style.selectedCard : ""
            }`}
            to={{}}
            onClick={() =>{
              !props.isSearchLoading && props.handleListView("gridOne");
            }
              
            }
            disabled={props.isSearchLoading}
          >
            <span>
              <SVGIcons type="SVG-listview" />
            </span>
          </ButtonElement>
        </li>
      </TooltipContainer>
    </ul>
  );
};
