import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export function xAxisSetting({chart, root, xRenderer, xAxis}) {
  return chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
      categoryField: xAxis,
    })
  );
}
