import { KEYS } from "../dataKeys";

/**
 *
 * @returns {Storage | null}
 */
export const getStorageLocation = () => {
  const storageType = localStorage.getItem(KEYS.RememberMe);
  return storageType === "sessionStorage"
    ? sessionStorage
    : storageType === "localStorage"
    ? localStorage
    : null;
};

export const CommonUtils = {
  isLoggedIn: () => {
    return getAccessToken() ? true : false;
  },
};
export const getAccessToken = () =>
  document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith(KEYS.ACCESS_TOKEN_FIELD + "="))
    ?.split("=")[1] || null;
//sort the data in accending order
export const sortDataInAccending = (data, key) => {
  if (key) {
    return data?.sort((prevData, nextData) =>
      prevData[key] > nextData[key] ? 1 : nextData[key] > prevData[key] ? -1 : 0
    );
  } else
    return data?.sort((prevData, nextData) => {
      prevData = prevData?.toLowerCase();
      nextData = nextData?.toLowerCase();
      return prevData > nextData ? 1 : prevData < nextData ? -1 : 0;
    });
};

export const getURL = (input) => {
  input = Array.isArray(input)
    ? input[0].replaceAll("<em>", "").replaceAll("</em>", "")
    : input;
  if (!input.includes("http")) return "Location info not available";
  let url = new URL(input);
  if (url.origin == "null" && url.href) {
    return { url: url.href, testFile: true };
  } else if (url.origin == "null") {
    return "Location info not available";
  } else {
    return url.origin;
  }
};

export const scrollToTop = () =>
  document
    .getElementsByClassName("simplebar-content-wrapper")[0]
    ?.scrollTo(0, 0);

// method to check the type of dateFilter and return array
export const setDateFilterData = (dateFilter) =>
  Array.isArray(dateFilter) ? dateFilter : [dateFilter];

// to check this API is canceled or not .
export const handleApiError = (error, next) =>
  error.message !== "CANCEL" && next();

export const handleWebsiteDomain = (data) => {
  return (
    data?.website_domain || data["Website Domain"] || getURL(data?.Location)
  );
};

// To format date
export const formatDate = (value, format) =>
  format ? value?.format(format) : value.format("YYYY-MM-DD");

//function to check the data changes
export const checkDataChange = ({ currentData, previousData, fields = [] }) => {
  let isChanged = false;
  fields.forEach((field) => {
    if (currentData[field] !== previousData[field]) {
      isChanged = true;
      return;
    }
  });
  return isChanged;
};