import React, { Component } from "react";
import ReactWordcloud from "react-wordcloud";

import {
  checkPreviousValue,
  getRandomHex,
  setKeyworldCloudColor,
} from "./helper";
import { WORD_CLOUD_MAXIMUM_WORD_COUNT } from "../../Constants/Insights/Insights.constants";
import { FilterFieldValues, setInsightData } from "../../Redux/Actions";

import style from "./style.module.scss";
import globel from "../../styles/globel/style.module.scss";
import { notify } from "../Notification";
import ExcludeModal from "./Children/ExcludeModal";
import { connect } from "react-redux";
import { addExcludeListItem, getExcludeListItem } from "../../api/insights";

class WordCloud extends Component {

  boundaries = [];

  formatData = (data) => {
    return data.map((item) => ({
      ...item,
      type: item?.type?.replace(/_/g, " "),
    }));
  };
  state = {
    data: this.formatData(this.props.data ?? []),
    colors: [],
    clickedText: "",
    isModalOpen: false,
  };
  componentDidMount = () => {
    this.updateChartData();
  };
  componentWillUnmount = () => {
    this.tooltipElement?.remove();
    this.tooltipElement = null;
  };
  /**
   * @param  {...any} text
   * @returns {import('react-wordcloud').Optional<import('react-wordcloud').Options>}
   */

  paddings = [1, 1, 1, 1, 1, 1, 10, 10 ];

  getCloudOptions = ()=>{
    return {
        rotations: 0,  // how many rotations
        rotationAngles: [0, 0], // in this range of angles 
        enableOptimizations: true, // beta support 
        deterministic: true, // means always render words in the same place 

        fontFamily: "Red Hat Display, Arial, Lato, sans-serif",
        fontSizes: [12, 80], // range of font sizes
        fontStyle: "normal",
        fontWeight: "normal",

        scale: 'log', 
        spiral: 'archimedean',  // archimedean seems more like lumininfo 

        transitionDuration: 1000,

        padding: (word)=>{
          // padding can be a function too !

          let i = 0;
          for (i = 1; i <= this.boundaries.length; i++){
              if (word.value <= this.boundaries[i]){
                  break;
              }
          }
         
          const padding = this.paddings[i - 1];
          return padding;
        },

        tooltipOptions: {
          placement: "bottom",
          render: (value) => {
            const data = value.props.content;
            this.tooltipElement = document.createElement("div");
            this.tooltipElement.className = style.wordcloudtoolTip;
            this.tooltipElement.innerHTML = `
              <span>
                <label>Value: </label>
                <span>${data.text}</span>
              </span>
              <span>
                <label>Type: </label>
                <span>${data.type}</span>
              </span>
              <span>
                <label>Documents: </label>
                <span>${data.value}</span>
              </span>
              ${
                data.parent
                  ? `<span>
                <label>Parent: </label>
                <span>${data.parent}</span>
              </span>`
                  : ""
              }
            `;
            return {
              popper: this.tooltipElement,
            };
          },
        },
    };
  }

  // don't use this method
  handleOptions = (...text) => {
    return {
      enableTooltip: true,
      deterministic: false,
      fontFamily: "Red Hat Display, Arial, Lato, sans-serif",
      fontSizes: [14, 50],
      fontStyle: "normal",
      fontWeight: "normal",
      colors: ["#000000"],
      padding: 5,

      rotations: 0,
      rotationAngles: [0, 0],
      enableOptimizations: true,

      
      spiral: "archimedean",
      transitionDuration: 1000,

      

      scale: "linear",
      tooltipOptions: {
        placement: "bottom",
        render: (value) => {
          const data = value.props.content;
          this.tooltipElement = document.createElement("div");
          this.tooltipElement.className = style.wordcloudtoolTip;
          this.tooltipElement.innerHTML = `
            <span>
              <label>Value: </label>
              <span>${data.text}</span>
            </span>
            <span>
              <label>Type: </label>
              <span>${data.type}</span>
            </span>
            <span>
              <label>Documents: </label>
              <span>${data.value}</span>
            </span>
            ${
              data.parent
                ? `<span>
              <label>Parent: </label>
              <span>${data.parent}</span>
            </span>`
                : ""
            }
          `;
          return {
            popper: this.tooltipElement,
          };
        },
      },
    };
  };

  colors = [
    "CadetBlue",  // teal  - smallest
    "Gray", 
    "#36454F", 
    // "FireBrick",   //brown
    "Navy",        // blue
    "LimeGreen",  // green
    "#ffc800", // yellow
    "DarkOrange", // orange
    
    
    "Red"
    
  ];

  doBoundaries = ()=>{
    this.boundaries = [];
    const words = [...this.props.data];
    words.sort((a, b)=>{
       
      if (a.value > b.value){
          return 1;
      }

      if (a.value < b.value){
          return -1
      }
      return 0;
  });

  let max = words[words.length - 1].value;
  let min = words[0].value;


  // temp
  // max = 1000;
  // min = 1;
  // end temp 

  const N = this.colors.length; // number of sections 
  const q = Math.pow((max / min), (1 / N));

  //const boundaries = [min]; // intervals 
  this.boundaries = [min];

  for (let i = 1; i < N; i++){
  const boundary = min * Math.pow(q, i)
      this.boundaries.push(boundary);
  }

  this.boundaries.push(max); 

  };

  updateChartData = () => {
    this.setState({
      data: this.formatData(this.props.data ?? []),
      colors: [
        ...new Set((this.state.data ?? []).map((item) => item.type)),
      ].reduce((accumulator, item) => {
        accumulator[item] = getRandomHex();
        return accumulator;
      }, {}),
    });

    this.doBoundaries();
  };
  componentDidUpdate(prevProps) {
    prevProps.data !== this.props.data && this.updateChartData();
  }
  handleCallback = (text) => {
    const props = this.props;
    const maxValue = Math.max(...props?.data?.map((item) => item.value)) || 0;
    const intervalSize = maxValue / 10;
    let intervals = [];
    for (let i = 0; i < 10; i++) {
      const start = i * intervalSize;
      const end = (i + 1) * intervalSize;
      intervals.push({ start, end });
    }
    let callbacks = {
      getWordColor: (text) => {
        // return this.state.colors?.[text.type] ?? '#2196F3';
        return setKeyworldCloudColor(intervals, text.value);
      },
      onWordClick: (word) => {
        this.tooltipElement?.remove();
        this.tooltipElement = null;
        if (checkPreviousValue(props.breadCrumbData, word.key)) {
          props.WordCloudClickAction({
            value: word.key,
            isWorCloudClick: true,
          });
        } else {
          notify.info("The word is already included in the search!");
          this.updateChartData();
        }
      },

      getWordTooltip: (text) => {
        return text;
      },
    };
    return callbacks;
  };
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextProps.data !== this.props.data ||
      this.state.isModalOpen !== nextState.isModalOpen
    );
  };
  handleRightClick = async (event) => {
    if (this.props.dashboard) return;
    event.preventDefault();
    if (event.target.__data__.hasText) {
      let newItem = event.target.__data__.text;
      notify.info(`The word ${newItem} is adding to the exclusion list`);
      try {
        let response = await addExcludeListItem({
          word: newItem?.toLowerCase(),
        });
        notify.success(response?.data?.result);
        this.props.setInsightData({
          dataChanges: [newItem.toLowerCase()],
        });
      } catch (error) {
        notify.error(error?.response?.data?.result);
      }
    }
  };

  onClose = () => {
    this.setState({ isModalOpen: false, clickedText: "" });
  };

  render() {
    return (
      <>
        <div
          onContextMenu={this.handleRightClick}
          className={`${
            this.props.dashboard ? style.rootContainer : globel.chartItem
          }  ${this.props.isDownload && globel.previewCharts}`}
          id={this.props.id}
        >
          <ReactWordcloud
            words={this.formatData(this.props.data ?? [])}
            // options={this.handleOptions()}
            options={this.getCloudOptions()}
            callbacks={this.handleCallback()}
            maxWords={WORD_CLOUD_MAXIMUM_WORD_COUNT}
          />
          {this.state.isModalOpen && (
            <ExcludeModal
              isModalOpen={this.state.isModalOpen}
              text={this.state.clickedText}
              onClose={this.onClose}
            />
          )}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return { createInsights: state.CreateInsights };
}
function mapDispatchToProps(dispatch) {
  return {
    loadFilterFieldValues: (payload) => dispatch(FilterFieldValues(payload)),
    getExcludeListItem: (payload) => dispatch(getExcludeListItem(payload)),
    setInsightData: (payload) => dispatch(setInsightData(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WordCloud);
