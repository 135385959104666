import React from 'react';
import PropType from 'prop-types';

import ButtonElement from '../../../Components/ButtonElement';

import style from './style.module.scss';

export default function ButtonGroup(props) {
  return (
    <>
      <li>
        <ButtonElement children={'Clear'} onClick={props.onClearAll} disabled={props.clearAllDisabled} type='link' />
      </li>
      <li>
        <ButtonElement children={'Apply'} onClick={props.onApply} disabled={props.disabled} type='primary' />
      </li>
    </>
  );
}

ButtonGroup.propTypes = {
  onApply: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
  onClearAll: PropType.func.isRequired,
  disabled: PropType.bool.isRequired,
  clearAllDisabled: PropType.bool.isRequired,
};
