import React from 'react';
import {Link} from 'react-router-dom';

import {
  OTP_PLACEHOLDER,
  RESEND_OTP,
  OTP_FORM_HEADER,
  OTP_FORM_BODY,
} from '../../../../../Constants/Login/Login.constants';
import style from './style.module.scss';
import InputField from '../../../../../Components/InputField';

function OtpForm(props) {
  return (
    <div className={style.otpForms}>
      <h3>{OTP_FORM_HEADER}</h3>
      <p>{OTP_FORM_BODY}</p>
      <ul className={style.otpList}>
        <li>
          <InputField
            name={'otp-block'}
            placeholder={OTP_PLACEHOLDER}
            onChange={props.handleOTP}
            value={props.state.otp}
            autoFocus={true}
            onPressEnter={(e) => props.forgotPasswordOkHandler()}
          />
        </li>
      </ul>
      <div className={style.otpError}>
        <p className={style.invalidOtp}>{props.state.otpErrorMsg != '' ? props.state.otpErrorMsg : ''}</p>
        <p className={style.resendOtpLink} onClick={props.handleResendOTP}>
          {RESEND_OTP}
        </p>
      </div>
    </div>
  );
}

export default OtpForm;
