import { GetDashboardDocumentsById } from "../../../../api/dashboard";

import { handleApiError } from "../../../../utils/commonUtils";

export const getDashboardArticlesHelper = async ({
  payload,
  responceHandler,
}) => {
  try {
    const { data } = await GetDashboardDocumentsById({
      ...payload.filter,
      ids: payload.ids,
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
      reqFields: [],
      sortorder: payload.sortOrder,
      sortby: payload.sortby,
    });
    responceHandler({
      data: data.result.result,
      total: data.result.resultHits,
    });
    // const filterData = {
    //   filter: payload.filter.filtersforData[0],
    //   dateFilter: payload.filter.dateFilters,
    // };
    // dispatch({
    //   type: SEARCH_SUCCESS,
    //   payload: {
    //     data: response.data.result,
    //     key: "",
    //     pageNumber: payload.pageNumber,
    //     pageSize: payload.pageSize,
    //     totalResultCount: response.data.resultHits,
    //     emptySearch: false,
    //     insightIds: payload.ids,
    //     sortorder: payload.sortOrder,
    //     sortby: payload.sortby,
    //     ...filterData,
    //   },
    // });
  } catch (error) {
    handleApiError(error, () => {
      responceHandler({ error: error });
    });
  }
};
