import React from "react";

import { ContentLoader } from "Components/ContentLoader";
import EmptyState from "Components/EmptyState";
import Pagination from "Components/Pagination";
import TableElement from "Components/TableElement";
import { INSIGHTS_CONFIG } from "Routes/Insights/Insights.config";
import { ShowModalwindow } from "./ShowModalWindow";
import { DEFAULT_PAGE_COUNT } from "constants";

import style from "./style.module.scss";
import globel from "styles/globel/style.module.scss";

export function SavedInsights(props) {
  return (
    <>
      <div className={style.savedInsight}>
        {props.state.showDeleteModal && <ShowModalwindow {...props} />}

        {props.props.InsightsPage?.loading ? (
          <>{ContentLoaderTable()}</>
        ) : (
          <>
            {props.props.InsightsPage?.error ? (
              <EmptyState content="ERROR" type={"errorImage"} />
            ) : (
              <>
                <div className={style.insightPagination}>
                  <div className={globel.searchresultCount}>
                    <span>
                      {props.insightData.length > 0
                        ? "Showing " +
                          props.insightData.length +
                          " out of " +
                          props.props.InsightsPage?.totalCount +
                          " Results"
                        : ""}
                    </span>
                  </div>
                  {props.props.InsightsPage?.totalCount > DEFAULT_PAGE_COUNT &&
                    InsightPagination({
                      ...props.state.pagination,
                      totalCount: props.props.InsightsPage?.totalCount,
                      onChange: props.handleInsightsPagination,
                    })}
                </div>
                {props.insightData.length > 0 ? (
                  <div className={style.insightData}>
                    <div className={style.insightData__table}>
                      <TableElement
                        columns={INSIGHTS_CONFIG.COLUMNS({
                          deleteHandler: props.handleDelete,
                          sortedInfo: props.state.sortedInfo,
                          handleViewInsights: props.handleViewInsights,
                          downloadInsightsHandler: props.downloadInsightHandler,
                        })}
                        data={props.insightData}
                        onChange={props.handleChangeSorter}
                        loading={props.props.InsightsPage?.loading}
                        scrollY={"calc(90vh - 270px)"}
                      />
                    </div>
                  </div>
                ) : (
                  <EmptyState
                    type="welcomeImage"
                    // showButton={true}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
function ContentLoaderTable() {
  return (
    <div className={style.insightData}>
      <div className={style.insightData__table}>
        <ContentLoader height={2} width={100} number={10} hasWrapper={true} />
      </div>
    </div>
  );
}

function InsightPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return (
    <Pagination
      pageSize={pageSize}
      current={pageNumber}
      total={totalCount}
      onChange={onChange}
      showSizeChanger={true}
      pageSizeOptions={["10", "20", "50", "100"]}
    />
  );
}
