import React from "react";

import template from "./template";
import {
  clearInputHandler,
  createInsigtsDidMountHelper,
  insightsInputResetHelper,
} from "./helper/insightsHelper";
import { formatAvailableFieldsEdit } from "./helper/formatter";
import {
  checkIsValueFieldController,
  dataSetController,
  handleCancelInsightHelper,
  handleEditHelper,
  saveModalWindowOkHelper,
} from "./helper/controller";
import {
  EMPTY_SAVE_NAME,
  WORD_CLOUD_FIELD_LABEL,
  WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL,
  WORD_CLOUD_MINIMUM_OCCURANCE_LABEL,
} from "../../../../Constants/Insights/CreateInsights.constants";
import {
  INSIGHT_NAME_ALREADY_EXISTS,
  INSIGHT_NAME_CHARACTER_LIMIT,
  INSIGHT_NAME_CHARACTER_LIMIT_MESSAGE,
  WORD_CLOUD_DEFAULT_WORD_COUNT,
  WORD_CLOUD_MINIMUM_OCCURRENCE_COUNT_DEFAULT,
  INSIGHT_UPDATED_SUCCESSFULLY_MESSAGE,
} from "../../../../Constants/Insights/Insights.constants";
import { DEFAULT_PAGE_COUNT } from "../../../../constants";
import { UpdateSaveModalOkHandlerHelper } from "./helper/modalWindowHelper";
import { notify } from "../../../../Components/Notification";
import { formatInsightData } from "Routes/Insights/Helper";

class CreateInsights extends React.Component {
  state = {
    selectedChart: "KEY_PHRASE_CLOUD",
    selectedField: {
      [WORD_CLOUD_MAXIMUM_WORD_COUNT_LABEL]: {
        value: WORD_CLOUD_DEFAULT_WORD_COUNT,
        index: "",
      },
      [WORD_CLOUD_MINIMUM_OCCURANCE_LABEL]: {
        value: WORD_CLOUD_MINIMUM_OCCURRENCE_COUNT_DEFAULT,
        index: "",
      },
      [WORD_CLOUD_FIELD_LABEL]: { value: "Keywords", index: "" },
    },
    isChart: false,
    saveModalWindow: false,
    insightName: "",
    dataSetValue: 3177,
    searchValue: "",
    maximumOccurance: 0,
    isEditDashboard: false,
    SaveLoading: false,
    insightNameErrorMessage: "",
    searchLoading: false,
    overWriteWindow: false,
    isClickedNo: false,
    filterTags: {},
  };

  componentDidMount() {
    createInsigtsDidMountHelper(this);
    this.props.getAvailableFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.payloadFields != this.props.payloadFields) {
      this.setState({ allFields: this.props.payloadFields });
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.isEdit
      ) {
        let newFields = formatAvailableFieldsEdit(
          this.props.location.state.chartInfo.payload,
          this.props.payloadFields
        );
        this.setState({ allFields: newFields });
      }
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state?.isEdit &&
      prevProps.location.state?.chartInfo !=
        this.props.location.state?.chartInfo
    ) {
      createInsigtsDidMountHelper(this);
    }
    if (prevProps.dataSet != this.props.dataSet) {
      dataSetController({ state: this });
    }
  }

  cleartextInput = (event, fields) => {
    clearInputHandler({ event, fields, state: this });
  };

  checkIsValueField = (value) =>
    checkIsValueFieldController({ value, state: this });

  handleReset = () => insightsInputResetHelper(this);

  SaveModalCancelHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isEdit
    )
      this.props.SaveModalCancelHandler();
    else {
      this.setState({
        insightName: "",
        insightNameErrorMessage: "",
      });
      this.props.SaveModalCancelHandler();
    }
  };

  handleInsightsName = (event) => {
    if (event.target.value.length > INSIGHT_NAME_CHARACTER_LIMIT) {
      this.setState({
        insightNameErrorMessage: INSIGHT_NAME_CHARACTER_LIMIT_MESSAGE,
      });
    } else {
      this.setState({
        insightName: event.target.value,
        insightNameErrorMessage: "",
      });
    }
  };

  SaveModalOkHandler = () => {
    if (this.state.insightName.trim() == "") {
      this.setState({
        insightNameErrorMessage: EMPTY_SAVE_NAME,
        SaveLoading: false,
      });
    } else {
      saveModalWindowOkHelper({ state: this, SaveLoading: false });
    }
  };

  handleEditUpdate = async (value) => {
    if (value == "true") {
      this.setState({
        insightNameErrorMessage: INSIGHT_NAME_ALREADY_EXISTS,
        SaveLoading: false,
      });
    } else {
      handleEditHelper({ state: this, value });
      this.props.getInsightData({});
      this.props.SaveModalCancelHandler();
    }
  };

  handleExisting = ({ error, message }) => {
    if (error && message.response.data) {
      if (this.props.history?.location?.state?.isEdit) {
        this.showOverWriteWindowHandler();
      }
      this.setState({
        insightNameErrorMessage: INSIGHT_NAME_ALREADY_EXISTS,
        SaveLoading: false,
      });
    } else {
      this.setState({
        insightNameErrorMessage: "",
        saveModalWindow: false,
        SaveLoading: false,
        insightName: "",
        isClickedNo: false,
      });
      this.props.SaveModalCancelHandler();
      this.props.getInsightData({});
    }
  };

  handleCancel = () => {
    handleCancelInsightHelper({ state: this });
  };

  handleDataSet = (value) => {
    if (value) this.props.getAvailableFields(value);
    this.setState({ dataSetValue: value });
  };

  handleWordCloudClick = (data) => {
    const breadCrumbData = this.props.createInsights.breadCrumbData;
    this.props.setInsightData(
      formatInsightData({
        breadCrumbs: [
          ...breadCrumbData,
          { value: breadCrumbData.length, label: data.value },
        ],
      })
    );
  };

  handleBreadCrumb = (crumbItem) => {
    if (this.props.createInsights.chartName === "HEAT_MAP") {
      if (crumbItem?.clear === true) {
        this.props.setInsightData({ filter: {} });
      }
    } else if (this.props.createInsights.chartName === "KEY_PHRASE_CLOUD") {
      if (crumbItem?.clear === true) {
        this.props.setInsightData(
          formatInsightData({
            breadCrumbs: [],
            chartParameters: this.state.selectedField,
          })
        );
      } else {
        this.props.setInsightData(
          formatInsightData({
            breadCrumbs: this.props.createInsights?.breadCrumbData?.slice(
              0,
              crumbItem?.value + 1
            ),
            chartParameters: this.state.selectedField,
          })
        );
      }
    }
  };

  showOverWriteWindowHandler = () => {
    this.props.SaveModalCancelHandler();
    this.setState({ overWriteWindow: true });
  };

  onOverWriteHandler = () => {
    UpdateSaveModalOkHandlerHelper({
      state: this,
      handleEditUpdate: () => {
        this.setState({
          insightNameErrorMessage: "",
          saveModalWindow: false,
          SaveLoading: false,
          overWriteWindow: false,
        });
        notify.success(INSIGHT_UPDATED_SUCCESSFULLY_MESSAGE, true);
      },
      isEditDashboardUpdate: false,
    });
  };

  onSaveCopyHandler = () => {
    this.setState({
      overWriteWindow: false,
      isClickedNo: true,
      insightNameErrorMessage: "",
    });
    this.props.handleShowModalWindow();
  };

  render() {
    return template.call(this);
  }
}

export default CreateInsights;
