import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export function getProjectListApi() {
  const instance = new APIInstance({
    baseURL: BASE_URL.USER_PROJECT,
  });

  const api = instance.api;
  return api.get('/GetUserProjects');
}
