import env from "react-dotenv";
import gobelStyle from "styles/globel/style.module.scss";
export const HEADINGS = {
  COUNTER: "Counter",
  LOGIN_HERE: "Login Here",
};

export const DEFAULT_SEARCH_KEY = "title";

export const SEARCH_RESULT_PDF_KEY = "ClippedPath";

export const DEFAULT_PAGE_COUNT = 10;

export const DEFAULT_NUMBER_OF_DAYS = 10;
export const DEFAULT_DATE_FILTER_TYPE = 1;
export const DEFAULT_VIEW_TYPE = "gridThree";
export const DEFAULT_DATE_FILTER_VALUE = {
  key: "",
  value: { from: "", to: "" },
};
export const DEFAULT_USER_ROLE = "analyst";
export const ARTICLE_DOCUMENT_API =
  env.API_DOMAIN_URL +
  "/api/Search/downloadfromurl?url=" +
  env.DOCUMENT_BASE_PATH;

// App version should be updated before every production release.
export const APP_VERSION = "2.3.5";

export const SEARCH_TYPE = {
  ADVANCED_SEARCH: "advanceSearch",
  NORMAL_SEARCH: "normalSearch",
  DASHBOARD_PREVIEW: "dashboardPreview",
};

export const COMMUNICATION_KEYS = {
  START_FROM_INSIGHT: "communication started from insights",
  START_FROM_ARTICLE: "Communication started from articles",
  PARENT_TERMINATE: "Parent terminated communication",
  CHILD_TERMINATE: "Child terminated communication",
  CHILD_CHOOSE_STAY: "Child chose to stay active",
  PARENT_CHOOSE_STAY: "Parent chose to stay active",
  BACK_TO_DEFAULT_FROM_DOWNLOAD_PREVIEW:
    "Back to default from download preview",
  CHANGE: "Change in data",
  PROJECT_CHANGE: "Switching projects",
};

export const DATA_FIELDS = {
  ARTICLE: [
    "value",
    "filter",
    "dateFilter",
    "pageNumber",
    "pageSize",
    "searchType",
    "advanceSearchPayloadData",
    "sortorder",
    "sortby",
    "searchCriterias",
    "searchWords",
    "buckets",
  ],
  INSIGHTS: [
    "chartName",
    "dateFilter",
    "filter",
    "searchWords",
    "payload",
    "advanceSearchPayloadData",
    "searchValue",
    "searchType",
    "searchCriterias",
    "dataChanges",
  ],
};
const SEARCH_INFO_ORDERED_LIST = [
  {
    description:
      "To get the results related to X or Y, search for X OR Y. e.g.",
    example: "Airport OR Baggage",
    note: "",
  },
  {
    description:
      "To get the results related to both X and Y, search for X AND Y. e.g.",
    example: "Airport AND Baggage",
    note: "",
  },
  {
    description:
      "To get the results related only to X but not Y, search for X NOT Y. e.g.",
    example: "Airport NOT Baggage",
    note: "",
  },
  {
    description:
      "To get the results matching an exact-word or phrase, search with that phrase/word within double quotes. e.g.",
    example: `"Hong Kong"`,
    note: {
      description:
        " NOTE : If the search keyword itself contain a double quoted section, replace it with single quote when trying for the exact match. e.g.",
      example: `if the user searches for He said: "I can do it" , search as "He said: 'I can do it'"`,
    },
  },
  {
    description: "To get the results that start with X, search for X* . e.g.",
    example: "Airport*",
    note: "",
  },
  {
    description: "To get the results that end with X, search for *X. e.g.",
    example: "*Airport",
    note: "",
  },
];
const SEARCH_INFO_UNORDERED_LIST = [
  "All logical operators must be in block letters",
  "The search terms and operators must be separated with a single character space.",
];
export const SEARCH_INFO = {
  STYLE: { width: "850px", background: "#000000",padding:'20px' },
  CONTENT: (
    <div>
      <ol type="1" className={gobelStyle.searchInfoToolTipInnerOl}>
        {SEARCH_INFO_ORDERED_LIST.map((list) => (
          <li>
            {list.description}
            <em>{list.example}</em>
            {list.note ? (
              <p>
                {list.note.description}
                <em>{list.note.example}</em>
              </p>
            ) : null}
          </li>
        ))}
      </ol>
      <br />
      <ul className={gobelStyle.searchInfoToolTipInnerUl}>
        {SEARCH_INFO_UNORDERED_LIST.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  ),
};
