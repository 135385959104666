import Container from "Components/Container";
import { ContentLoader } from "Components/ContentLoader";
import ErrorState from "Components/ErrorState";
import { handlePublishDatePosition } from "Components/FilterSection/Children/helper";
import { useState } from "react";
import { MyFilter } from "../MyFilter";

const { useLocation } = require("react-router-dom/cjs/react-router-dom.min");
const { DATE_FILTER_KEY } = require("Routes/Home/constants");

export const MyFilterOptions = props=>{
    // debugger;

    const {filterLoad, settings, searchTab, handleCheckbox} = props;
    

    const [openItems, setOpenItems] = useState({});

    let arr = [...Array(15).keys()]; // numbers from 0 to 14

    const handleOpenItems = (item, index) =>{
        let keys = Object.keys(openItems);
        const found = keys.includes(item);
        if (found){
            const newOpenItems = {...openItems, [item]: !openItems[item]};
            setOpenItems(newOpenItems);
        }
        else{
            if (index == 1){
                let mappedArray = handlePublishDatePosition(props.settings, props.searchTab);
                mappedArray.map((value, index) => {
                    let valueList = Object.keys(openItems);
                    let isValuePresent = valueList.includes(item);
                    if (!isValuePresent && index == 1) {
                        let listItems = {[mappedArray[0]]: true, [mappedArray[1]]: true};
                        setOpenItems(listItems);
                    }
                });
            }

            if (index !== 0 && index !== 1){
                const newOpenItems = {...openItems, [item]: true};
                setOpenItems(newOpenItems);
            }
        }
    };

    return (<>

    {filterLoad && arr.map(index=>(

        // ContentLoader is the grid with the loading bars.. 
        <Container
            key={index}
            height={3}
            width={100}
            spaceBetween={0.375}
        />
    ))}

    {!filterLoad && 
    Object.keys(settings).length == 0 ? 
    <ErrorState 
        content="ERROR"    
        type={'errorImage'}
        errorMessage={{title: "Empty", description: "It's all empty here!"}}
        customHeight='500'
    /> : 
    handlePublishDatePosition(settings, searchTab).map((item, index)=>{
        //debugger;

        // settings is an object where keys (property names) are filter section names, 
        // eg Aircraft Model, Airlines, Airlines NEW ..

        // value of each key is an object, where
        // checked - means filter is active , otherwise it's not rendered
        // data - array of options (keywords)
        // 

        /*
        Object.keys(settings): 
            (23) ['Aircraft Model', 'Airlines', 'Airlines NEW', 'Airports', 'Author', 'Aviation Industry', 'Countries', 'Date Published', 'Document Author', 'EN Airports', 'EN Airports Short', 'Global Unit', 'Green', 'Keywords', 'Last Used Date', 'Locations', 'Manufacturers', 'Organizations', 'Top N KeyPhrases', 'Top N People', 'Topical Research', 'Travel Retail Operators', 'Website Domain']
        */
        
        return settings[item].checked ? (<> 
        <MyFilter 
            
            item={item}
            index={index}
            key={index}
            openItemsHandler={handleOpenItems}

            
            dateFormat={props.dateFormat}
        
            

            openItems={openItems}
            props={{
                settings: props.settings, 
                loadFilterFieldValues: props.loadFilterFieldValues,
                search: props.search,
                createInsights: props.createInsights,
                filterResults: props.filterResults, 
                handleChangeDate: props.handleChangeDate,
                handleCheckbox, 
                handleSelectAll: props.handleSelectAll,
                handleClearAll: props.handleClearAll,
                handleChangefilterDateType: props.handleChangefilterDateType,
                filterDateType: props.filterDateType,
                
                numberOfDays: props.numberOfDays

            }}
        /></>) : <></>
    })}
    </>);
};