import React from "react";
import ModalWindow from "../../Components/ModalWindow";
import ConfirmationMessage from "../../Components/ConfirmationMessage";
import { DASHBOARD_CONSTANTS } from "../../Constants/Dashboard/Dashboard.constants";

function ShowModalwindowDelete(props) {
  return (
    <>
      <ModalWindow
        isNotClosable={true}
        okText={DASHBOARD_CONSTANTS.NO_TEXT_CONSTANT}
        cancelText={DASHBOARD_CONSTANTS.YES_TEXT_CONSTANT}
        open={props.state.showDeleteModal}
        footer={false}
      >
        <ConfirmationMessage
          heading={"Confirm Delete"}
          message={"Are you sure you want to delete this item?"}
          hide={false}
          onCancel={(e) => props.deleteModalHandlerYes()}
          onOk={(e) => props.deleteModalHandler(e, "modalOff")}
          cancelButtonLabel="Yes"
          confirmButtonLabel="No"
          loading={props.state.deleteLoading}
        />
      </ModalWindow>
    </>
  );
}

export default ShowModalwindowDelete;
