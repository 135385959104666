import React from "react";

import LoadDropdown from "./LoadResults/LoadDropDown";
import SaveResults from "./children/SaveResults";
import LoadResults from "./children/LoadResults";
import ExportsResults from "./children/ExportsResults";
import SaveResultsModal from "./children/SaveResultsModal";
import style from "../style.module.scss";
import SearchBar from "../../SearchBar";
import { Link } from "react-router-dom";
import SVGIcons from "../../SVGIcons";
import SortContent from "./SortContent";
import TooltipContainer from "../../Tooltip";
import {
  FILTER_DISABLE_TOOLTIP,
  VIEW_INSIGHT_DISABLED_TOOLTIP,
} from "./constants";
import ButtonElement from "../../ButtonElement";
import { SEARCH_TYPE } from "constants";

function template() {
  const {
    search,
    handleSave,
    handleExport,
    exportLoading,
    handleViewNewTab,
    viewTabButtonVisible,
    searchData,
  } = this.props;
  const isAdvanceSearch = search.searchType === SEARCH_TYPE.ADVANCED_SEARCH;
  return (
    <>
      <div className={style.headerControls}>
        <div className={style.controlsSearch}>
          <ul className={style.controlsSearch__list}>
            <li>
              <TooltipContainer
                title={isAdvanceSearch && FILTER_DISABLE_TOOLTIP}
                placement={"right"}
              >
                <a
                  disabled={isAdvanceSearch || searchData.loading}
                  className={`${style.filtersearchButton} ${
                    this.props.filterVisible.visible
                      ? style["filterToggled"]
                      : ""
                  }`}
                  onClick={
                    !searchData.loading && !isAdvanceSearch
                      ? this.props.handleFilterVisible
                      : undefined
                  }
                >
                  <span>
                    <SVGIcons type={"SVG-arrow"} />
                  </span>
                  <span>Filter</span>
                </a>
              </TooltipContainer>
            </li>
            <li>
              <SearchBar className={style.text} />
            </li>
          </ul>
        </div>
        <div className={style.controlsElement}>
          <ButtonElement
            type={"primary"}
            size={"small"}
            onClick={!viewTabButtonVisible ? undefined : handleViewNewTab}
            disabled={!viewTabButtonVisible}
          >
            <TooltipContainer
              title={!viewTabButtonVisible ? VIEW_INSIGHT_DISABLED_TOOLTIP : ""}
              placement={"bottom"}
            >
              <span>View insights</span>
            </TooltipContainer>
          </ButtonElement>
          <div className={style.advancDrawertrigger}>
            <a
              to={{}}
              className={style.advancesearchButton}
              onClick={this.handleAdvanceSearchClick}
            >
              <span>Advanced Search</span>
            </a>
          </div>
          <SortContent
            {...this}
            listViewType={this.props.listViewType}
            handleListView={this.props.handleListView}
            searchData={searchData}
          />
          <ul className={style.controlsElement__list}>
            <li>
              <div className={style.controlsItem}>
                <SaveResults
                  title={"Save Results"}
                  handleSave={handleSave}
                  disabled={
                    !searchData.loading && searchData?.data?.length
                      ? false
                      : true
                  }
                />
              </div>
            </li>
            <li>
              <div className={style.controlsItem}>
                <LoadResults
                  toolTipTitle={"Saved Queries"}
                  handleLoad={(value) => this.handleLoad(value)}
                />
              </div>
              <LoadDropdown
                {...this}
                optionList={search?.fullSavedData}
                handleLoad={this.handleLoad}
              />
            </li>

            <li>
              <div className={style.controlsItem}>
                <ExportsResults
                  handleExport={handleExport}
                  exportLoading={exportLoading}
                  disabled={
                    !searchData.loading && searchData?.data?.length
                      ? false
                      : true
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <SaveResultsModal {...this} />
    </>
  );
}

export default template;
