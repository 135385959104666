export const INSIGHTS_CONSTANTS = {
  HEADING: "Saved Insights",
  BUTTON_LABEL: "Add Insight",
  NO_TEXT_CONSTANT: "Yes",
  YES_TEXT_CONSTANT: "No",
  INSIGHT_DELETE_MODAL_BODY: "Are you sure you want to delete this item?",
  INSIGHT_DELETE_MODAL_HEADER: "Confirm Delete",
};
export const CREATE_INSIGHT_INFO =
  "Drag and drop the available fields to the chart parameters for creating and modifying the insights";
export const UPDATE_INSIGHT_INFO =
  "Note : Any change in this insight will not reflect in the dashboards " +
  "that are composed with a previous version of this insight. ";
export const DELETE_INSIGHTS_INFO =
  "Deleting will cause permanent loss of this item.";
export const SAVE_INSIGHT_NAME_PLACEHOLDER = "Save your insights with a name";
export const INSIGHT_UPDATED_SUCCESSFULLY_MESSAGE =
  "Insights updated sucessfully";
export const INSIGHT_SAVED_SUCCESSFULLY_MESSAGE = "Insights saved sucessfully";
export const INSIGHT_NAME_CHARACTER_LIMIT = 30;
export const INSIGHT_NAME_CHARACTER_LIMIT_MESSAGE =
  "Maximum allowed characters is " + INSIGHT_NAME_CHARACTER_LIMIT;
export const INSIGHT_NAME_ALREADY_EXISTS = "Insight name already exists";
export const CREATE_INSIGHT = "Create Insight";
export const INFORMATION_RESOURCE = "Information Resources";
export const FILL_CHART_PARAMETERS_WARNING = "Please fill the chart parameters";
export const SELECT_DATASET_WARNING = "Please select a dataset";
export const WORD_CLOUD_MAXIMUM_WORD_COUNT = 300;
export const WORD_CLOUD_DEFAULT_WORD_COUNT = 300;
export const WORD_CLOUD_MINIMUM_OCCURRENCE_COUNT_DEFAULT = 1;

export const KEYPHRASE_DATA_FETCHING_FIELDS = [
  // 'Airline Sectors',
  // 'Airport Codes',
  // 'Airport Services',
  // 'Annotations',
  // 'Author',
  // 'Aviation Industry Segments',
  // 'Cities',
  // 'Countries',
  // 'Facilities',
  // 'Facility Groups',
  // 'Global Units',
  // 'Green Business Topics',
  // 'Hk Lcc Airlines',
  // 'user_tags',
  // 'Locations',
  // 'Mainland Airlines',
  // 'Mainland Airports',
  // 'Mainland Cities',
  // 'Mainland Provinces',
  // 'Manufacturing Areas',
  // 'Home-based Airlines',
  // 'Non-Homebased Airlines',
  // 'Non-Mainland Cities',
  // 'Non-Mainland Airports',
  // 'Non-Mainland Airlines',
  // 'Top N KeyPhrases',
  // 'Top N Locations',
  // 'Top N People',
  // 'Topics',
];

export const OVERWRITE_MESSAGE =
  "An insight with this name already exists. Are you sure you want to overwrite?";
export const MATCHED_RESULTS_DISABLED_TOOLTIP =
  "Matched results is already opened in another tab";
export const CLOSE_TAB_MESSAGE1 =
  "You have navigated away from the parent tab on the first screen and the synchronization between the parent tab and this child tab has been terminated.";
export const CLOSE_TAB_MESSAGE2 =
  "You can start another synchronized session by closing this tab and opening this screen again from the same tab on the first screen.";
export const EXCLUSIOM_INFO = "Note: Maximum words allowed is 20";

export const CLOSE_TAB_NEW_MESSAGE1 =
  "You have moved away from the Articles screen causing this tab to stop the synced communication with it.";
export const CLOSE_TAB_NEW_MESSAGE2 =
  "Close this tab and reopen Insights from the Articles screen to start a new synced session.";
export const INITIAL_KEY_PHRASE_PAYLOAD = {
  chartName: "KEY_PHRASE_CLOUD",
  parameters: {
    "Maximum word count": {
      value: 300,
      index: "",
    },
    "Minimum word occurrence": {
      value: 1,
      index: "",
    },
    Datafield: {
      value: "Keywords",
      index: "",
    },
  },
  searchWords: [],
};

export const RELOAD_CONFIRM_MESSAGE =
  "Refreshing the page. The filters applied on this page will be cleared.";
