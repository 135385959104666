import React from 'react';
import {handlePublishDatePosition} from '../helper';
import template from './template';

class FilterSection extends React.Component {
  state = {
    openItems: {},
  };

  handleOpenItems = (item, index) => {
    let keys = Object.keys(this.state.openItems);
    const found = keys.includes(item);
    if (found) {
      this.setState({openItems: {...this.state.openItems, [item]: !this.state.openItems[item]}});
    } else {
      if (index == 1) {
        let mappedArray = handlePublishDatePosition(this.props.settings, this.props.searchTab);
        mappedArray.map((value, index) => {
          let valueList = Object.keys(this.state.openItems);
          let isValuePresent = valueList.includes(item);
          if (!isValuePresent && index == 1) {
            let listItems = {[mappedArray[0]]: true, [mappedArray[1]]: true};
            this.setState({openItems: listItems});
          }
        });
      }
      if (index !== 0 && index !== 1) {
        this.setState({openItems: {...this.state.openItems, [item]: true}});
      }
    }
  };

  render() {
    return template.call(this);
  }
}
export default FilterSection;
