import React from "react";
import SVGIcons from "../SVGIcons";
import { TextInput } from "../TextInput";
import style from "./style.module.scss";
export const SearchInput = (props) => {
  return (
    <div className={style.searchInput}>
      <TextInput
        className={style.textBox}
        onChange={props.handleChange}
        onBlur={props.onBlurSearch}
        value={props.value}
        placeholder={props.placeholder}
        htmlType={"text"}
        name={"searchInputField"}
      />
      <span className={style.lenceIcon} hidden={props.showLens}>
        <SVGIcons type={"SVG-lens"} />
      </span>
    </div>
  );
};
