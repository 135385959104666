import React from 'react';
import InputField from '../../../Components/InputField';
import TooltipContainer from '../../../Components/Tooltip';

import style from './style.module.scss';

function SelectValue(props) {

  const selectAll = (searchedValue)=>{
    props.handleSelectAll(searchedValue);
  };

  const clearAll = ()=>{
    props.handleClearAll();
  }

  return (
    <>
      {props.inputType === 'selectElement' ? (
        <TooltipContainer
          title={props.value?.length ? '' : props?.placeholder}
          placement='right'
          visible={props.value?.length ? false : undefined}
        >
          <div className={style.facetSelect}>
            <InputField
              showSelectAllButtons={true} 
              autoClearSearchValue={false}
              showSearch={true}
              onSearch={props.onSearch}
              name={props.title ? props.title : '  Select Value'}
              title={props.title ? props.title : '  Select Value'}
              onChange={props.onChange}
              value={props.value}
              optionList={props.optionList}
              placeholder={props.placeholder}
              inputType={props.inputType}
              htmlForName={'Select Value'}
              disabled={props.disabled}
              onBlur={props.onBlur}
              // style={props.value && props.style}
              loading={props.loading}
              mode={props.mode}
              maxTagCount={props.maxTagCount}
              className={props.className}
              onPopupScroll={props.onPopupScroll}
              onClick={props.onClick}
              handleSelectAll={selectAll}
              handleClearAll={clearAll}
            />
          </div>
        </TooltipContainer>
      ) : (
        <div className={style.facetSelect}>
          <InputField
            htmlType={props.date && 'number'}
            min={props.min}
            max={props.max}
            showSearch={true}
            name={props.title ? props.title : '  Select Value'}
            title={props.title ? props.title : '  Select Value'}
            onChange={props.onChange}
            value={props.value}
            optionList={props.optionList}
            placeholder={props.placeholder}
            inputType={props.inputType}
            htmlForName={'Select Value'}
            allowClear={!props.date && true}
            disabled={props.disabled}
            loading={props.loading}
          />
        </div>
      )}
    </>
  );
}

export default SelectValue;
