import React from "react";

import { Link } from "react-router-dom";

import SVGIcons from "../../SVGIcons";
import TooltipContainer from "../../Tooltip";
import { articlePdfDownloadHandler } from "../Helper";
import {
  ARTICLE_DOCUMENT_API,
  SEARCH_RESULT_PDF_KEY,
} from "../../../constants";

import global from "../../../styles/globel/style.module.scss";
import { removeEMTag } from "utils/dataFormatter";

const ButtonBlock = ({ article, handleTagsList, showPreview }) => {
  const pdfRelativePath = article[SEARCH_RESULT_PDF_KEY];
  const downloadLink = ARTICLE_DOCUMENT_API + pdfRelativePath;
  const fileName =
    "Article-" +
    article.DocumentId +
    downloadLink.slice(downloadLink.length - 20);
  let link = article.location || article.Location;
  link = link
    ? typeof link === "string"
      ? removeEMTag(link)
      : removeEMTag(link[0])
    : null;

  const downloadHandler = () =>
    pdfRelativePath && articlePdfDownloadHandler(downloadLink, fileName);

  return (
    <>
      <li>
        <TooltipContainer title={"Add Tags"} placement="bottom">
          <a onClick={handleTagsList}>
            <span>
              <SVGIcons type="SVG-tags" />
            </span>
            <span>Add Tags</span>
          </a>
        </TooltipContainer>
      </li>
      <li>
        <TooltipContainer
          title={
            pdfRelativePath
              ? "Download Article"
              : "This file cannot be found in the given path!"
          }
          placement="bottom"
        >
          <a
            onClick={downloadHandler}
            className={pdfRelativePath ? "" : global.disabled}
          >
            <span>
              <SVGIcons type={"SVG-download"} />
            </span>
            <span>Download</span>
          </a>
        </TooltipContainer>
      </li>
      <li>
        <TooltipContainer
          title={
            pdfRelativePath
              ? "Preview Article"
              : "This file cannot be found in the given path!"
          }
          placement="bottom"
        >
          <a
            onClick={() => (pdfRelativePath ? showPreview(true) : undefined)}
            className={pdfRelativePath ? "" : global.disabled}
          >
            <span>
              <SVGIcons type={"SVG-eye"} />
            </span>
            <span>Preview</span>
          </a>
        </TooltipContainer>
      </li>
      <li>
        <TooltipContainer
          title={
            link && link.startsWith("http")
              ? "Navigate to source"
              : !(link && link.startsWith("http"))
              ? "There is no valid link that can navigate to a website"
              : "This file cannot be found in the given path!"
          }
          placement="bottom"
        >
          <a
            className={link && link.startsWith("http") ? "" : global.disabled}
            onClick={() =>
              !(link && link.startsWith("http")) ? undefined : window.open(link)
            }
          >
            <span>
              <SVGIcons type={"SVG-redirect"} />
            </span>
            <span>Navigate</span>
          </a>
        </TooltipContainer>
      </li>
    </>
  );
};

export default ButtonBlock;
