import { notify } from "../../../../Components/Notification";
import { message } from "antd";

export const blockUserPayloadGenerator = (data, value) => {
  let updateUserPayload;
  if (value == "block") {
    updateUserPayload = {
      isActive: false,
      emailid: data.emailid,
      userProfile: data.userProfile,
      firstname: data.firstName,
      lastname: data.lastName,
    };
  }
  if (value == "unBlock") {
    updateUserPayload = {
      isActive: true,
      emailid: data.emailid,
      userProfile: data.userProfile,
      firstname: data.firstName,
      lastname: data.lastName,
    };
  }
  return updateUserPayload;
};

export const blockUserApiHandlerHelper = ({ state, event }) => {
  const name = getUserName(state.state.suspendUser);

  if (event == "success") {
    let userDetailsList = state.deactivateUser(false);
    state.setState({
      suspendUserModalWindow: false,
      userList: userDetailsList,
    });
    notify.success("User " + name + " Blocked", true);
  } else {
    notify.error("Network error", true);
  }
};

export const unBlockUserApiHandlerHelper = ({ event, state }) => {
  const name = getUserName(state.state.suspendUser);

  if (event == "success") {
    let userDetailsList = state.deactivateUser(true);
    state.setState({
      suspendUserModalWindow: false,
      userList: userDetailsList,
    });
    notify.success("User " + name + " Unblocked", true);
  } else {
    notify.error("Network error", true);
  }
};

export const updateUserInfo = ({ userRecord, state }) => {
  let updateUserPayload;
  updateUserPayload = {
    isActive: userRecord.isActive,
    emailid: userRecord.emailid,
    userProfile: state.userRole == "" ? userRecord.userProfile : state.userRole,
    firstname: state.firstName == "" ? userRecord.firstname : state.firstName,
    lastname: state.lastName == "" ? userRecord.lastname : state.lastName,
  };
  return updateUserPayload;
};

export function findUserRole(value) {
  switch (value) {
    case 1:
      return "admin";
    case 2:
      return "analyst";
    case 4:
      return "user";
    default:
      return "analyst";
  }
}

export function validateUserDetails(values) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (values.emailid.length == 0 || !values?.emailid?.match(validRegex)) {
    return { message: "Please enter the email ID", valid: false };
  } else if (values.firstname.length == 0) {
    return { message: "Please enter the first name", valid: false };
  } else if (values.lastname.length == 0) {
    return { message: "Please enter the last name", valid: false };
  } else {
    return { message: "", valid: true };
  }
}

/**
 * Function return name of user used for notifying
 * if user have firstname and lastname returns both as concatenated
 * if user have only firstname or lastname returns it
 * if user don't have firstname or lastname return the email id as name of user
 * @param {{firstname: string, lastname: string, emailid: string}} userDetails
 * @returns {string}
 */
function getUserName(userDetails) {
  let { firstname, lastname, emailid } = userDetails;
  let name = firstname
    ? firstname && lastname
      ? firstname + " " + lastname
      : firstname
    : lastname
    ? lastname
    : "";
  name = name || emailid;
  return name;
}
