import React from 'react';

import {template} from './template';

class TagBlock extends React.Component {
  render() {
    return template.call(this);
  }
}
export default TagBlock;
