import {
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_SUCCESS,
} from '../../Redux.constants';
import {resetSuccess, resetStarted, resetFailed} from './Helper';
import {INITIAL_STATE} from './initialState';

export default function ResetPasswordReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return resetSuccess(state, action.payload);
    case RESET_PASSWORD_STARTED:
      return resetStarted(state, action.payload);
    case RESET_PASSWORD_FAILED:
      return resetFailed(state, action.payload);
    default:
      return {...state};
  }
}
