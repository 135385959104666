import moment from 'moment';

export const handleDatePublishedPayloadHelper = ({data, facetData, facetValues}) => {
  Object.keys(data['facet']).map((facet) => {
    facetValues.push({
      key: data['facet'][facet]['isOR'] ?? true,
      value: handlePayloadTypeSelector(data['facet'][facet])?.split(',') || [''],
      options: '',
    });
  });
  return (facetData[0]['value']['facetList'] = facetValues);
};

const handlePayloadTypeSelector = (data) => {
  let payload;
  let string = '';
  if (data.data == 'lastNdays') {
    payload = handleLastNDaysPayload(data.option);
    string += `${payload.from},${payload.to}`;
  } else if (data.data == 'UntilNow') {
    payload = UntilNowPayload(data);
    string += `${payload.from},${payload.to}`;
  } else {
    payload = betWeenRange(data);
    string += `${payload.from},${payload.to}`;
  }
  return string;
};

export const handleLastNDaysPayload = (days) => {
  let now = new Date();
  const backdate = new Date(now.setDate(now.getDate() - days));
  let fullDateInfo = {
    from: moment(backdate).format('YYYY-MM-DD'),
    to: moment(new Date()).format('YYYY-MM-DD'),
  };
  return fullDateInfo;
};

export const UntilNowPayload = (data) => {
  var newdate = data.option.split('/').join('-');
  let fullDateInfo = {from: newdate, to: moment(new Date()).format('YYYY-MM-DD')};
  return fullDateInfo;
};

export const betWeenRange = (data) => {
  let fromDate = data.option[0].split('/').join('-');
  let toDate = data.option[1].split('/').join('-');
  let fullDateInfo = {from: fromDate, to: toDate};
  return fullDateInfo;
};
