import React from "react";
import { ContentLoader } from "Components/ContentLoader";
import Pagination from "Components/Pagination";
import TableElement from "Components/TableElement";
import Emptypage from "../Emptypage";
import ShowModalwindowAdd from "../showModalWindowAdd";
import ShowModalwindowDelete from "../ShowModalWindowDelete";
import { DASHBOARD_CONFIG } from "../Dashboard.config";
import { DEFAULT_PAGE_COUNT } from "../../../constants";
import EmptyState from "Components/EmptyState";

import globel from "../../../styles/globel/style.module.scss";
import style from "../style.module.scss";

export function SavedDashboards(props) {
  return (
    <div className={style.savedDashboard}>
      {props.state.showDeleteModal && <ShowModalwindowDelete {...props} />}
      {props.state.showAddModal && <ShowModalwindowAdd {...props} />}
      {!props.props.importedInsights?.getLoading &&
      !props.props.projectListInfo.loading &&
      props.props.importedInsights?.dashboardData.length === 0 ? (
        <Emptypage {...props} />
      ) : (
        <>
          <div className={style.dashboardPagination}>
            <DashboardContentNumber {...props.props} />

            {props.props.importedInsights?.totalCount > DEFAULT_PAGE_COUNT &&
              DashboardPagination({
                ...props.state.pagination,
                totalCount: props.props.importedInsights?.totalCount,
                onChange: props.handleDashboardPagination,
              })}
          </div>
          <div className={style.dashboardData}>
            {props.props.importedInsights?.getLoading ||
            props.props.projectListInfo.loading ? (
              ContentLoaderTable()
            ) : (
              <>
                {props.props.importedInsights?.error ? (
                  <EmptyState content="ERROR" type={"errorImage"} />
                ) : (
                  <>
                    {props.props.importedInsights?.dashboardData.length !==
                      0 && (
                      <>
                        <div className={style.dashboardData__table}>
                          <TableElement
                            columns={DASHBOARD_CONFIG.COLUMNS({
                              deleteHandler: props.handleDelete,
                              handleEditDashboard: props.handleEditDashboard,
                              handleViewDashboard: props.handleViewDashboard,
                              handleDownloadDashboard:
                                props.handleDownloadDashboard,
                              projectList:
                                props.props.projectListInfo.data.length > 0
                                  ? props.props.projectListInfo.data
                                  : [],
                            })}
                            data={
                              props.props.importedInsights?.dashboardData
                                .length > 0
                                ? props.props.importedInsights?.dashboardData
                                : []
                            }
                            scrollY={"calc(100vh - 400px)"}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function ContentLoaderTable() {
  return (
    <div className={style.dashboardData__table}>
      <ContentLoader height={2} width={100} number={10} hasWrapper={true} />
    </div>
  );
}

function DashboardPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return (
    <Pagination
      pageSize={pageSize}
      current={pageNumber}
      total={totalCount}
      onChange={onChange}
      showSizeChanger={true}
      pageSizeOptions={["10", "20", "50", "100"]}
    />
  );
}

function DashboardContentNumber(props) {
  return (
    <div className={globel.searchresultCount}>
      <span>
        {props.importedInsights?.dashboardData.length > 0
          ? "Showing " +
            props.importedInsights?.dashboardData.length +
            " out of " +
            props.importedInsights?.totalCount +
            " Results"
          : ""}
      </span>
    </div>
  );
}
