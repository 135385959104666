import React, {Component} from 'react';
import PropType from 'prop-types';
import style from './style.module.scss';
export default class ContentBody extends Component {
  render() {
    const className = `${style.contentBody} ${this.props.className}`;
    return <div className={className}>{this.props.children}</div>;
  }
}
ContentBody.defaultValue = {
  childern: null,
};
