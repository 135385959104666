import FilterSortButton from "./FilterSortButton";
import style from "./style.module.scss";

export const MySortButtons = (props)=>{

  const {
    handleSortingVisible,
    showSorting,
    title,
    publishDate,
    relevance,
    conceptName, 
    tagValues,
    handlePublish,
    handleTitle,
    handleRelevance,
    handleConceptName, 
    handleTagValues,
    loading,
    sortingType,
    handleChangeSortingType,
    search,
    searchData,
  } = props;
    
        const disabledClass = loading ? style.disabled : "";
        const { value } = search;
        const { data, loading: dataLoading } = searchData;
        return (
          <div className={style.controlsBox + " " + disabledClass}>
            <FilterSortButton
              title={title}
              publishDate={publishDate}
              handlePublish={handlePublish}
              handleTitle={handleTitle}
              relevance={relevance}
              handleRelevance={handleRelevance}
              conceptName={conceptName}
              handleConceptName={handleConceptName}
              tagValues={tagValues}
              handleTagValues={handleTagValues}
              showSorting={showSorting}
              sortingType={sortingType}
              handleChangeSortingType={handleChangeSortingType}
              handleSortingVisible={handleSortingVisible}
              isRelevanceVisible={
                !dataLoading && data?.length && value?.length ? true : false
              }
            />
          </div>
        );
      
};