import {errorMEssageHandler} from '../../../../utils/ErrorHandler';

export function getAllUserSettingsStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
  };
}

export function getAllUserSettingsSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    data: payload.data,
  };
}

export function getAllUserSettingsFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function changeDateFormatStarted(state, payload) {
  return {
    ...state,
    error: false,
  };
}

export function changeDateFormatSuccess(state, payload) {
  return {
    ...state,
    error: false,
    format: {
      ...state.format,
      dateFormat: payload.dateFormat,
      timeFormat: payload.timeFormat,
      languageFormat: payload.languageFormat,
    },
  };
}

export function changeDateFormatFailed(state, payload) {
  return {
    ...state,
    error: payload,
  };
}

export function setCurrentProfileStarted(state, payload) {
  return {
    ...state,
    profile: {profileLoading: true, profileError: null},
  };
}

export function setCurrentProfileSuccess(state, payload) {
  return {
    ...state,
    profile: {...payload, profileLoading: false, profileError: null},
    format: {...state.format, dateFormat: payload.dateFormat},
    sortOrder: {
      ...state.sortOrder,
      type: payload.sortOrder,
    },
    hideResultsZeroCount: !payload.showAllFields,
    userProjectSettings: payload.userProjectSettings
  };
}


export function setCurrentProfileFailed(state, payload) {
  return {
    ...state,
    profile: {profileLoading: false, profileError: payload},
  };
}

export function changesortOrderStarted(state, payload) {
  return {
    ...state,
    error: false,
    preferenceSettingLoading: true,
  };
}

export function changesortOrderSuccess(state, payload) {
  return {
    ...state,
    error: false,
    sortOrder: {
      ...state.sortOrder,
      type: payload.sortOrder,
    },
    format: {
      ...state.format,
      dateFormat: payload.dateFormat,
    },
    preferenceSettingLoading: false,
    hideResultsZeroCount: payload.hideResultsZeroCount,
  };
}

export function changesortOrderFailed(state, payload) {
  return {
    ...state,
    error: true,
    preferenceSettingLoading: false,
  };
}

export function addUserDetailsStarted(state, payload) {
  return {
    ...state,
    addUserLoading:true,
    addUserMessage:''
  };
}

export function addUserDetailsSuccess(state, payload) {
  return {
    ...state,
    addUserLoading:false,
    addUserMessage:''
  };
}

export function addUserDetailsFailed(state, payload) {
  return {
    ...state,
    addUserLoading:false,
    addUserMessage:payload.errorMessage
  };
}

export function updateUserDetailsStarted(state, payload) {
  return {
    ...state,
    updateUserLoading:true,
    updateUserMessage:''
  };
}

export function updateUserDetailsSuccess(state, payload) {
  return {
    ...state,
    updateUserLoading:false,
    updateUserMessage:''
  };
}

export function updateUserDetailsFailed(state, payload) {
  return {
    ...state,
    updateUserLoading:false,
    updateUserMessage:payload.errorMessage
  };
}


export function deleteUserDetailsStarted(state, payload) {
  return {
    ...state,
    deleteUserLoading:true,
    deleteUserMessage:''
  };
}

export function deleteUserDetailsSuccess(state, payload) {
  return {
    ...state,
    deleteUserLoading:false,
    deleteUserMessage:''
  };
}

export function deleteUserDetailsFailed(state, payload) {
  return {
    ...state,
    deleteUserLoading:false,
    deleteUserMessage:payload.errorMessage
  };
}
deleteUserDetailsStarted