import React, { useEffect, useMemo, useState } from "react";
import parse from "html-react-parser";

import TooltipContainer from "../../Tooltip";
import {
  formatFilterLabel,
  formatMetadataKeyword,
} from "../../../utils/formatFilterLabel";
import {
  EXTERNAL_TAB,
  NEWS_TAB,
  SEARCH_CARD_METADATA_ITEMS_BLACK_LIST,
} from "../../../Constants/Home/Home.constants";
import { removeEMTag } from "../../../utils/dataFormatter";

import global from "../../../styles/globel/style.module.scss";

const METADATA_VIEW_LESS_COUNT = 6;

export function MetadataBlock({ data, currentTabType, metaDataList, expandAllTags }) {
  const [isViewMore, setIsViewMore] = useState(expandAllTags);
  const [slicedData, setSlicedData] = useState(Array());

  const formattedData = useMemo(
    () =>{
      const items = 
      getFormattedMetadata(
        removeEMTagFromSourceType(data, currentTabType),
        metaDataList
      );
      // items need to be sorted by type, and within the same item.type - by item.data
      const sortedObj = {}; // object where keys are item.type. and values are arrays of item.type for that item.data
      for(let i = 0; i < items.length; i++){
        const type = items[i].type;
        const data = items[i].data;
        if (sortedObj[type]){
          sortedObj[type].push(data);
        }
        else {
          sortedObj[type] = [data];
        }
      }

      const sortedItems = [];
      const sortedKeys = Object.keys(sortedObj).sort((a, b)=>{
        if (a<b){
          return -1;
        }
        if (a>b){
          return 1;
        }
        return 0;
      });
      
      for (let i = 0; i < sortedKeys.length; i++){
        const sortedValues = sortedObj[sortedKeys[i]].sort((a, b)=>{
          if (a>b){
            return 1;
          }
          if (a < b){
            return -1;
          }
          return 0;
        });
        for (let j = 0; j < sortedValues.length; j++){
          sortedItems.push({type: sortedKeys[i], data: sortedValues[j]});
        }
      }
      return sortedItems;
    },
    [data]
  );

  useEffect(() => {
    let slicedData = formattedData;
    if (!isViewMore) slicedData = slicedData.slice(0, METADATA_VIEW_LESS_COUNT);
    setSlicedData(slicedData);
  }, [isViewMore]);

  useEffect(()=>{
    if (expandAllTags){
      if (!isViewMore){
        setIsViewMore(true);
      }
    }
    else {
      if (!expandAllTags){
        if (isViewMore){
          setIsViewMore(false);
        }
      }
    }
  }, [expandAllTags]);


  const toggleViewMore = () => {
    setIsViewMore(!isViewMore);
  };

  return (
    <>
      <ul className={global.metaTagList}>
        {slicedData.map((meta, i) => (
          <li key={i + meta?.data}>
            <div className={global.metaListTagElement}>
              <TooltipContainer
                title={formatMetadataKeyword(formatFilterLabel(meta.type))}
              >
                <span>{parse(meta.data)}</span>
              </TooltipContainer>
            </div>
          </li>
        ))}
      </ul>
      {Object.keys(formattedData).length > METADATA_VIEW_LESS_COUNT && (
        <a className={global.metaTagMoreLink} onClick={toggleViewMore}>
          {isViewMore ? <>View Less</> : <>View More</>}
        </a>
      )}
    </>
  );
}

/**
 *
 * @param {{}} data
 * @returns {{type: string, data: string}[]}
 */
const getFormattedMetadata = (data, metaDataList) => {
  const filteredKeys = Object.keys(data || {})
    .filter((key) => !SEARCH_CARD_METADATA_ITEMS_BLACK_LIST.includes(key))
    .filter((key) => {
      const filterValues = data[key];
      return Array.isArray(filterValues)
        ? filterValues.some((element) => element !== "")
        : !["", undefined, null].includes(filterValues);
    })
    ?.filter((key) => metaDataList.includes(key));

  return filteredKeys
    .reduce((acc, curr) => {
      const filterValues = data[curr];
      const metadataItems = Array.isArray(filterValues)
        ? filterValues.map((x) => ({ type: curr, data: String(x) }))
        : [{ type: curr, data: String(filterValues) }];
      acc.push(...metadataItems);
      return acc;
    }, Array())
    .sort((a, b) =>
      a.data.length > b.data.length ? 1 : a.data.length < b.data.length ? -1 : 0
    );
};

/**
 *
 * @param {{[key: string]: string | number | string[]}} data
 * @param {'All Articles' |'News' | 'External Reports'} currentTabType
 * @returns
 */
const removeEMTagFromSourceType = (data, currentTabType) => {
  if (currentTabType === EXTERNAL_TAB || currentTabType === NEWS_TAB) {
    const sourceType = data["Source Type"];
    data["Source Type"] = Array.isArray(sourceType)
      ? sourceType.map((type) => removeEMTag(type))
      : removeEMTag(String(sourceType));
  }
  return data;
};
