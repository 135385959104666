import { SAML_LOGIN_URL } from "../../../../Constants/Login/Login.constants";

/**
 *
 * @param {{history, setAdfsLoading}} param
 */
export const handleLoginWithADFS = async ({ setAdfsLoading }) => {
  try {
    setAdfsLoading(true);
    window.location.href = SAML_LOGIN_URL;
  } catch (error) {
    setAdfsLoading(false);
  }
};
