import React, { Component } from "react";

import ApexCharts from "react-apexcharts";

import style from "./style.module.scss";
import globel from "../../styles/globel/style.module.scss";
import { createAdvancedFilterData, createFilterData } from "./helper";
import { SEARCH_TYPE } from "constants";
import { Radio } from "antd";

export class ApexHeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorScheme: 0, // 0 - default, red to green, 1 - grayscale, 2 - bluescale
      options: {
        chart: {
          type: "heatmap",
          toolbar: {
            show: false,
          },
          events: {
            click:
              !this.props.isDownload &&
              !this.props.dashboard &&
              this.handleClickEvent,
          },
        },

        legend: {
          show: false,
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: false,
          },
        },

        dataLabels: {
          enabled: false,
        },
        fill: {
          strokeWidth: 10,
        },
        xaxis: {
          labels: {
            show: true,
          },
          title: {
            text: this.props.config?.categoryXField,
            offsetX: 0,
          },
        },
        title: {
          text: this.props.config?.categoryXField,
          align: "center",
          style: {
            color: "#444",
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          title: {
            text: this.props.config?.categoryYField,
            offsetX: 0,
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: (seriesName) => {
                return `${this.props.config?.categoryYField}: ${seriesName}`;
              },
            },
          },
        },
      },
      series: [],
    };
  }
  handleClickEvent = (event, chartContext, config) => {
    const yValue = config.config.series[config.seriesIndex].name;
    const xValue =
      config.config.series[config.seriesIndex].data[config.dataPointIndex]?.x;
    this.handleHeatMapClick({ yValue, xValue });
  };
  handleHeatMapClick = (data) => {
    let filter = createFilterData(this.props.createInsights, data);
    if (this.props.createInsights.searchType == SEARCH_TYPE.ADVANCED_SEARCH) {
      const { advanceSearchPayloadData } = this.props.createInsights;
      const filterGroup = {
        ...(advanceSearchPayloadData.group0001
          ? advanceSearchPayloadData.group0001
          : {}),
      };
      const newGroup = {
        ...filterGroup,
        ...createAdvancedFilterData({
          stateData: this.props.createInsights,
          data,
          filterGroup,
        }),
        isOR: filterGroup.isOR ?? false,
      };
      this.props.setInsightData({
        advanceSearchPayloadData: {
          ...advanceSearchPayloadData,
          group0001: { ...newGroup },
        },
      });
    } else {
      this.props.setInsightData({ filter });
    }
  };
  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data || prevState.colorScheme !== this.state.colorScheme){
      this.updateChartData();
    }
  }
  
  updateChartData = () => {
    this.setState({ series: this.props.data });

    const xx = this.props.data.map(x=>x.data).flat().filter(x=>x);
    const numbers = xx.map(x=>x.y);

    const uniqueNumbers = numbers.filter((x, i, arr)=>{
      return arr.indexOf(x) === i;
    });
    
    uniqueNumbers.sort((a, b)=>{
      if (a > b) {
        return 1
      }
      else {
        return -1
      }
    });


    const N = 10;
    const groups = [];

    const groupSize = Math.ceil(uniqueNumbers.length / N);


    for (let i = 0; i < N; i++) {
      const start = i * groupSize;
      const end = start + groupSize;
      groups.push(uniqueNumbers.slice(start, end));
    }
     
      const redToGreenColors = [
        '#00ff00', // green
        '#33ff00', 
        '#66ff00', 
        '#99ff00', 
        '#ccff00', 
        '#ffff00', 
        '#ffcc00',
        '#ff9900', 
        '#ff6600', 
        '#ff3300' // red
      ];
      const zeroColorGreen = '#00ff00'; 
      

      const grayscaleColors = [
        '#dcdcdc', // lightest
        '#c7c7c7', 
        '#b2b2b2',
        '#9d9d9d',
        '#888888', 
        '#737373',
        '#5e5e5e', 
        '#494949', 
        '#343434',
        '#1f1f1f' // darkest
      ];

      const bluescaleColors = ['#1e3f66']; 
      const zeroColorBlue = '#6c879e'; 


      let zeroColor = zeroColorGreen; 
      let colors = redToGreenColors; 
      
      if (this.state.colorScheme == 1){
        colors = grayscaleColors; 
        zeroColor = '#dcdcdc'; 
      }

      if (this.state.colorScheme == 2){ // blue-scale
        colors = bluescaleColors;
        zeroColor = zeroColorBlue;
      }
       
      let ranges = [
        {
          from: 0,
          to: 0,
          color: zeroColor
        },
      ];

      if (this.state.colorScheme != 2){
        ranges = groups.map((g, i)=>{
          return {
            from: g[0], 
            to: g[g.length - 1], 
            color: colors[i]
          }
        });
      }
    
      this.setState({
        options: {
          chart: {
            type: "heatmap",
            toolbar: {
              show: false,
            },
            events: {
              click:
                !this.props.isDownload &&
                !this.props.dashboard &&
                this.handleClickEvent,
            },
          },

          legend: {
            show: false,
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              radius: 0,
              useFillColorAsStroke: false,
              colorScale: {
                  ranges: ranges
              },
            },
          },
          colors: colors,
          // colors: ['#ff3300'], // will blend from red into light red 
          //colors: redToGreenColors.reverse(),
          //colors: ["#1e3f66"], // default color for darkest cell in bluescale
          // colors: ["#000000"], // this is the default color for top cell (highest value)
          //colors: ["#000000"], // this is the default color for top cell. Grayscale can be achieved by setting this and range for 0 to to to the lightest 
          dataLabels: {
            enabled: false,
          },
          fill: {
            strokeWidth: 10,
          },
          xaxis: {
            labels: {
              show: true,
            },
            title: {
              text: this.props.config?.categoryXField,
              offsetX: 0,
            },
          },
          title: {
            text: this.props.config?.categoryXField,
            align: "center",
            style: {
              color: "#444",
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
            title: {
              text: this.props.config?.categoryYField,
              offsetX: 0,
            },
          },
          tooltip: {
            y: {
              title: {
                formatter: (seriesName) => {
                  return `${this.props.config?.categoryYField}: ${seriesName}`;
                },
              },
            },
          },
        },
      });
  };

  handleColorSchemeChange (e){
    this.setState({
      colorScheme: e.target.value || 0
    });
  }

  render() {
    return (
      <>
      <div>
        <Radio value={0} checked={this.state.colorScheme == 0}
          onChange={this.handleColorSchemeChange.bind(this)}
        >Default</Radio>
        
        <Radio value={1} checked={this.state.colorScheme == 1}
          onChange={this.handleColorSchemeChange.bind(this)}
        >Grayscale</Radio>

        <Radio value={2} checked={this.state.colorScheme == 2}
          onChange={this.handleColorSchemeChange.bind(this)}
        >Blue</Radio>
      </div>
      <div
        className={`${
          this.props.dashboard
            ? style["chart-root-container"]
            : globel.chartItem
        } ${this.props.isDownload && globel.previewCharts}`}
        id={this.props.id}
      >
        <ApexCharts
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          width={"100%"}
          height={"95%"}
        />
      </div>
      </>
    );
  }
}
