export const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {},
  profile: {profileLoading: false, profileError: null},
  format: {
    dateFormat: 'DD MONTH YYYY',
    timeFormat: 'HH::MM:SS',
    languageFormat: 'English',
  },
  sortOrder: {
    type: 'Ascending',
  },
  preferenceSettingLoading: false,
  hideResultsZeroCount: true,
  addUserLoading:false,
  addUserMessage:'',
  updateUserLoading:false,
  updateUserMessage:'',
  deleteUserLoading:false,
  deleteUserMessage:''
};
