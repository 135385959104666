import { connect } from "react-redux";
import {
  DashboardAction,
  editDashboardsAction,
  FilterFields,
  getDashboardData,
  getInsightData,
  saveDashboardsAction,
  viewDashboardAction,
} from "../../../../Redux/Actions";
import BuildDashboard from "./BuildDashboard";

function mapStateToProps(state) {
  return {
    importedInsights: state.Dashboard,
    insightsList: state.InsightsPage,
    projectListInfo: state.FetchProjectList,
    allFields: state.FilterFields?.allFields,
    allFieldsWithMetadata: state.FilterFields?.allFieldsWithMetadata,
    allFieldLoading: state.FilterFields?.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getInsightData: (params) => {
      dispatch(getInsightData(params));
    },
    importInsights: ({ payload, handleImportSuccess, clear }) => {
      dispatch(DashboardAction({ payload, handleImportSuccess, clear }));
    },
    saveDashboard: (payload, handleSuccess, loadDashboardList) => {
      dispatch(saveDashboardsAction(payload, handleSuccess, loadDashboardList));
    },
    updateSavedDashboard: (params, handleSuccess, loadDashboardList) => {
      dispatch(editDashboardsAction(params, handleSuccess, loadDashboardList));
    },
    getDashboardList: (params) => {
      dispatch(getDashboardData(params));
    },
    loadFilterFields: () => dispatch(FilterFields()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildDashboard);
