import React from "react";

import TagElement from "../Tag";
import { formatFilterLabel } from "../../utils/formatFilterLabel";
import { formatDateFromTag } from "../../utils/dateFormatter";

import style from "./style.module.scss";
import ButtonElement from "Components/ButtonElement";

export default class TagFilter extends React.Component {
  createTag = (tag) => {
    const tagElem = (
      <TagElement
        className={style.outerTags}
        closable={true}
        onClose={(e) => {
          e.preventDefault();
          this.props.handleDeleteMainTag(tag);
        }}
      >
        <span className={style.tagLabel}>{formatFilterLabel(tag)}</span>
        {tag.toLowerCase().includes("date") &&
        this.props.items[tag].from &&
        this.props.items[tag].to ? (
          <>
            {this.props.items[tag].from && (
              <TagElement
                className={style.tagInner}
                closable={false}
                onClose={(e) => {
                  e.preventDefault();
                  this.props.handleDeleteDate("from");
                }}
              >
                <span className={style.tagLabel}>From:</span>
                <span>{formatDateFromTag(this.props.items[tag].from)}</span>
              </TagElement>
            )}
            {this.props.items[tag].to && (
              <TagElement
                className={style.tagInner}
                onClose={(e) => {
                  e.preventDefault();
                  this.props.handleDeleteDate("to");
                }}
                closable={false}
              >
                <span className={style.tagLabel}>To</span>
                <span>{formatDateFromTag(this.props.items[tag].to)}</span>
              </TagElement>
            )}
          </>
        ) : (
          this.props.items[tag]?.map((item, index) => (
            <TagElement
              className={style.tagInner}
              closable={true}
              onClose={(e) => {
                e.preventDefault();
                this.props.handleDeleteTags(item, tag);
              }}
              key={index}
            >
              {item}
            </TagElement>
          ))
        )}
      </TagElement>
    );
    return <li key={tag}>{tagElem}</li>;
  };
  render() {
    const tags = Object.keys(this.props.items);
    tags.sort((a, b)=>{
      if (a > b){
        return 1;
      }
      if (a < b){
        return -1;
      }
      return 0;
    });
    const tagChild = tags.map(this.createTag);
    return (
      <>
        
        
      {/* The component takes items object which is the filter 
      properties are filterGroups and values selected values 
      eg {"Aircraft Model":["boeing 737"],"Airlines":["fleet","network"]}

      We want to display the filter all the time, regardless of what's  selected
      */}          
          <div className={style.filtertagsWrapper}>
            <ul className={style.filterTags}>{tagChild}</ul>

            <div style={{display: 'flex'}}>
              <ButtonElement
                styleProps={{margin: 'auto', marginRight: '4px'}}
                size={'small'}
                onClick={() => {
                  this.props.handleApplyFilterButtonClick();
                }}
              >
                <span>Apply Filter</span>
              </ButtonElement>


              <ButtonElement
                styleProps={{margin: 'auto', marginLeft: '4px'}}
                size={'small'}
                onClick={() => {
                  this.props.handleDeleteAllTags();
                }}
              >
                <span>Clear All</span>
              </ButtonElement>
              
            </div>
          </div>
      </>
    );
  }
}
