import React, { useState } from "react";

import { connect } from "react-redux";

import { setSearchData } from "Redux/Actions";
import { MySortControls } from "./Childern/SortControls/MySortControls";

const MySortContentComponent = (props) =>{
  

  // sort by relevance
  const [relevance, setRelevance] = useState({className: "activeDown", visible: false});

  // sort by title:
  const [title, setTitle] = useState({ className: "", visible: false });

  // sort by publish date: 
  const [publishDate, setPublishDate] = useState({className: "activeDown", visible: true});


  // sort by concept name:
  const [conceptName, setConceptName] = useState({ className: "", visible: false });

  // sort by tag values:
  const [tagValues, setTagValues] = useState({ className: "", visible: false });


  const propsForSortControls = {
    relevance, title, publishDate, conceptName, tagValues, setPublishDate, setRelevance, setTitle, setConceptName, setTagValues, 

    // plus any other props consumed within the control: 

    search: props.search,  // from the redux state, 
    
    allFieldsWithDatatype: props.allFieldsWithDatatype, //  from redux mapStateToProps . TODO: is this needed? 
    searchData: props.searchData, // is this loading + data + something 
    setSearchData: props.setSearchData, // ultimately from dispatch//
    // listViewType: props.listViewType, // these are 
    // handleListView: props.handleListView, 

  };
  return (
    <>
      <MySortControls
      {...propsForSortControls}
        // {...props}
        // relevance={relevance}
        // title={title}
        // publishDate={publishDate}
        // setPublishDate={setPublishDate}
        // setRelevance={setRelevance}
        // setTitle={setTitle}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    ...state.Search,
    allFieldsWithDatatype: state.FilterFields.allFieldsWithMetadata,
    // ...state, // TODO: why???
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export const MySortContent = connect(mapStateToProps, mapDispatchToProps)(MySortContentComponent);

