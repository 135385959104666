import { connect } from "react-redux";

import {
  ClearFilterFieldData,
  fetchProjectList,
  FilterFields,
  GetCurrentProfileAction,
  setSearchData,
} from "../../Redux/Actions/";
import MainFrame from "./MainFrame";

function mapStateToProps(state) {
  return {
    insight: state.CreateInsights,
    search: state.Search.search,
    projectList: state.FetchProjectList,
    FilterFields: state.FilterFields,
    CurrentProfile: state.Settings.profile,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getProjectList: (params) => dispatch(fetchProjectList(params)),
    // selectProjectNameHandler: (data) => dispatch(selectProjectNameHandler(data)),
    loadFilterFields: () => dispatch(FilterFields()),
    GetCurrentProfileAction: () => dispatch(GetCurrentProfileAction()),
    ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
    setSearchData: (params) => dispatch(setSearchData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainFrame);
