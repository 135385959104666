import React from "react";
import { ROUTES } from "../../Constants/Routes/Routes.constants";
import DashboardHeader from "./DashboardHeader";
import HomeHeader from "./HomeHeader";
import InsightHeader from "./InsightHeader";
import InsightNewTabHeader from "./ViewNewTabHeader";

const HeaderSection = (props) => {
  const pathName = props.location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  const queryterm = queryParams.get("page");
  const fromTerm = queryParams.get("from");
  const renderHeader = () => {
    switch (pathName) {
      case ROUTES.HOME:
        return (
          <HomeHeader
            {...props}
            viewTabButtonVisible={
              queryterm == "homePage" || fromTerm == "newtab" ? false : true
            }
          />
        );
      case ROUTES.VIEW_TAB_ARTICLES:
        return <HomeHeader {...props} viewTabButtonVisible={false} />;
      case ROUTES.INSIGHTS:
        return (
          <InsightHeader
            {...props}
            viewTabButtonVisible={
              queryterm == "homePage" || fromTerm == "newtab" ? false : true
            }
          />
        );
      case ROUTES.DASHBOARD:
        return <DashboardHeader {...props} />;
      default:
        return <></>;
    }
  };
  return <>{renderHeader()}</>;
};

export default HeaderSection;
