import React from 'react';
import loginPic from '../../../../../public/img/logi-theam.webp';
import style from './style.module.scss';
function template() {
  return (
    <div className={style.leftBlock}>
      <div className={style.leftBlock__theam}>
        <span>
          <img src={loginPic} alt='loginPic' />
        </span>
      </div>
    </div>
  );
}

export default template;
