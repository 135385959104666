import React from "react";
import moment from "moment";
export const checkFromDateIsGreater = ({ from, to, setErrorMessage }) => {
  if (from && to) {
    let fromDate = from;
    let toDate = to;
    let difference = fromDate.diff(toDate, "days");
    if (difference > 0) {
      setErrorMessage("From date is greater than to date");
    } else {
      setErrorMessage("");
      return true;
    }
  }
};

export const checkToDateIsLesser = ({ from, to, setErrorMessage }) => {
  if (from && to) {
    let fromDate = moment(from);
    let toDate = moment(to);
    let difference = toDate.diff(fromDate, "days");
    if (difference < 0) {
      setErrorMessage("From date is greater than to date");
    } else {
      setErrorMessage("");
      return true;
    }
  }
};
