import React, { useEffect, useState } from "react";
import { ContentLoader } from "Components/ContentLoader";
import EmptyState from "Components/EmptyState";
import Pagination from "Components/Pagination";
import TableElement from "Components/TableElement";
import { geDataSetDetails, TABLE_CONFIG } from "../Helper";

import ErrorState from "Components/ErrorState";

import { DEFAULT_PAGE_COUNT } from "../../../constants";
import style from "./style.module.scss";
import globel from "../../../styles/globel/style.module.scss";
import {
  DATE_FIELDS_DATA_TYPE,
  TITLE_FIELDS_DATA_TYPE,
} from "Components/FilterSection/FilterSection.constants";

export const DashboardInfoTable = (props) => {
  const [loading, setLoading] = useState(true);
  const [dashBoardData, setDashBoardData] = useState([]);
  const [error, setError] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_COUNT,
    totalCount: 0,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const dateField =
    props.allFieldsDataType.find(
      (field) =>
        field.dataType === DATE_FIELDS_DATA_TYPE &&
        field.name.toLowerCase().includes("published")
    )?.name || "Date Published";
  const titleField =
    props.allFieldsDataType.find(
      (field) => field.dataType === TITLE_FIELDS_DATA_TYPE
    )?.name || "Title";

  const getData = (paginate, pagination) => {
    if (props.insightsData.length > 0) {
      if (paginate) {
        geDataSetDetails({
          filter: props.filter,
          insightsData: props.insightsData,
          setDashBoardData,
          setLoading,
          pagination,
          setError,
          setPagination,
          setErrorMessage,
          dateAndTitleFields: [titleField, dateField],
        });
      } else {
        setPagination({
          ...pagination,
          pageNumber: 1,
          pageSize: DEFAULT_PAGE_COUNT,
        });
        geDataSetDetails({
          filter: props.filter,
          insightsData: props.insightsData,
          setDashBoardData,
          setLoading,
          pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
          setError,
          setPagination,
          setErrorMessage,
          dateAndTitleFields: [titleField, dateField],
        });
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(false);
  }, [props.insightsData.length]);

  const handlePagination = (pageNumber, pageSize) => {
    getData(true, { pageNumber, pageSize });
    setPagination({
      ...pagination,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  };

  return (
    <div className={style.dashboadrinfoTable}>
      <div className={style.dashboadrinfoTable__header}>
        <h4>Matched Results</h4>
      </div>
      <div className={style.dashboadrinfoTable__content}>
        {loading || props.loading ? (
          <ContentLoader height={2} width={100} number={10} hasWrapper={true} />
        ) : dashBoardData.length != 0 ? (
          <>
            <div className={style.infopagElement}>
              <div className={globel.searchresultCount}>
                <span>
                  {pagination.totalCount === 0
                    ? "Empty "
                    : "Showing " +
                      dashBoardData.length +
                      " out of " +
                      pagination.totalCount +
                      " "}
                  Results
                </span>
              </div>
              {UserLogPagination({
                onChange: handlePagination,
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize,
                totalCount: pagination.totalCount,
              })}
            </div>

            <TableElement
              scrollY={"calc(100vh - 270px)"}
              columns={TABLE_CONFIG.COLUMNS({dateField,titleField})}
              data={dashBoardData}
            />
          </>
        ) : error ? (
          <ErrorState
            content="ERROR"
            type={"errorImage"}
            errorMessage={errorMessage}
          />
        ) : (
          <EmptyState
            type="welcomeImage"
            showButton={false}
            content={"DASHBOARD_INFO_TABLE_EMPTY_STATE_TAB"}
          />
        )}
      </div>
    </div>
  );
};

function UserLogPagination({ pageNumber, pageSize, totalCount, onChange }) {
  return totalCount > DEFAULT_PAGE_COUNT ? (
    <Pagination
      pageSize={pageSize}
      current={pageNumber}
      total={totalCount}
      onChange={onChange}
      showSizeChanger={true}
      pageSizeOptions={["10", "20", "50", "100"]}
    />
  ) : (
    <></>
  );
}
