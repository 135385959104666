/* eslint-disable max-params */
import React from 'react';
import { useState, useEffect } from "react";
import TableElement from "../../Components/TableElement";
import { TABLE_COLUMNS } from "./TableColumns";
import { ContentLoader } from "../../Components/ContentLoader";
import Pagination from "../../Components/Pagination";
import ContentBody from "../../Components/ContentBody";
import globel from "../../styles/globel/style.module.scss";
import { notify } from '../../Components/Notification';
import { DEFAULT_PAGE_COUNT, DEFAULT_USER_ROLE } from '../../constants';
import ButtonElement from "../../Components/ButtonElement";
import { connect } from "react-redux";
import style from "./style.module.scss";
import {
  getAllUserDetails,
  AddUserDetails,
  UpdateUserDetails,
  DeleteUserDetails,
} from "../../Redux/Actions";

import {
  getUserGroupAction,
} from "../../Redux/Actions/UserGroups";

import {
  validateUserDetails,
} from './helper';

import { EditUserModal } from "./TableActionModal/EditUserModal";
import { DeleteUserModal } from "./TableActionModal/DeleteUserModal";

const Users = props => {
  const isSuperAdmin = props.userProfile?.userProfile===3;
  const groupId = props.userProfile?.groupId??1;
  const initUser = { firstname: '', lastname: '', emailid: '', userrole: DEFAULT_USER_ROLE, groupId};
  const initState = {
    filteredInfo: null,
    sortedInfo: { columnKey: 'id', field: 'id', order: 'asc' },
    updateModalVisible: false,
    deleteModalVisible: false,
    editUserRecord: '',
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
    addModalVisible: false,
    deleteUserPayload: {},
    Id: '',
    userGroupStore: [],
    users: [],
    totalCount: 0,
    user: initUser,
    projectId: 0,
  };

  const [state, setLocalState] = useState(initState);
  const setState = (data) => {
    setLocalState(prevState => {
      return { ...prevState, ...data };
    });
  };
  
  useEffect(() => {
    console.log(`projectId changed from ${state.projectId} to ${props.projectId}`);
    setState({ projectId: props.projectId });
  }, [props.projectId]);

  useEffect(() => {
    if (!props.userGroupModified && state.userGroupStore.length>0) return;
    props.getUserGroup({projectId: state.projectId||null}, ()=>{
      props.getUserProfiles(getPayload());
    });
  }, [props.userGroupModified]);

  useEffect(() => {
    if (!Array.isArray(props.users)) return;
    const visibleUsers = isSuperAdmin?props.users:props.users?.filter(u=>u.groupId===groupId);
    setState({ users: visibleUsers });
  }, [props.users]);

  useEffect(() => {
    setState({ totalCount: props.totalCount });
  }, [props.totalCount]);

  useEffect(() => {
    setState({ userGroupStore: props.userGroupStore });
  }, [props.userGroupStore]);

  useEffect(() => {
    props.getUserProfiles(getPayload());
  }, [state.pagination, state.sortedInfo]);

  const setAddModalVisibility = (visible) => {
    setState({ addModalVisible: visible });
  };
  const setUpdateModalVisibility = (visible) => {
    setState({ updateModalVisible: visible });
  };
  const setDeleteModalVisibility = (visible) => {
    setState({ deleteModalVisible: visible });
  };

  const onDetailsChange = (field, value) => {
    const json = {};
    json[field] = value;
    setState({ user: { ...state.user, ...json } });
  };

  const handleAdd = () => {
    setState({ user: initUser });
    setAddModalVisibility(true);
  }

  const cancelAdd = () => {
    setAddModalVisibility(false);
  };

  const onAddSubmit = () => {
    let { firstname, lastname, userrole, emailid, groupId, isGroupAdmin } = state.user;
    let userProfile = userrole == 'analyst' ? 2 : 1;
    let userDetailsObject = { firstname, lastname, userProfile, emailid, isActive: true, groupId, isGroupAdmin };
    let errorMessage = validateUserDetails(userDetailsObject);
    !errorMessage.valid
      ? notify.error(errorMessage.message)
      : props.addUser(userDetailsObject, () => {
        setAddModalVisibility(false);
        notify.success('Successfully added user');
        props.getUserProfiles(getPayload());
      });
  };

  const handleUpdate = (record) => {
    setState({ user: record });
    setUpdateModalVisibility(true);
  };

  const cancelUpdate = (record) => {
    setUpdateModalVisibility(false);
  };

  const onUpdateSubmit = () => {
    const { firstname, lastname, userrole, emailid, id, groupId, isGroupAdmin } = state.user;
    const userProfile = userrole == 'analyst' ? 2 : 1;
    const userDetailsObject = { firstname, lastname, userProfile, emailid, id, isActive: true, groupId, isGroupAdmin };
    const errorMessage = validateUserDetails(userDetailsObject);
    !errorMessage.valid
      ? notify.error(errorMessage.message)
      : props.updateUser(userDetailsObject, () => {
        setUpdateModalVisibility(false);
        setState({ user: initUser });
        notify.success('Successfully updated user');
        props.getUserProfiles(getPayload());
      });
  };

  const handleDelete = (record) => {
    setState({ user: record });
    setDeleteModalVisibility(true);
  };

  const cancelDelete = (record) => {
    setDeleteModalVisibility(false);
  };

  const onConfirmDelete = () => {
    props.deleteUser({ ...state.user }, () => {
      setDeleteModalVisibility(false);
      setState({ user: initUser });
      notify.success('Successfully deleted user');
      props.getUserProfiles(getPayload());
    });
  };

  const handleSortingChange = (_, __, sorter) => {
    const sortedInfo = {
      columnKey: sorter.columnKey,
      field: sorter.field,
      order: sorter.order == 'descend' ? 'desc' : 'asc',
    };
    setState({ sortedInfo: sortedInfo });
  };

  const getPayload = () => {
    const { pagination, sortedInfo } = state;
    const data = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      sortField: sortedInfo.field,
      sortOrder: sortedInfo.order,
    };
    return data;
  };

  const handlePagination = (pageNumber, pageSize) => {
    setState({ pagination: { pageNumber: pageNumber, pageSize: pageSize } });
  };

  const UserPagination = () => {
    return (
      <Pagination
        pageSize={state.pagination?.pageSize}
        current={state.pagination?.pageNumber}
        total={state.totalCount}
        onChange={handlePagination}
        showSizeChanger={true}
        pageSizeOptions={["10", "20", "50", "100"]}
      />
    );
  };

  return (
    <>
      <ContentBody>
        {props.error ? (
          <ErrorState
            customHeight={"600"}
            content="ERROR"
            type={"errorImage"}
            errorMessage={props.errorMessage}
          />
        ) : (
          (state.users.length > 0) && (
            <>
              <div className={globel.paginationWrapper}>
                <div className={style.addButtonWrapper}>
                  <ButtonElement
                    size={"small"}
                    onClick={handleAdd}
                    children={"Add User"}
                  />
                </div>
                <EditUserModal
                  title={"Add User"}
                  buttonTitle={"Add"}
                  onCancel={cancelAdd}
                  visible={state.addModalVisible}
                  onChange={onDetailsChange}
                  onSubmit={() => onAddSubmit()}
                  loader={props.addUserLoading}
                  user={state.user}
                  userGroupStore={state.userGroupStore}
                  isSuperAdmin = {isSuperAdmin}
                />
              </div>
              <div className={globel.paginationWrapper}>
                <div className={globel.searchresultCount}>
                  <span>
                    {state.users.length > 0
                      ? "Showing " +
                      state.users.length +
                      " out of " +
                      state.totalCount +
                      " Results"
                      : ""}
                  </span>
                </div>
                <div className={globel.searchresultPage}>
                  {state.totalCount > DEFAULT_PAGE_COUNT ? UserPagination() : ""}
                </div>
              </div>
              <TableElement
                columns={TABLE_COLUMNS.COLUMNS({
                  sortedInfo: state.sortedInfo,
                  handleUpdate: handleUpdate,
                  handleDelete: handleDelete,
                  currentProfileId: props?.CurrentUserProfile
                    ?.emailid,
                })}
                data={state.users.map((item, key) => ({
                  ...item,
                  groupName: state.userGroupStore.find(g => g.id === item.groupId)?.name,
                  key,
                }))}
                onChange={handleSortingChange}
                scrollY={"calc(100vh - 270px)"}
                loading={props.userListloading}
                resourceName="Users"
              />
            </>
          )
        )}
      </ContentBody>
      <EditUserModal
        title={"Update User"}
        buttonTitle={"Update"}
        onCancel={cancelUpdate}
        visible={state.updateModalVisible}
        onChange={onDetailsChange}
        onSubmit={onUpdateSubmit}
        user={state.user}
        loader={props.updateUserLoading}
        userGroupStore={state.userGroupStore}
        isSuperAdmin = {isSuperAdmin}
      />
      <DeleteUserModal
        visible={state.deleteModalVisible}
        onCancel={cancelDelete}
        confirmDelete={onConfirmDelete}
        loader={props.deleteUserLoading}
      />
    </>
  );
}


const mapStateToProps = state => {
  const fromState = {
    users: state.UserProfiles.data,
    userGroupStore: state.UserGroups.userGroupStore,
    userListloading: state.UserProfiles.userListloading,
    error: state.UserProfiles.error,
    errorMessage: state.UserProfiles.errorMessage,
    userUpdateLoading: state.UserProfiles.loading,
    projectList: state.FetchProjectList,
    totalCount: state.UserProfiles.totalCount,
    addUserLoading: state.Settings.addUserLoading,
    addUserMessage: state.Settings.addUserMessage,
    updateUserLoading: state.Settings.updateUserLoading,
    updateUserMessage: state.Settings.updateUserMessage,
    deleteUserLoading: state.Settings.deleteUserLoading,
    deleteUserMessage: state.Settings.deleteUserMessage,
    userGroupModified: state.UserGroups.modified,
    projectId: state.Settings.profile?.currentProject ?? 0,
    userProfile: state.Settings.profile,
  };
  return fromState;
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfiles: (params) => {
      dispatch(getAllUserDetails(params));
    },
    addUser: (params, callback) => {
      dispatch(AddUserDetails(params, callback));
    },
    updateUser: (params, callback) => {
      dispatch(UpdateUserDetails(params, callback));
    },
    deleteUser: (params, callback) => {
      dispatch(DeleteUserDetails(params, callback));
    },
    getUserGroup: (payload, callback) => {
      dispatch(getUserGroupAction(payload, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
