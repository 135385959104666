import { connect } from "react-redux";
import Component from "./CreateInsights";
import {
  clearInsightData,
  getInsightData,
  InsightsDataset,
  InsightsMappingPayload,
  SaveInsights,
  setInsightData,
  UpdateSaveInsights,
} from "Redux/Actions";

function mapStateToProps(state) {
  return {
    createInsights: state.CreateInsights, //
    payloadFields: state.InsightsPage.data, //
    loading: state.InsightsPage.loading, //
    dataSet: state.InsightsPage.dataSet,
    dataSetLoading: state.InsightsPage.dataSetLoading, //
    websiteDomain: state.InsightsPage.data.website_domain,
    insightDataSetLoading: state.InsightsPage.insightDataSetLoading,
    ...state.Search,
    ...state.Tags,
    dateFormat: state.Settings.format.dateFormat,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearInsightData: () => {
      dispatch(clearInsightData());
    },
    getAvailableFields: (params) => dispatch(InsightsMappingPayload(params)),
    saveInsights: (params, handleExisting) =>
      dispatch(SaveInsights(params, handleExisting)),
    updateSaveInsights: ({
      params,
      handleEditUpdate,
      isEditDashboardUpdate,
    }) => {
      dispatch(
        UpdateSaveInsights({ params, handleEditUpdate, isEditDashboardUpdate })
      );
    },
    getInsightsDataSet: (params) => dispatch(InsightsDataset(params)),
    getInsightData: (params) => {
      dispatch(getInsightData(params));
    },
    setInsightData: (params) => dispatch(setInsightData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
