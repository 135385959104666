import {
  fetchProjectListSuccess,
  fetchProjectListStarted,
  fetchProjectListFailed,
} from "./Helper";
import {
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_STARTED,
  FETCH_PROJECT_LIST_SUCCESS,
} from "../../Redux.constants";
import { INITIAL_STATE } from "./initialState";

export default function FetchProjectList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROJECT_LIST_SUCCESS:
      return fetchProjectListSuccess(state, action.payload);
    case FETCH_PROJECT_LIST_STARTED:
      return fetchProjectListStarted(state, action.payload);
    case FETCH_PROJECT_LIST_FAILED:
      return fetchProjectListFailed(state, action.payload);
    default:
      return { ...state };
  }
}
