import { getInsightsById } from "../../../../../api/insights";
import { KEYS } from "../../../../../dataKeys";

export const generateSavePayload = ({
  config,
  payload,
  insightName,
  chartName,
  dataSet,
  requestPayload,
}) => {
  let apiPayload = {
    emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
    insightsName: insightName,
    InsightsContent: {
      payload: payload,
      config: config,
      chartName: chartName,
      dataSet: dataSet,
      requestPayload: requestPayload,
    },
    chartType: chartName,
  };
  return apiPayload;
};

export const generateInsightsPayload = (state, props) => {
  let payload = {
    chartName: state.selectedChart,
    parameters: state.selectedField,
    dataSet: state.dataSetValue,
    searchWords:
      state.selectedChart == "KEY_PHRASE_CLOUD"
        ? props.createInsights?.config["KEY_PHRASE_CLOUD"]?.searchWords
        : [],
  };
  return payload;
};

export const generateInsightsPayloadEdit = (data) => {
  let payload = {
    chartName: data?.chartName,
    parameters: data?.payload,
    dataSet: data?.dataSet,
    searchWords: data?.config?.searchWords,
  };
  return payload;
};

export const generateUpdateSavedPayload = ({
  config,
  payload,
  insightName,
  chartName,
  insightId,
  dataSet,
  requestPayload,
}) => {
  let apiPayload = {
    id: insightId,
    emailid: localStorage.getItem(KEYS.ACCESS_EMAIL),
    insightsName: insightName,
    InsightsContent: {
      payload: payload,
      config: config,
      chartName: chartName,
      dataSet: dataSet,
      requestPayload: requestPayload,
    },
    chartType: chartName,
  };
  return apiPayload;
};

export const generateEditDashboardPayload = async ({
  currentPayload,
  insightName,
  selectedList,
}) => {
  let index = selectedList.indexOf(insightName);
  let payload = { id: currentPayload[index].id };
  let { data } = await getInsightsById(payload);
  let updatedPayload = currentPayload;
  updatedPayload[index] = data;
  return updatedPayload;
};
