import {useState} from 'react';
import style from "./style.module.scss";
import CheckBox from 'Components/CheckBox';


// filter element that contains a checkbox 
export const MyFilterContents = props=>{
    // debugger;
    const {loading, filterResults, name, data, headingName, handleCheckbox} = props;
    // headingName: 'Aircraft Model'

    const value = filterResults[name] || [];

    const [searchValue, setSearchValue] = useState('');
    const searchHandler = data=>{
        let firstArray = [];
        let secondArray = [];
        data.filter(item => item['value'].toUpperCase().includes(searchValue.toUpperCase()))
          .map((item) => {
            if (
              item['value'].substring(0, searchValue.length).toUpperCase() === searchValue.toUpperCase()
            ){
                firstArray.push(item);
            }
            else {
                secondArray.push(item);
            }
          });
        
        return firstArray.concat(secondArray);
    };

    const items = searchHandler(data, headingName);
    


    const selectPlaceholder = (item) => item.split("_").join(" ");

    const handleSelectAll = (searchedOptions)=>{
        debugger;
        console.log(searchedOptions);
        props.handleSelectAll({name, items: searchedOptions || items});
    }

    return (
    <div className={style.selectFilter}>
        <div className={style.selectFilter__list}>
            <CheckBox 
                showSelectAll={true}
                loading={loading}
                value={value}
                items={items}
                handleChange={selectedValues=>{
                    debugger;
                    handleCheckbox({name, selectedValues, items});
                }}
                handleSelectAll={handleSelectAll}
                handleClearAll={()=>props.handleClearAll({name, items})}
                placeholderSearch={selectPlaceholder(name)}
            />
        </div>
    </div>);
};