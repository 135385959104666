import React from "react";
import ModalWindow from "../../Components/ModalWindow";

export const ProjectSwitchingModal = ({ showProjectSwitchingModal }) => {
  return (
    <ModalWindow
      title={false}
      open={showProjectSwitchingModal}
      footer={false}
      isNotClosable={true}
    >
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        Reloading the UI with new project.
      </div>
    </ModalWindow>
  );
};
