import React from 'react';
import PropTypes from 'prop-types';

import template from './template';

class FilterContents extends React.Component {
  state = {
    searchValue: '',
  };

  /**
   * Filter the inputted filter data against the searchValue in local state
   * @param {string[]} data filter data array
   * @returns {string[]}
   */
  searchHandler = (data) => {
    let firstArray = [];
    let secondArray = [];
    data
      .filter((item) => item['value'].toUpperCase().includes(this.state.searchValue.toUpperCase()))
      .map((item) => {
        if (
          item['value'].substring(0, this.state.searchValue.length).toUpperCase() ===
          this.state.searchValue.toUpperCase()
        )
          firstArray.push(item);
        else secondArray.push(item);
      });
    return firstArray.concat(secondArray);
  };

  handlerOnChange = (e) => this.setState({searchValue: e.target.value});

  handleSelectAll = (searchedOptions)=>{
    this.props.handleSelectAll({name: this.props.name, items: searchedOptions || this.props.items});
  }

  handleClearAll = ()=>{
    this.props.handleClearAll({name: this.props.name, items: this.props.items});
  }

  render() {
    return template.call(this);
  }
}

FilterContents.propTypes = {
  /**
   * data
   */
  data: PropTypes.any,
  /**
   *headingName
   */
  headingName: PropTypes.string,
  /**
   * placeholderSearch
   */
  placeholderSearch: PropTypes.string,
  /**
   *filterResults
   */
  filterResults: PropTypes.any,
  /**
   * name
   */
  name: PropTypes.string,
  /**
   *handleCheckbox
   */
  handleCheckbox: PropTypes.func,
  /**
   * filter values loading
   */
  loading: PropTypes.bool,
};

export default FilterContents;
