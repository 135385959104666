import {
  addFilterFields,
  addFilterFieldsFailed,
  addFilterFieldsStarted,
  addFilterFieldValues,
  addFilterFieldValuesFailed,
  addFilterFieldValuesStarted,
  clearFilterFieldData,
} from './Helper';
import {INITIAL_STATE} from './initialState';
import {
  GET_FILTER_FIELDS_SUCCESS,
  GET_FILTER_FIELDS_FAILED,
  GET_FILTER_FIELDS_STARTED,
  GET_FILTER_FIELD_VALUES_SUCCESS,
  GET_FILTER_FIELD_VALUES_STARTED,
  GET_FILTER_FIELD_VALUES_FAILED,
  CLEAR_FILTER_FIELD_DATA,
} from '../../Redux.constants';

export function FilterFields(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FILTER_FIELDS_STARTED:
      return addFilterFieldsStarted(state, action.payload);
    case GET_FILTER_FIELDS_SUCCESS:
      return addFilterFields(state, action.payload);
    case GET_FILTER_FIELDS_FAILED:
      return addFilterFieldsFailed(state, action.payload);
    case GET_FILTER_FIELD_VALUES_STARTED:
      return addFilterFieldValuesStarted(state, action.payload);
    case GET_FILTER_FIELD_VALUES_SUCCESS:
      return addFilterFieldValues(state, action.payload);
    case GET_FILTER_FIELD_VALUES_FAILED:
      return addFilterFieldValuesFailed(state, action.payload);
    case CLEAR_FILTER_FIELD_DATA:
      return clearFilterFieldData();
    default:
      return {...state};
  }
}
