import {
  DATE_FIELDS_DATA_TYPE,
  TITLE_FIELDS_DATA_TYPE,
} from "../../../../../FilterSection/FilterSection.constants";
/**
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFieldsWithDatatype
 * @returns {string | null}
 */
export const getDatePublishedFieldName = (allFieldsWithDatatype) =>
  allFieldsWithDatatype.length
    ? allFieldsWithDatatype.find(
        (field) =>
          field.dataType === DATE_FIELDS_DATA_TYPE &&
          field.name.toLowerCase().includes("published")
      )?.name || "Date Published"
    : null;

/**
 * @param {{name: string, dataType: number, isFilter: boolean}[]} allFieldsWithDatatype
 * @returns {string | null}
 */
export const getTitleFieldName = (allFieldsWithDatatype) =>
  allFieldsWithDatatype.length
    ? allFieldsWithDatatype.find(
        (field) => field.dataType === TITLE_FIELDS_DATA_TYPE
      )?.name || "Title"
    : null;
