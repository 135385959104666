/**
 *
 * @param {string} item
 * @returns {string}
 */
export const formatFilterLabel = (item) => {
  let itemLabel = item?.split("_")?.join(" ")?.toLowerCase();
  let newItem = itemLabel?.charAt(0)?.toUpperCase() + itemLabel?.slice(1);
  return newItem;
};

export const formatTitleCase = (item) => {
  if (!item) return "";

  const splitStr = item.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const formatChartData = (data) => {
  let newArray = [];
  data.map((item) => {
    item["key"] = item["key"]
      .replaceAll("|", "")
      .replaceAll("[", " ")
      .replaceAll("]", " ");
    newArray.push(item);
  });
  return newArray;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatMetadataKeyword = (string) => {
  let arr = string.split(" ");
  let str2 = "";
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    str2 = arr.join(" ");
  }
  return str2;
};
