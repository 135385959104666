import React from "react";
import moment from "moment";

export const USER_LOGS_CONFIG = {
  COLUMNS: ({ sortedInfo }) => [
    displayTimeStamp(sortedInfo),
    displayEmail(sortedInfo),
    {
      title: "Login ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    displayInformation(sortedInfo),
  ],
};

function displayEmail(sortedInfo) {
  return {
    title: "Account",
    dataIndex: "emailid",
    key: "emailid",
    sorter: (a, b) => a.emailid.localeCompare(b.emailid),
    sortOrder: sortedInfo.columnKey === "emailid" && sortedInfo.order,
  };
}

function displayTimeStamp(sortedInfo) {
  return {
    title: "Time",
    dataIndex: "timestamp",
    key: "timestamp",
    render: (date) => `${formatTime(date)}`,
    sorter: (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix(),
    sortOrder: sortedInfo.columnKey === "timestamp" && sortedInfo.order,
    defaultSortOrder: "ascend",
  };
}

function displayInformation(sortedInfo) {
  return {
    title: "Description",
    dataIndex: "content",
    key: "content",
  };
}

const formatTime = (value) => {
  value = value + "Z";
  let date = new Date(value);
  return date.toLocaleDateString("FR-CA") + " at " + date.toLocaleTimeString();
};
