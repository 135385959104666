import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';

import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';

import MainFrame from './Routes/MainFrame';
import LoginPage from './Routes/Login';
import {CommonUtils, getAccessToken} from './utils/commonUtils';
import PrivateRoute from './utils/PrivateRoute';
import {ROUTES} from './Constants/Routes/Routes.constants';
import {ClearFilterFieldData} from './Redux/Actions';
import {SAML_LOGOUT_URL} from './Constants/Login/Login.constants';
import Loading from './Routes/Loading';
import {getCurrentUserDetails} from './api/users';
import {notify} from './Components/Notification';
import SuperAdminLogin from './Routes/SuperAdminLogin';
import {errorMEssageHandler} from 'utils/ErrorHandler';
import { MyMainFrame } from 'Routes/MainFrame/MyMainFrame';

function render(View) {
  return CommonUtils.isLoggedIn() ? <Redirect to={ROUTES.INDEX} /> : View;
}

export default function App() {
  const INACTIVE_TIMEOUT = 2 * 60 * 60 * 1000;
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState('loading');
  const handleOnActive = () => {};
  const handleOnIdle = async () => {
    if (CommonUtils.isLoggedIn()) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(ClearFilterFieldData());
      // await msalInstance.logoutRedirect();
      window.location.href = SAML_LOGOUT_URL;
    }
  };
  const handleUserExists = async () => {
    try {
      const emailId = '';
      const {data} = await getCurrentUserDetails({emailId});
      if (data.result) {
        setLoggedIn('success');
      } else {
        setLoggedIn('fail');
      }
    } catch (error) {
      //401 check
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(ClearFilterFieldData());
        window.location.href = SAML_LOGOUT_URL;
      }, 2000);
      notify.error(errorMEssageHandler(error?.response?.status).description || 'Something went wrong!');
    }
  };
  useEffect(async () => {
    if (env.REACT_APP_ENV !== 'local') {
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
    //check if user exists in db
    if (getAccessToken()) {
      await handleUserExists();
      // dispatch(GetCurrentProfileAction);
    } else {
      setLoggedIn('fail');
    }
  }, []);

  const {reset, pause, resume, getRemainingTime, getLastActiveTime, getElapsedTime} = useIdleTimer({
    timeout: INACTIVE_TIMEOUT,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  return (
    <>
      <ToastContainer limit={5} />
      <Router>
        <Switch>
          <Route path={ROUTES.LOGIN} render={(props) => render(<LoginPage {...props} />)} />
          <Route path={ROUTES.SUPER_ADMIN_LOGIN} component={SuperAdminLogin} componentProps={{appState: this}} />
          {loggedIn === 'loading' ? (
            <Route path={ROUTES.INDEX} component={Loading} componentProps={{appState: this}} />
          ) : loggedIn === 'success' ? (
            // <PrivateRoute path={ROUTES.INDEX} component={MainFrame} componentProps={undefined} />
            <PrivateRoute path={ROUTES.INDEX} component={MyMainFrame} componentProps={undefined} />
          ) : (
            <Redirect to={ROUTES.LOGIN} />
          )}

          <Redirect from='*' to={ROUTES.INDEX} />
        </Switch>
      </Router>
    </>
  );
}
