import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

export async function exportMultipleChartsToPdf({state}) {
  state.setState({exportPdfLoading: true});
  const doc = new jsPDF('p', 'px');
  const elements = document.getElementById('dashboard-block');
  await creatPdf({doc, elements});
  doc.save('charts.pdf');
  state.setState({exportPdfLoading: false});
}

async function creatPdf({doc, elements}) {
  const padding = 10;
  const marginTop = 20;
  let top = marginTop;

  const el = elements;
  const imgData = await htmlToImage.toPng(el);
  let elHeight = el.offsetHeight;
  let elWidth = el.offsetWidth;

  const pageWidth = doc.internal.pageSize.getWidth();

  if (elWidth > pageWidth) {
    const ratio = pageWidth / elWidth;
    elHeight = elHeight * ratio - padding * 2;
    elWidth = elWidth * ratio - padding * 2;
  }
  const pageHeight = doc.internal.pageSize.getHeight();
  if (top + elHeight > pageHeight) {
    doc.addPage();
    top = marginTop;
  }

  doc.addImage(imgData, 'PNG', padding, top, elWidth, elHeight, `image${1}`);
  top += elHeight + marginTop;
}
