import React from 'react';
import style from '../style.module.scss';
import ButtonElement from '../../ButtonElement';
import SaveResults from '../HomeHeader/children/SaveResults';
import LoadResults from '../HomeHeader/children/LoadResults';

function template(props) {
  return (
    <>
      {(this.state.isView || this.state.isEdit || this.props.history.location?.state?.selectedList) && (
        <div className={style.headerControls}>
          <div className={style.controlsElement}>
            <ul className={style.controlsElement__list}>
              {this.props.displayDashboards ? (
                <>
                  <li>
                    <ButtonElement
                      size={'small'}
                      onClick={() => {
                        this.props.handleButtonClick(this.state.isView ? 'Cancel' : 'ClearAll', this.state);
                        this.setState({ isView: false, isEdit: false, selectedList: [] });
                      }}>
                      <span>{this.state.isView ? 'Cancel' : 'Clear All'} </span>
                    </ButtonElement>
                  </li>
                  <li>
                    <div className={style.controlsItem}>
                      <SaveResults
                        isEdit={this.state.isView}
                        title={this.state.isView ? 'Edit dashboard' : 'Save this changes'}
                        handleSave={() => this.props.handleButtonClick(this.state.isView ? 'Edit' : 'Save', this.state)}
                        disabled={this.state.selectedList?.length == 0}
                      />
                    </div>
                  </li>
                  {!this.state.isView && (
                    <li>
                      <div className={style.controlsItem}>
                        <LoadResults
                          icon={'SVG-download'}
                          toolTipTitle={
                            this.state.selectedList?.length < 3
                              ? 'Import insights'
                              : '3 is max allowed insights to import'
                          }
                          handleLoad={(value) =>
                            this.state.selectedList?.length < 3 && this.props.handleButtonClick('Import', this.state)
                          }
                        />
                      </div>
                    </li>
                  )}
                </>
              ) : (
                <li>
                  <div className={style.controlsItem}>
                    <LoadResults
                      toolTipTitle={'Saved dashboards'}
                      handleLoad={() => this.props.handleSavedModal(true)}
                    />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default template;
